import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import GButton from "components/GButton";
import spaceNetProjects from "common/spaceNetProjects";
import SpaceNetImageBox from "./ImageBox";
import { useSet } from "react-use";
import addSpaceNetProjectsToCampaign from "./helpers/addSpaceNetProjectsToCampaign";
import { Campaign } from "datamodel/campaign";
import { queryClient } from "App";

type Props = Omit<ModalProps, "children"> & {
  campaign: Campaign;
};

const SpacenetImageModal = ({ campaign, onClose, isOpen }: Props) => {
  const [ids, { toggle, has, reset }] = useSet<string>();
  const [isLoading, setIsLoading] = useState(false);

  const _onClose = useCallback(() => {
    setIsLoading(false);
    reset();
    onClose();
  }, [reset, onClose]);

  const onClickAdd = useCallback(async () => {
    setIsLoading(true);
    await addSpaceNetProjectsToCampaign(Array.from(ids), campaign);
    setTimeout(() => {
      queryClient.invalidateQueries(["campaign-projects", campaign.id]);
      _onClose();
    }, 2000);
  }, [ids, campaign, _onClose]);

  return (
    <Modal onClose={_onClose} isOpen={isOpen} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxWidth="fit-content">
        <ModalHeader fontSize="16px">Add images from SpaceNet</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack maxWidth="1024px" p={0} flex={1} alignItems="flex-start" spacing={8}>
            <Box maxWidth="600px" color="textLight">
              Our partner <a href="https://spacenet.ai/">SpaceNet</a> delivers free access to
              high-quality geospatial data for developers, researchers, and startups.
            </Box>
            <Box maxWidth="600px" color="textLight">
              Select one or more images
            </Box>
            <Wrap alignItems="stretch" spacing={4}>
              {spaceNetProjects.map((p, idx) => (
                <SpaceNetImageBox
                  spaceNetProject={p}
                  key={idx}
                  onClick={() => {
                    toggle(p.id);
                  }}
                  active={has(p.id)}
                />
              ))}
            </Wrap>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <GButton disabled={!ids.size} onClick={onClickAdd} isLoading={isLoading}>
            Add
          </GButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SpacenetImageModal;
