import React from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import { FaRobot } from "react-icons/fa";

const HitlLimitBanner = ({ message }: { message: string }) => (
  <Box display="flex" bg="#fff8d9" border="1px solid #e6dfc3" mb={4} px={2} py={2}>
    <HStack flex="1">
      <FaRobot />
      <Text fontWeight="500">HITL-enabled account</Text>
      <Text>: {message}</Text>
    </HStack>
  </Box>
);

export default HitlLimitBanner;
