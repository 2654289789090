import { pipe } from "fp-ts/es6/pipeable";
import { fold, map, none, Option } from "fp-ts/Option";
import { findFirst, compact } from "fp-ts/es6/Array";

import { basemaps } from "common/basemaps";
import { LayerConfig } from "types";
import { foldOption, noop } from "lib";
import config from "config";
import {
  Expression,
  Sources,
  Layer,
  Style,
  AnySourceData,
  LinePaint,
  GeoJSONSourceRaw,
} from "mapbox-gl";
import { Task } from "datamodel/task";
import { fromArray } from "fp-ts/es6/NonEmptyArray";
import mask from "lib/geojsonMask";
import { Project } from "datamodel/project";
import { Tile } from "datamodel/tile";

type SourceLayerPair = [Sources, Layer];

// order of the layers from top to bottom:
// 1. labels of the current task
// 2. labels from surrounding tasks
// 3. task boundary
// 4. any number of overlay layers
// 5. the project imagery
// 6. the basemap
const generateMapStyle = (
  projectOption: Option<Project>,
  layerConfigs: LayerConfig[],
  idTokenOption: Option<string>,
  taskOption: Option<Task> = none,
  hiddenLabelClasses: string[] = []
): Option<Style> =>
  pipe(
    projectOption,
    map((project) => {
      let allPairs: SourceLayerPair[] = [];
      const basemapPairs: SourceLayerPair[] = Object.entries(basemaps.layers).map(
        ([key, basemapDef]) => {
          const c = layerConfigs.find((c) => c.id === key);
          const url = basemapDef.url;
          const tiles = ["1", "2", "3"].map((d) => {
            let u = url.replace("{s}", d);
            for (let propKey in basemapDef.properties) {
              // we know the property exists
              u = u.replace(`{${propKey}}`, (basemapDef.properties as any)[propKey] as string);
            }
            return u;
          });

          let additionalSourceProps = {};

          if ("maxNativeZoom" in basemapDef.properties) {
            additionalSourceProps = { maxzoom: basemapDef.properties.maxNativeZoom };
          }

          const sourceData: AnySourceData = {
            type: "raster",
            tiles,
            tileSize: 256,
            scheme: "xyz",
            ...additionalSourceProps,
          };

          const layer: Layer = {
            id: `${key}-layer`,
            type: "raster",
            source: key,
            layout: {
              visibility: c?.enabled ? "visible" : "none",
            },
          };
          return [{ [key]: sourceData }, layer];
        }
      );

      allPairs = [...allPairs, ...basemapPairs];

      foldOption(findFirst((tms: Tile) => tms.default)(project.tileLayers), noop, (tms) => {
        const c = layerConfigs.find((c) => c.id === "projectImagery");
        const source: Sources = {
          projectImagery: {
            type: "raster",
            tiles: [tms.zxyUrl],
            tileSize: 256,
            scheme: "xyz",
          },
        };
        const layer: Layer = {
          id: "projectImageryLayer",
          type: "raster",
          source: "projectImagery",
          paint: {
            "raster-opacity": c?.opacity || 0,
          },
          layout: {
            visibility: c?.enabled ? "visible" : "none",
          },
        };
        allPairs = [...allPairs, [source, layer]];
      });

      const overlayPairs: SourceLayerPair[] = project.tileLayers.reduce(
        (pairs, tileLayer) =>
          pipe(
            layerConfigs,
            findFirst((conf: LayerConfig) => conf.id === tileLayer.id),
            fold(
              () => pairs,
              (c) => {
                const source: Sources = {
                  [`overlay-${c.id}`]: {
                    type: "raster",
                    tiles: [tileLayer.zxyUrl],
                    tileSize: 256,
                    scheme: "xyz" as const,
                  },
                };
                const layer: Layer = {
                  id: `overlay-${c.id}-layer`,
                  type: "raster" as const,
                  source: `overlay-${c.id}`,
                  paint: {
                    "raster-opacity": c.opacity,
                  },
                  layout: {
                    visibility: c.enabled ? "visible" : "none",
                  },
                };
                return [...pairs, [source, layer]];
              }
            )
          ),
        [] as SourceLayerPair[]
      );

      allPairs = [...allPairs, ...overlayPairs];

      pipe(
        idTokenOption,
        fold(noop, (token) => {
          const labelConfig = layerConfigs.find((c) => c.id === "adjacent-tasks");
          const taskConfig = layerConfigs.find((c) => c.id === "task-mask");

          const labelSource: Sources = {
            projectLabels: {
              type: "vector",
              tiles: [`${config.api}/mvt/projects/${project.id}/labels/{z}/{x}/{y}?token=${token}`],
            },
          };
          const taskSource: Sources = {
            projectTasks: {
              type: "vector",
              tiles: [`${config.api}/mvt/projects/${project.id}/tasks/{z}/{x}/{y}?token=${token}`],
            },
          };
          const taskLabelSource: Sources = {
            projectTaskLabels: {
              type: "vector",
              tiles: [`${config.api}/mvt/projects/${project.id}/tasks/{z}/{x}/{y}?token=${token}`],
            },
          };
          const taskMaskSource: Sources = foldOption(
            taskOption,
            () => ({
              taskMask: {
                type: "geojson",
              },
            }),
            (t) => ({
              taskMask: {
                type: "geojson",
                data: mask(t),
              } as GeoJSONSourceRaw,
            })
          );
          const labelBaseLayer: Layer = {
            id: "projectLabelsLayer",
            type: "fill",
            source: "projectLabels",
            "source-layer": "default",
            paint: {
              "fill-color": ["get", "color_hex_code"] as Expression,
              "fill-opacity": labelConfig?.opacity || 0,
            },
            layout: {
              visibility: labelConfig?.enabled ? "visible" : "none",
            },
          };
          const taskBaseLayer: Layer = {
            id: "projectTasksLayer",
            type: "line",
            source: "projectTasks",
            "source-layer": "default",
            layout: {
              visibility: taskConfig?.enabled ? "visible" : "none",
            },
          };
          const tasklabelBaseLayer: Layer = {
            id: "projectTasksLabelLayer",
            type: "symbol",
            source: "projectTaskLabels",
            "source-layer": "default",
            paint: {
              "text-color": "#000000",
              "text-halo-color": "#ffffff",
              "text-halo-width": 2,
              "text-halo-blur": 2,
              "text-opacity": taskConfig ? Math.min(taskConfig.opacity * 2, 1) : 0,
            },
            layout: {
              "text-letter-spacing": 0.1,
              "text-size": 13,
              "text-anchor": "bottom",
              "text-offset": [0, -1],
              "symbol-placement": "line",
              "text-font": ["Open Sans Bold"],
              "text-field": "Current task area",
              // visibility: taskConfig?.enabled ? "visible" : "none",
              visibility: "none",
            },
          };
          const taskMaskLayer: Layer = {
            id: "taskMaskLayer",
            type: "fill",
            source: "taskMask",
            paint: {
              "fill-color": "rgb(0,0,0)",
              "fill-opacity": taskConfig?.opacity || 0,
            },
            layout: {
              visibility: taskConfig?.enabled ? "visible" : "none",
            },
          };

          const labelFilters = pipe(
            [
              pipe(
                taskOption,
                map((task) => ["!=", "task_id", task.id] as Expression)
              ),
              pipe(
                fromArray(hiddenLabelClasses),
                // typings are wrong and !in is a valid operator
                //@ts-expect-error
                map((ids) => ["!in", "label_class_id", ...ids] as Expression)
              ),
            ],
            compact,
            fromArray
          );

          const taskPaint = pipe(
            taskOption,
            map(
              (task) =>
                ({
                  "line-color": [
                    "match",
                    ["get", "id"],
                    task.id,
                    "#ffff99",
                    "#999999",
                  ] as Expression,
                  "line-opacity": taskConfig?.opacity || 0,
                  "line-width": ["match", ["get", "id"], task.id, 2, 2] as Expression,
                  "line-offset": ["match", ["get", "id"], task.id, -1, 0] as Expression,
                } as LinePaint)
            )
          );
          const taskLabelFilter = pipe(
            taskOption,
            map((task) => ["==", "id", task.id])
          );

          const labelLayer = foldOption(
            labelFilters,
            () => labelBaseLayer,
            (filters) => ({
              ...labelBaseLayer,
              filter: filters.length === 1 ? filters[0] : ["all", ...filters],
            })
          );

          const taskLayer = foldOption(
            taskPaint,
            () => taskBaseLayer,
            (paint) => ({
              ...taskBaseLayer,
              paint,
            })
          );

          const taskLabelLayer = foldOption(
            taskLabelFilter,
            () => tasklabelBaseLayer,
            (filter) => ({ ...tasklabelBaseLayer, filter })
          );

          allPairs = [
            ...allPairs,
            [taskMaskSource, taskMaskLayer],
            [labelSource, labelLayer],
            [taskSource, taskLayer],
            [taskLabelSource, taskLabelLayer],
          ];
        })
      );

      const style = {
        version: 8,
        glyphs: "/app/glyphs/{fontstack}/{range}.pbf",
        sources: allPairs.map((p) => p[0]).reduce((acc, s) => ({ ...acc, ...s })),
        layers: allPairs.map((p) => p[1]).reduce((acc, s) => [...acc, s], [] as Layer[]),
      };

      return style;

      // const baseStyle = allPairs.reduce(
      //   ([]) => {
      //     const sources = {
      //       ...obj,
      //       sources: {
      //         ...obj.sources,
      //         [`${item.label}Source`]: item.source,
      //       },
      //     };
      //     return foldOption(
      //       findFirst((conf: LayerConfig) => conf.id === item.label && conf.enabled)(layerConfigs),
      //       () => ({
      //         ...sources,
      //         layers: obj.layers,
      //       }),
      //       (config) => ({
      //         ...sources,
      //         layers: [
      //           ...obj.layers,
      //           {
      //             ...item.layer,
      //             paint: {
      //               "raster-opacity": config.opacity,
      //             },
      //           },
      //         ],
      //       })
      //     );
      //   },
      //   {
      //
      //   } as Style
      // );
    })
  );
export default generateMapStyle;
