import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";
import { ApplicationStore } from "types";

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const [isAuthenticated, isLoading] = useSelector((state: ApplicationStore) => [
    state.newAuth.isAuthenticated,
    state.newAuth.isLoading,
  ]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isLoading ? (
          <></>
        ) : isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
