import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  ModalFooter,
  Divider,
  VStack,
  Box,
  Checkbox,
  Text,
  Icon,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { FaLock } from "react-icons/fa";

import GButton from "components/GButton";
import { ExportAssetTypes } from "datamodel/export";
import UpgradeModal from "components/UpgradeModal";

type Props = Omit<ModalProps, "children"> & {
  isProUser: boolean;
  onExport: (assets: ExportAssetTypes[]) => void;
};

const checkgroupInit = {
  [ExportAssetTypes.SignedUrl]: false,
  [ExportAssetTypes.Cog]: false,
};

const ExportCreateModalModal = ({ isOpen, onClose, isProUser, onExport }: Props) => {
  const [checkgroup, setCheckgroup] = useState<Record<ExportAssetTypes, boolean>>(checkgroupInit);

  const { isOpen: isUpgradeOpen, onClose: onUpgradeClose, onOpen: onUpgradeOpen } = useDisclosure();

  const onChangeCheckbox = (asset: ExportAssetTypes) => {
    setCheckgroup({ ...checkgroup, [asset]: !checkgroup[asset] });
  };

  const onClickExport = () => {
    let assets = [];
    if (checkgroup[ExportAssetTypes.SignedUrl]) assets.push(ExportAssetTypes.SignedUrl);
    if (checkgroup[ExportAssetTypes.Cog]) assets.push(ExportAssetTypes.Cog);
    setCheckgroup(checkgroupInit);
    onExport(assets);
    onClose();
  };

  const onClickUpgrade = () => {
    // clean modal state
    setCheckgroup(checkgroupInit);
    // close the export modal
    onClose();
    // open upgrade modal
    onUpgradeOpen();
  };

  const onClickClose = () => {
    setCheckgroup(checkgroupInit);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClickClose}>
        <ModalOverlay />
        <ModalContent maxWidth="600px">
          <ModalHeader fontSize="16px">Image options</ModalHeader>
          <ModalCloseButton />
          <Divider color="gray400" />
          <ModalBody my={6}>
            <VStack spacing={5} alignItems="flex-start">
              <VStack alignItems="flex-start" spacing={1}>
                <Checkbox
                  size="lg"
                  spacing={5}
                  colorScheme="orange"
                  borderColor="gray400"
                  isChecked={true}
                >
                  <Text fontSize="14px" fontWeight="bold">
                    Tile layer (always included)
                  </Text>
                </Checkbox>
                <Box pl="25px">
                  <Text>Images will be represented with a xyz tile URL</Text>
                </Box>
              </VStack>
              <VStack alignItems="flex-start" spacing={1}>
                <Checkbox
                  size="lg"
                  spacing={5}
                  colorScheme="orange"
                  borderColor="gray400"
                  isChecked={checkgroup[ExportAssetTypes.SignedUrl]}
                  onChange={() => onChangeCheckbox(ExportAssetTypes.SignedUrl)}
                >
                  <Text fontSize="14px" fontWeight="bold">
                    Download URLs
                  </Text>
                </Checkbox>
                <Box pl="25px">
                  <Text>
                    Original images will be available to download for 7 days in an S3 bucket
                  </Text>
                </Box>
              </VStack>
              <VStack alignItems="flex-start" spacing={1}>
                <Checkbox
                  size="lg"
                  spacing={5}
                  colorScheme="orange"
                  borderColor="gray400"
                  isDisabled={!isProUser}
                  isChecked={checkgroup[ExportAssetTypes.Cog]}
                  onChange={() => onChangeCheckbox(ExportAssetTypes.Cog)}
                >
                  <Text fontSize="14px" fontWeight="bold">
                    Export images
                  </Text>
                </Checkbox>
                <VStack pl="25px" alignItems="flex-start">
                  <Text {...(!isProUser ? { color: "gray500" } : {})}>
                    Original image files will be exported in your STAC catalog
                  </Text>
                  {!isProUser && (
                    <Button colorScheme="orange" borderRadius="500px" onClick={onClickUpgrade}>
                      <Icon as={FaLock} mr={2} h="4" />
                      <Text fontSize="12px">GroundWork Pro</Text>
                    </Button>
                  )}
                </VStack>
              </VStack>
            </VStack>
          </ModalBody>
          <Divider color="gray400" />
          <ModalFooter alignItems="flex-start" flexDirection="column">
            <GButton colorScheme="primary" onClick={onClickExport}>
              Export
            </GButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <UpgradeModal isOpen={isUpgradeOpen} onClose={onUpgradeClose} />
    </>
  );
};

export default ExportCreateModalModal;
