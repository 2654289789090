import React from "react";
import { Tooltip, Box, Swatch, Text } from "@blasterjs/core";
import { CampaignType } from "datamodel/campaign";
import collapseLabelClassGroups from "./helpers/collapseLabelClassGroups";
import styled from "styled-components";
import { Campaign } from "datamodel/campaign";

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    pointer-events: none;
  }
`;

const ClassificationTooltip = ({ campaign }: { campaign: Campaign }) => (
  <StyledTooltip
    pt={2}
    pr={2}
    pb={1}
    pl={2}
    bg="gray100"
    hasArrow
    fontSize={2}
    textAlign="left"
    delay={{
      show: 500,
      hide: 0,
    }}
    className="project-classification-tooltip"
  >
    {campaign?.labelClassGroups.length ? (
      campaign.campaignType === CampaignType.Classification ? (
        campaign.labelClassGroups.map((group, idx) => (
          <Box display="block" mb="4px" key={idx}>
            <Text display="block">{group.name}</Text>
          </Box>
        ))
      ) : (
        collapseLabelClassGroups(campaign.labelClassGroups).map((label, idx) => (
          <Box display="flex" alignItems="center" mb="4px" key={idx}>
            <Swatch color={label.colorHexCode} mr={1} />
            {label.name}
          </Box>
        ))
      )
    ) : (
      <Text display="block">No class definition yet</Text>
    )}
  </StyledTooltip>
);

export default ClassificationTooltip;
