import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextArea, Label } from "@blasterjs/core";
import { useForm } from "react-hook-form";

import { noop, foldOption, seqOption } from "lib";
import { TaskUIMode, ApplicationStore, TaskURLActionType } from "types";
import { createSetMode } from "state/ui-segmentation/actions";
import flagTask from "../helpers/flagTask";
import initializeAnnotations from "pages/SegmentationTask/helpers/initializeAnnotations";
import { Box, Heading, Stack } from "@chakra-ui/react";
import GButton from "components/GButton";
import { pipe } from "fp-ts/es6/function";
import { map } from "fp-ts/es6/Option";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";
import useReactRouter from "use-react-router";

// This type ensures we can't compile without a valid set of reasons -- there
// must be an OTHER key
type Reasons = {
  [r: string]: string;
  OTHER: "Other";
};

const REASONS: Reasons = {
  CLOUDY: "Obstructed by clouds",
  OBSTRUCTED: "Otherwise obstructed",
  DATA: "Imagery corrupted or missing",
  OTHER: "Other",
};

type FlagFormData = {
  reason: string;
  note: string;
};
const Flagging = () => {
  const dispatch = useDispatch();

  const hitlEnabled = useUserFeatureFlag("hitl");

  const {
    match: {
      params: { action },
    },
  } = useReactRouter<{ projectId: string; taskId: string; action: TaskURLActionType }>();

  const { register, handleSubmit, formState, watch, getValues, setValue, trigger } =
    useForm<FlagFormData>({
      mode: "onChange",
    });

  const [campaignOption, projectOption, labelClassGroupsOpt, taskOption, displayBuffer] =
    useSelector(
      (state: ApplicationStore) =>
        [
          state.segmentationUI.campaign,
          state.segmentationUI.project,
          state.segmentationUI.labelClassGroups,
          state.segmentationUI.task,
          state.segmentationUI.displayBuffer,
        ] as const
    );

  useEffect(() => {
    register({ name: "note" });
  }, [register]);

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue("note", e.target.value);
    trigger();
  };

  const onClickCancel = () => {
    dispatch(createSetMode(TaskUIMode.Labeling));
  };

  const onSubmit = ({ reason, note }: FlagFormData) => {
    const safeReason = reason === REASONS.OTHER ? note : reason;

    foldOption(
      seqOption(projectOption, campaignOption, taskOption),
      noop,
      ([project, campaign, task]) => {
        dispatch(createSetMode(TaskUIMode.Loading));
        flagTask(task, safeReason, displayBuffer, dispatch).then(() => {
          pipe(
            labelClassGroupsOpt,
            map((labelClassGroups) => {
              initializeAnnotations(
                campaign,
                project,
                task,
                labelClassGroups,
                action,
                hitlEnabled,
                dispatch
              );
            })
          );
          dispatch(createSetMode(TaskUIMode.Flagged));
        });
      }
    );
  };

  return (
    <Box flex="1" alignSelf="flexStart" m={4}>
      <Heading as="h5" fontSize="18px" mt={6}>
        Flag this task
      </Heading>
      <Box mt={8}>What's wrong with this task?</Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack mt={8} spacing={4}>
          {Object.entries(REASONS).map(([k, v], idx) => (
            <Box key={idx}>
              <input
                type="radio"
                id={k}
                name="reason"
                value={v}
                ref={register({
                  // If "Other" is selected, we validate by looking up the note
                  validate: (v) => (v === REASONS.OTHER ? !!getValues()?.note : !!v),
                })}
              />
              <Label ml={1} htmlFor={k} flex="1">
                {v}
              </Label>
            </Box>
          ))}
        </Stack>
        {watch("reason") === REASONS.OTHER && (
          <Box display="flex" alignItems="center" px={1} mb={1}>
            <TextArea id="note" name="note" width="100%" onChange={handleNoteChange} autoFocus />
          </Box>
        )}
        <Stack mt={8} spacing={4}>
          <GButton
            type="submit"
            data-intercom-target="flag-submit"
            colorScheme="primary"
            width="100%"
            disabled={!formState.isValid}
          >
            Finish flagging
          </GButton>
          <GButton
            type="button"
            data-intercom-target="cancel"
            colorScheme="#fff"
            width="100%"
            onClick={onClickCancel}
          >
            Cancel
          </GButton>
        </Stack>
      </form>
    </Box>
  );
};

export default Flagging;
