import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSetMode } from "state/ui-segmentation/actions";
import { TaskUIMode, ApplicationStore, TaskURLActionType } from "types";
import { noop, foldOption, seqOption } from "lib";
import useRouter from "use-react-router";
import pushRandomTask from "pages/SegmentationTask/helpers/pushRandomTask";
import { Stack, VStack } from "@chakra-ui/react";
import GButton from "components/GButton";
import { FaLock } from "react-icons/fa";
import MessageBox from "../components/MessageBox";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";

const Locked = () => {
  const dispatch = useDispatch();

  const { location } = useRouter();

  const [campaignOption, projectOption, urlActionType] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.campaign,
        state.segmentationUI.project,
        state.segmentationUI.urlActionType,
      ] as const
  );

  const message =
    urlActionType === TaskURLActionType.Label
      ? "This task is currently being labeled by another user"
      : "This task is currently being validated by another user";

  const buttonText =
    urlActionType === TaskURLActionType.Label
      ? "Label a different task"
      : "Validate a different task";

  const hitlEnabled = useUserFeatureFlag("hitl");

  const onSkipClick = () => {
    dispatch(createSetMode(TaskUIMode.Loading));
    foldOption(seqOption(projectOption, campaignOption), noop, ([project, campaign]) => {
      pushRandomTask(location.search, urlActionType, hitlEnabled, project, campaign, dispatch, {
        projectId: project.id,
      });
    });
  };

  return (
    <VStack spacing={8} flex="1" m={4}>
      <MessageBox icon={FaLock} iconColor="orange.500" title="Task locked" message={message} />
      <Stack spacing={4} alignSelf="stretch">
        <GButton colorScheme="primary" width="100%" onClick={onSkipClick}>
          {buttonText}
        </GButton>
      </Stack>
    </VStack>
  );
};

export default Locked;
