import React from "react";
import { Box, Text } from "@blasterjs/core";
import { Image } from "@chakra-ui/react";
import styled from "styled-components";
import { IntercomAPI } from "react-intercom";
import FlexFiller from "./FlexFiller";

const ITEM_MARGIN_RIGHT = 2;

const Untouchable = styled(Box)`
  pointer-events: none;
`;

const FooterA = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:visited,
  &:active,
  &:link {
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const FooterButton = styled.button`
  padding: 0;
  background: none;
  border: none;
  display: block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  &:visited,
  &:active,
  &:link {
    text-decoration: none;
    color: inherit;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const LeftFooterItem: React.FC = (props) => (
  <Box fontWeight="normal" mr={ITEM_MARGIN_RIGHT}>
    {props.children}
  </Box>
);

const RightFooterItem: React.FC = (props) => (
  <Box mr={ITEM_MARGIN_RIGHT} display="inline-flex" align-items="center">
    {props.children}
  </Box>
);

const onContactUsClick = () => {
  IntercomAPI("showNewMessage", "");
  IntercomAPI("trackEvent", "upgrade-form-accessed");
};

const AppFooter = () => (
  <Box
    borderTop="1px solid"
    borderColor="#dddddd"
    mx={2}
    px={1}
    height="65px"
    position="relative"
    display="flex"
    alignItems="center"
  >
    <Untouchable
      position="absolute"
      top="0"
      right="0"
      bottom="0"
      left="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Image
        alt="logo"
        title="Logo"
        height="36px"
        src={`${process.env.PUBLIC_URL}/groundwork_mark.svg`}
      />
    </Untouchable>
    <Box width="100%" display="flex" alignItems="center" color="textLight" fontSize="1.5rem">
      <LeftFooterItem>
        <FooterA href="https://element84.com/groundwork">Home</FooterA>
      </LeftFooterItem>
      <LeftFooterItem>
        <FooterA href="https://element84.com/groundwork/pricing">Pricing</FooterA>
      </LeftFooterItem>
      <LeftFooterItem>
        <FooterButton onClick={() => onContactUsClick()}>Contact Us</FooterButton>
      </LeftFooterItem>
      <LeftFooterItem>
        <FooterA href="https://azavea.com/terms-of-use/" target="_blank">
          Terms
        </FooterA>
      </LeftFooterItem>
      <LeftFooterItem>
        <FooterA href="https://azavea.com/privacy-policy/" target="_blank">
          Privacy
        </FooterA>
      </LeftFooterItem>
      <FlexFiller />
      <RightFooterItem>
        <FooterA href="https://element84.com">
          <Text>Made by</Text>
          <Image
            src={`${process.env.PUBLIC_URL}/element84-logo.png`}
            alt="E84 logo"
            height="1.8em"
            mx="0.4em"
            display="inline-block"
          />
        </FooterA>
      </RightFooterItem>
    </Box>
  </Box>
);

export default AppFooter;
