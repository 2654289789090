import { none, some } from "fp-ts/es6/Option";

import { getCampaign } from "http/campaign";
import { postProject } from "http/project";

import { putUpload } from "http/upload";
import { UploadSequence } from "../types";
import { foldOption, noop } from "lib";
import { ProjectStatus } from "datamodel/project";

const createProjectForUploadSequence = async (sequence: UploadSequence) => {
  foldOption(sequence.upload, noop, async (upload) => {
    const name = sequence.file.name.replace(/\.[^/.]+$/, "");
    const { data: campaign } = await getCampaign(sequence.campaignId);
    const { data: project } = await postProject({
      name,
      taskSizePixels: 512,
      aoi: none,
      campaignId: campaign.id,
      status: ProjectStatus.Waiting,
      tileLayers: [],
    });
    return await putUpload({
      ...upload,
      projectId: some(project.id),
    });
  });
};

export default createProjectForUploadSequence;
