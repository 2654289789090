import { AxiosResponse } from "axios";
import { Option, some, none } from "fp-ts/es6/Option";
import { TypeC } from "io-ts";
import { decodeT } from "./base";

export const decoder =
  <T extends GeoJSON.Feature>(c: TypeC<any>) =>
  (resp: AxiosResponse<any>): AxiosResponse<T> => ({
    ...resp,
    data: {
      ...resp.data,
      properties: decodeT<T>(c)(resp.data.properties),
    },
  });

export const decoderO =
  <T extends GeoJSON.Feature>(c: TypeC<any>) =>
  (resp: AxiosResponse<any>): AxiosResponse<Option<T>> => {
    return {
      ...resp,
      data: resp.data
        ? some({
            ...resp.data,
            properties: decodeT<T>(c)(resp.data.properties),
          })
        : none,
    };
  };

export const decoderC =
  <T extends GeoJSON.Feature, C extends GeoJSON.FeatureCollection & { features: T[] }>(
    c: TypeC<any>
  ) =>
  (resp: AxiosResponse<any>): AxiosResponse<C> => ({
    ...resp,
    data: {
      ...resp.data,
      features: resp.data.features.map((f: any) => ({
        ...f,
        properties: decodeT<T>(c)(f.properties),
      })),
    },
  });

export const encoder =
  <T extends GeoJSON.Feature>(c: TypeC<any>) =>
  (data: T) => ({
    ...data,
    properties: c.encode(data.properties as { [x: string]: any }),
  });

export const encoderC =
  <T extends GeoJSON.Feature, C extends GeoJSON.FeatureCollection & { features: T[] }>(
    c: TypeC<any>
  ) =>
  (data: C) => ({
    ...data,
    features: data.features.map(encoder<T>(c)),
  });
