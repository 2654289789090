import { Option } from "fp-ts/es6/Option";
import * as t from "io-ts";
import { Task, TaskActionWithRelated } from "datamodel/task";
import { OrderedSet } from "immutable";
import DrawingAction from "common/modules/DrawingAction";
import { Project } from "datamodel/project";
import { LabelCollection } from "datamodel/label";
import { NewAuthState } from "state/newAuth/reducer";
import { Campaign } from "datamodel/campaign";
import { LabelGroup } from "datamodel/labelGroup";
import { LabelClass } from "datamodel/labeClass";

// Application Util Types
export type PaginatedQueryParams = {
  pageSize?: number;
  page?: number;
};

export type Paginated = {
  count: number;
  hasPrevious: boolean;
  hasNext: boolean;
  page: number;
  pageSize: number;
};

export type PaginatedResponse<T> = Paginated & {
  results: T[];
};

export class EnumType<A> extends t.Type<A> {
  public readonly _tag: "EnumType" = "EnumType";
  public enumObject!: object;
  public constructor(e: object, name?: string) {
    super(
      name || "enum",
      (u): u is A => Object.values(this.enumObject).some((v) => v === u),
      (u, c) => (this.is(u) ? t.success(u) : t.failure(u, c)),
      t.identity
    );
    this.enumObject = e;
  }
}

// simple helper function
export function createEnumType<T>(e: object, name?: string) {
  return new EnumType<T>(e, name);
}

export const geoJSONPolygonCodec = t.type({
  type: t.literal("Polygon"),
  coordinates: t.array(t.array(t.tuple([t.number, t.number]))),
});

export const geoJSONMultiPolygonCodec = t.type({
  type: t.literal("MultiPolygon"),
  coordinates: t.array(t.array(t.array(t.tuple([t.number, t.number])))),
});

// Drawing
export enum DrawingActionType {
  DrawAbove = "DRAW_ABOVE",
  DrawBelow = "DRAW_BELOW",
  Erase = "ERASE",
  Delete = "DELETE",
  Reclassify = "RECLASSIFY",
  NoOp = "NOOP",
  MagicWand = "MAGIC_WAND",
  Clear = "CLEAR",
}

export interface SemanticLabelProperties {
  label: string;
  color: string;
}

export type SemanticLabelGeometry = GeoJSON.MultiPolygon;

export type SemanticLabelFeature = GeoJSON.Feature<
  SemanticLabelGeometry,
  SemanticLabelProperties
> & {
  id: string;
};

export type SemanticLabelFeatureCollection = GeoJSON.FeatureCollection<
  SemanticLabelGeometry,
  SemanticLabelProperties
> & {
  features: SemanticLabelFeature[];
};

export type CampaignListState = {
  count: Option<number>;
  hasPrevious: Option<boolean>;
  hasNext: Option<boolean>;
  pageSize: number;
  campaigns: Option<Campaign[]>;
  dirty: boolean;
};

export interface PredictionState {
  used: number;
  limit: number;
}

export interface LimitsState {
  dirty: boolean;
  predictionO: Option<PredictionState>;
}

export enum TaskURLActionType {
  Label = "label",
  Validate = "validate",
}

export interface Error {
  message: string;
  stack?: string;
}

export interface TimeMachine<T> {
  past: Array<T>;
  future: Array<T>;
}

export interface TaskUIState {
  campaign: Option<Campaign>;
  project: Option<Project>;
  task: Option<Task>;
  hasTaskLock: boolean;
  urlActionType: TaskURLActionType;
  existingAnnotations: Option<LabelCollection>;
  adjacentTaskAnnotations: Option<SemanticLabelFeatureCollection>;
  lastLabelingActions: TaskActionWithRelated[];
  lastValidatingActions: TaskActionWithRelated[];
  lastError: Option<string>;
  mode: TaskUIMode;
  labelClassGroups: Option<LabelGroup[]>;
}

export enum TaskUIMode {
  Error = "Error",
  Labeling = "Labeling",
  LabelingCompleteByUser = "LabelingCompleteByUser",
  LabelingNotPermitted = "LabelingNotPermitted",
  Loading = "Loading",
  Locked = "Locked",
  ProjectComplete = "ProjectComplete",
  Validating = "Validating",
  ValidatingCompleteByUser = "ValidatingCompleteByUser",
  ValidatingNotPermitted = "ValidatingNotPermitted",
  Flagged = "Flagged",
  ConfirmSplitTask = "ConfirmSplitTask",
  Rejecting = "Rejecting",
  Flagging = "Flagging",
}

export interface TaskQueueState {
  [key: string]: Map<String, OrderedSet<string>>;
}

interface SegmentationSnapshot {
  actionBuffer: DrawingAction[];
}

export interface LayerConfig {
  id: string;
  label: string;
  opacity: number;
  enabled: boolean;
}

interface LayerPickerState {
  imageryLayersConfig: LayerConfig[];
  basemapLayersConfig: LayerConfig[];
  vectorLayersConfig: LayerConfig[];
  overlayLayersConfig: LayerConfig[];
}

export type SegmentationUIState = TaskUIState &
  SegmentationSnapshot &
  TimeMachine<SegmentationSnapshot> & {
    actionBufferCache: DrawingAction[];
    selectedClass: Option<LabelClass>;
    isDrawingActive: boolean; // is the user currently drawing
    activeDrawType: Option<DrawingActionType>; // the currently selected type of drawing -- can be unselected
    urlActionType: TaskURLActionType;
    lastError: Option<string>;
    displayBuffer: SemanticLabelFeatureCollection;
    drawingInProgress: boolean;
    taskQueue: Task[];
    taskQueueUpdatedAt: number;
    taskQueueAction: Option<TaskURLActionType>;
    taskQueueParamsString: string;
    isMouseInMap: boolean;
    labelObjectCreate: Option<LabelObjectCreate>;
    isConfirmDisabled: boolean;
    magicWandTolerance: number;
    hiddenClassIds: string[];
    isAutosavingInProgress: boolean;
    lastActionAtOption: Option<number>;
    collapsedGroupIds: string[];
  } & LayerPickerState;

export interface ApplicationStore {
  segmentationUI: SegmentationUIState;
  campaignListUI: CampaignListState;
  newAuth: NewAuthState;
  taskQueue: TaskQueueState;
  limits: LimitsState;
}

export enum LayerPickerSection {
  Imagery = "IMAGERY",
  Basemap = "BASEMAP",
  Vector = "VECTOR",
  Overlay = "OVERLAY",
}

export enum TaskGeoJsonLayerType {
  Adjacent = "ADJACENT",
  Current = "CURRENT",
  Mask = "MASK",
}

export interface DeckGLClickInfo {
  object: {
    geometry: GeoJSON.MultiPolygon;
    id: string | undefined;
    properties: {
      label: string;
      color: string;
    };
    type: string;
  };
  coordinate: number[];
  index: number; // the object index in the layer
}

export enum ImagerySelectionTabs {
  Upload = "upload",
  Remote = "remote",
  SpaceNet = "spacenet",
}

export interface MvtLabelProps {
  task_id: string;
  color_hex_code: string;
  label_class_id: string;
  name: string;
}

export type MapContextMenuEvent = {
  pick: DeckPick | Option<MvtLabelProps>;
  mouseEvent: MouseEvent;
  mapElement: HTMLElement;
  t: number;
};

export type DeckPick = {
  object: SemanticLabelFeature;
  coordinate: number[];
};

export interface LabelObjectCreate {
  [id: string]: string;
}

export enum StatusFilter {
  All = "all",
  LabelingIncomplete = "labeling-incomplete",
  Flagged = "flagged",
  ValidationIncomplete = "validation-incomplete",
}

export const statusFilterCodec = createEnumType<StatusFilter>(StatusFilter, "StatusFilter");
