import * as t from "io-ts";
import { labelClassCodec, labelClassCreateCodec, labelClassSummaryCodec } from "./labeClass";

export const labelGroupCreateCodec = t.type({
  name: t.string,
  index: t.number,
  classes: t.array(labelClassCreateCodec),
  isActive: t.boolean,
});

export const labelGroupCodec = t.type({
  id: t.string,
  name: t.string,
  index: t.number,
  classes: t.array(labelClassCodec),
  isActive: t.boolean,
});

export const labelGroupSummaryCodec = t.type({
  groupId: t.string,
  groupName: t.string,
  classSummaries: t.array(labelClassSummaryCodec),
});

export type LabelGroup = t.TypeOf<typeof labelGroupCodec>;

export type LabelGroupCreate = t.TypeOf<typeof labelGroupCreateCodec>;

export type LabelGroupSummary = t.TypeOf<typeof labelGroupSummaryCodec>;
