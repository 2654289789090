import React, { useEffect, useState } from "react";
import { Button, Swatch, Icon } from "@blasterjs/core";
import { useSelector, useDispatch } from "react-redux";
import { Option, some, none, exists, map } from "fp-ts/es6/Option";

import { foldOption, noop, noEl } from "lib";
import { DrawingActionType, ApplicationStore } from "types";
import {
  createSetSelectedClass,
  createSetActionBuffer,
  createSetIsConfirmDisabled,
  createSetHiddenClassIds,
  createSetCollapsedGroupIds,
} from "state/ui-segmentation/actions";
import { StyledLabelSelect } from "../styled";
import { createDrawingAction } from "../../helpers";
import { DrawingActionSourceFeature } from "common/modules/DrawingAction";
import { LabelClass } from "datamodel/labeClass";
import collapseLabelClassGroups from "pages/Campaigns/CampaignCard/helpers/collapseLabelClassGroups";
import initializeLabelClassSelection from "../helpers/initializeLabelClassSelection";
import FlexFiller from "components/FlexFiller";
import { pipe } from "fp-ts/es6/pipeable";
import { UserFeatureFlag } from "components/FeatureFlags";
import bboxPolygon from "@turf/bbox-polygon";
import bbox from "@turf/bbox";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ExpandedIndex,
  Stack,
} from "@chakra-ui/react";

const Sidebar = () => {
  const dispatch = useDispatch();
  const [hoveredLabelOption, setHoveredLabelOption] = useState<Option<LabelClass>>(none);

  const [
    labelClassGroupsOpt,
    taskOption,
    activeLabelClassOption,
    actionBufferCache,
    hiddenClassIds,
    collapsedGroupIds,
  ] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.labelClassGroups,
        state.segmentationUI.task,
        state.segmentationUI.selectedClass,
        state.segmentationUI.actionBufferCache,
        state.segmentationUI.hiddenClassIds,
        state.segmentationUI.collapsedGroupIds,
      ] as const
  );

  const isLabelActive = (label: LabelClass): boolean =>
    pipe(
      activeLabelClassOption,
      exists((activeLabelClass) => label.id === activeLabelClass.id)
    );

  const isLabelHovered = (label: LabelClass): boolean =>
    pipe(
      hoveredLabelOption,
      exists((h) => h.id === label.id)
    );

  const isLabelHidden = (label: LabelClass): boolean => hiddenClassIds.includes(label.id);

  const onClickLabelSelect = (labelClass: LabelClass) => {
    dispatch(createSetHiddenClassIds(hiddenClassIds.filter((id) => id !== labelClass.id)));
    dispatch(createSetSelectedClass(some(labelClass)));
  };

  const onPaintBucketClick = (labelClass: LabelClass) => {
    foldOption(taskOption, noop, (task) => {
      const bboxGeojson = bboxPolygon(bbox(task));
      if (bboxGeojson) {
        const geojson = {
          ...bboxGeojson,
          properties: {},
          geometry: { type: "MultiPolygon", coordinates: [task.geometry.coordinates] },
        } as DrawingActionSourceFeature;
        dispatch(
          createSetActionBuffer([
            ...actionBufferCache,
            createDrawingAction(labelClass, geojson, DrawingActionType.DrawBelow),
          ])
        );
      }
    });
  };

  const onClickLabelVisibilityToggle = (
    labelClass: LabelClass,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    const next = isLabelHidden(labelClass)
      ? hiddenClassIds.filter((id) => id !== labelClass.id)
      : [...hiddenClassIds, labelClass.id];
    dispatch(createSetHiddenClassIds(next));
  };

  useEffect(() => {
    initializeLabelClassSelection(labelClassGroupsOpt, dispatch);
    dispatch(createSetIsConfirmDisabled(false));
  }, [labelClassGroupsOpt, dispatch]);

  const labelClassesOption = pipe(
    labelClassGroupsOpt,
    map((labelClassGroups) => collapseLabelClassGroups(labelClassGroups))
  );

  const onChangeCollapsed = (indices: ExpandedIndex) => {
    if (Array.isArray(indices)) {
      foldOption(labelClassGroupsOpt, noop, (labelClassGroups) => {
        dispatch(
          createSetCollapsedGroupIds(
            labelClassGroups.filter((_, idx) => !indices.includes(idx)).map((g) => g.id)
          )
        );
      });
    } else {
    }
  };

  return (
    <>
      {foldOption(labelClassGroupsOpt, noEl, (labelClassGroups) =>
        labelClassGroups.length > 1 ? (
          <Accordion
            allowMultiple
            defaultIndex={labelClassGroups.reduce(
              (acc, g, idx) => (collapsedGroupIds.includes(g.id) ? acc : [...acc, idx]),
              [] as number[]
            )}
            onChange={onChangeCollapsed}
            reduceMotion
          >
            <Stack spacing={4}>
              {labelClassGroups
                .sort((a, b) => (a.index > b.index ? 1 : -1))
                .map((group, index) => (
                  <AccordionItem key={index}>
                    <AccordionButton fontWeight="bold" color="textLight" px={0}>
                      <AccordionIcon fontSize="18px" mx={5} />
                      <Box flex="1" fontSize="14px" align="left">
                        {group.name}
                      </Box>
                    </AccordionButton>
                    <AccordionPanel p={0} m={0}>
                      {group.classes
                        .sort((a, b) => (a.index > b.index ? 1 : -1))
                        .map((label, idx) => (
                          <StyledLabelSelect
                            data-intercom-target={`label-${idx}`}
                            isActive={isLabelActive(label)}
                            isHidden={isLabelHidden(label)}
                            key={idx}
                            onClick={() => onClickLabelSelect(label)}
                            onMouseEnter={() => setHoveredLabelOption(some(label))}
                            onMouseLeave={() => setHoveredLabelOption(none)}
                          >
                            <Swatch color={label.colorHexCode} mr={2} size="13px" /> {label.name}
                            <FlexFiller />
                            <UserFeatureFlag featureName="hideLabelClasses">
                              {!isLabelActive(label) && (
                                <Button
                                  data-intercom-target="hide-class"
                                  appearance="minimal"
                                  m="-5px"
                                  mr={1}
                                  p="5px"
                                  borderRadius="100px"
                                  onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                    onClickLabelVisibilityToggle(label, e)
                                  }
                                  opacity={isLabelHidden(label) || isLabelHovered(label) ? 1 : 0}
                                >
                                  {isLabelHidden(label) ? (
                                    <Icon name="eyeOff" />
                                  ) : (
                                    <Icon name="eye" />
                                  )}
                                </Button>
                              )}
                            </UserFeatureFlag>
                            <Button
                              data-intercom-target="paint-bucket"
                              appearance="minimal"
                              m="-5px"
                              p="5px"
                              borderRadius="100px"
                              onClick={() => onPaintBucketClick(label)}
                              opacity={isLabelHovered(label) ? 1 : 0}
                            >
                              <Icon name="paintBucket"></Icon>
                            </Button>
                          </StyledLabelSelect>
                        ))}
                    </AccordionPanel>
                  </AccordionItem>
                ))}
            </Stack>
          </Accordion>
        ) : (
          foldOption(labelClassesOption, noEl, (labelClasses) =>
            labelClasses
              .sort((a, b) => (a.index > b.index ? 1 : -1))
              .map((label, idx) => (
                <StyledLabelSelect
                  data-intercom-target={`label-${idx}`}
                  isActive={isLabelActive(label)}
                  isHidden={isLabelHidden(label)}
                  key={idx}
                  onClick={() => onClickLabelSelect(label)}
                  onMouseEnter={() => setHoveredLabelOption(some(label))}
                  onMouseLeave={() => setHoveredLabelOption(none)}
                >
                  <Swatch color={label.colorHexCode} mr={2} size="13px" /> {label.name}
                  <FlexFiller />
                  <UserFeatureFlag featureName="hideLabelClasses">
                    {!isLabelActive(label) && (
                      <Button
                        data-intercom-target="hide-class"
                        appearance="minimal"
                        m="-5px"
                        mr={1}
                        p="5px"
                        borderRadius="100px"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                          onClickLabelVisibilityToggle(label, e)
                        }
                        opacity={isLabelHidden(label) || isLabelHovered(label) ? 1 : 0}
                      >
                        {isLabelHidden(label) ? <Icon name="eyeOff" /> : <Icon name="eye" />}
                      </Button>
                    )}
                  </UserFeatureFlag>
                  <Button
                    data-intercom-target="paint-bucket"
                    appearance="minimal"
                    m="-5px"
                    p="5px"
                    borderRadius="100px"
                    onClick={() => onPaintBucketClick(label)}
                    opacity={isLabelHovered(label) ? 1 : 0}
                  >
                    <Icon name="paintBucket"></Icon>
                  </Button>
                </StyledLabelSelect>
              ))
          )
        )
      )}
      {/*  */}
    </>
  );
};

export default Sidebar;
