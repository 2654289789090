import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import GTM from "./GTM";

import createRootReducer from "./state/reducer";

export const history = createBrowserHistory({ basename: "/app" });

export default createStore(
  createRootReducer(history),
  composeWithDevTools(applyMiddleware(routerMiddleware(history), GTM, thunk))
);
