import React, { useCallback, useEffect, useState } from "react";
import { Box, Text, Heading, Tooltip, Icon, Button } from "@blasterjs/core";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Input } from "@chakra-ui/react";
import { BlockPicker, ColorResult } from "react-color";

import { queryClient } from "App";
import { Campaign } from "datamodel/campaign";
import GButton from "components/GButton";
import ColorPickerButton from "components/ColorPickerButton";
import Swatch from "components/Swatch";
import { getEmptyClass } from "pages/CampaignCreate/Steps/ClassCreation/helpers";
import updateClasses from "./helpers/updateClasses";
import groupUpdateAndPost, { UILabelClasses } from "./helpers/groupUpdateAndPost";
import uiLabelClassesBuilder from "./helpers/uiLabelClassesBuilder";
import LoadingIcon from "components/LoadingIcon";
import { COLORS } from "pages/CampaignCreate/Steps/ClassCreation/helpers/getEmptyClass";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";
import HitlLimitBanner from "components/Hitl/LimitBanner";
import { listCampaignLabelGroups } from "http/labelGroup";
import { LabelClass } from "datamodel/labeClass";

const Classifications = ({ campaign }: { campaign: Campaign }) => {
  const [labelClassGroupId, setLabelClassGroupId] = useState<string>("");
  const [dbClassDef, setDbClassDef] = useState<LabelClass[]>([]);
  const [selectedColors, setSelectedColors] = useState<Set<string>>(new Set());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const hitlEnabled = useUserFeatureFlag("hitl");

  const {
    register,
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isDirty: isFormDirty, isValid },
  } = useForm<UILabelClasses>({
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "classes",
  });

  useEffect(() => {
    return () => {
      setDbClassDef([]);
      setIsLoading(false);
      setSelectedColors(new Set());
    };
  }, []);

  useEffect(() => {
    const fetchClasses = async () => {
      setIsLoading(true);
      const { data: clasGroup } = await listCampaignLabelGroups(campaign.id);
      setIsLoading(false);
      if (clasGroup.length) {
        setLabelClassGroupId(clasGroup[0].id);
        setDbClassDef(clasGroup[0].classes.sort((a, b) => a.index - b.index));
      }
    };
    fetchClasses();
  }, [campaign.id, isDirty]);

  useEffect(() => {
    let classes = uiLabelClassesBuilder(dbClassDef);
    setValue("classes", uiLabelClassesBuilder(dbClassDef));
    setSelectedColors(
      classes.reduce((acc, cls) => acc.add(cls.colorHexCode), new Set() as Set<string>)
    );
  }, [setValue, dbClassDef]);

  const onUpdateClasses = (data: UILabelClasses) => {
    const grouped = groupUpdateAndPost(data, dbClassDef);
    setIsLoading(true);
    updateClasses(grouped, campaign.id, labelClassGroupId).then(() => {
      setIsLoading(false);
      setIsDirty(!isDirty);
      queryClient.invalidateQueries(["campaign", campaign.id]);
    });
  };

  const modifyColorSet = useCallback(
    (toDelete: string = "", toAdd: string = "") => {
      const colors = selectedColors;
      toDelete && colors.delete(toDelete);
      toAdd && colors.add(toAdd);
      setSelectedColors(colors);
    },
    [selectedColors]
  );

  const onAppendClass = useCallback(() => {
    const emptyClass = getEmptyClass(Array.from(selectedColors));
    append({ ...emptyClass, dbId: "" });
    modifyColorSet("", emptyClass.colorHexCode);
  }, [modifyColorSet, selectedColors, append]);

  const onColorChange = useCallback(
    (fieldName: string, prevColor: string, currColor: string) => {
      modifyColorSet(prevColor, currColor);
      setValue(fieldName, currColor);
    },
    [modifyColorSet, setValue]
  );

  const onRemoveClassItem = useCallback(
    (idx: number, colorCode: string): void => {
      modifyColorSet(colorCode, "");
      remove(idx);
      trigger();
    },
    [modifyColorSet, remove, trigger]
  );

  return (
    <Box p={0} mb={3} display="flex" flexDirection="column">
      <Heading as="h5" mb={1}>
        Classifications
      </Heading>
      {hitlEnabled && <HitlLimitBanner message="one class definition is supported per campaign." />}
      {isLoading ? (
        <Box
          display="flex"
          height="100px"
          width="200px"
          alignItems="center"
          justifyContent="center"
        >
          <LoadingIcon />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onUpdateClasses)}>
          {fields.map((field, index) => {
            return (
              <Box key={field.id} mb={1} display="flex" flexDirection="row" alignItems="center">
                <Input
                  type="text"
                  mr={3}
                  height="41px"
                  width="150px"
                  padding="0.8rem"
                  background="white"
                  border="2px solid"
                  borderColor="#e0e0e0"
                  borderRadius="2px"
                  fontSize="1.4rem"
                  name={`classes[${index}].name`}
                  ref={register({
                    required: true,
                    validate: (value: string) => !!value.trim().length,
                  })}
                  onChange={() => trigger(`classes[${index}].name`)}
                  onBlur={() => trigger(`classes[${index}].name`)}
                  defaultValue={field.name}
                />
                <Controller
                  control={control}
                  name={`classes[${index}].colorHexCode`}
                  defaultValue={field.colorHexCode}
                  render={({ value, onChange }) => (
                    <ColorPickerButton
                      data-intercom-target="select-color-input"
                      iconAfter="caretDown"
                      height="41px"
                      data-tooltip
                      type="button"
                      mr={1}
                    >
                      <Swatch color={value} size="2.3rem" m="-3px" />
                      <Tooltip
                        trigger="click"
                        placement="right"
                        closeOnClickOutside={true}
                        backgroundColor="transparent"
                        p="0"
                        hasArrow={false}
                        className="withPointer"
                      >
                        <BlockPicker
                          triangle="hide"
                          color={value}
                          onChange={onChange}
                          onChangeComplete={(color: ColorResult) =>
                            onColorChange(
                              `classes[${index}].colorHexCode`,
                              field.colorHexCode,
                              color.hex
                            )
                          }
                          colors={COLORS}
                        />
                      </Tooltip>
                    </ColorPickerButton>
                  )}
                />
                {/* This is a weird thing in react hook form: if a key of a field is not used in form as any type of
                input/select/controller, it will be removed from the form data. Thus, a work-around is to add this
                hidden input for dbId */}
                <Input
                  type="hidden"
                  name={`classes[${index}].dbId`}
                  ref={register()}
                  defaultValue={field.dbId}
                />
                {!field?.dbId && (
                  <button
                    ref={register()}
                    onClick={() => onRemoveClassItem(index, field.colorHexCode)}
                    name={`classes[${index}].dbId`}
                  >
                    <Icon name="cross" />
                  </button>
                )}
                {errors && errors?.classes && errors.classes[index] && (
                  <Box ml={1}>
                    <Text color="danger">A class name is required.</Text>
                  </Box>
                )}
              </Box>
            );
          })}
          {!hitlEnabled && (
            <Box>
              <Button
                p={1}
                disabled={isLoading}
                appearance="minimal"
                type="button"
                iconBefore="plus"
                mr={2}
                onClick={onAppendClass}
              >
                {isLoading ? <Icon name="load" /> : <Text>Add new class</Text>}
              </Button>
            </Box>
          )}
          <GButton
            disabled={!isValid || isLoading || !isFormDirty}
            type="submit"
            data-intercom-target="flag-submit"
            colorScheme="primary"
            mt={2}
          >
            {isLoading ? <Icon name="load" /> : <Text>Save changes</Text>}
          </GButton>
        </form>
      )}
    </Box>
  );
};

export default Classifications;
