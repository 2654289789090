import React, { useCallback } from "react";
import { map, getOrElse } from "fp-ts/es6/Option";
import UploadItem from "./UploadItem";
import { pipe } from "fp-ts/es6/pipeable";
import { CircularProgress, CircularProgressLabel, Flex } from "@chakra-ui/react";
import { Icon } from "@blasterjs/core";
import { UploadSequence, UploadStep } from "./types";
import useUploader from "./store";
import exhaustiveCheck from "lib/exhaustiveCheck";

type Props = {
  sequence: UploadSequence;
};

const UploadItemContainer = ({ sequence }: Props) => {
  const { setAll } = useUploader();

  const onUploadCancel = useCallback(() => {
    sequence.cancelTokenSource.cancel();
    setAll((seqs) => seqs.filter((s) => s.id !== sequence.id));
  }, [sequence, setAll]);

  const onErrorClear = useCallback(() => {
    setAll((seqs) => seqs.filter((s) => s.id !== sequence.id));
  }, [sequence, setAll]);

  switch (sequence.uploadStep) {
    case UploadStep.WAITING: {
      return <></>;
    }
    case UploadStep.START:
    case UploadStep.VALIDATED: {
      return (
        <UploadItem file={sequence.file} statusText="Validating" onClickClose={onUploadCancel}>
          <CircularProgress
            isIndeterminate
            color="primary"
            trackColor="gray.100"
          ></CircularProgress>
        </UploadItem>
      );
    }
    case UploadStep.UPLOADING: {
      return (
        <UploadItem file={sequence.file} statusText="Uploading" onClickClose={onUploadCancel}>
          <CircularProgress value={sequence.uploadProgress} color="primary" trackColor="gray.100">
            <CircularProgressLabel>{sequence.uploadProgress.toFixed(0)}%</CircularProgressLabel>
          </CircularProgress>
        </UploadItem>
      );
    }
    case UploadStep.UPLOADED:
    case UploadStep.COMPLETE: {
      return (
        <UploadItem file={sequence.file} statusText="Complete">
          <CircularProgress value={100} color="green" trackColor="gray200">
            <CircularProgressLabel>
              <Flex justifyContent="center">
                <Icon name="check" color="green" size="22px" />
              </Flex>
            </CircularProgressLabel>
          </CircularProgress>
        </UploadItem>
      );
    }
    case UploadStep.ERROR: {
      return (
        <UploadItem
          file={sequence.file}
          statusText="Failed"
          onClickClose={onErrorClear}
          message={pipe(
            sequence.error,
            map((e) => e.message),
            getOrElse(() => "An unknown error occurred")
          )}
        >
          <CircularProgress value={100} color="danger" trackColor="gray.100">
            <CircularProgressLabel>
              <Flex justifyContent="center">
                <Icon name="cross" color="danger" size="22px" />
              </Flex>
            </CircularProgressLabel>
          </CircularProgress>
        </UploadItem>
      );
    }
    default:
      exhaustiveCheck(sequence.uploadStep);
  }

  return <></>;
};

export default UploadItemContainer;
