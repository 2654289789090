import { useEffect, useState } from "react";

import { Project } from "datamodel/project";
import { Campaign, CampaignType } from "datamodel/campaign";
import buildSummaryFromCampaign from "./helpers/buildSummaryFromCampaign";
import buildSummaryFromCounts from "./helpers/buildSummaryFromCounts";
import { GroupSummaryItem } from "./helpers/types";
import { getCampaignLabelClassSummary } from "http/labelClass";

const useLabelClassSummary = (
  campaign: Campaign,
  project: Project | undefined,
  projectCount: number | undefined = undefined
) => {
  const [summary, setSummary] = useState<GroupSummaryItem[]>([]);
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false);

  useEffect(
    () => () => {
      setSummary([]);
      setLoadingSummary(false);
    },
    []
  );

  useEffect(() => {
    const fetchSummary = async () => {
      setLoadingSummary(true);
      const { data: groupSummary } = await getCampaignLabelClassSummary(campaign.id);
      setSummary(buildSummaryFromCounts(campaign, groupSummary));
      setLoadingSummary(false);
    };

    if (campaign.campaignType === CampaignType.Segmentation) {
      setSummary(buildSummaryFromCampaign(campaign));
    } else if (!projectCount) {
      setSummary(buildSummaryFromCampaign(campaign));
    } else if (!!campaign.labelClassGroups.length) {
      fetchSummary();
    }
  }, [campaign, project, projectCount]);

  return { summary, loadingSummary };
};

export default useLabelClassSummary;
