import React from "react";
import styled from "styled-components";
import BalloonAnimationShort from "./BalloonAnimationShort";

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f7f8f7;
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FullscreenLoadingAnimation = () => (
  <Container>
    <BalloonAnimationShort />
  </Container>
);

export default FullscreenLoadingAnimation;
