export interface ProjectSharePayload {
  userId: string;
  count: number;
}

export interface TrackingAction {
  type: TrackingActionTypes;
  payload: {} | ProjectSharePayload;
}

export enum TrackingActionTypes {
  LabelingTaskReopened = "TRACKING_LABELING_REOPENED",
  LabelingTaskConfirmed = "TRACKING_LABELING_CONFIRMED",
  LabelingTaskSkipped = "TRACKING_LABELING_SKIPPED",
  ProjectShared = "TRACKING_PROJECT_SHARED",
}

export const trackingActionLabels: { [x: string]: string } = {
  [TrackingActionTypes.LabelingTaskReopened]: "Task Reopened",
  [TrackingActionTypes.LabelingTaskConfirmed]: "Task Confirmed",
  [TrackingActionTypes.LabelingTaskSkipped]: "Task Skipped",
  [TrackingActionTypes.ProjectShared]: "Project Shared",
};

export const createTrackingAction = (
  type: TrackingActionTypes,
  payload: {} | ProjectSharePayload = {}
): TrackingAction => ({
  type,
  payload,
});
