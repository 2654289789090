import { BoxProps, Square, SquareProps } from "@chakra-ui/react";
import React from "react";

type Props = SquareProps & {
  color: BoxProps["bg"];
  size: SquareProps["size"];
};

const Swatch = ({ color, size, ...rest }: Props) => (
  <Square bg={color} size={size} flexShrink={0} {...rest} />
);

export default Swatch;
