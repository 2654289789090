import * as t from "io-ts";
import { createEnumType } from "types";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { Campaign } from "./campaign";

export enum UploadType {
  S3 = "S3",
  Local = "LOCAL",
}

export const UploadTypeCodec = createEnumType<UploadType>(UploadType, "UploadType");

export enum UploadStatus {
  Created = "CREATED",
  Uploading = "UPLOADING",
  Uploaded = "UPLOADED",
  Queued = "QUEUED",
  Processing = "PROCESSING",
  Complete = "COMPLETE",
  Failed = "FAILED",
  Aborted = "ABORTED",
}
export const UploadStatusCodec = createEnumType<UploadStatus>(UploadStatus, "UploadStatus");

export const uploadCreateCodec = t.type({
  uploadStatus: UploadStatusCodec,
  uploadType: UploadTypeCodec,
  fileUrl: t.string,
  generateTasks: t.boolean,
  bytesUploaded: optionFromNullable(t.number),
  singleBand: t.boolean,
});

export const uploadCodec = t.type({
  id: t.string,
  projectId: optionFromNullable(t.string),
  uploadStatus: UploadStatusCodec,
  uploadType: UploadTypeCodec,
  fileUrl: t.string,
  generateTasks: t.boolean,
  singleBand: t.boolean,
});

export type UploadCreate = t.TypeOf<typeof uploadCreateCodec>;
export type Upload = t.TypeOf<typeof uploadCodec>;

export const uploadSignedUrlCodec = t.type({
  url: t.string,
  fields: t.type({
    AWSAccessKeyId: t.string,
    key: t.string,
    policy: t.string,
    signature: t.string,
  }),
});
export type UploadSignedUrl = t.TypeOf<typeof uploadSignedUrlCodec>;

export type UploadWithFile = Upload & {
  sourceFile: File;
};

export type CampaignUpload = Upload & {
  sourceFile: File;
  campaign: Campaign;
};
