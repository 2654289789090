import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import getBaseRequestConfig from "./helpers/getBaseRequestConfig";
import { decodeAxiosResponse } from "./transformers/base";
import {
  uploadCodec,
  uploadCreateCodec,
  Upload,
  UploadCreate,
  uploadSignedUrlCodec,
  UploadSignedUrl,
} from "datamodel/upload";

export const postUpload = async (upload: UploadCreate): Promise<AxiosResponse<Upload>> =>
  decodeAxiosResponse(uploadCodec)(
    await axios.post("uploads/", uploadCreateCodec.encode(upload), {
      ...getBaseRequestConfig(),
    })
  );

export const putUpload = async (upload: Upload): Promise<AxiosResponse<"">> =>
  await axios.put(`uploads/${upload.id}`, uploadCodec.encode(upload), {
    ...getBaseRequestConfig(),
  });

export const getUploadSignedUrl = async (
  uploadId: string,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<UploadSignedUrl>> =>
  decodeAxiosResponse(uploadSignedUrlCodec)(
    await axios.get(`uploads/${uploadId}/signed-url`, {
      ...getBaseRequestConfig(),
      ...config,
    })
  );
