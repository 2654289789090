import React, { useCallback } from "react";
import { ApplicationStore, DrawingActionType, DeckPick } from "types";
import { MenuItem, MenuHeader } from "./styled";
import { Swatch, Text } from "@blasterjs/core";
import { foldOption, noop, noEl } from "lib";
import { useDispatch, useSelector } from "react-redux";
import { DrawingActionSourceFeature } from "common/modules/DrawingAction";
import { LabelClass } from "datamodel/labeClass";
import { createDrawingAction } from "pages/SegmentationTask/helpers";
import { createSetActionBuffer } from "state/ui-segmentation/actions";
import { some } from "fp-ts/es6/Option";
import collapseLabelClassGroups from "pages/Campaigns/CampaignCard/helpers/collapseLabelClassGroups";

type Props = {
  pick: DeckPick;
  callback?: () => void;
};

const DetectionReclassifyMenu = ({
  pick: { object: feature, coordinate },
  callback = noop,
}: Props) => {
  const dispatch = useDispatch();

  const [actionBuffer, labelClassGroupsOpt] = useSelector(
    (state: ApplicationStore) =>
      [state.segmentationUI.actionBuffer, state.segmentationUI.labelClassGroups] as const
  );

  const onClick = useCallback(
    (selectedClass: LabelClass) => {
      const f = feature as DrawingActionSourceFeature;
      dispatch(
        createSetActionBuffer([
          ...actionBuffer,
          createDrawingAction(
            selectedClass,
            f,
            DrawingActionType.Reclassify,
            some({
              type: "Feature",
              properties: {},
              geometry: {
                type: "Point",
                coordinates: coordinate as [number, number],
              },
            })
          ),
        ])
      );
      callback();
    },
    [actionBuffer, callback, coordinate, dispatch, feature]
  );

  return (
    <div style={{ maxHeight: "250px", overflowY: "scroll" }}>
      {foldOption(labelClassGroupsOpt, noEl, (labelClassGroups) => (
        <>
          <MenuHeader>Change classification to...</MenuHeader>
          {collapseLabelClassGroups(labelClassGroups)
            .filter((l) => feature.properties.label !== l.id)
            .map((l, idx) => (
              <MenuItem onClick={() => onClick(l)} key={idx}>
                <Swatch color={l.colorHexCode} mr={2} size="13px" />
                <Text>{l.name}</Text>
              </MenuItem>
            ))}
        </>
      ))}
    </div>
  );
};

export default DetectionReclassifyMenu;
