import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  MenuDivider,
  Progress,
  HStack,
  VStack,
  Text,
  Portal,
  useDisclosure,
  Skeleton,
  Image,
  Square,
  ModalProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { IoMdTrendingUp, IoMdExit } from "react-icons/io";
import { IoStatsChartSharp } from "react-icons/io5";
import { Icon } from "@blasterjs/core";
import { useSelector } from "react-redux";
import { ApplicationStore } from "types";
import { isNone } from "fp-ts/es6/Option";
import { foldOption } from "lib";
import LimitsProvider from "components/Limits/LimitsProvider";
import LimitMessage from "components/Limits/LimitMessage";
import { LimitDomain } from "datamodel/limits";
import bytes from "bytes";
import { Link } from "react-router-dom";
import UpgradeModal from "components/UpgradeModal";
import FlexFiller from "components/FlexFiller";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";
import LinkButton from "components/LinkButton";
import { FaRobot } from "react-icons/fa";

const HitlInfoModal = ({ isOpen, onClose }: Omit<ModalProps, "children">) => {
  const hitlLimitationCopy = [
    {
      text: "Semantic segmentation is the only supported campaign type",
    },
    {
      text: "One class definition is supported per campaign",
    },
    {
      text: "Draw some initial labels before a prediction",
    },
    {
      text: "Your remaining prediction runs are in the usage panel",
    },
    {
      text: "Campaign sharing is not available at this time",
    },
  ];
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="fit-content">
        <ModalHeader fontSize="16px">HITL account limitations</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={4}>
          <VStack alignItems="flex-start">
            {hitlLimitationCopy.map((limitation, idx) => (
              <HStack pb={1} display="flex" alignItems="center" key={idx}>
                <Icon name="check" color="primary" size="2rem" mr={1} />
                <Text color="#5b6259">{limitation.text}</Text>
              </HStack>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const UserMenu = () => {
  const { isOpen: isUpgradeOpen, onClose: onUpgradeClose, onOpen: onUpgradeOpen } = useDisclosure();
  const { isOpen: isHitlOpen, onClose: onHitlClose, onOpen: onHitlOpen } = useDisclosure();
  const hitlEnabled = useUserFeatureFlag("hitl");
  const [userOption] = useSelector((state: ApplicationStore) => [state.newAuth.user]);
  return (
    <>
      <Menu autoSelect={false}>
        <MenuButton
          as={Button}
          variant="ghost"
          rightIcon={<Icon name="caretDown" />}
          disabled={isNone(userOption)}
          fontSize="14px"
          p={3}
          height="auto"
        >
          {foldOption(
            userOption,
            () => (
              <Skeleton width="125px" />
            ),
            (user) => (
              <HStack mr={2} spacing={3}>
                <Image height="24px" src={user.picture} />
                <VStack
                  alignItems="flext-start"
                  textAlign="left"
                  fontWeight="normal"
                  color="textLight"
                  spacing={0}
                >
                  <Text fontWeight="semibold">{user.email}</Text>
                  <HStack>
                    <FlexFiller />
                    <Text fontSize="12px">
                      {user.groundworkProUser ? "GroundWork Pro" : "GroundWork Free"}
                    </Text>
                    {hitlEnabled && (
                      <Square bg="orange.500" color="white" p={1}>
                        <Text fontSize="12px">HITL</Text>
                      </Square>
                    )}
                  </HStack>
                </VStack>
              </HStack>
            )
          )}
        </MenuButton>
        <Portal>
          <MenuList border="1px" borderColor="gray.300" color="textLight" fontWeight="semibold">
            {hitlEnabled && (
              <>
                <MenuItem onClick={onHitlOpen}>
                  <VStack maxW="300px" alignItems="flex-start">
                    <HStack alignItems="baseline" spacing="0.75rem">
                      <FaRobot />
                      <Text fontWeight="semibold">HITL</Text>
                    </HStack>
                    <HStack pl={5}>
                      <Text>
                        This account is enabled for Human-in-the-loop (HITL) Learning only.{" "}
                        <LinkButton type="button">Learn more</LinkButton>
                      </Text>
                    </HStack>
                  </VStack>
                </MenuItem>
                <MenuDivider />
              </>
            )}
            <MenuItem
              as={VStack}
              spacing={6}
              alignItems="flex-start"
              isFocusable={false}
              pointerEvents="none"
            >
              <HStack alignItems="baseline" spacing="0.75rem">
                <IoStatsChartSharp size="14px" />
                <Text fontWeight="semibold">Usage</Text>
              </HStack>
              <LimitsProvider>
                <LimitMessage
                  domain={LimitDomain.Storage}
                  render={(limit) => (
                    <VStack alignItems="flex-start" pl={5}>
                      <Progress size="sm" width="225px" value={(limit.used / limit.limit) * 100} />
                      <Text fontWeight="normal">
                        {bytes(limit.used)} used of {bytes(limit.limit)}
                      </Text>
                    </VStack>
                  )}
                />
                <LimitMessage
                  domain={LimitDomain.Campaigns}
                  render={(limit) => (
                    <VStack alignItems="flex-start" pl={5}>
                      <Progress size="sm" width="225px" value={(limit.used / limit.limit) * 100} />
                      <Text fontWeight="normal">
                        {limit.used} campaigns created of {limit.limit}
                      </Text>
                    </VStack>
                  )}
                />
                {hitlEnabled && (
                  <LimitMessage
                    domain={LimitDomain.Predictions}
                    render={(limit) => (
                      <VStack alignItems="flex-start" pl={5}>
                        <Progress
                          size="sm"
                          width="225px"
                          value={(limit.used / limit.limit) * 100}
                        />
                        <Text fontWeight="normal">
                          {limit.used} predictions run of {limit.limit}
                        </Text>
                      </VStack>
                    )}
                  />
                )}
              </LimitsProvider>
            </MenuItem>
            {foldOption(
              userOption,
              () => (
                <></>
              ),
              (user) =>
                user.groundworkProUser ? (
                  <></>
                ) : (
                  <>
                    <MenuDivider />
                    <MenuItem
                      tabIndex={0}
                      onClick={onUpgradeOpen}
                      fontWeight="semibold"
                      icon={<IoMdTrendingUp size="14px" />}
                    >
                      Upgrade to GroundWork Pro
                    </MenuItem>
                  </>
                )
            )}
            <MenuDivider />
            <MenuItem as={Link} to="/logout" icon={<IoMdExit size="14px" />}>
              Logout
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
      <UpgradeModal onClose={onUpgradeClose} isOpen={isUpgradeOpen} />
      <HitlInfoModal onClose={onHitlClose} isOpen={isHitlOpen} />
    </>
  );
};

export default UserMenu;
