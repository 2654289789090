import React from "react";
import { foldOption } from "lib";
import { TaskAction } from "datamodel/task";
import { BiMessageSquareError } from "react-icons/bi";
import MessageBox from "./MessageBox";

type Props = {
  action: TaskAction;
};

const RejectionNote = ({ action }: Props) =>
  foldOption(
    action.note,
    () => (
      <MessageBox icon={BiMessageSquareError} iconColor="yellow.600" title="Task needs attention" />
    ),
    (note) => (
      <MessageBox
        icon={BiMessageSquareError}
        iconColor="yellow.600"
        title="Task needs attention"
        message={note}
      />
    )
  );

export default RejectionNote;
