import axios, { AxiosResponse } from "axios";
import getBaseRequestConfig from "./helpers/getBaseRequestConfig";
import { decodeAxiosResponse } from "./transformers/base";
import { ApiUser, apiUserCodec } from "datamodel/user";

export const getUser = async (userId: string): Promise<AxiosResponse<ApiUser>> =>
  decodeAxiosResponse(apiUserCodec)(
    await axios.get(`users/${encodeURIComponent(userId)}`, {
      ...getBaseRequestConfig(),
    })
  );

export const getUsers = async (userIds: string[]): Promise<AxiosResponse<ApiUser>[]> =>
  await Promise.all(userIds.map((id) => getUser(id)));
