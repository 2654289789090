import axios from "axios";

import { getUploadSignedUrl } from "http/upload";
import { Upload, UploadStatus } from "datamodel/upload";
import { UploadSequence } from "../types";
import { pipe } from "fp-ts/lib/function";
import { map } from "fp-ts/es6/Option";

const uploadFile = (
  sequence: UploadSequence,
  onUploadProgress: (e: any) => void,
  onUploadComplete: (upload: Upload) => void,
  onUploadError: (e: Error) => void
): void => {
  pipe(
    sequence.upload,
    map((upload) => {
      getUploadSignedUrl(upload.id, { cancelToken: sequence.cancelTokenSource.token }).then(
        (resp) => {
          const { url, fields } = resp.data;
          let fileURL = new URL(url);
          fileURL.host = fileURL.host.substr(0, fileURL.host.indexOf("."));
          fileURL.protocol = "s3:";
          const formData = new FormData();
          Object.entries(fields).forEach(([key, val]) => {
            formData.append(key, val);
          });
          formData.append("file", sequence.file);
          axios
            .post(url, formData, {
              onUploadProgress,
              cancelToken: sequence.cancelTokenSource.token,
            })
            .then(() => {
              onUploadComplete({
                ...upload,
                fileUrl: decodeURI(fileURL.toString() + fields.key),
                uploadStatus: UploadStatus.Uploaded,
              });
            })
            .catch((err) => {
              onUploadError(err);
            });
        }
      );
    })
  );
};

export default uploadFile;
