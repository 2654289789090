import { css } from "styled-components";
import { shade, rgba } from "polished";
import { themeGet as tg } from "@styled-system/theme-get";

function buttonBorders(props) {
  let COLOR = tg(
    `button.intents.colors.${props.intent}`,
    tg(`button.base.colors.color`)(props)
  )(props);
  return props.disabled ? rgba(shade(0.2, COLOR), 0.3) : shade(0.2, COLOR);
}

const theme = {
  colors: {
    primary: "#3da1d1",
    secondary: "#f8f8f8",
    orange: "#d16720",
    danger: "#d13d3d",
    green: "#28c662",
    textBase: "#262224",
    textLight: "#666163",
    gray100: "#F7F8F7",
    gray200: "#EDEEED",
    gray300: "#DCDDDC",
    gray400: "#C4C2C3",
    gray500: "#9E9B9C",
    gray600: "#7A7879",
    gray700: "#666163",
    gray800: "#474044",
    gray900: "#262224",
    link: "#3da1d1",
  },
  fonts: {
    body: "proxima-nova,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
    display:
      "proxima-nova,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
    code: "'Source Code Pro', monospace",
  },
  radii: {
    base: "2px",
  },
  fontSizes: {
    0: "1rem",
    1: "1.2rem",
    2: "1.4rem",
    3: "1.8rem",
    4: "2.2rem",
    5: "2.8rem",
    6: "3.2rem",
    7: "3.8rem",
    body: "1.4rem",
    display: "3.4rem",
  },
  callout: {
    radii: {
      borderRadius: 0,
    },
    intents: {
      colors: {
        info: "primaryShade",
      },
    },
  },
  textInput: {
    radii: {
      borderRadius: "2px",
    },
    colors: {
      borderColor: "#e0e0e0",
      bg: "white !important",
    },
    borders: {
      border: "2px solid",
    },
  },
  heading: {
    fontWeights: {
      fontWeight: "bold",
    },
  },
  card: {
    radii: {
      borderRadius: "2px",
    },
    colors: {
      borderColor: "#e5e8e5",
    },
    shadows: {
      boxShadow: "none",
    },
  },
  button: {
    base: {
      colors: {
        color: "textLight",
      },
      radii: {
        radius: "1000px",
      },
      fontWeights: {
        fontWeight: "normal",
      },
      space: {
        pt: "1.2rem",
        pb: "1.2rem",
        pr: "2.4rem",
        pl: "2.4rem",
      },
      intents: {
        colors: {
          secondary: "#f8f8f8",
          tertiary: "#ffffff",
        },
      },
    },
    scale: {
      small: {
        radii: {
          radius: "100px",
        },
        space: {
          pt: "1.1rem",
          pb: "1.1rem",
          pr: "1.4rem",
          pl: "1.4rem",
        },
        fontSizes: {
          fontSize: "inherit",
        },
        fontWeights: {
          fontWeight: "normal",
        },
      },
    },
    styles: css`
      border: ${(props) => props.withBorder && "none"};
      border-bottom: ${(props) => props.withBorder && "2px solid"};
      border-color: ${(props) => props.withBorder && buttonBorders(props)};
      min-width: ${(props) => props.withMinWidth && "75px"};
      > span {
        text-align: center;
        margin-bottom: -2px;
        svg {
          margin-top: -2px;
        }
        ${(props) =>
          props.truncate
            ? css`
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
              `
            : css`
                display: flex;
                align-items: center;
              `};
      }
    `,
  },
  select: {
    colors: {
      borderColor: "gray300",
    },
    radii: {
      borderRadius: "2px",
    },
    borders: {
      border: "2px solid",
    },
  },
  textArea: {
    colors: {
      borderColor: "gray300",
    },
  },
  icons: {
    arrowUp: {
      title: "arrowUp",
      viewBox: "0 0 448 512",
      path: "M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z",
    },
    arrowDown: {
      title: "arrowDown",
      viewBox: "0 0 448 512",
      path: "M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z",
    },
    arrowLeft: {
      title: "arrowLeft",
      viewBox: "0 0 448 512",
      path: "M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z",
    },
    arrowRight: {
      title: "arrowRight",
      viewBox: "0 0 448 512",
      path: "M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z",
    },
    keyboard: {
      title: "keyboard",
      viewBox: "0 0 576 512",
      path: "M528 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm8 336c0 4.411-3.589 8-8 8H48c-4.411 0-8-3.589-8-8V112c0-4.411 3.589-8 8-8h480c4.411 0 8 3.589 8 8v288zM170 270v-28c0-6.627-5.373-12-12-12h-28c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12zm96 0v-28c0-6.627-5.373-12-12-12h-28c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12zm96 0v-28c0-6.627-5.373-12-12-12h-28c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12zm96 0v-28c0-6.627-5.373-12-12-12h-28c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12zm-336 82v-28c0-6.627-5.373-12-12-12H82c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12zm384 0v-28c0-6.627-5.373-12-12-12h-28c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12zM122 188v-28c0-6.627-5.373-12-12-12H82c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12zm96 0v-28c0-6.627-5.373-12-12-12h-28c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12zm96 0v-28c0-6.627-5.373-12-12-12h-28c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12zm96 0v-28c0-6.627-5.373-12-12-12h-28c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12zm96 0v-28c0-6.627-5.373-12-12-12h-28c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12zm-98 158v-16c0-6.627-5.373-12-12-12H180c-6.627 0-12 5.373-12 12v16c0 6.627 5.373 12 12 12h216c6.627 0 12-5.373 12-12z",
    },
    eraser: {
      title: "eraser",
      viewBox: "0 0 548.157 548.157",
      path: "M545.027,112.765c-3.046-6.471-7.57-11.657-13.565-15.555c-5.996-3.9-12.614-5.852-19.846-5.852H292.351   c-11.04,0-20.175,4.184-27.408,12.56L9.13,396.279c-4.758,5.328-7.661,11.56-8.708,18.698c-1.049,7.139-0.144,13.941,2.712,20.417   c3.044,6.468,7.564,11.652,13.561,15.553c5.997,3.898,12.612,5.853,19.845,5.853h219.268c11.042,0,20.177-4.179,27.41-12.56   l255.813-292.363c4.75-5.33,7.655-11.561,8.699-18.699C548.788,126.039,547.877,119.238,545.027,112.765z M255.811,420.254H36.54   l95.93-109.632h219.27L255.811,420.254z",
    },
    hand: {
      title: "hand",
      viewBox: "0 0 448 512",
      path: "M372.57 112.641v-10.825c0-43.612-40.52-76.691-83.039-65.546-25.629-49.5-94.09-47.45-117.982.747C130.269 26.456 89.144 57.945 89.144 102v126.13c-19.953-7.427-43.308-5.068-62.083 8.871-29.355 21.796-35.794 63.333-14.55 93.153L132.48 498.569a32 32 0 0 0 26.062 13.432h222.897c14.904 0 27.835-10.289 31.182-24.813l30.184-130.958A203.637 203.637 0 0 0 448 310.564V179c0-40.62-35.523-71.992-75.43-66.359zm27.427 197.922c0 11.731-1.334 23.469-3.965 34.886L368.707 464h-201.92L51.591 302.303c-14.439-20.27 15.023-42.776 29.394-22.605l27.128 38.079c8.995 12.626 29.031 6.287 29.031-9.283V102c0-25.645 36.571-24.81 36.571.691V256c0 8.837 7.163 16 16 16h6.856c8.837 0 16-7.163 16-16V67c0-25.663 36.571-24.81 36.571.691V256c0 8.837 7.163 16 16 16h6.856c8.837 0 16-7.163 16-16V101.125c0-25.672 36.57-24.81 36.57.691V256c0 8.837 7.163 16 16 16h6.857c8.837 0 16-7.163 16-16v-76.309c0-26.242 36.57-25.64 36.57-.691v131.563z",
    },
    skip: {
      title: "skip",
      viewBox: "0 0 384 512",
      path: "M363.8 264.5L217 412.5c-4.7 4.7-12.3 4.7-17 0l-19.8-19.8c-4.7-4.7-4.7-12.3 0-17L298.7 256 180.2 136.3c-4.7-4.7-4.7-12.3 0-17L200 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17zm-160-17L57 99.5c-4.7-4.7-12.3-4.7-17 0l-19.8 19.8c-4.7 4.7-4.7 12.3 0 17L138.7 256 20.2 375.7c-4.7 4.7-4.7 12.3 0 17L40 412.5c4.7 4.7 12.3 4.7 17 0l146.8-148c4.7-4.7 4.7-12.3 0-17z",
    },
    mapDraw: {
      title: "mapDraw",
      viewBox: "0 0 17 17",
      path: "M14.316 7.056L4.82 16.552l-3.894.444a.823.823 0 01-.683-.239.823.823 0 01-.24-.683l.445-3.894 9.496-9.496zm2.243-4.727c.294.294.441.65.441 1.07s-.147.776-.44 1.07l-1.795 1.794-4.028-4.028L12.53.44C12.825.147 13.18 0 13.6 0s.776.147 1.07.44z",
    },
    mapDrawBehind: {
      title: "mapDrawBehind",
      viewBox: "0 0 17 17",
      path: "M14,0 C15.6568542,0 17,1.34314575 17,3 L17,3 L17,9 C17,10.6568542 15.6568542,12 14,12 L14,12 L12,12 L12,15 C12,16.1045695 11.1045695,17 10,17 L2,17 C0.8954305,17 1.3527075e-16,16.1045695 0,15 L0,7 C-1.3527075e-16,5.8954305 0.8954305,5 2,5 L5,5 L5,3 C5,1.40231912 6.24891996,0.0963391206 7.82372721,0.00509269341 L8,0 Z M14,2 L8,2 C7.44771525,2 7,2.44771525 7,3 L7,3 L7,9 C7,9.55228475 7.44771525,10 8,10 L8,10 L14,10 C14.5522847,10 15,9.55228475 15,9 L15,9 L15,3 C15,2.44771525 14.5522847,2 14,2 L14,2 Z",
    },
    mapSubtract: {
      title: "mapSubtract",
      viewBox: "0 0 19 17",
      path: "M18.480413,8.980442 L13.210898,14.249957 L18.554632,14.249957 C18.678329,14.249957 18.783472,14.293251 18.87006,14.37984 C18.956648,14.466428 18.999943,14.571571 18.999943,14.695268 L18.999943,16.179639 C18.999943,16.303336 18.956648,16.408479 18.87006,16.495068 C18.783472,16.581656 18.678329,16.62495 18.554632,16.62495 L5.343734,16.62495 C4.848944,16.62495 4.428372,16.451773 4.082019,16.10542 L0.51953,12.542931 C0.173177,12.196578 -2.84217094e-14,11.776006 -2.84217094e-14,11.281216 C-2.84217094e-14,10.786426 0.173177,10.365854 0.51953,10.019501 L10.019501,0.51953 C10.365854,0.173177 10.786426,5.68434189e-14 11.281216,5.68434189e-14 C11.776006,5.68434189e-14 12.196578,0.173177 12.542931,0.51953 L18.480413,6.457012 C18.826766,6.803365 18.999943,7.223937 18.999943,7.718727 C18.999943,8.213517 18.826766,8.634089 18.480413,8.980442 L18.480413,8.980442 Z M7.236306,6.642558 L2.634758,11.281216 L5.603499,14.249957 L9.833955,14.249957 L12.357385,11.763636 L7.236306,6.642558 Z",
    },
    mapPan: {
      title: "mapPan",
      viewBox: "0 0 16 18",
      path: "M13.41 18c.524 0 .979-.362 1.096-.872l1.061-4.604c.101-.438.183-1.157.183-1.606V6.293c0-1.428-1.249-2.531-2.652-2.333v-.38c0-1.534-1.424-2.697-2.92-2.305-.9-1.74-3.307-1.668-4.147.026-1.451-.37-2.897.736-2.897 2.285V8.02a2.302 2.302 0 00-2.183.312 2.371 2.371 0 00-.511 3.275l4.218 5.92c.185.261.596.473.916.473zm-.448-1.688H5.864l-4.05-5.684c-.508-.713.528-1.504 1.033-.795l.954 1.339c.316.444 1.02.22 1.02-.327v-7.26c0-.9 1.286-.871 1.286.025V9c0 .31.252.562.563.562h.24c.311 0 .563-.251.563-.562V2.355c0-.902 1.286-.872 1.286.025V9c0 .31.252.562.562.562h.241c.311 0 .563-.251.563-.562V3.555c0-.902 1.286-.872 1.286.024V9c0 .31.251.562.562.562h.241c.31 0 .563-.251.563-.562V6.317c0-.922 1.285-.901 1.285-.024v4.625c0 .413-.047.825-.139 1.227z",
    },
    copy: {
      title: "copy",
      viewBox: "0 0 448 512",
      path: "M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z",
    },
    download: {
      title: "download",
      viewBox: "0 0 640 512",
      path: "M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zm-132.9 88.7L299.3 420.7c-6.2 6.2-16.4 6.2-22.6 0L171.3 315.3c-10.1-10.1-2.9-27.3 11.3-27.3H248V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v112h65.4c14.2 0 21.4 17.2 11.3 27.3z",
    },
    paintBucket: {
      title: "paintBucket",
      viewBox: "0 0 19 18",
      path: "M15.002 13.989c.076 1.287 1.021 2.093 2.124 2.004 1.103-.088 1.945-1.037 1.87-2.324-.076-1.288-2.238-3.928-2.252-3.017-.019 1.267-1.817 2.049-1.742 3.337zM5.252.794C5.628.06 6.497-.214 7.164.166L17.312 5.51c.857.487.937 1.663.105 2.307l-.547.386-.278.202-.223.166c-.127.096-.234.18-.317.248l-.065.055-4.405 8.326c-.354.691-1.144.974-1.815.677l-.124-.063-8.888-5.046c-.698-.396-.96-1.318-.583-2.055zM6.75 2.31l-4.512 8.811 11.529-2.385.53-1c.132-.259.307-.43.742-.762l.155-.118.077-.057z",
    },
    flag: {
      title: "flag",
      viewBox: "0 0 512 512",
      path: "M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z",
    },
    magicWand: {
      title: "magicWand",
      viewBox: "0 0 512 512",
      path: "M224 96l16-32 32-16-32-16-16-32-16 32-32 16 32 16 16 32zM80 160l26.66-53.33L160 80l-53.34-26.67L80 0 53.34 53.33 0 80l53.34 26.67L80 160zm352 128l-26.66 53.33L352 368l53.34 26.67L432 448l26.66-53.33L512 368l-53.34-26.67L432 288zm70.62-193.77L417.77 9.38C411.53 3.12 403.34 0 395.15 0c-8.19 0-16.38 3.12-22.63 9.38L9.38 372.52c-12.5 12.5-12.5 32.76 0 45.25l84.85 84.85c6.25 6.25 14.44 9.37 22.62 9.37 8.19 0 16.38-3.12 22.63-9.37l363.14-363.15c12.5-12.48 12.5-32.75 0-45.24zM359.45 203.46l-50.91-50.91 86.6-86.6 50.91 50.91-86.6 86.6z",
    },
    checkCircle: {
      title: "checkCircle",
      viewBox: "0 0 512 512",
      path: "M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z",
    },
    drawPolygon: {
      title: "drawPolygon",
      viewBox: "0 0 448 512",
      path: "M384 352c-3.36 0-6.59.49-9.81.99l-35.21-58.68C347.05 283.6 352 270.43 352 256s-4.95-27.6-13.01-38.31l35.21-58.68c3.22.5 6.45.99 9.81.99 35.35 0 64-28.65 64-64s-28.65-64-64-64c-26.84 0-49.75 16.56-59.25 40H123.25c-9.5-23.44-32.4-40-59.25-40C28.65 32 0 60.65 0 96c0 26.84 16.56 49.75 40 59.25v201.49C16.56 366.25 0 389.15 0 416c0 35.35 28.65 64 64 64 26.85 0 49.75-16.56 59.25-40h201.49c9.5 23.44 32.41 40 59.25 40 35.35 0 64-28.65 64-64 .01-35.35-28.64-64-63.99-64zm-296 4.75v-201.5A64.053 64.053 0 0 0 123.25 120h201.49c2.1 5.19 4.96 9.92 8.28 14.32l-35.21 58.67c-3.22-.5-6.45-.99-9.82-.99-35.35 0-64 28.65-64 64s28.65 64 64 64c3.36 0 6.59-.49 9.82-.99l35.21 58.67c-3.32 4.4-6.18 9.14-8.28 14.32H123.25A64.053 64.053 0 0 0 88 356.75zM288 240c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zm96-160c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM64 80c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zm0 352c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm320 0c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16z",
    },
    eyeRegular: {
      title: "eyeRegular",
      viewBox: "0 0 576 512",
      path: "M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z",
    },
    splitIcon: {
      title: "splitIcon",
      viewBox: "0 0 448 512",
      path: "M416 32H32A32 32 0 0 0 0 64v384a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32zm-32 64v128H256V96zm-192 0v128H64V96zM64 416V288h128v128zm192 0V288h128v128z",
    },
  },
};

export default theme;
