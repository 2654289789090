import { CampaignType } from "datamodel/campaign";

const textForProjectType = (projectType: CampaignType): string => {
  switch (projectType) {
    case CampaignType.Detection:
      return "Object Detection";
    case CampaignType.Classification:
      return "Chip Classification";
    case CampaignType.Segmentation:
      return "Semantic Segmentation";
    default:
      throw new Error(`Invalid project type '${projectType}'`);
  }
};

export default textForProjectType;
