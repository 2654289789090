import { some, Option } from "fp-ts/es6/Option";
import { Task } from "datamodel/task";
import { TaskActionWithRelated } from "datamodel/task";
import DrawingAction from "common/modules/DrawingAction";
import {
  Error,
  TaskURLActionType,
  DrawingActionType,
  SemanticLabelFeatureCollection,
  TaskUIMode,
  LayerPickerSection,
  LayerConfig,
  LabelObjectCreate,
} from "types";

import { SegmentationActionTypes } from "./reducer";

import { LabelCollection } from "datamodel/label";
import { Project } from "datamodel/project";
import { LabelClass } from "datamodel/labeClass";
import { LabelGroup } from "datamodel/labelGroup";
import { Campaign } from "datamodel/campaign";

export const createSetProject = (
  projectOption: Option<Project>,
  campaignOption: Option<Campaign>
) =>
  ({
    type: SegmentationActionTypes.SetProject,
    payload: {
      projectOption,
      campaignOption,
    },
  } as const);

export const createSetTask = (taskOption: Option<Task>) =>
  ({
    type: SegmentationActionTypes.SetTask,
    payload: taskOption,
  } as const);

export const createSetLastError = (context: string, err: Error) =>
  ({
    type: SegmentationActionTypes.SetLastError,
    payload: some(`${context}: ${err.message}`),
  } as const);

export const createSetUrlActionType = (actionType: TaskURLActionType) =>
  ({
    type: SegmentationActionTypes.SetURLActionType,
    payload: actionType,
  } as const);

export const createPurge = () =>
  ({
    type: SegmentationActionTypes.Purge,
  } as const);

export const createPurgeTask = () =>
  ({
    type: SegmentationActionTypes.PurgeTask,
  } as const);

export const createSetMode = (mode: TaskUIMode) =>
  ({
    type: SegmentationActionTypes.SetMode,
    payload: mode,
  } as const);

export const createSetHasTaskLock = (hasTaskLock: boolean) =>
  ({
    type: SegmentationActionTypes.SetHasTaskLock,
    payload: hasTaskLock,
  } as const);

export const createSetActiveDrawType = (activeDrawTypeOption: Option<DrawingActionType>) =>
  ({
    type: SegmentationActionTypes.SetActiveDrawType,
    payload: activeDrawTypeOption,
  } as const);

export const createSetDisplayBuffer = (displayBuffer: SemanticLabelFeatureCollection) =>
  ({
    type: SegmentationActionTypes.SetDisplayBuffer,
    payload: displayBuffer,
  } as const);

export const createSetLabelObjectCreate = (payload: Option<LabelObjectCreate>) =>
  ({
    type: SegmentationActionTypes.SetLabelObjectCreate,
    payload,
  } as const);

export const createSetSelectedClass = (selectedClass: Option<LabelClass>) =>
  ({
    type: SegmentationActionTypes.SetSelectedClass,
    payload: selectedClass,
  } as const);

export const createSetActionBuffer = (actionBuffer: DrawingAction[]) =>
  ({
    type: SegmentationActionTypes.SetActionBuffer,
    payload: actionBuffer,
  } as const);

export const createInitActionBuffer = (actionBuffer: DrawingAction[]) =>
  ({
    type: SegmentationActionTypes.InitActionBuffer,
    payload: actionBuffer,
  } as const);

export const createSetExistingAnnotations = (annotations: LabelCollection) =>
  ({
    type: SegmentationActionTypes.SetExistingAnnotations,
    payload: annotations,
  } as const);

export const createSetAdjacentTaskAnnotations = (
  annotations: Option<SemanticLabelFeatureCollection>
) =>
  ({
    type: SegmentationActionTypes.SetAdjacentTaskAnnotations,
    payload: annotations,
  } as const);

export const createUndo = () =>
  ({
    type: SegmentationActionTypes.Undo,
  } as const);

export const createRedo = () =>
  ({
    type: SegmentationActionTypes.Redo,
  } as const);

export const createSetLayerPickerConfig = (section: LayerPickerSection, configs: LayerConfig[]) => {
  return {
    type: SegmentationActionTypes.SetLayerPickerConfig,
    payload: {
      section,
      configs,
    },
  } as const;
};

export const createSetLastLabelingAction = (payload: TaskActionWithRelated[]) =>
  ({
    type: SegmentationActionTypes.SetLastLabelingAction,
    payload,
  } as const);

export const createSetLastValidatingAction = (payload: TaskActionWithRelated[]) =>
  ({
    type: SegmentationActionTypes.SetLastValidatingAction,
    payload,
  } as const);

export const createSetDrawingInProgress = (payload: boolean) =>
  ({
    type: SegmentationActionTypes.SetDrawingInProgress,
    payload,
  } as const);

export const createSetTaskQueue = (
  taskQueue: Task[],
  action: Option<TaskURLActionType>,
  paramsString: string
) =>
  ({
    type: SegmentationActionTypes.SetTaskQueue,
    payload: {
      taskQueue,
      action,
      paramsString,
    },
  } as const);

export const createSetIsMouseInMap = (payload: boolean) =>
  ({
    type: SegmentationActionTypes.SetIsMouseInMap,
    payload,
  } as const);

export const createSetIsConfirmDisabled = (payload: boolean) =>
  ({
    type: SegmentationActionTypes.SetIsConfirmDisabled,
    payload,
  } as const);

export const createSetActionBufferCache = (actionBuffer: DrawingAction[]) =>
  ({
    type: SegmentationActionTypes.SetActionBufferCache,
    payload: actionBuffer,
  } as const);

export const createSetMagicWandTolerance = (payload: number) =>
  ({
    type: SegmentationActionTypes.SetMagicWandTolerance,
    payload,
  } as const);

export const createSetHiddenClassIds = (payload: string[]) =>
  ({
    type: SegmentationActionTypes.SetHiddenClassIds,
    payload,
  } as const);

export const createSetIsAutosavingInProgress = (payload: boolean) =>
  ({
    type: SegmentationActionTypes.SetIsAutosavingInProgress,
    payload,
  } as const);

export const createSetCollapsedGroupIds = (payload: string[]) =>
  ({
    type: SegmentationActionTypes.SetCollapsedGroupIds,
    payload,
  } as const);

export const createSetLabelClassGroups = (payload: Option<LabelGroup[]>) =>
  ({
    type: SegmentationActionTypes.SetLabelClassGroups,
    payload,
  } as const);
