import * as t from "io-ts";
import { createEnumType } from "types";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export enum LimitDomain {
  Campaigns = "campaigns",
  Predictions = "predictions",
  Storage = "uploads",
}

export const limitCodec = t.type({
  domain: createEnumType<LimitDomain>(LimitDomain, "LimitDomain"),
  action: t.string,
  objectId: optionFromNullable(t.string),
  used: t.number,
  limit: t.number,
});

export const limitsCodec = t.array(limitCodec);

export type Limit = t.TypeOf<typeof limitCodec>;

export type ExistingLimit = Limit;
