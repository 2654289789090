import React, { useEffect } from "react";
import { foldOption, noop } from "lib";
import { useSelector } from "react-redux";
import { ApplicationStore } from "types";
import FullScreenLoadingAnimation from "components/FullScreenLoadingAnimation";
import * as Cookies from "es-cookie";

const Login = () => {
  const [clientOption] = useSelector((state: ApplicationStore) => [state.newAuth.client]);

  useEffect(() => {
    foldOption(clientOption, noop, (client) => {
      Object.keys(Cookies.getAll()).forEach((key) => {
        if (key.includes("a0.spajs.txs.")) {
          Cookies.remove(key);
        }
      });
      client.loginWithRedirect();
    });
  }, [clientOption]);

  return <FullScreenLoadingAnimation />;
};

export default Login;
