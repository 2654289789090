import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const labelClassCreateCodec = t.type({
  name: t.string,
  colorHexCode: t.string,
  default: optionFromNullable(t.boolean),
  determinant: optionFromNullable(t.boolean),
  index: t.number,
  isActive: t.boolean,
});

export const labelClassCodec = t.intersection([
  t.type({
    id: t.string,
    labelGroupId: t.string,
  }),
  labelClassCreateCodec,
]);

export const labelClassSummaryCodec = t.type({
  classId: t.string,
  className: t.string,
  count: t.number,
});

export type LabelClassCreate = t.TypeOf<typeof labelClassCreateCodec>;

export type LabelClass = t.TypeOf<typeof labelClassCodec>;

export type LabelClassSummary = t.TypeOf<typeof labelClassSummaryCodec>;
