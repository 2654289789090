import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const tileCreateCodec = t.type({
  name: t.string,
  source: optionFromNullable(t.string),
  zxyUrl: optionFromNullable(t.string),
  default: optionFromNullable(t.boolean),
  overlay: optionFromNullable(t.boolean),
  projectId: t.string,
});

export const tileCodec = t.type({
  id: t.string,
  name: t.string,
  source: optionFromNullable(t.string),
  zxyUrl: t.string,
  default: t.boolean,
  overlay: t.boolean,
  projectId: t.string,
});

export type Tile = t.TypeOf<typeof tileCodec>;

export type TileCreate = t.TypeOf<typeof tileCreateCodec>;

export type TileConfig = {
  name: string;
  url: string;
  default: boolean;
  overlay: boolean;
  source: string;
};
