import React, { useCallback, useRef } from "react";
import { FileInput } from "@blasterjs/core";
import styled from "styled-components";
import useUploader from "./store";
import { UUID } from "io-ts-types";

const StyledFileInput = styled(FileInput)`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

type Props = {
  campaignId: UUID;
};

const UploadTrigger: React.FC<Props> = ({ campaignId, children }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { addByFiles } = useUploader();

  const onClick = useCallback(() => inputRef?.current?.click(), [inputRef]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files) {
        addByFiles(campaignId, Array.from(files));
      }
    },
    [addByFiles, campaignId]
  );

  const _children = React.Children.map(children, (child) =>
    React.isValidElement(child) ? React.cloneElement(child, { onClick }) : child
  );

  return (
    <>
      {_children}
      <StyledFileInput
        ref={inputRef}
        opacity="0"
        pointerEvents="none"
        id="imagerySelect"
        onChange={onChange}
        accept="image/tiff"
        multiple
      />
    </>
  );
};

export default UploadTrigger;
