import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { TaskURLActionType, TaskUIMode, ApplicationStore } from "types";

import { createSetMode } from "state/ui-segmentation/actions";
import { foldOption, noop, seqOption } from "lib";
import useRouter from "use-react-router";
import pushRandomTask from "pages/SegmentationTask/helpers/pushRandomTask";
import { Stack, VStack } from "@chakra-ui/react";
import GButton from "components/GButton";
import MessageBox from "../components/MessageBox";
import { FaCheckCircle } from "react-icons/fa";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";

const TaskValidated = () => {
  const dispatch = useDispatch();
  const { location } = useRouter();

  const [campaignOption, projectOption, mode] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.campaign,
        state.segmentationUI.project,
        state.segmentationUI.mode,
      ] as const
  );

  const hitlEnabled = useUserFeatureFlag("hitl");

  const onSkipClick = () => {
    dispatch(createSetMode(TaskUIMode.Loading));
    foldOption(seqOption(campaignOption, projectOption), noop, ([campaign, project]) => {
      pushRandomTask(
        location.search,
        TaskURLActionType.Label,
        hitlEnabled,
        project,
        campaign,
        dispatch,
        {
          projectId: project.id,
        }
      );
    });
  };

  return (
    <VStack spacing={8} flex="1" m={4}>
      <MessageBox
        icon={FaCheckCircle}
        iconColor="blue.500"
        title="Task validated"
        message="This task has already been validated and can't be opened for labeling"
      />
      <Stack spacing={8} alignSelf="stretch">
        <GButton
          disabled={mode === TaskUIMode.Loading}
          colorScheme="primary"
          width="100%"
          onClick={onSkipClick}
        >
          Label a different task
        </GButton>
      </Stack>
    </VStack>
  );
};

export default TaskValidated;
