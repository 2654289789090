import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { TaskURLActionType, TaskUIMode, ApplicationStore } from "types";
import { IoMdClose } from "react-icons/io";

import { createSetMode } from "state/ui-segmentation/actions";
import { foldOption, noop, seqOption } from "lib";
import useRouter from "use-react-router";
import pushRandomTask from "pages/SegmentationTask/helpers/pushRandomTask";
import { useHistory } from "react-router";
import getTaskUrl from "pages/SegmentationTask/helpers/getTaskUrl";
import { VStack } from "@chakra-ui/react";
import GButton from "components/GButton";
import MessageBox from "../components/MessageBox";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";

const TaskNotLabeled = () => {
  const dispatch = useDispatch();
  const { location } = useRouter();
  const history = useHistory();

  const [campaignOption, projectOption, taskOption, mode] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.campaign,
        state.segmentationUI.project,
        state.segmentationUI.task,
        state.segmentationUI.mode,
      ] as const
  );

  const hitlEnabled = useUserFeatureFlag("hitl");

  const onSkipClick = () => {
    dispatch(createSetMode(TaskUIMode.Loading));
    foldOption(seqOption(campaignOption, projectOption), noop, ([campaign, project]) => {
      pushRandomTask(
        location.search,
        TaskURLActionType.Validate,
        hitlEnabled,
        project,
        campaign,
        dispatch,
        {
          projectId: project.id,
        }
      );
    });
  };

  const onLabelClick = () => {
    dispatch(createSetMode(TaskUIMode.Loading));
    foldOption(taskOption, noop, (task) => {
      history.push(getTaskUrl(task, location.search, TaskURLActionType.Label));
    });
  };

  return (
    <VStack spacing={8} flex="1" m={4}>
      <MessageBox
        icon={IoMdClose}
        title="Task unlabeled"
        message="This task needs to be labeled before it can be validated"
      />

      <VStack spacing={4} alignSelf="stretch">
        <GButton
          disabled={mode === TaskUIMode.Loading}
          colorScheme="primary"
          width="100%"
          onClick={onSkipClick}
        >
          Validate a different task
        </GButton>
        <GButton
          disabled={mode === TaskUIMode.Loading}
          colorScheme="#fff"
          width="100%"
          onClick={onLabelClick}
        >
          Label this task
        </GButton>
      </VStack>
    </VStack>
  );
};

export default TaskNotLabeled;
