import { CampaignType } from "datamodel/campaign";
import { Campaign } from "datamodel/campaign";
import collapseLabelClassGroups from "./collapseLabelClassGroups";

const classificationCountForCampaign = (campaign: Campaign): number => {
  switch (campaign.campaignType) {
    case CampaignType.Segmentation:
    // fall through since both projects are handled in the same way
    // es-lint-disable-no-fallthrough
    case CampaignType.Detection:
      return collapseLabelClassGroups(campaign.labelClassGroups).length;
    case CampaignType.Classification:
      return Object.keys(campaign.labelClassGroups).length;
  }
};

export default classificationCountForCampaign;
