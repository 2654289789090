import { some, none } from "fp-ts/es6/Option";
import {
  TaskURLActionType,
  SegmentationUIState,
  SemanticLabelFeatureCollection,
  TaskUIMode,
  LayerPickerSection,
} from "types";
import { createFeatureCollection } from "lib";
import { Task } from "datamodel/task";
import {
  defaultImageryLayersConfig,
  defaultBasemapLayersConfig,
} from "pages/SegmentationTask/MapPane/helpers/layerConfigDefaults";
import {
  createSetIsAutosavingInProgress,
  createSetHiddenClassIds,
  createSetMagicWandTolerance,
  createSetActionBufferCache,
  createSetIsConfirmDisabled,
  createSetIsMouseInMap,
  createSetDrawingInProgress,
  createSetTaskQueue,
  createSetProject,
  createSetTask,
  createSetLastError,
  createSetUrlActionType,
  createPurge,
  createPurgeTask,
  createSetMode,
  createSetHasTaskLock,
  createSetActiveDrawType,
  createSetDisplayBuffer,
  createSetLabelObjectCreate,
  createSetSelectedClass,
  createSetActionBuffer,
  createInitActionBuffer,
  createSetExistingAnnotations,
  createSetAdjacentTaskAnnotations,
  createUndo,
  createRedo,
  createSetLayerPickerConfig,
  createSetLastLabelingAction,
  createSetLastValidatingAction,
  createSetCollapsedGroupIds,
  createSetLabelClassGroups,
} from "./actions";

export enum SegmentationActionTypes {
  SetProject = "SEGMENTATION_SET_PROJECT",
  SetTask = "SEGMENTATION_SET_TASK",
  SetURLActionType = "SEGMENTATION_SET_URL_ACTION_TYPE",
  SetLastError = "SEGMENTATION_SET_LAST_ERROR",
  Purge = "SEGMENTATION_PURGE",
  PurgeTask = "SEGMENTATION_PURGE_TASK",
  SetMode = "SEGMENTATION_SET_MODE",
  SetHasTaskLock = "SEGMENTATION_SET_HAS_TASK_LOCK",
  SetActiveDrawType = "SEGMENTATION_SET_ACTIVE_DRAW_TYPE",
  SetDisplayBuffer = "SEGMENTATION_SET_DISPLAY_BUFFER",
  SetLabelObjectCreate = "SEGMENTATION_LABEL_OBJECT_CREATE",
  SetSelectedClass = "SEGMENTATION_SET_SELECTED_CLASS",
  SetActionBuffer = "SEGMENTATION_SET_ACTION_BUFFER",
  SetExistingAnnotations = "SEGEMENTATION_SET_EXISTING_ANNOTATIONS",
  SetAdjacentTaskAnnotations = "SEGMENTATION_SET_ADJACENT_TASK_ANNOTATIONS",
  Undo = "SEGMENTATION_UNDO",
  Redo = "SEGMENTATION_REDO",
  SetLayerPickerConfig = "SEGMENTATION_SET_LAYER_PICKER_CONFIG",
  SetLastLabelingAction = "SEGMENTATION_SET_LAST_LABELING_ACTION",
  SetLastValidatingAction = "SEGMENTATION_SET_LAST_VALIDATING_ACTION",
  SetDrawingInProgress = "SEGMENTATION_SET_DRAWING_IN_PROGRESS",
  SetTaskQueue = "SEGMENTATION_SET_TASK_QUEUE",
  SetIsMouseInMap = "SEGMENTATION_SET_IS_MOUSE_IN_MAP",
  SetIsConfirmDisabled = "SEGMENTATION_SET_IS_CONFIRM_DISABLED",
  SetActionBufferCache = "SEGMENTATION_SET_ACTION_BUFFER_CACHE",
  SetMagicWandTolerance = "SEGMENTATION_SET_MAGIC_WAND_TOLERANCE",
  SetHiddenClassIds = "SEGMENTATION_SET_HIDDEN_CLASS_IDS",
  SetIsAutosavingInProgress = "SEGMENTATION_SET_IS_AUTOSAVING_IN_PROGRESS",
  InitActionBuffer = "SEGMENTATION_INIT_ACTION_BUFFER",
  SetCollapsedGroupIds = "SEGMENTATION_SET_COLLAPSED_GROUP_IDS",
  SetLabelClassGroups = "SEGMENTATION_SET_LABEL_CLASS_GROUPS",
}

export type SegmentationActions =
  | ReturnType<typeof createSetProject>
  | ReturnType<typeof createSetTask>
  | ReturnType<typeof createSetLastError>
  | ReturnType<typeof createSetUrlActionType>
  | ReturnType<typeof createPurge>
  | ReturnType<typeof createPurgeTask>
  | ReturnType<typeof createSetMode>
  | ReturnType<typeof createSetHasTaskLock>
  | ReturnType<typeof createSetActiveDrawType>
  | ReturnType<typeof createSetDisplayBuffer>
  | ReturnType<typeof createSetLabelObjectCreate>
  | ReturnType<typeof createSetSelectedClass>
  | ReturnType<typeof createSetActionBuffer>
  | ReturnType<typeof createSetExistingAnnotations>
  | ReturnType<typeof createSetAdjacentTaskAnnotations>
  | ReturnType<typeof createUndo>
  | ReturnType<typeof createRedo>
  | ReturnType<typeof createSetLayerPickerConfig>
  | ReturnType<typeof createSetLastLabelingAction>
  | ReturnType<typeof createSetLastValidatingAction>
  | ReturnType<typeof createSetHiddenClassIds>
  | ReturnType<typeof createSetDrawingInProgress>
  | ReturnType<typeof createSetTaskQueue>
  | ReturnType<typeof createSetIsMouseInMap>
  | ReturnType<typeof createSetIsConfirmDisabled>
  | ReturnType<typeof createSetActionBufferCache>
  | ReturnType<typeof createSetMagicWandTolerance>
  | ReturnType<typeof createSetHiddenClassIds>
  | ReturnType<typeof createSetIsAutosavingInProgress>
  | ReturnType<typeof createInitActionBuffer>
  | ReturnType<typeof createSetCollapsedGroupIds>
  | ReturnType<typeof createSetLabelClassGroups>;

const INITIAL_STATE: SegmentationUIState = {
  campaign: none,
  project: none,
  task: none,
  hasTaskLock: false,
  urlActionType: TaskURLActionType.Label,
  existingAnnotations: none,
  adjacentTaskAnnotations: none,
  lastLabelingActions: [],
  lastValidatingActions: [],
  selectedClass: none,
  isDrawingActive: false,
  past: [],
  future: [],
  actionBuffer: [],
  actionBufferCache: [],
  displayBuffer: createFeatureCollection() as SemanticLabelFeatureCollection,
  labelObjectCreate: some({}),
  lastError: none,
  mode: TaskUIMode.Loading,
  activeDrawType: none,
  imageryLayersConfig: defaultImageryLayersConfig,
  basemapLayersConfig: defaultBasemapLayersConfig,
  vectorLayersConfig: [],
  overlayLayersConfig: [],
  drawingInProgress: false,
  taskQueue: [] as Task[],
  taskQueueUpdatedAt: 0,
  taskQueueAction: none,
  taskQueueParamsString: "",
  isMouseInMap: false,
  isConfirmDisabled: true,
  magicWandTolerance: 1,
  hiddenClassIds: [],
  isAutosavingInProgress: false,
  lastActionAtOption: none,
  collapsedGroupIds: [],
  labelClassGroups: none,
};

const reducer = (
  state: SegmentationUIState = INITIAL_STATE,
  action: SegmentationActions
): SegmentationUIState => {
  switch (action.type) {
    case SegmentationActionTypes.SetProject:
      return {
        ...state,
        project: action.payload.projectOption,
        campaign: action.payload.campaignOption,
      };
    case SegmentationActionTypes.SetTask:
      return {
        ...state,
        task: action.payload,
      };
    case SegmentationActionTypes.SetURLActionType:
      return {
        ...state,
        urlActionType: action.payload,
      };
    case SegmentationActionTypes.SetLastError:
      return {
        ...state,
        lastError: action.payload,
      };
    case SegmentationActionTypes.Purge:
      return {
        ...INITIAL_STATE,
        // Preserve user-preferences
        imageryLayersConfig: state.imageryLayersConfig,
        basemapLayersConfig: state.basemapLayersConfig,
        vectorLayersConfig: state.vectorLayersConfig,
        overlayLayersConfig: state.overlayLayersConfig,
        taskQueue: state.taskQueue,
        taskQueueAction: state.taskQueueAction,
        taskQueueParamsString: state.taskQueueParamsString,
        taskQueueUpdatedAt: state.taskQueueUpdatedAt,
        magicWandTolerance: state.magicWandTolerance,
        collapsedGroupIds: state.collapsedGroupIds,
      };
    case SegmentationActionTypes.PurgeTask:
      return {
        ...INITIAL_STATE,
        project: state.project,
        campaign: state.campaign,
        labelClassGroups: state.labelClassGroups,
        // Preserve user-preferences
        imageryLayersConfig: state.imageryLayersConfig,
        basemapLayersConfig: state.basemapLayersConfig,
        vectorLayersConfig: state.vectorLayersConfig,
        overlayLayersConfig: state.overlayLayersConfig,
        taskQueue: state.taskQueue,
        taskQueueAction: state.taskQueueAction,
        taskQueueParamsString: state.taskQueueParamsString,
        taskQueueUpdatedAt: state.taskQueueUpdatedAt,
        activeDrawType: state.activeDrawType,
        magicWandTolerance: state.magicWandTolerance,
        collapsedGroupIds: state.collapsedGroupIds,
      };
    case SegmentationActionTypes.SetMode:
      return {
        ...state,
        mode: action.payload,
      };
    case SegmentationActionTypes.SetHasTaskLock:
      return {
        ...state,
        hasTaskLock: action.payload,
      };
    case SegmentationActionTypes.SetActiveDrawType:
      return {
        ...state,
        activeDrawType: action.payload,
      };
    case SegmentationActionTypes.SetDisplayBuffer:
      return {
        ...state,
        displayBuffer: action.payload,
      };
    case SegmentationActionTypes.SetLabelObjectCreate:
      return {
        ...state,
        labelObjectCreate: action.payload,
      };
    case SegmentationActionTypes.SetSelectedClass:
      return {
        ...state,
        selectedClass: action.payload,
      };
    case SegmentationActionTypes.SetActionBuffer:
      return {
        ...state,
        actionBuffer: action.payload,
        past: [getSnapshotFromState(state), ...state.past],
        future: [],
        lastActionAtOption: action.payload.length
          ? some(new Date().getTime())
          : state.lastActionAtOption,
      };
    case SegmentationActionTypes.InitActionBuffer:
      return {
        ...state,
        actionBuffer: action.payload,
      };
    case SegmentationActionTypes.SetExistingAnnotations:
      return {
        ...state,
        existingAnnotations: some(action.payload),
      };

    case SegmentationActionTypes.SetAdjacentTaskAnnotations:
      return {
        ...state,
        adjacentTaskAnnotations: action.payload,
      };
    case SegmentationActionTypes.Undo:
      if (state.drawingInProgress) {
        return {
          ...state,
          drawingInProgress: false,
        };
      } else if (state.past.length) {
        return {
          ...state,
          ...state.past[0],
          drawingInProgress: false,
          past: [...state.past.slice(1)],
          future: [getSnapshotFromState(state), ...state.future],
          lastActionAtOption: some(new Date().getTime()),
        };
      }
      return { ...state };
    case SegmentationActionTypes.Redo:
      if (state.future.length) {
        return {
          ...state,
          ...state.future[0],
          past: [getSnapshotFromState(state), ...state.past],
          future: [...state.future.slice(1)],
          lastActionAtOption: some(new Date().getTime()),
        };
      }
      return { ...state };
    case SegmentationActionTypes.SetLayerPickerConfig:
      switch (action.payload.section) {
        case LayerPickerSection.Basemap:
          return {
            ...state,
            basemapLayersConfig: action.payload.configs,
          };
        case LayerPickerSection.Imagery:
          return {
            ...state,
            imageryLayersConfig: action.payload.configs,
          };
        case LayerPickerSection.Vector:
          return {
            ...state,
            vectorLayersConfig: action.payload.configs,
          };
        case LayerPickerSection.Overlay:
          return {
            ...state,
            overlayLayersConfig: action.payload.configs,
          };
        default:
          return state;
      }
    case SegmentationActionTypes.SetLastLabelingAction:
      return {
        ...state,
        lastLabelingActions: action.payload,
      };
    case SegmentationActionTypes.SetLastValidatingAction:
      return {
        ...state,
        lastValidatingActions: action.payload,
      };
    case SegmentationActionTypes.SetDrawingInProgress:
      return {
        ...state,
        drawingInProgress: action.payload,
      };
    case SegmentationActionTypes.SetTaskQueue:
      let t = new Date().getTime();
      return {
        ...state,
        taskQueue: action.payload.taskQueue,
        taskQueueAction: action.payload.action,
        taskQueueParamsString: action.payload.paramsString,
        taskQueueUpdatedAt: t,
      };
    case SegmentationActionTypes.SetIsMouseInMap:
      return {
        ...state,
        isMouseInMap: action.payload,
      };
    case SegmentationActionTypes.SetIsConfirmDisabled:
      return {
        ...state,
        isConfirmDisabled: action.payload,
      };
    case SegmentationActionTypes.SetActionBufferCache:
      return {
        ...state,
        actionBufferCache: action.payload,
      };
    case SegmentationActionTypes.SetMagicWandTolerance:
      return {
        ...state,
        magicWandTolerance: action.payload,
      };
    case SegmentationActionTypes.SetHiddenClassIds:
      return {
        ...state,
        hiddenClassIds: action.payload,
      };
    case SegmentationActionTypes.SetIsAutosavingInProgress:
      return {
        ...state,
        isAutosavingInProgress: action.payload,
      };
    case SegmentationActionTypes.SetCollapsedGroupIds:
      return {
        ...state,
        collapsedGroupIds: action.payload,
      };
    case SegmentationActionTypes.SetLabelClassGroups:
      return {
        ...state,
        labelClassGroups: action.payload,
      };
    default:
      return state;
  }
};

const getSnapshotFromState = (state: SegmentationUIState) => ({
  actionBuffer: state.actionBuffer,
});

export default reducer;
