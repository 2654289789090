import { fromNullable, filterMap, getOrElse } from "fp-ts/es6/Option";
import bbox from "@turf/bbox";
import { pipe } from "fp-ts/es6/pipeable";
import center from "@turf/center";
import WebMercatorViewport from "viewport-mercator-project";

import { Project } from "datamodel/project";

export type TrueViewport = {
  width: number | string;
  height: number | string;
  latitude?: number;
  longitude?: number;
  zoom?: number;
  maxZoom?: number;
  minZoom?: number;
};

const calculateViewport = (project: Project): TrueViewport => {
  const [zoom, lat, lng] = getOrElse(() => [9, 0, 0])(
    pipe(
      project.aoi,
      filterMap((aoi) => {
        const bound = bbox(aoi);
        const centroid = center(aoi);

        const { zoom }: { zoom: number } = new WebMercatorViewport({
          width: 600,
          height: 600,
        }).fitBounds(
          [
            [bound[0], bound[1]],
            [bound[2], bound[3]],
          ],
          {
            padding: 5,
            offset: [0, 0],
          }
        );
        return fromNullable(
          centroid.geometry
            ? [zoom, centroid.geometry.coordinates[1], centroid.geometry.coordinates[0]]
            : null
        );
      })
    )
  );
  return {
    width: "auto",
    height: "100%",
    latitude: lat,
    longitude: lng,
    zoom: zoom,
    maxZoom: 24,
    minZoom: 0,
  };
};

export default calculateViewport;
