import * as t from "io-ts";
import { UUID } from "io-ts-types";

import { createEnumType } from "types";
import { taskStatusTypeCodec } from "./task";
import { ACRActionType } from "./permissions";
import { labelGroupCodec } from "./labelGroup";

export enum CampaignProjectStatus {
  Waiting = "WAITING",
  Queued = "QUEUED",
  Processing = "PROCESSING",
  Ready = "READY",
  UnknownFailure = "UNKNOWN_FAILURE",
  TaskGridFailure = "TASK_GRID_FAILURE",
  ImageIngestionFailure = "IMAGE_INGESTION_FAILURE",
}

export const CampaignProjectStatusKeyCodec = createEnumType<CampaignProjectStatus>(
  CampaignProjectStatus,
  "CampaignProjectStatus"
);

export enum CampaignType {
  Detection = "DETECTION",
  Classification = "CLASSIFICATION",
  Segmentation = "SEGMENTATION",
}

export const campaignTypeCodec = createEnumType<CampaignType>(CampaignType, "CampaignType");

export const CampaignCreateCodec = t.type({
  name: t.string,
  campaignType: campaignTypeCodec,
});

export const CampaignCodec = t.intersection([
  t.type({
    id: UUID,
    createdAt: t.string,
    owner: t.string,
    isActive: t.boolean,
    projectStatuses: t.record(CampaignProjectStatusKeyCodec, t.number),
    taskStatusSummary: t.record(taskStatusTypeCodec, t.number),
    labelClassGroups: t.array(labelGroupCodec),
  }),
  CampaignCreateCodec,
]);
export type CampaignCreate = t.TypeOf<typeof CampaignCreateCodec>;

export type Campaign = t.TypeOf<typeof CampaignCodec>;

export type ShareCreate = {
  email: string;
  actionType: ACRActionType;
  silent: boolean;
};

export const getTypeString = (campaign: Campaign) => {
  switch (campaign.campaignType) {
    case CampaignType.Detection:
      return "Object detection";
    case CampaignType.Classification:
      return "Chip classification";
    case CampaignType.Segmentation:
      return "Semantic segmentation";
  }
};
