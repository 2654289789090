import axios, { AxiosResponse } from "axios";

import { decodeAxiosResponse } from "./transformers/base";
import getBaseRequestConfig from "./helpers/getBaseRequestConfig";
import { decodePaginated } from "./transformers/paginated";
import { PaginatedResponse, PaginatedQueryParams } from "types";
import {
  stacExportCreateCodec,
  StacExportCreate,
  stacExportCodec,
  StacExport,
} from "datamodel/export";

export enum SortMethod {
  NewestFirst = "created_at,desc",
  OldestFirst = "created_at,asc",
}

interface SortQueryParams {
  sort?: SortMethod;
}

export type StacExportListParams = {
  campaignId?: string;
} & PaginatedQueryParams &
  SortQueryParams;

export const listCampaignExports = async (
  params: StacExportListParams
): Promise<AxiosResponse<PaginatedResponse<StacExport>>> =>
  decodePaginated(stacExportCodec)(
    await axios.get("/stac", {
      ...getBaseRequestConfig(),
      params,
    })
  );

export const postCampaignExport = async (
  exportCreate: StacExportCreate
): Promise<AxiosResponse<StacExport>> =>
  decodeAxiosResponse(stacExportCodec)(
    await axios.post(`/stac/`, stacExportCreateCodec.encode(exportCreate), {
      ...getBaseRequestConfig(),
    })
  );
