import React from "react";
import { Box, Text, Icon, HStack } from "@chakra-ui/react";

import { TaskStatus } from "datamodel/task";
import { Campaign } from "datamodel/campaign";
import { FaFlag } from "react-icons/fa";

type Props = {
  campaign: Campaign;
};

const TaskFlags = ({ campaign }: Props) => (
  <HStack alignItems="center" spacing={2}>
    <Icon as={FaFlag} color="orange.500" mr={1} />
    <Box>
      <Text as="span" fontWeight="bold">
        {campaign.taskStatusSummary[TaskStatus.Flagged]}
      </Text>
      <Text as="span">&nbsp;flagged</Text>
    </Box>
    <Box mx="4px">&middot;</Box>
    <Box>
      <Text as="span" fontWeight="bold">
        {campaign.taskStatusSummary[TaskStatus.Invalid]}
      </Text>
      <Text as="span">&nbsp;deleted</Text>
    </Box>
  </HStack>
);

export default TaskFlags;
