import * as t from "io-ts";
import { createEnumType } from "types";

export enum ACRActionType {
  View = "VIEW",
  Create = "CREATE",
  Edit = "EDIT",
  Export = "EXPORT",
  Delete = "DELETE",
  Annotate = "ANNOTATE",
  Validate = "VALIDATE",
  All = "*",
}

export const ACRActionTypeCodec = createEnumType<ACRActionType>(ACRActionType, "ACRActionType");

export const userThinWithActionTypeCodec = t.type({
  id: t.string,
  email: t.string,
  profileImageUri: t.string,
  actionType: ACRActionTypeCodec,
});

export type UserThinWithActionType = t.TypeOf<typeof userThinWithActionTypeCodec>;
