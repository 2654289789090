import React, { useState, useMemo } from "react";
import { exists } from "fp-ts/es6/Option";
import { pipe } from "fp-ts/es6/function";
import { FaCheckCircle, FaFlag } from "react-icons/fa";
import { Flex, Box, Fade, Heading, Stack, WrapItem, Text, Icon } from "@chakra-ui/react";

import logo from "assets/logo.png";
import PreviewMaps from "./PreviewMaps";
import { Project, ProjectStatus } from "datamodel/project";
import { Campaign } from "datamodel/campaign";
import ProgressIndicators from "./ProgressIndicators";
import GButton from "components/GButton";
import LabelButton from "../LabelButton";
import ValidateButton from "../ValidateButton";
import StatusLine from "./StatusLine";
import useHitlJob from "hooks/useHitlJob";
import { foldOption } from "lib";
import { HITLJobStatus } from "datamodel/hitlJob";

interface Props {
  campaign: Campaign;
  project: Project;
  onOpen: () => void;
}

const ProjectCard = ({ campaign, project, onOpen }: Props) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const hasFlaggedTasks = pipe(
    project.taskStatusSummary,
    exists((summary) => !!summary.FLAGGED)
  );

  const hasUnlabeledTasks = pipe(
    project.taskStatusSummary,
    exists((summary) => !!(summary.UNLABELED + summary.LABELING_IN_PROGRESS))
  );

  const hasLabeledTasks = pipe(
    project.taskStatusSummary,
    exists((summary) => !!(summary.LABELED + summary.VALIDATION_IN_PROGRESS))
  );

  const showFlaggedIndicator = hasFlaggedTasks;
  const showLabelingCompleteIndicator = !hasUnlabeledTasks;
  const showValidatingCompleteIndicator =
    !hasUnlabeledTasks && !hasLabeledTasks && !hasFlaggedTasks;

  const isReady = project.status === ProjectStatus.Ready;

  const { jobOpt } = useHitlJob(project.id, campaign.id);

  const allowTaskAccess = useMemo(
    () =>
      foldOption(
        jobOpt,
        () => true,
        (job) => job.status === HITLJobStatus.Ran
      ),
    [jobOpt]
  );

  const hasHitlTask = useMemo(
    () =>
      foldOption(
        jobOpt,
        () => false,
        (job) => job.status === HITLJobStatus.Ran
      ),
    [jobOpt]
  );

  return (
    <WrapItem>
      <Box
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        onMouseOver={() => setIsMouseOver(true)}
        border="1px solid"
        borderColor="gray.100"
        shadow="base"
        position="relative"
      >
        <Stack direction="column" width="236px">
          <Box position="relative" flex="1">
            {isReady ? (
              <PreviewMaps project={project} />
            ) : (
              <Box
                height="236px"
                bg="gray.200"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <img src={logo} alt="Logo" width="33%" height="auto" />
              </Box>
            )}
          </Box>
          <Stack p={4} pb={2} direction="column" spacing={1} position="relative">
            <Stack direction="row" spacing={3}>
              <Heading
                as="h5"
                fontSize="16px"
                maxWidth="100%"
                isTruncated={true}
                // proxima-nova fix for first pixel of certain letters being clipped
                pl="1px"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                title={project.name}
                flex="1"
              >
                {project.name}
              </Heading>
              {isReady && showFlaggedIndicator && (
                <Icon as={FaFlag} color="orange.500" size="14px" title="Image has flagged tasks" />
              )}
              {isReady && showLabelingCompleteIndicator && (
                <Icon
                  as={FaCheckCircle}
                  color="gray.500"
                  size="14px"
                  title="Image is completely labeled"
                />
              )}
              {isReady && showValidatingCompleteIndicator && (
                <Icon
                  as={FaCheckCircle}
                  color="blue.500"
                  size="14px"
                  title="Image is completely validated"
                />
              )}
            </Stack>
            <StatusLine project={project} />
            <ProgressIndicators project={project} />
          </Stack>
        </Stack>
        <Fade in={isMouseOver} unmountOnExit={true}>
          <Flex
            position="absolute"
            top="0"
            right="0"
            bottom="0"
            left="0"
            backgroundColor="rgba(0,0,0,0.75)"
            height="236px"
            justify="center"
            align="center"
          >
            {isReady ? (
              allowTaskAccess ? (
                <Stack py={4} px={20} direction="column" spacing={4} flex="1">
                  <LabelButton campaign={campaign} project={project} />
                  <ValidateButton campaign={campaign} project={project} hasHitlTask={hasHitlTask} />
                  <GButton colorScheme="#ffffff" onClick={onOpen}>
                    Open
                  </GButton>
                </Stack>
              ) : (
                <Stack py={4} px={20} direction="column" spacing={4} flex="1">
                  <Text color="white" fontSize="18px" align="center" p={4}>
                    Prediction in progress
                  </Text>
                  <GButton colorScheme="#ffffff" onClick={onOpen}>
                    Open
                  </GButton>
                </Stack>
              )
            ) : (
              <Text color="white" fontSize="18px" align="center" p={4}>
                This image is currently being processed.
              </Text>
            )}
          </Flex>
        </Fade>
      </Box>
    </WrapItem>
  );
};

export default ProjectCard;
