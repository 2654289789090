import {
  ModeHandler,
  getEditHandlesForGeometry,
} from "@nebula.gl/layers/dist-es6/mode-handlers/mode-handler";
import throttle from "lodash.throttle";
import uniq from "lodash.uniq";
import config from "config";
import simplify from "@turf/simplify";
import { createSetDrawingInProgress } from "state/ui-segmentation/actions";

const SIMPLIFY_TOLERANCE = 0.0000001;

export default class DrawPolygonHandler extends ModeHandler {
  isDrawing = false;
  isDragging = false;
  dispatch = null;
  updateLayers = null;

  cancelDraw() {
    // to stop an in progress draw we do some resets and set isDragging so that
    // continous draw mode will be stopped
    this.isDragging = false;
    this.isDrawing = false;
    this.resetClickSequence();
    this._setTentativeFeature(null);
    if (this.updateLayers) {
      this.updateLayers();
    }
  }

  getEditHandles(picks, groundCoords) {
    let handles = super.getEditHandles(picks, groundCoords);

    if (this._tentativeFeature) {
      handles = handles.concat(getEditHandlesForGeometry(this._tentativeFeature.geometry, -1));
      // Slice off the handles that are are next to the pointer
      if (this._tentativeFeature && this._tentativeFeature.geometry.type === "LineString") {
        // Remove the last existing handle
        handles = handles.slice(0, -1);
      } else if (this._tentativeFeature && this._tentativeFeature.geometry.type === "Polygon") {
        // Remove the last existing handle
        handles = handles.slice(0, -1);
      }
    }

    return handles;
  }

  handleClick(event) {
    super.handleClick(event);
    this.isDragging = false;
    if (this.dispatch) {
      this.dispatch(createSetDrawingInProgress(true));
    }

    const { picks } = event;
    const tentativeFeature = this.getTentativeFeature();

    let editAction = null;
    const snappable = picks.find((p) => p.index === 0);

    if (tentativeFeature && tentativeFeature.geometry.type === "Polygon" && snappable) {
      const polygon = tentativeFeature.geometry;
      // Remove the hovered position
      const polygonToAdd = {
        type: "MultiPolygon",
        coordinates: [[[...polygon.coordinates[0], polygon.coordinates[0][0]]]],
      };

      this.resetClickSequence();
      this._setTentativeFeature(null);
      editAction = this.getAddFeatureOrBooleanPolygonAction(
        simplify(polygonToAdd, { tolerance: SIMPLIFY_TOLERANCE })
      );
    }

    // Trigger pointer move right away in order for it to update edit handles (to support double-click)
    const fakePointerMoveEvent = {
      screenCoords: [-1, -1],
      groundCoords: event.groundCoords,
      picks: [],
      isDragging: false,
      pointerDownPicks: null,
      pointerDownScreenCoords: null,
      pointerDownGroundCoords: null,
      sourceEvent: null,
    };
    this.handlePointerMove(fakePointerMoveEvent);
    return editAction;
  }

  isFunctionKeyPressed(event) {
    const { sourceEvent } = event;
    return Boolean(
      sourceEvent.metaKey || sourceEvent.altKey || sourceEvent.ctrlKey || sourceEvent.shiftKey
    );
  }

  handleStartDragging(event) {
    this.isDragging = false;
    if (this.isFunctionKeyPressed(event)) {
      // enable dragging map interactions when function keys are held
      this.isDragging = true;
    } else {
      // otherwise, enable drawing mode
      this.dispatch(createSetDrawingInProgress(true));
      this.isDrawing = true;
    }
  }

  handleStopDragging(event) {
    // stop dragging or drawing
    this.isDragging = false;
    this.isDrawing = false;
    const { pointerUpPicks: picks } = event;
    const tentativeFeature = this.getTentativeFeature();

    let editAction = null;
    const snappable = picks.find((p) => p.index === 0);

    if (tentativeFeature && tentativeFeature.geometry.type === "Polygon" && snappable) {
      const polygon = tentativeFeature.geometry;
      // Remove the hovered position
      const polygonToAdd = {
        type: "MultiPolygon",
        coordinates: [[[...polygon.coordinates[0], polygon.coordinates[0][0]]]],
      };

      this.resetClickSequence();
      this._setTentativeFeature(null);
      editAction = this.getAddFeatureOrBooleanPolygonAction(
        simplify(polygonToAdd, { tolerance: SIMPLIFY_TOLERANCE })
      );
    }

    // Trigger pointer move right away in order for it to update edit handles (to support double-click)
    const fakePointerMoveEvent = {
      screenCoords: [-1, -1],
      groundCoords: event.groundCoords,
      picks: [],
      isDragging: false,
      pointerDownPicks: null,
      pointerDownScreenCoords: null,
      pointerDownGroundCoords: null,
      sourceEvent: null,
    };
    this.handlePointerMove(fakePointerMoveEvent);
    return editAction;
  }

  handlePointerMove = throttle(function (event) {
    const { picks, groundCoords } = event;
    const snappable = this._clickSequence.length > 2 && picks.find((p) => p.index === 0);
    const result = { editAction: null, cancelMapPan: true };
    const ignoreResult = { editAction: null, cancelMapPan: false };

    if (this.isDragging) {
      // allow map panning when used with DrawingMapController
      return ignoreResult;
    }

    this._clickSequence = this._clickSequence.map((coords) => [
      this.round(coords[0], config.drawingPrecision),
      this.round(coords[1], config.drawingPrecision),
    ]);

    if (snappable && !this.isFunctionKeyPressed(event)) {
      // We only want to display a polygon when the mouse is in a position where
      // snapping would occur (close to the first vertex of the drawing)
      this._setTentativeFeature({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [[...this._clickSequence, this._clickSequence[0]]],
        },
      });
    } else if (this.isDrawing || this._clickSequence.length > 0) {
      // handle point precision when rendering the tentative feature
      const coordsToAdd = [
        this.round(groundCoords[0], config.drawingPrecision),
        this.round(groundCoords[1], config.drawingPrecision),
      ];
      // update point precision in the click sequence
      this._clickSequence = this._clickSequence.map((coords) => [
        this.round(coords[0], config.drawingPrecision),
        this.round(coords[1], config.drawingPrecision),
      ]);
      // Only push the current mouse position if free-drawing
      // and only when the point to be added is not the same
      // as the last one in the click sequence
      if (this.isDrawing) {
        const lastPoint = this._clickSequence[this._clickSequence.length - 1];
        if (
          (lastPoint &&
            coordsToAdd &&
            lastPoint[0] !== coordsToAdd[0] &&
            lastPoint[1] !== coordsToAdd[1]) ||
          (!lastPoint && coordsToAdd)
        ) {
          this._clickSequence.push(coordsToAdd);
        }
      }

      this._setTentativeFeature({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: uniq([...this._clickSequence, coordsToAdd]),
        },
      });
    }

    return result;
  }, 16);

  round(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  }

  clear() {
    this.resetClickSequence();
    this._setTentativeFeature(null);
  }
}
