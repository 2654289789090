import React from "react";
import { Box } from "@blasterjs/core";
import { useSelector } from "react-redux";

import SegmentationMap from "./SegmentationMap";
import DetectionMap from "./DetectionMap";
import ClassificationMap from "./ClassificationMap";
import Toolbar from "./Toolbar";
import { ApplicationStore } from "types";
import { foldOption, noEl } from "lib";
import styled from "styled-components";
import { CampaignType } from "datamodel/campaign";

const StyledBox = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow: hidden;
`;

const Map = ({ projectType }: { projectType: CampaignType }) => {
  switch (projectType) {
    case CampaignType.Segmentation:
      return <SegmentationMap />;
    case CampaignType.Detection:
      return <DetectionMap />;
    case CampaignType.Classification:
      return <ClassificationMap />;
  }
};

const MapPane = () => {
  const [campaignOption] = useSelector((state: ApplicationStore) => [
    state.segmentationUI.campaign,
  ]);

  return foldOption(campaignOption, noEl, (campaign) => (
    <StyledBox display="flex" flexDirection="column" alignSelf="stretch" flex="1">
      <Toolbar projectType={campaign.campaignType} />
      <Box
        data-intercom-target="map-box"
        flex="1"
        bg="#555"
        position="relative"
        display="flex"
        justifyContent="center"
      >
        <Map projectType={campaign.campaignType} />
      </Box>
    </StyledBox>
  ));
};

export default MapPane;
