import { findFirst } from "fp-ts/es6/Array";
import { isSome, Option } from "fp-ts/es6/Option";
import { pipe } from "fp-ts/es6/pipeable";

import { Task, TaskAction, TaskStatus } from "datamodel/task";

/* Iterate backwards through a task's actions, looking for the last action which makes a change
to the task. This means ignoring actions like `labelling_in_progress -> unlabelled` which
prevent `getLatestAction` from seeing that a task has been flagged, rejected, etc.
*/

export const isRejectingAction = (action: TaskAction): boolean =>
  action.fromStatus === TaskStatus.ValidationInProgress &&
  action.toStatus === TaskStatus.Unlabeled &&
  isSome(action.note);

export const isFlaggingAction = (action: TaskAction): boolean =>
  action.toStatus === TaskStatus.Flagged;

export const isLabellingAction = (action: TaskAction): boolean =>
  action.fromStatus === TaskStatus.LabelingInProgress && action.toStatus === TaskStatus.Labeled;

export const isReopeningAction = (action: TaskAction): boolean =>
  (action.fromStatus === TaskStatus.Labeled && action.toStatus === TaskStatus.LabelingInProgress) ||
  (action.fromStatus === TaskStatus.Validated &&
    action.toStatus === TaskStatus.ValidationInProgress);

const getLatestTaskQueueTransition = (task: Task): Option<TaskAction> =>
  pipe(
    task.properties.actions.sort(
      (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    ),
    findFirst(
      (a) =>
        isRejectingAction(a) || isFlaggingAction(a) || isLabellingAction(a) || isReopeningAction(a)
    )
  );

export default getLatestTaskQueueTransition;
