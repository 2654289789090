import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { _MapContext as MapContext, StaticMap, NavigationControl } from "react-map-gl";
import { DeckGL, GeoJsonLayer } from "deck.gl";
import { Box, Divider } from "@blasterjs/core";
import { pipe } from "fp-ts/es6/pipeable";
import { Option, some, none, exists } from "fp-ts/es6/Option";
import useEvent from "@react-hook/event";

import {
  currentTaskLayerConfig,
  taskMaskLayerConfig,
  adjacentTaskLayerConfig,
} from "../helpers/layerConfigDefaults";
import {
  DrawPolygonHandler,
  DrawingMapController,
  generateMapStyle,
  generateViewport,
  createSegmentationDrawLayer,
  createLayerProps,
  maybeUpdateLayer,
  createCursor,
} from "../helpers";
import {
  ApplicationStore,
  TaskUIMode,
  LayerPickerSection,
  TaskGeoJsonLayerType,
  DrawingActionType,
} from "types";
import {
  createSetLayerPickerConfig,
  createSetActionBuffer,
  createSetActionBufferCache,
} from "state/ui-segmentation/actions";
import { createSetDisplayBuffer } from "state/ui-segmentation/actions";
import LabelingGeoJsonLayer from "../helpers/LabelingGeoJsonLayer";
import { createDrawingAction } from "../../helpers";
import { merge, transform } from "../../helpers/transformSegmentationDrawingActions";
import { foldOption, seqOption, noEl, noop } from "lib";
import LoadingIcon from "components/LoadingIcon";
import SemanticLayerToggle from "components/map/control/SemanticLayerToggle/SemanticLayerToggle";
import MapControlsContainer from "../MapControlsContainer";
import setLayerPickerConfig from "../helpers/setLayerPickerConfig";
import SegmentationDeleteMenu from "../ContextMenu/SegmentationDeleteMenu";
import SegmentationReclassifyMenu from "../ContextMenu/SegmentationReclassifyMenu";
import ContextMenu from "../ContextMenu";
import HiddenClassMessage from "../HiddenClassMessage";
import { DrawingActionSourceFeature } from "common/modules/DrawingAction";
import { useThrottle } from "@react-hook/throttle";
import coordsToTolerance from "./helpers/coordsToTolerance";
import computeWandResult from "./helpers/computeWandResult";
import MVTMenu from "./MVTMenu";
import verifyActions from "components/AccessControl/helpers/verifyActions";
import { ACRActionType } from "datamodel/permissions";
import { getCampaignActions } from "http/campaign";

const adjacentTaskLayerId = "adjacent-tasks";
const currentTaskLayerId = "current-task";
const taskMaskLayerId = "task-mask";

const WAND_BLUR_RADIUS = 2;
const WAND_SIMPLIFY_TOLERANCE = 4;
const WAND_SIMPLIFY_COUNT = 6;
const WAND_DRAG_SENSITIVITY = 10;

const SegmentationMap = () => {
  const dispatch = useDispatch();
  const [
    selectedClassOption,
    activeDrawingTypeOption,
    projectOption,
    taskOption,
    displayBuffer,
    actionBuffer,
    actionBufferCache,
    mode,
    imageryLayersConfig,
    basemapLayersConfig,
    vectorLayersConfig,
    overlayLayersConfig,
    drawingInProgress,
    magicWandTolerance,
    hiddenClassIds,
    idTokenOption,
  ] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.selectedClass,
        state.segmentationUI.activeDrawType,
        state.segmentationUI.project,
        state.segmentationUI.task,
        state.segmentationUI.displayBuffer,
        state.segmentationUI.actionBuffer,
        state.segmentationUI.actionBufferCache,
        state.segmentationUI.mode,
        state.segmentationUI.imageryLayersConfig,
        state.segmentationUI.basemapLayersConfig,
        state.segmentationUI.vectorLayersConfig,
        state.segmentationUI.overlayLayersConfig,
        state.segmentationUI.drawingInProgress,
        state.segmentationUI.magicWandTolerance,
        state.segmentationUI.hiddenClassIds,
        state.newAuth.idToken,
      ] as const
  );

  const mapWrapper = document.getElementById("deckgl-overlay");

  const [isRightClick, setIsRightClick] = useState(false);
  const [wandStartPoint, setWandStartPoint] = useState<Option<[number, number]>>(none);
  const [tentativeWandShape, setTentativeWandShape] =
    useState<Option<DrawingActionSourceFeature>>(none);
  const [userPerm, setUserPerm] = useState<{ canLabel: boolean; canValidate: boolean }>({
    canLabel: false,
    canValidate: false,
  });
  const [momentaryTolerance, setMomentaryTolerance] = useThrottle<number>(magicWandTolerance, 30);

  const deckRef = useRef<any>();
  const mapRef = useRef<any>();

  const magicWandActive = (evt: MouseEvent) => {
    const deck = deckRef?.current;
    const map = mapRef?.current?.getMap();
    return (
      evt.button === 0 &&
      !(evt.ctrlKey || evt.metaKey) &&
      deck &&
      map &&
      pipe(
        activeDrawingTypeOption,
        exists((a) => a === DrawingActionType.MagicWand)
      )
    );
  };

  useEvent(mapWrapper, "mousedown", (evt: MouseEvent) => {
    if (magicWandActive(evt)) {
      evt.preventDefault();
      setMomentaryTolerance(magicWandTolerance);
      setWandStartPoint(some([evt.offsetX, evt.offsetY]));
    }
  });

  useEvent(mapWrapper, "mousemove", (evt: MouseEvent) => {
    if (magicWandActive(evt)) {
      evt.preventDefault();
      foldOption(wandStartPoint, noop, (start) => {
        const nextTolerance = coordsToTolerance(
          start,
          [evt.offsetX, evt.offsetY],
          magicWandTolerance,
          WAND_DRAG_SENSITIVITY
        );
        setMomentaryTolerance(nextTolerance);
      });
    }
  });

  useEvent(mapWrapper, "mouseup", (evt: MouseEvent) => {
    if (magicWandActive(evt)) {
      evt.preventDefault();
      const deck = deckRef?.current;
      const map = mapRef?.current?.getMap();
      setTentativeWandShape(none);
      foldOption(
        seqOption(wandStartPoint, taskOption, selectedClassOption),
        noop,
        ([start, task, selectedClass]) => {
          foldOption(
            computeWandResult(
              start,
              task,
              deck,
              map,
              momentaryTolerance,
              WAND_BLUR_RADIUS,
              WAND_SIMPLIFY_TOLERANCE,
              WAND_SIMPLIFY_COUNT
            ),
            noop,
            (result) => {
              dispatch(
                createSetActionBuffer([
                  ...actionBufferCache,
                  createDrawingAction(selectedClass, result, DrawingActionType.DrawBelow),
                ])
              );
            }
          );
        }
      );
      setWandStartPoint(none);
      setMomentaryTolerance(magicWandTolerance);
    }
  });

  useEffect(() => {
    foldOption(projectOption, noop, async (project) => {
      const { data: actions } = await getCampaignActions(project.campaignId);
      const canLabel = verifyActions(actions, [[ACRActionType.Annotate]]);
      const canValidate = verifyActions(actions, [[ACRActionType.Validate]]);
      setUserPerm({ canLabel, canValidate });
    });
  }, [projectOption]);

  useEffect(() => {
    const deck = deckRef?.current;
    const map = mapRef?.current?.getMap();
    deck &&
      map &&
      foldOption(seqOption(wandStartPoint, taskOption), noop, ([start, task]) => {
        setTentativeWandShape(
          computeWandResult(
            start,
            task,
            deck,
            map,
            momentaryTolerance,
            WAND_BLUR_RADIUS,
            WAND_SIMPLIFY_TOLERANCE,
            WAND_SIMPLIFY_COUNT
          )
        );
      });
  }, [momentaryTolerance, taskOption, wandStartPoint]);

  useEffect(() => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Vector,
        vectorLayersConfig.length
          ? vectorLayersConfig
          : [
              currentTaskLayerConfig(currentTaskLayerId),
              adjacentTaskLayerConfig(adjacentTaskLayerId),
              taskMaskLayerConfig(taskMaskLayerId),
            ]
      )
    );
  }, [vectorLayersConfig, dispatch]);

  useEffect(() => {
    if (!overlayLayersConfig.length) {
      setLayerPickerConfig(projectOption, dispatch);
    }
  }, [projectOption, overlayLayersConfig.length, dispatch]);

  const [isLoading, isLabeling] = useMemo(
    () => [
      mode === TaskUIMode.Loading,
      [TaskUIMode.Labeling, TaskUIMode.Validating].includes(mode),
    ],
    [mode]
  );

  const currentCursor = useMemo(
    () => createCursor(isLabeling, activeDrawingTypeOption),
    [isLabeling, activeDrawingTypeOption]
  );

  const drawHandler = useMemo(() => {
    const handler = new DrawPolygonHandler();
    handler.dispatch = dispatch as any;
    return handler;
  }, [dispatch]);

  const drawingLayer = useMemo<void | LabelingGeoJsonLayer>(
    () =>
      createSegmentationDrawLayer(
        taskOption,
        activeDrawingTypeOption,
        selectedClassOption,
        actionBufferCache,
        isLabeling,
        isRightClick,
        drawHandler,
        dispatch
      ),
    [
      taskOption,
      activeDrawingTypeOption,
      selectedClassOption,
      actionBufferCache,
      isLabeling,
      isRightClick,
      drawHandler,
      dispatch,
    ]
  );

  const { viewportOption } = useMemo(() => generateViewport(taskOption), [taskOption]);

  useEffect(() => {
    const merged = merge(actionBuffer);
    dispatch(createSetActionBufferCache(merged));
    dispatch(createSetDisplayBuffer(transform(merged)));
  }, [actionBuffer, dispatch]);

  useEffect(() => {
    if (!drawingInProgress) {
      drawHandler.cancelDraw();
    }
  }, [drawingInProgress, drawHandler]);

  const onImageryLayerChange = (id: string, enabled: boolean, opacity: number) => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Imagery,
        imageryLayersConfig.map(maybeUpdateLayer(id, enabled, opacity))
      )
    );
  };

  const onBasemapChange = (id: string) => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Basemap,
        basemapLayersConfig.map((l) => ({ ...l, enabled: l.id === id }))
      )
    );
  };

  const onVectorLayerChange = (id: string, enabled: boolean, opacity: number) => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Vector,
        vectorLayersConfig.map(maybeUpdateLayer(id, enabled, opacity))
      )
    );
  };

  const onOverlayChange = (id: string, enabled: boolean, opacity: number) => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Overlay,
        overlayLayersConfig.map(maybeUpdateLayer(id, enabled, opacity))
      )
    );
  };

  const getLayerProps = useCallback(
    (layerId: string, layerType: TaskGeoJsonLayerType) =>
      createLayerProps(layerId, layerType, vectorLayersConfig),
    [vectorLayersConfig]
  );

  const mapStyleOption = useMemo(
    () =>
      generateMapStyle(
        projectOption,
        imageryLayersConfig.concat(basemapLayersConfig, overlayLayersConfig, vectorLayersConfig),
        idTokenOption,
        taskOption,
        hiddenClassIds
      ),
    [
      projectOption,
      imageryLayersConfig,
      basemapLayersConfig,
      overlayLayersConfig,
      idTokenOption,
      taskOption,
      hiddenClassIds,
      vectorLayersConfig,
    ]
  );

  return isLoading ? (
    <Box display="flex" width="100%" height="100%">
      <LoadingIcon />
    </Box>
  ) : (
    foldOption(
      seqOption(viewportOption, mapStyleOption),
      () => (
        <Box display="flex" width="100%" height="100%">
          <LoadingIcon />
        </Box>
      ),
      ([viewport, mapStyle]) => (
        <>
          <DeckGL
            initialViewState={viewport}
            controller={DrawingMapController}
            layers={[drawingLayer]}
            getCursor={() => currentCursor}
            ContextProvider={MapContext.Provider}
            ref={deckRef}
          >
            <MapControlsContainer>
              <NavigationControl showCompass={false} />
              <SemanticLayerToggle
                imageryLayers={{ onChange: onImageryLayerChange, layers: imageryLayersConfig }}
                vectorLayers={{ onChange: onVectorLayerChange, layers: vectorLayersConfig }}
                basemaps={{ onChange: onBasemapChange, layers: basemapLayersConfig }}
                overlayLayers={{ onChange: onOverlayChange, layers: overlayLayersConfig }}
              />
            </MapControlsContainer>
            <GeoJsonLayer
              {...getLayerProps(currentTaskLayerId, TaskGeoJsonLayerType.Current)}
              data={displayBuffer.features.filter(
                (f) => !hiddenClassIds.includes(f.properties.label)
              )}
              pickable={true}
            />
            {foldOption(tentativeWandShape, noEl, (f) => (
              <GeoJsonLayer data={f} getFillColor={[0, 0, 0, 128]} getLineWidth={0} />
            ))}
            <StaticMap
              mapStyle={mapStyle}
              width="100%"
              height="100%"
              ref={mapRef}
              preserveDrawingBuffer={true}
            />
          </DeckGL>
          {isLabeling && (
            <ContextMenu
              deckRef={deckRef}
              staticMapRef={mapRef}
              onActivate={() => {
                setIsRightClick(true);
              }}
              onHide={() => {
                setIsRightClick(false);
              }}
            >
              {(pick, callback, t) => (
                <>
                  {"coordinate" in pick ? (
                    <>
                      <SegmentationReclassifyMenu pick={pick} callback={callback} />
                      <Divider m={0} />
                      <SegmentationDeleteMenu pick={pick} callback={callback} />
                    </>
                  ) : (
                    foldOption(pick, noEl, (p) => (
                      <MVTMenu
                        canLabel={userPerm.canLabel}
                        canValidate={userPerm.canValidate}
                        projectOption={projectOption}
                        pick={p}
                        callback={callback}
                        timestamp={t}
                      />
                    ))
                  )}
                </>
              )}
            </ContextMenu>
          )}
          <HiddenClassMessage />
        </>
      )
    )
  );
};

export default SegmentationMap;
