import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

import { createEnumType } from "types";
import { UUID } from "io-ts-types";

export enum ExportStatus {
  NotExported = "NOTEXPORTED",
  ToBeExported = "TOBEEXPORTED",
  Exporting = "EXPORTING",
  Exported = "EXPORTED",
  Failed = "FAILED",
}

export enum ExportAssetTypes {
  Cog = "COG",
  SignedUrl = "SIGNED_URL",
}

export const exportStatusCodec = createEnumType<ExportStatus>(ExportStatus, "ExportStatus");

export const exportAssetTypesCodec = createEnumType<ExportAssetTypes>(
  ExportAssetTypes,
  "ExportAssetTypes"
);

export const stacExportLicenseCodec = t.type({
  license: t.string,
  url: optionFromNullable(t.string),
});

export const stacExportCreateCodec = t.type({
  name: t.string,
  license: stacExportLicenseCodec,
  campaignId: UUID,
  exportAssetTypes: optionFromNullable(t.array(exportAssetTypesCodec)),
  exportStatus: exportStatusCodec,
});

export const stacExportCodec = t.type({
  id: UUID,
  createdAt: t.string,
  modifiedAt: t.string,
  owner: t.string,
  name: t.string,
  campaignId: UUID,
  license: stacExportLicenseCodec,
  exportLocation: optionFromNullable(t.string),
  exportStatus: exportStatusCodec,
  exportAssetTypes: optionFromNullable(t.array(exportAssetTypesCodec)),
  expiration: optionFromNullable(t.string),
  downloadUrl: optionFromNullable(t.string),
});

export type StacExport = t.TypeOf<typeof stacExportCodec>;

export type StacExportCreate = t.TypeOf<typeof stacExportCreateCodec>;
