import { RandomTaskParams } from "http/task";
import qs from "qs";
import { Dispatch } from "redux";
import { push } from "connected-react-router";

import urlForRandomTask from "pages/helpers/urlForRandomTask";
import { TaskURLActionType, TaskUIMode } from "types";
import { foldOption } from "lib";
import { createSetMode } from "state/ui-segmentation/actions";
import getNextTaskOption from "./getNextTaskOption";
import { Project } from "datamodel/project";
import { Campaign } from "datamodel/campaign";

const pushRandomTask = async (
  queryString: string,
  actionType: TaskURLActionType,
  hitlEnabled: boolean,
  project: Project,
  campaign: Campaign,
  dispatch: Dispatch,
  fallbackParams: RandomTaskParams = {}
) => {
  const urlParams = qs.parse(queryString.substring(1));
  const hasParams = Object.values(urlParams).length > 0;
  const params = hasParams ? urlParams : fallbackParams;
  try {
    const nextTaskOption = await getNextTaskOption(
      actionType,
      hitlEnabled,
      project,
      campaign,
      params
    );
    foldOption(
      nextTaskOption,
      () => {
        dispatch(createSetMode(TaskUIMode.ProjectComplete));
      },
      (task) => {
        const url = urlForRandomTask(task.properties.projectId, task.id, actionType, params);
        dispatch(push(url));
      }
    );
  } catch (e) {
    dispatch(createSetMode(TaskUIMode.Error));
  }
};

export default pushRandomTask;
