import axios, { AxiosResponse } from "axios";
import { UUID } from "io-ts-types";
import * as T from "io-ts";
import qs from "qs";

import { ACRActionType, ACRActionTypeCodec, UserThinWithActionType } from "datamodel/permissions";
import { ProjectListRequestParams } from "./project";
import { PaginatedQueryParams, PaginatedResponse } from "types";
import { decodeAxiosResponse } from "./transformers/base";
import { decodePaginated } from "./transformers/paginated";
import getBaseRequestConfig from "./helpers/getBaseRequestConfig";
import {
  CampaignCreate,
  Campaign,
  CampaignCodec,
  CampaignCreateCodec,
  ShareCreate,
} from "datamodel/campaign";
import { Project, projectCodec } from "datamodel/project";

export enum CampaignSort {
  NewestFirst = "created_at,desc",
  OldestFirst = "created_at,asc",
}

export enum CampaignOwnershipType {
  Owned = "owned",
  Shared = "shared",
  All = "all",
}

export interface CampaignListQP extends PaginatedQueryParams {
  search?: string;
  sort?: CampaignSort;
  ownershipType?: CampaignOwnershipType;
  isActive?: boolean;
}

export const postCampaign = async (
  campaignCreate: CampaignCreate
): Promise<AxiosResponse<Campaign>> =>
  decodeAxiosResponse(CampaignCodec)(
    await axios.post(
      `/campaigns/`,
      CampaignCreateCodec.encode(campaignCreate),
      getBaseRequestConfig()
    )
  );

export const getCampaign = async (campaignId: UUID): Promise<AxiosResponse<Campaign>> =>
  decodeAxiosResponse(CampaignCodec)(
    await axios.get(`/campaigns/${campaignId}`, getBaseRequestConfig())
  );

export const listCampaigns = async (
  params: CampaignListQP
): Promise<AxiosResponse<PaginatedResponse<Campaign>>> =>
  decodePaginated(CampaignCodec)(
    await axios.get("campaigns", {
      ...getBaseRequestConfig(),
      params,
    })
  );

export const updateCampaign = async (campaign: Campaign): Promise<AxiosResponse<null>> =>
  await axios.put(
    `/campaigns/${campaign.id}`,
    CampaignCodec.encode(campaign),
    getBaseRequestConfig()
  );

export const deleteCampaign = async (campaignId: UUID): Promise<AxiosResponse<null>> =>
  await axios.delete(`/campaigns/${campaignId}`, getBaseRequestConfig());

export const getCampaignActions = async (
  campaignId: UUID
): Promise<AxiosResponse<Array<ACRActionType>>> =>
  decodeAxiosResponse(T.array(ACRActionTypeCodec))(
    await axios.get(`/campaigns/${campaignId}/actions`, getBaseRequestConfig())
  );

export const postShares = async (
  campaign: Campaign,
  emails: string[],
  actionType: ACRActionType = ACRActionType.Annotate,
  silent: boolean = false
): Promise<AxiosResponse<"">[]> =>
  await Promise.all(
    emails.map((email) =>
      axios.post(
        `campaigns/${campaign.id}/share`,
        { email, actionType, silent },
        getBaseRequestConfig()
      )
    )
  );

export const postShare = async (
  campaign: Campaign,
  shareCreate: ShareCreate
): Promise<AxiosResponse<"">[]> =>
  await axios.post(`campaigns/${campaign.id}/share`, shareCreate, getBaseRequestConfig());

export const getShares = async (
  campaign: Campaign
): Promise<AxiosResponse<UserThinWithActionType[]>> =>
  await axios.get(`campaigns/${campaign.id}/share`, getBaseRequestConfig());

export const deleteShare = async (
  campaign: Campaign,
  userId: string
): Promise<AxiosResponse<number>> =>
  await axios.delete(`campaigns/${campaign.id}/share/${encodeURI(userId)}`, getBaseRequestConfig());

export const listProjects = async (
  campaignId: UUID,
  params: ProjectListRequestParams = {}
): Promise<AxiosResponse<PaginatedResponse<Project>>> =>
  decodePaginated(projectCodec)(
    await axios.get(`campaigns/${campaignId}/projects`, {
      ...getBaseRequestConfig(),
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    })
  );
