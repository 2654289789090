import { createSetProject } from "state/ui-segmentation/actions";
import { fromNullable } from "fp-ts/es6/Option";
import { Dispatch } from "redux";
import { getProject } from "http/project";
import { getCampaign } from "http/campaign";

const initializeProjectFromUrlParams = async (projectId: string, dispatch: Dispatch) => {
  const { data: project } = await getProject(projectId);
  const { data: campaign } = await getCampaign(project.campaignId);
  dispatch(createSetProject(fromNullable(project), fromNullable(campaign)));
};

export default initializeProjectFromUrlParams;
