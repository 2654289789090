import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Swatch, Text, Divider, Button, Icon } from "@blasterjs/core";
import { Option, none, some, map } from "fp-ts/es6/Option";
import styled from "styled-components";

import { ApplicationStore, MvtLabelProps, TaskUIMode, TaskURLActionType } from "types";
import FlexFiller from "components/FlexFiller";
import { Task } from "datamodel/task";
import { Project } from "datamodel/project";
import { foldOption, noop, seqOption } from "lib";
import { getTask } from "http/task";
import LabelerMenu from "components/map/menu/LabelerMenu";
import { useHistory } from "react-router";
import ValidatorMenu from "components/map/menu/ValidatorMenuForTaskUI";
import Skeleton from "react-loading-skeleton";
import { createSetMode } from "state/ui-segmentation/actions";
import { createTrackingAction, TrackingActionTypes } from "state/tracking/actions";
import confirmTask from "pages/SegmentationTask/Sidebar/helpers/confirmTask";
import { pipe } from "fp-ts/es6/pipeable";
import isTaskInProgress from "pages/SegmentationTask/Sidebar/helpers/isTaskInProgress";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";

const StyledHeader = styled(Box)`
  cursor: default;
`;

enum AsyncStatus {
  NotRun,
  Failed,
  InProgress,
  Success,
}

interface Props {
  pick: MvtLabelProps;
  callback: () => void;
  projectOption: Option<Project>;
  timestamp: number;
  canLabel: boolean;
  canValidate: boolean;
}

const MVTMenu = ({ pick, callback, projectOption, canLabel, canValidate, timestamp }: Props) => {
  const [asyncStatus, setAsyncStatus] = useState<AsyncStatus>(AsyncStatus.NotRun);
  const [nextTaskOption, setNextTaskOption] = useState<Option<Task>>(none);

  const hitlEnabled = useUserFeatureFlag("hitl");

  const [displayBuffer, currentTaskOption, urlActionType] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.displayBuffer,
        state.segmentationUI.task,
        state.segmentationUI.urlActionType,
      ] as const
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setNextTaskOption(none);
    setAsyncStatus(AsyncStatus.NotRun);

    return () => {
      setNextTaskOption(none);
      setAsyncStatus(AsyncStatus.NotRun);
    };
  }, []);

  useEffect(
    () =>
      foldOption(
        projectOption,
        () => {
          setAsyncStatus(AsyncStatus.Failed);
        },
        (project) => {
          const fetchTask = async () => {
            setAsyncStatus(AsyncStatus.InProgress);
            try {
              const { data: task } = await getTask(project.id, pick.task_id);
              setNextTaskOption(some(task));
              setAsyncStatus(AsyncStatus.Success);
            } catch (err) {
              console.error(err);
              setAsyncStatus(AsyncStatus.Failed);
            }
          };

          fetchTask();
        }
      ),
    [pick.task_id, timestamp, projectOption]
  );

  const onClickUrlAction = (actionType: TaskURLActionType) =>
    foldOption(seqOption(nextTaskOption, currentTaskOption), noop, ([nextTask, currentTask]) => {
      const redirect = () =>
        history.push(
          `/projects/${nextTask.properties.projectId}/tasks/${nextTask.id}/${actionType}`
        );
      dispatch(createSetMode(TaskUIMode.Loading));
      dispatch(createTrackingAction(TrackingActionTypes.LabelingTaskConfirmed));
      if (!isTaskInProgress(currentTask)) return redirect();
      confirmTask(currentTask, displayBuffer, hitlEnabled).then(() => redirect());
    });

  return (
    <div style={{ maxHeight: "250px" }}>
      <StyledHeader
        display="flex"
        flexDirection="row"
        m={1}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Swatch
          color={pick.color_hex_code}
          mr={1}
          size="1.4rem"
          border="1px solid #c4c4c4"
          borderRadius="3px"
        />
        <Text color="textLight" as="strong">
          {pick.name}
        </Text>
        <FlexFiller />
        <Button appearance="minimal" p="0" onClick={callback}>
          <Icon name="cross" />
        </Button>
      </StyledHeader>
      <Divider height="2px" color="#ebebeb" />
      {asyncStatus === AsyncStatus.InProgress && (
        <Box
          p={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Skeleton width="60px" />
        </Box>
      )}
      {asyncStatus === AsyncStatus.Failed && (
        <Box
          p={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box my={1}>
            <Icon name="close" color="danger" size="24px" />
          </Box>
          <Box my={1}>
            <Text>Oops, something went wrong...</Text>
          </Box>
        </Box>
      )}
      {asyncStatus === AsyncStatus.Success && (
        <Box p={1} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          {canLabel && !canValidate && urlActionType === TaskURLActionType.Label ? (
            <LabelerMenu
              taskStatusOption={pipe(
                nextTaskOption,
                map((task) => task.properties.status)
              )}
              onClickUrlAction={onClickUrlAction}
            />
          ) : canValidate ? (
            <ValidatorMenu
              taskOption={nextTaskOption}
              onClickUrlAction={onClickUrlAction}
              callback={callback}
              urlActionType={urlActionType}
            />
          ) : (
            <Text>
              No action available due to permissions or mismatching task queue and task status.
            </Text>
          )}
        </Box>
      )}
    </div>
  );
};

export default MVTMenu;
