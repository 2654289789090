import { Dispatch } from "redux";
import { pipe } from "fp-ts/es6/pipeable";
import { fromArray, head } from "fp-ts/es6/NonEmptyArray";
import { Option, map } from "fp-ts/es6/Option";

import { foldOption, noop } from "lib";
import { LabelGroup } from "datamodel/labelGroup";
import { createSetSelectedClass } from "state/ui-segmentation/actions";
import collapseLabelClassGroups from "pages/Campaigns/CampaignCard/helpers/collapseLabelClassGroups";

const initializeLabelClassSelection = (
  labelClassGroupsOpt: Option<LabelGroup[]>,
  dispatch: Dispatch
) =>
  foldOption(labelClassGroupsOpt, noop, (labelClassGroups) => {
    dispatch(
      createSetSelectedClass(
        pipe(
          fromArray(collapseLabelClassGroups(labelClassGroups)),
          map((classes) => head(classes))
        )
      )
    );
  });

export default initializeLabelClassSelection;
