import React from "react";
import { Box, Link, Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";

interface StorageAlertProps {
  onModalOpen: () => void;
}

const StorageAlert = ({ onModalOpen }: StorageAlertProps) => (
  <Alert status="warning">
    <AlertIcon />
    <Box flex="1">
      <AlertTitle>Seems like your storage is running out.</AlertTitle>
      <AlertDescription display="block">
        <Link color="teal.500" onClick={onModalOpen}>
          Click here to learn about PRO tier!
        </Link>
      </AlertDescription>
    </Box>
  </Alert>
);

export default StorageAlert;
