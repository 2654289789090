import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactMapGL, { InteractiveMap } from "react-map-gl";
import { Option, some, none, map, isSome, filterMap } from "fp-ts/es6/Option";
import { Box } from "@blasterjs/core";
import styled from "styled-components";
import { pipe } from "fp-ts/es6/pipeable";
import { findFirst } from "fp-ts/es6/Array";

import { foldOption, noEl, seqOption } from "lib";
import { listProjects } from "http/campaign";
import { getViewportFromAoi } from "./helpers/getViewportFromAoi";
import getMiniMapViews from "./helpers/getMiniMapViews";
import { UUID } from "io-ts-types";
import { Project } from "datamodel/project";
import { Tile } from "datamodel/tile";

type Props = {
  project: Project;
  campaignId: UUID;
};

const MapImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const MiniMapBox = styled(Box)`
  transform: scale(0.15);
  transform-origin: bottom right;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
`;

const PreviewMaps = ({ project, campaignId }: Props) => {
  const imageryMapRef = useRef<InteractiveMap>(null);
  const miniMapRef = useRef<InteractiveMap>(null);
  const [allProjects, setAllProjects] = useState<Project[]>([]);
  const [imageryMapDataUrlOption, setImageryMapDataUrlOption] = useState<Option<string>>(none);
  const [miniMapDataUrlOption, setMiniMapDataUrlOption] = useState<Option<string>>(none);

  useEffect(() => {
    const fetchProjects = async () => {
      const {
        data: { results: projects },
      } = await listProjects(campaignId, { pageSize: 9999, isActive: true });
      setAllProjects(projects);
    };
    fetchProjects();
  }, [campaignId]);

  const [minimapViewportOption, minimapStyleOption] = useMemo(() => {
    if (allProjects.length) {
      const { viewportOpt, styleOpt } = getMiniMapViews(allProjects);
      return [viewportOpt, styleOpt];
    }
    return [none, none];
  }, [allProjects]);

  const viewportOption = useMemo(() => pipe(project.aoi, filterMap(getViewportFromAoi)), [project]);

  const mapStyleOption = useMemo(
    () =>
      pipe(
        findFirst((tms: Tile) => tms.default)(project.tileLayers),
        map((tms) => ({
          version: 8,
          sources: {
            projectTile: {
              type: "raster",
              tiles: [tms.zxyUrl],
              tileSize: 256,
              scheme: "xyz",
            },
          },
          layers: [
            {
              id: "projectTile",
              type: "raster",
              source: "projectTile",
              fadeDuration: 0,
            },
          ],
        }))
      ),
    [project]
  );

  const onImageryMapLoad = () => {
    const map = imageryMapRef.current && imageryMapRef.current.getMap();
    if (map) {
      map.once("idle", () => setImageryMapDataUrlOption(some(map.getCanvas().toDataURL())));
    }
  };

  const onMiniMapLoad = () => {
    const map = miniMapRef.current && miniMapRef.current.getMap();
    if (map) {
      map.once("idle", () => setMiniMapDataUrlOption(some(map.getCanvas().toDataURL())));
    }
  };

  return foldOption(
    imageryMapDataUrlOption,
    () =>
      foldOption(seqOption(viewportOption, mapStyleOption), noEl, ([viewport, mapStyle]) => (
        <ReactMapGL
          ref={imageryMapRef}
          {...viewport}
          mapStyle={mapStyle}
          getCursor={() => "default"}
          onLoad={() => onImageryMapLoad()}
        ></ReactMapGL>
      )),
    (imageryMapDataUrl) => (
      <>
        <MapImage src={imageryMapDataUrl} />
        <MiniMapBox
          opacity={isSome(miniMapDataUrlOption) ? "1" : "0"}
          position="absolute"
          right="1px"
          bottom="1px"
          width="600px"
          height="400px"
          bg="none"
          borderRadius="base"
          p="0"
        >
          {foldOption(
            miniMapDataUrlOption,
            () =>
              foldOption(
                seqOption(minimapViewportOption, minimapStyleOption),
                noEl,
                ([minimapViewport, minimapStyle]) => (
                  <ReactMapGL
                    ref={miniMapRef}
                    {...minimapViewport}
                    mapStyle={minimapStyle}
                    getCursor={() => "default"}
                    onLoad={() => onMiniMapLoad()}
                  ></ReactMapGL>
                )
              ),
            (miniMapDataUrl) => (
              <MapImage src={miniMapDataUrl} />
            )
          )}
        </MiniMapBox>
      </>
    )
  );
};

export default PreviewMaps;
