import React, { useMemo, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  CloseButton,
  Box,
  Divider,
  Stack,
  Text,
  Heading,
  Skeleton,
  Portal,
  Tooltip,
} from "@chakra-ui/react";
import { Icon } from "@blasterjs/core";
import { useQuery } from "react-query";

import { getProject } from "http/project";
import { Campaign } from "datamodel/campaign";
import AccessControlProvider from "components/AccessControl/AccessControlProvider";
import AccessLimiterRedirect from "components/AccessControl/AccessLimiterRedirect";
import { ACRActionType } from "datamodel/permissions";
import Tab from "components/Tab";
import LabelButton from "../LabelButton";
import ValidateButton from "../ValidateButton";
import Overview from "./Overview";
import Settings from "./Settings";
import useEntityOwner from "hooks/useEntityOwner";
import { HITLJobStatus } from "datamodel/hitlJob";
import GButton from "components/GButton";
import { foldOption } from "lib";
import useHitlJob from "hooks/useHitlJob";

type Props = {
  campaign: Campaign;
  projectId: string;
  onClose: () => void;
};

enum ImageView {
  Overview = "overview",
  Settings = "settings",
}

const UnclickableButtons = () => (
  <Tooltip placement="auto" label="Prediction in progress">
    <Stack direction="row" spacing={4}>
      <GButton data-intercom-target="label-button" colorScheme="#fff" ml={1} disabled={true}>
        <Icon name="drawPolygon" mr={1} />
        Label
      </GButton>
      <GButton data-intercom-target="label-button" colorScheme="#fff" ml={1} disabled={true}>
        <Icon name="checkCircle" mr={1} />
        Validate
      </GButton>
    </Stack>
  </Tooltip>
);

const UnauthedProjectView = ({ campaign, projectId, onClose }: Props) => {
  const { data: project, isLoading } = useQuery(["projects", projectId], () =>
    getProject(projectId).then((resp) => resp.data)
  );

  const [view, setView] = useState(ImageView.Overview);

  const isOwner = useEntityOwner(campaign.owner);
  const { jobOpt, dirty, setDirty } = useHitlJob(projectId, campaign.id);

  const allowTaskAccess = useMemo(
    () =>
      foldOption(
        jobOpt,
        () => true,
        (job) => job.status === HITLJobStatus.Ran
      ),
    [jobOpt]
  );

  const hasHitlTask = useMemo(
    () =>
      foldOption(
        jobOpt,
        () => false,
        (job) => job.status === HITLJobStatus.Ran
      ),
    [jobOpt]
  );

  return (
    <Modal isOpen={true} size="full" onClose={onClose}>
      <ModalOverlay />
      <Portal>
        <ModalContent m={0} p={0}>
          <ModalBody p={0} bg="gray.50" display="flex" flexDirection="column">
            <Flex direction="column" flex="1">
              <Flex direction="row" bg="#fff" align="center" shadow="base">
                <CloseButton color="orange.500" size="lg" p={10} onClick={onClose} />
                <Box height="27px">
                  <Divider orientation="vertical" color="gray.400" />
                </Box>
                <Stack
                  direction="row"
                  ml={6}
                  flex="1"
                  spacing={4}
                  fontSize="14px"
                  fontWeight="bold"
                >
                  <Text>{campaign.name}</Text>
                  <Text>/</Text>
                  <Text>Image details</Text>
                </Stack>
              </Flex>
              <Box display="flex" flex={1} flexDirection="column">
                <Box
                  display="flex"
                  alignItems="stretch"
                  px={2}
                  m="0 auto"
                  maxWidth="1172px"
                  width="100%"
                >
                  <Box display="flex" flexDirection="column" alignItems="stretch" flex="1">
                    <Box display="flex" alignItems="center" my={10}>
                      <Box flex="1">
                        <Heading fontSize="38px" fontWeight="bold">
                          {isLoading ? <Skeleton width="300px" /> : <>{project?.name}</>}
                        </Heading>
                      </Box>
                      <Box alignment="right" justifySelf="flex-end">
                        {!!project &&
                          (allowTaskAccess ? (
                            <Stack direction="row" spacing={4}>
                              <LabelButton
                                campaign={campaign}
                                project={project}
                                colorScheme="#fff"
                              />
                              <ValidateButton
                                campaign={campaign}
                                project={project}
                                colorScheme="#fff"
                                hasHitlTask={hasHitlTask}
                              />
                            </Stack>
                          ) : (
                            <UnclickableButtons />
                          ))}
                      </Box>
                    </Box>
                    <Box display="flex" mt={2}>
                      <Tab
                        isActive={view === ImageView.Overview}
                        onClick={() => setView(ImageView.Overview)}
                      >
                        Overview
                      </Tab>
                      {isOwner && (
                        <Tab
                          isActive={view === ImageView.Settings}
                          onClick={() => setView(ImageView.Settings)}
                        >
                          Settings
                        </Tab>
                      )}
                    </Box>
                  </Box>
                </Box>
                {view === ImageView.Overview && (
                  <Overview
                    campaign={campaign}
                    project={project}
                    jobOpt={jobOpt}
                    dirty={dirty}
                    setDirty={setDirty}
                  />
                )}
                {project && view === ImageView.Settings && (
                  <Settings project={project} campaign={campaign} />
                )}
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Portal>
    </Modal>
  );
};

const ProjectView = ({ projectId, campaign, ...rest }: Props) => (
  <AccessControlProvider campaignId={campaign.id}>
    <AccessLimiterRedirect
      requiredActions={[[ACRActionType.Annotate], [ACRActionType.Validate]]}
      otherwise="/"
    >
      <UnauthedProjectView projectId={projectId} campaign={campaign} {...rest} />
    </AccessLimiterRedirect>
  </AccessControlProvider>
);

export default ProjectView;
