import { Option } from "fp-ts/es6/Option";
import { Dispatch } from "react";
import * as Nel from "fp-ts/es6/NonEmptyArray";

import { DrawPolygonHandler } from ".";
import { foldOption, seqOption, noop } from "lib";
import LabelingGeoJsonLayer from "./LabelingGeoJsonLayer";
import { DrawingActionType } from "types";
import DrawingAction, { DrawingActionSourceFeature } from "common/modules/DrawingAction";
import { createSetActionBuffer, createSetDrawingInProgress } from "state/ui-segmentation/actions";
import { createDrawingAction } from "pages/SegmentationTask/helpers";
import createLabelingGeoJsonLayer from "./createLabelingGeoJsonLayer";
import { v4 as uuid } from "uuid";
import { LabelClass } from "datamodel/labeClass";

interface LayerData {
  type: string;
  features: DrawingActionSourceFeature[];
}

interface OnEditParams {
  updatedData: LayerData;
}

const createDetectionDrawLayer = (
  activeDrawingTypeOption: Option<DrawingActionType>,
  selectedClassOption: Option<LabelClass>,
  actionBuffer: DrawingAction[],
  isLabeling: boolean,
  isRightClick: boolean,
  drawHandler: DrawPolygonHandler,
  dispatch: Dispatch<any>
) => {
  // We only want to create and use a drawing layer if the appropriate mode is
  // active. Otherwise, we return void to remove any map interactions
  if (isLabeling && !isRightClick) {
    return foldOption(
      seqOption(activeDrawingTypeOption, selectedClassOption),
      noop,
      ([activeDrawingType, selectedClass]): LabelingGeoJsonLayer | void => {
        if (activeDrawingType === DrawingActionType.NoOp) {
          return;
        }
        const onEdit = ({ updatedData }: OnEditParams) =>
          foldOption(Nel.fromArray(updatedData.features), noop, ([f]) => {
            dispatch(createSetDrawingInProgress(false));
            dispatch(
              createSetActionBuffer([
                ...actionBuffer,
                createDrawingAction(selectedClass, { ...f, id: uuid() }, activeDrawingType),
              ])
            );
          });

        return createLabelingGeoJsonLayer(onEdit, drawHandler);
      }
    );
  }
  return;
};

export default createDetectionDrawLayer;
