import React, { ReactNode } from "react";
import { UserFeatures } from "config";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";

type UserFeatureProps = {
  featureName: keyof UserFeatures;
  children: ReactNode;
};

export const UserFeatureFlag = ({ featureName, children }: UserFeatureProps) =>
  useUserFeatureFlag(featureName) ? <>{children}</> : <></>;
