import { Task, TaskStatus } from "datamodel/task";
import { Dispatch } from "redux";
import { putTaskStatus } from "http/task";
import { createSetLastError } from "state/ui-segmentation/actions";
import { Error } from "types";
import { none } from "fp-ts/es6/Option";

const cancelTask = async (task: Task, dispatch: Dispatch) => {
  // We always want to return a promise even if we choose not to send a request
  // so we initialize with a resolved promise
  let requestPromise = Promise.resolve();

  if (
    [TaskStatus.LabelingInProgress, TaskStatus.ValidationInProgress].includes(
      task.properties.status
    )
  ) {
    let nextStatus =
      task.properties.status === TaskStatus.ValidationInProgress
        ? TaskStatus.Validated
        : TaskStatus.Labeled;

    requestPromise = putTaskStatus(task.properties.projectId, task.id, {
      nextStatus,
      note: none,
    }).then(() => Promise.resolve());

    requestPromise.catch((err: Error) =>
      dispatch(createSetLastError("Failed to update task when trying to confirm", err))
    );
  }

  return requestPromise;
};

export default cancelTask;
