import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Intercom from "react-intercom";
import { some, none } from "fp-ts/es6/Option";

import { ApplicationStore } from "types";
import { foldOption, noEl } from "lib";
import { initializeAuth0Client } from "state/newAuth/helpers";
import TopBar from "./TopBar";
import Uploader from "components/Uploader";
import config from "config";

const Top = () => {
  const dispatch = useDispatch();

  const [clientOption, userOption] = useSelector(
    (state: ApplicationStore) => [state.newAuth.client, state.newAuth.user] as const
  );

  useEffect(() => {
    initializeAuth0Client(dispatch);
  }, [dispatch]);

  const intercomPropsOption = useMemo(
    () =>
      foldOption(
        userOption,
        () => none,
        (user) =>
          some({
            appID: config.intercomAppId,
            user_id: user.sub,
            name: user.name,
            email: user.email,
          })
      ),
    [userOption]
  );

  return foldOption(clientOption, noEl, () => (
    <>
      <TopBar />
      {foldOption(intercomPropsOption, noEl, (intercomProps) => (
        <Intercom {...intercomProps} />
      ))}
      <Uploader />
    </>
  ));
};

export default Top;
