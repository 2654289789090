import { Option, some, none } from "fp-ts/es6/Option";
import { Task, TaskNextStatus, TaskStatus } from "datamodel/task";
import { TaskUIMode } from "types";
import { Dispatch } from "redux";
import { foldOption, noop } from "lib";
import { putTaskStatus } from "http/task";
import { createSetTask, createSetLastError } from "state/ui-segmentation/actions";

const addInProgressStatus = (
  taskOption: Option<Task>,
  hasTaskLock: boolean,
  mode: TaskUIMode,
  dispatch: Dispatch
) => {
  if (hasTaskLock) {
    foldOption(taskOption, noop, (task) => {
      let taskNextStatusOpt: Option<TaskNextStatus> = none;
      if (
        mode === TaskUIMode.Labeling &&
        task.properties.status !== TaskStatus.LabelingInProgress
      ) {
        taskNextStatusOpt = some({
          nextStatus: TaskStatus.LabelingInProgress,
          note: none,
        });
      } else if (
        mode === TaskUIMode.Validating &&
        task.properties.status !== TaskStatus.ValidationInProgress
      ) {
        taskNextStatusOpt = some({
          nextStatus: TaskStatus.ValidationInProgress,
          note: none,
        });
      }
      foldOption(taskNextStatusOpt, noop, (taskNextStatus) => {
        putTaskStatus(task.properties.projectId, task.id, taskNextStatus)
          .then((resp) => {
            dispatch(createSetTask(some(resp.data)));
          })
          .catch((err) => dispatch(createSetLastError("Error updating task status", err)));
      });
    });
  }
};

export default addInProgressStatus;
