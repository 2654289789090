import React from "react";
import { FieldArrayRenderProps, FieldArray } from "react-final-form-arrays";
import { Field, FieldRenderProps } from "react-final-form";
import { Box, Card, Text, TextInput, Button } from "@blasterjs/core";

import { FieldGroupValue, FieldValue } from "..";
import { getEmptyClass, getEmptyClassGroup, errorFromValidate } from "../helpers";
import FormError from "components/FormError";
import ClassFieldArray from "./ClassFieldArray";

interface Props {
  fieldArrayProps: FieldArrayRenderProps<FieldGroupValue, HTMLElement>;
  maxClassCount: number;
}

const ClassGroupArray = ({ fieldArrayProps, maxClassCount }: Props) => {
  const onGroupNameChange = (
    props: FieldRenderProps<string, HTMLElement>,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.input.onChange(e);
  };

  const onClickNewQuestion = () => {
    fieldArrayProps.fields.push(
      getEmptyClassGroup(
        "",
        ["", ""].map((answer) => getEmptyClass())
      )
    );
  };

  const onClickRemoveQuestion = (index: number) => {
    fieldArrayProps.fields.remove(index);
  };

  const showRemoveIcon = () => {
    const fieldLength = fieldArrayProps.fields && fieldArrayProps.fields.length;
    return fieldLength && fieldLength > 1;
  };

  return (
    <>
      {fieldArrayProps.fields.map((name, index) => {
        return (
          <>
            <Card data-intercom-target="list-export" p={0} key={index} mt={1} maxWidth="520px">
              <Box display="flex" alignItems={"stretch"} flex={1} flexDirection="column">
                <Box m={2}>
                  <Box display="flex" alignItems={"stretch"} flexDirection="row">
                    <Text fontSize="1.6rem" fontWeight="600">{`Question ${index + 1}`}</Text>
                  </Box>
                  <Field
                    name={`${name}.name`}
                    validate={(value) => errorFromValidate(value, "A question is required")}
                  >
                    {(props) => {
                      return (
                        <>
                          <TextInput
                            maxWidth="340px"
                            mt={1}
                            data-intercom-target="class-group-name-input"
                            name={props.input.name}
                            value={props.input.value}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              onGroupNameChange(props, e)
                            }
                            onBlur={props.input.onBlur}
                            onFocus={props.input.onFocus}
                            placeholder="e.g., Are clouds present?"
                            mr={2}
                            invalid={props.meta.touched && props.meta.invalid}
                            autocomplete="false"
                            autoFocus={index === 0}
                          ></TextInput>
                          <Box mt="4px">
                            <FormError name={`${name}.name`} />
                          </Box>
                        </>
                      );
                    }}
                  </Field>
                </Box>
                <Box mx={2} mb={2} mt={1}>
                  <Text fontSize="1.6rem" fontWeight="600">
                    Answers
                  </Text>
                  <FieldArray name={`${name}.classes`}>
                    {(fieldArrProps: FieldArrayRenderProps<FieldValue, HTMLElement>) => (
                      <ClassFieldArray fieldArrProps={fieldArrProps} index={index} />
                    )}
                  </FieldArray>
                </Box>
                {showRemoveIcon() && (
                  <Box display="flex" alignItems={"left"} flexDirection="row" mb={1} ml={1}>
                    <Button
                      type="button"
                      borderRadius="0"
                      appearance="minimal"
                      onClick={() => onClickRemoveQuestion(index)}
                      p={1}
                      iconBefore="trash"
                    >
                      Delete question
                    </Button>
                  </Box>
                )}
              </Box>
            </Card>
          </>
        );
      })}
      <Button
        type="button"
        data-intercom-target="add-new-question"
        appearance="prominent"
        intent="primary"
        onClick={onClickNewQuestion}
        fontWeight="600"
        iconBefore="plus"
        maxWidth="190px"
        disabled={maxClassCount !== 0 && fieldArrayProps.fields.value.length >= maxClassCount}
        mt={2}
        withBorder
      >
        Add new question
      </Button>
    </>
  );
};

export default ClassGroupArray;
