import React from "react";
import { Box, Text } from "@blasterjs/core";

import { Skeleton } from "@chakra-ui/react";
import { GroupSummaryItem } from "hooks/useLabelClassSummary/helpers/types";
import commaSeparateNumber from "lib/commaSeparateNumber";

const GroupSummary = ({ summary }: { summary: GroupSummaryItem[] }) => (
  <Box>
    {summary.map((item) => (
      <Box mb={2} display="flex" flexDirection="column" alignItems="stretch" key={item.groupId}>
        <Text fontWeight="600" fontSize={2} mb="4px">
          {item.groupName}
        </Text>
        {item.classes.map((labelClass) => (
          <Box display="flex" mb="2px" alignItems="center" key={labelClass.classId}>
            <Text color="textLight" flex="1" fontSize={2}>
              {labelClass.className}
            </Text>
            <Box>
              <Text color="textLight" flex="1" fontSize={2}>
                {commaSeparateNumber(parseInt(labelClass.count) || 0)}
              </Text>
            </Box>
            <Text fontWeight="bold" fontSize={2} ml={2} color="#32323c">
              <Skeleton width="20px" />
            </Text>
          </Box>
        ))}
      </Box>
    ))}
  </Box>
);

export default GroupSummary;
