import React, { useCallback, useState } from "react";
import { Box, TextInput, Text, Heading } from "@blasterjs/core";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router";
import { useToast } from "@chakra-ui/react";
import { NumberFromString } from "io-ts-types";

import { deleteProject } from "http/project";
import GButton from "components/GButton";
import { Project } from "datamodel/project";
import { Campaign } from "datamodel/campaign";
import { queryClient } from "App";
import useDecodedQueryParamOrElse from "hooks/useDecodedQueryParamOrElse";
import { StatusFilter, statusFilterCodec } from "types";

const DELETE_TARGET = "DELETE";

const Settings = ({ project, campaign }: { project: Project; campaign: Campaign }) => {
  const history = useHistory();
  const toast = useToast();

  const [deleteKey, setDeleteKey] = useState<string>("");

  const location = useLocation();

  const page = useDecodedQueryParamOrElse("p", NumberFromString, 0);
  const statusFilter = useDecodedQueryParamOrElse("f", statusFilterCodec, StatusFilter.All);

  const { mutateAsync: deleteProjectMutation, ...deleteInfo } = useMutation(
    (project: Project) => deleteProject(project.id),
    {
      onError: () => {
        toast({
          title: "An error occurred.",
          description: "Unable to delete this image",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const onSubmitDelete = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      deleteProjectMutation(project).then((response) => {
        if (response) {
          queryClient.invalidateQueries(["campaign-projects", campaign.id, statusFilter, page]);
          history.push(location.pathname);
        }
      });
      return false;
    },
    [location, campaign, project, deleteProjectMutation, statusFilter, page, history]
  );

  return (
    <Box
      bg="white"
      borderTop="1px solid"
      borderColor="gray200"
      flex={1}
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        alignItems="stretch"
        px={2}
        py={3}
        m="0 auto"
        maxWidth="1172px"
        width="100%"
        flexDirection="column"
      >
        <Box p={0}>
          <Heading as="h5" mb={1}>
            Delete this image
          </Heading>
          <Box maxWidth="500px" mb={2}>
            <Text color="textLight" lineHeight="1.3">
              Deleting an image is an irrecoverable operation. When an image is deleted, all
              associated labels are also deleted.
            </Text>
          </Box>
          <Box p={0} mb={2}>
            <Text fontSize={2} color="textLight">
              Type <Text fontWeight="bold">{DELETE_TARGET}</Text> below to confirm.
            </Text>
          </Box>
          <Box p={0} display="inline-block">
            <form onSubmit={onSubmitDelete}>
              <TextInput
                width="400px"
                value={deleteKey}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeleteKey(e.target.value)}
                disabled={deleteInfo.isLoading}
              />
              <GButton
                type="submit"
                colorScheme="red.500"
                isLoading={deleteInfo.isLoading}
                disabled={deleteInfo.isLoading || deleteKey !== DELETE_TARGET}
                ml={2}
              >
                Delete permanently
              </GButton>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
