import { Project } from "datamodel/project";
import isProjectFailed from "./isProjectFailed";
import isProjectInProgress from "./isProjectInProgress";
import isProjectReady from "./isProjectReady";

interface ProjectStatusCount {
  failed: number;
  inProgress: number;
  ready: number;
}

const initProjectStatusCount = {
  failed: 0,
  inProgress: 0,
  ready: 0,
};

const getProjectsStatusCounts = (projects: Project[]): ProjectStatusCount =>
  projects.reduce((acc, project) => {
    if (isProjectInProgress(project)) {
      return { ...acc, inProgress: acc.inProgress + 1 };
    } else if (isProjectReady(project)) {
      return { ...acc, ready: acc.ready + 1 };
    } else if (isProjectFailed(project)) {
      return { ...acc, failed: acc.failed + 1 };
    }
    return acc;
  }, initProjectStatusCount);

export default getProjectsStatusCounts;
