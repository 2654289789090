import React from "react";
import { Text } from "@blasterjs/core";
import { Option } from "fp-ts/es6/Option";

import { TaskStatus } from "datamodel/task";
import { TaskURLActionType } from "types";
import { foldOption, noEl } from "lib";
import { StartLabeling } from "./Buttons";

interface Props {
  taskStatusOption: Option<TaskStatus>;
  onClickUrlAction: (actionType: TaskURLActionType) => void;
}

const LabelerMenu = ({ taskStatusOption, onClickUrlAction }: Props) =>
  foldOption(taskStatusOption, noEl, (status) =>
    status === TaskStatus.Unlabeled ? (
      <StartLabeling onClickUrlAction={onClickUrlAction} />
    ) : (
      <Text>No actions available</Text>
    )
  );

export default LabelerMenu;
