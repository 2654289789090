import React, { ReactNode } from "react";
import { Box, Image, Text } from "@chakra-ui/react";

type Props = {
  title: string;
  description?: string;
  children?: ReactNode;
};

const EmptyState = ({ title, description, children }: Props) => (
  <Box display="flex" alignItems="center" justifyContent="center" flex="1" mt="100px">
    <Box display="flex" flexDirection="column" alignItems="center" width="300px">
      <Image
        alt="logo"
        title="Logo"
        width="60%"
        src={`${process.env.PUBLIC_URL}/groundwork_geospatial_context_illo.svg`}
      />
      <Text my={8} color="#262224" textAlign="center">
        {title}
      </Text>
      <Box>
        <Text color="textLight">{description}</Text>
      </Box>
      <Box>{children}</Box>
    </Box>
  </Box>
);

export default EmptyState;
