import React, { useState, useEffect, useCallback, ReactNode, MutableRefObject } from "react";
import { findFirst } from "fp-ts/es6/Array";
import { pipe } from "fp-ts/es6/pipeable";
import { Option, none, some, map, isSome } from "fp-ts/es6/Option";

import { DeckPick, MapContextMenuEvent, MvtLabelProps } from "types";
import { foldOption, noEl, noop } from "lib";
import MenuPositioner from "./MenuPositioner";

export type MenuProps = {
  children: (pick: DeckPick | Option<MvtLabelProps>, callback: () => void, t: number) => ReactNode;
  deckRef: MutableRefObject<any>;
  staticMapRef?: MutableRefObject<any>;
  onActivate: () => void;
  onHide: () => void;
};

interface MvtLabelLayer {
  layer: {
    id: string;
  };
  properties: MvtLabelProps;
}

const ContextMenu = ({
  children,
  deckRef,
  staticMapRef,
  onActivate = noop,
  onHide = noop,
}: MenuProps) => {
  const mapWrapper = document.getElementById("deckgl-overlay");

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [lastClickOption, setLastClickOption] = useState<Option<MapContextMenuEvent>>(none);

  useEffect(() => {
    setLastClickOption(none);
    return () => {
      setLastClickOption(none);
    };
  }, []);

  const callback = useCallback(() => {
    setLastClickOption(none);
    setShowContextMenu(false);
    onHide();
  }, [onHide]);

  const onContextMenu = useCallback(
    (evt: MouseEvent) => {
      const deck = deckRef.current;
      evt.preventDefault();
      const x = evt.offsetX;
      const y = evt.offsetY;
      if (mapWrapper && deck && evt.button === 2) {
        const d = deckRef?.current;
        const p = d.pickObject({ x, y });
        onActivate();
        if (p?.object?.properties?.label) {
          setShowContextMenu(true);
          setLastClickOption(
            some({
              pick: p,
              mouseEvent: evt,
              mapElement: mapWrapper,
              t: new Date().getTime(),
            })
          );
        } else if (staticMapRef?.current) {
          const layers: MvtLabelLayer[] = staticMapRef.current.queryRenderedFeatures([x, y]);
          const pickOption = pipe(
            findFirst(
              (layer: MvtLabelLayer) =>
                "task_id" in layer.properties && layer.layer.id === "projectLabelsLayer"
            )(layers),
            map((layer) => layer.properties)
          );
          if (isSome(pickOption)) {
            setShowContextMenu(true);
            setLastClickOption(
              some({
                pick: pickOption,
                mouseEvent: evt,
                mapElement: mapWrapper,
                t: new Date().getTime(),
              })
            );
          }
        }
      }
    },
    [deckRef, staticMapRef, mapWrapper, onActivate]
  );

  useEffect(() => {
    if (mapWrapper) {
      mapWrapper.addEventListener("contextmenu", onContextMenu);
      mapWrapper.addEventListener("click", callback);
    }
    return () => {
      mapWrapper?.removeEventListener("contextmenu", onContextMenu);
      mapWrapper?.removeEventListener("click", callback);
    };
  }, [callback, mapWrapper, onContextMenu]);

  return showContextMenu
    ? foldOption(lastClickOption, noEl, ({ pick, mouseEvent, mapElement, t }) => (
        <MenuPositioner e={mouseEvent} el={mapElement}>
          {children(pick, callback, t)}
        </MenuPositioner>
      ))
    : null;
};

export default ContextMenu;
