import { Box, HStack, Icon, IconButton, VStack, WrapItem, Flex, Skeleton } from "@chakra-ui/react";
import GButton from "components/GButton";
import React from "react";
import { FaFlag } from "react-icons/fa";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";

const SkeletonIssueCard = () => (
  <WrapItem>
    <Flex
      direction="column"
      border="1px solid"
      borderColor="gray.100"
      shadow="base"
      position="relative"
      height="100%"
    >
      <VStack width="374px" flex="1">
        <Box height="308px" background="gray.100" alignSelf="stretch" />
        <VStack alignSelf="stretch" p={4} flex="1">
          <HStack alignSelf="stretch" alignItems="flex-start" flex="1">
            <VStack flex="1" alignItems="flex-start" spacing={3}>
              <HStack spacing={3}>
                <Icon as={FaFlag} color="gray.300" size="14px" />
                <Skeleton width="40px" />
              </HStack>
              <Skeleton width="200px" />
            </VStack>
            <IconButton
              aria-label="task menu"
              variant="ghost"
              icon={<Icon as={IoEllipsisHorizontalSharp} />}
              fontSize="14px"
              color="textLight"
              disabled
            />
          </HStack>
          <HStack alignSelf="stretch" spacing={6}>
            <GButton colorScheme="#fff" flex="1" disabled></GButton>
            <GButton colorScheme="danger" flex="1" disabled></GButton>
          </HStack>
        </VStack>
      </VStack>
    </Flex>
  </WrapItem>
);

export default SkeletonIssueCard;
