import { Option } from "fp-ts/es6/Option";

import { foldOption } from "lib";
import { getEmptyClass, getEmptyClassGroup } from "../helpers";
import { CampaignType } from "datamodel/campaign";

const DEFAULTS = {
  maxClassCount: 0,
  classDefInitVals: [getEmptyClassGroup("", [getEmptyClass()])],
};

const getClassGroupInitVal = (projectTypeOption: Option<CampaignType>) =>
  foldOption(
    projectTypeOption,
    () => DEFAULTS.classDefInitVals,
    (projectType: CampaignType) => {
      if (projectType === CampaignType.Classification) {
        return [getEmptyClassGroup("", [getEmptyClass(), getEmptyClass()])];
      }
      return DEFAULTS.classDefInitVals;
    }
  );

export default getClassGroupInitVal;
