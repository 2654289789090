import { Option } from "fp-ts/es6/Option";
import { Limit, LimitDomain } from "datamodel/limits";
import { foldOption } from "lib";
import pickLimit from "./pickLimit";

const isWithinLimit = (
  limitsOption: Option<Limit[]>,
  domain: LimitDomain,
  action: string = "",
  objectId: string = ""
): boolean =>
  foldOption(
    pickLimit(limitsOption, domain, action, objectId),
    () => true,
    (l) => !l.limit || l.used < l.limit
  );

export default isWithinLimit;
