import React, { useCallback } from "react";
import { Box, Icon, Button, Text, Select } from "@blasterjs/core";
import styled from "styled-components";
import { ACRActionType, UserThinWithActionType } from "datamodel/permissions";
import { UserFeatureFlag } from "components/FeatureFlags";
import { Spinner, useToast } from "@chakra-ui/react";
import { Campaign } from "datamodel/campaign";
import { useMutation } from "react-query";
import { deleteShare, postShares } from "http/campaign";
import { queryClient } from "App";

const UserEmail = styled(Text)`
  text-overflow: ellipsis;
  color: #262224;
`;

const TextButton = styled(Button)`
  border: none;
  text-decoration: underline;
  padding: 0px;
  margin: 0px;
`;

interface Props {
  campaign: Campaign;
  userShare: UserThinWithActionType;
}

type ChangeShareMutation = {
  emails: string[];
  action: ACRActionType;
  silent: boolean;
};

const UserRow = ({ campaign, userShare }: Props) => {
  const toast = useToast();
  const { mutateAsync: changeShareMutation, ...changeShareQueryInfo } = useMutation(
    ({ emails, action, silent }: ChangeShareMutation) =>
      postShares(campaign, emails, action, silent),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaign.id, "shares"]);
        queryClient.invalidateQueries(["limits"]);
      },
      onError: () => {
        toast({
          title: "An error occurred.",
          description: "Unable to change the sharing level",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const { mutateAsync: removeShareMutation, ...removeShareQueryInfo } = useMutation(
    (userId: string) => deleteShare(campaign, userId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaign.id, "shares"]);
        queryClient.invalidateQueries(["limits"]);
      },
      onError: () => {
        toast({
          title: "An error occurred.",
          description: "Unable to remove that user from the campaign",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const onChangeAction = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      changeShareMutation({
        emails: [userShare.email],
        action: e.target.value as ACRActionType,
        silent: true,
      });
    },
    [changeShareMutation, userShare.email]
  );

  const onClickDelete = useCallback(
    () => removeShareMutation(userShare.id),
    [removeShareMutation, userShare.id]
  );

  const isLoading = changeShareQueryInfo.isLoading || removeShareQueryInfo.isLoading;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      width="575px"
      mb={2}
    >
      <UserEmail flex={1} mr={1} fontWeight="bold">
        {userShare.email}
      </UserEmail>
      <UserFeatureFlag featureName="validation">
        {isLoading && <Spinner color="primary" mr={4} />}
        <Select
          width="auto"
          mr={2}
          value={userShare.actionType}
          disabled={isLoading}
          onChange={onChangeAction}
        >
          <option value={ACRActionType.Annotate}>Labeler</option>
          <option value={ACRActionType.Validate}>Validator</option>
        </Select>
      </UserFeatureFlag>
      <TextButton onClick={onClickDelete} data-intercom-target="remove-user-share">
        <Icon name="cross" size="16px" color="#262224" />
      </TextButton>
    </Box>
  );
};

export default UserRow;
