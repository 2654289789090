import config, { UserFeatures } from "config";
import { useSelector } from "react-redux";
import { ApplicationStore } from "types";
import { exists } from "fp-ts/es6/Option";
import { pipe } from "fp-ts/es6/pipeable";

const useUserFeatureFlag = (featureName: keyof UserFeatures) => {
  const userOption = useSelector((state: ApplicationStore) => state.newAuth.user);
  return pipe(
    userOption,
    exists((user) => config.userFeatures[featureName](user))
  );
};

export default useUserFeatureFlag;
