import { ACRActionType } from "datamodel/permissions";

const verifyActions = (userActions: ACRActionType[], requiredActions: ACRActionType[][]) =>
  userActions.includes(ACRActionType.All) ||
  requiredActions.reduce(
    (acc, actionSet) =>
      acc || actionSet.length === actionSet.filter((a) => userActions.includes(a)).length,
    false as boolean
  );

export default verifyActions;
