import { LabelClass, LabelClassCreate } from "datamodel/labeClass";
import { LabelGroupCreate } from "datamodel/labelGroup";
import { postCampaignLabelClasses, updateCampaignLabelClasses } from "http/labelClass";
import { postCampaignLabelGroup } from "http/labelGroup";

export interface ClassUpdate {
  toUpdate: LabelClass[];
  toPost: LabelClassCreate[];
}
const updateClasses = async (
  grouped: ClassUpdate,
  campaignId: string,
  labelClassGroupId: string
) => {
  if (labelClassGroupId) {
    if (!!grouped.toUpdate.length) {
      try {
        await updateCampaignLabelClasses(campaignId, grouped.toUpdate);
      } catch (err) {
        console.error(err);
      }
    }

    if (!!grouped.toPost.length) {
      try {
        await postCampaignLabelClasses(campaignId, labelClassGroupId, grouped.toPost);
      } catch (err) {
        console.error(err);
      }
    }
  } else {
    // if there is no label class group, there should not be anything to update
    // so we create a new group with classes
    const group = {
      name: "default",
      index: 0,
      classes: grouped.toPost,
      isActive: true,
    } as LabelGroupCreate;
    try {
      await postCampaignLabelGroup(campaignId, group);
    } catch (err) {
      console.error(err);
    }
  }
};

export default updateClasses;
