import axios, { AxiosResponse } from "axios";
import qs from "qs";

import getBaseRequestConfig from "./helpers/getBaseRequestConfig";
import { PaginatedResponse, PaginatedQueryParams } from "types";
import { decodePaginated } from "./transformers/paginated";
import { decodeAxiosResponse } from "./transformers/base";
import { TaskStatus } from "datamodel/task";
import { foldOption } from "lib";
import { Project, ProjectCreate, projectCodec, projectCreateCodec } from "datamodel/project";

export enum ProjectSort {
  NewestFirst = "created_at,desc",
  OldestFirst = "create_at,asc",
}

export interface ProjectListRequestParams extends PaginatedQueryParams {
  search?: string;
  sort?: ProjectSort;
  taskStatusesInclude?: TaskStatus[];
  isActive?: boolean;
}

export const listProjects = async (
  params: ProjectListRequestParams
): Promise<AxiosResponse<PaginatedResponse<Project>>> =>
  decodePaginated(projectCodec)(
    await axios.get("/projects", {
      ...getBaseRequestConfig(),
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    })
  );

export const postProject = async (project: ProjectCreate): Promise<AxiosResponse<Project>> =>
  decodeAxiosResponse(projectCodec)(
    await axios.post(`/projects/`, projectCreateCodec.encode(project), {
      ...getBaseRequestConfig(),
    })
  );

export const getProject = async (id: string): Promise<AxiosResponse<Project>> =>
  decodeAxiosResponse(projectCodec)(
    await axios.get(`projects/${id}`, {
      ...getBaseRequestConfig(),
    })
  );

export const putProject = async (project: Project, id: string): Promise<AxiosResponse<"">> =>
  await axios.put(`projects/${id}`, projectCodec.encode(project), {
    ...getBaseRequestConfig(),
  });

export const deleteProject = async (id: string): Promise<AxiosResponse<"">> =>
  await axios.delete(`projects/${id}`, getBaseRequestConfig());

export const createTaskGrid = async (
  project: Project,
  gridSizeMeters: number
): Promise<AxiosResponse<null>> =>
  foldOption(
    project.aoi,
    () => Promise.reject(),
    async (aoi) =>
      await axios.post(
        `projects/${project.id}/tasks/grid`,
        { type: "Feature", geometry: aoi, properties: { sizeMeters: gridSizeMeters } },
        { ...getBaseRequestConfig() }
      )
  );
