import { CancelTokenSource } from "axios";
import { Upload } from "datamodel/upload";
import { Option } from "fp-ts/Option";
import { UUID } from "io-ts-types";

export enum UploadStep {
  WAITING,
  START,
  VALIDATED,
  UPLOADING,
  UPLOADED,
  COMPLETE,
  ERROR,
}

export type UploadSequence = {
  id: string;
  campaignId: UUID;
  uploadProgress: number;
  isSingleBand: boolean;
  uploadStep: UploadStep;
  file: File;
  upload: Option<Upload>;
  error: Option<Error>;
  cancelTokenSource: CancelTokenSource;
};
