import React, { useEffect, useMemo } from "react";
import { Box, Button, Pagination, Text } from "@blasterjs/core";
import { RouteComponentProps } from "react-router-dom";
import useRouter from "use-react-router";
import { useDebouncedCallback } from "use-debounce";
import { ApplicationStore } from "types";
import { useSelector, useDispatch } from "react-redux";
import { replace, push } from "connected-react-router";
import { useLocalStorage } from "@rehooks/local-storage";
import { Alert, AlertIcon, AlertDescription, CloseButton, Flex } from "@chakra-ui/react";

import { createSetPaginatedResponse, createPurge } from "state/ui-campaign-list/actions";
import { foldOption, noEl } from "lib";
import SearchInput from "./SearchInput";
import LabeledSelectT, { SelectOptions } from "./LabeledSelectT";
import LimitsProvider from "components/Limits/LimitsProvider";
import { LimitsContextConsumer } from "components/Limits/LimitsContext";
import isWithinLimit from "pages/helpers/isWithinLimit";
import { LimitDomain, ExistingLimit } from "datamodel/limits";
import LimitMessage from "components/Limits/LimitMessage";
import UpgradePrompt from "components/Limits/UpgradePrompt";
import EmptyState from "components/EmptyState";
import AppFooter from "components/AppFooter";
import LoadingIcon from "components/LoadingIcon";
import FlexFiller from "components/FlexFiller";
import provideDefaults from "./helpers/provideDefaults";
import { CampaignSort, listCampaigns, CampaignOwnershipType } from "http/campaign";
import CampaignCard from "./CampaignCard";
import { UserFeatureFlag } from "components/FeatureFlags";
import config from "config";

export interface RouteProps {
  page: string | undefined;
  sort: CampaignSort | undefined;
  ownershipType: CampaignOwnershipType | undefined;
  search: string | undefined;
}

type Route = RouteComponentProps<RouteProps>;

export interface SearchParams {
  page: number;
  sort: CampaignSort;
  ownershipType: CampaignOwnershipType;
  search: string;
}

export const DEFAULTS: SearchParams = {
  page: 1,
  sort: CampaignSort.NewestFirst,
  ownershipType: CampaignOwnershipType.All,
  search: "",
};

const sortOptions: SelectOptions = [
  [CampaignSort.NewestFirst, "newest first"],
  [CampaignSort.OldestFirst, "oldest first"],
];

const ownershipOptions: SelectOptions = [
  [CampaignOwnershipType.All, "all campaigns"],
  [CampaignOwnershipType.Owned, "my campaigns"],
  [CampaignOwnershipType.Shared, "shared with me"],
];

const Campaigns = () => {
  const dispatch = useDispatch();
  const {
    match: {
      params: { page, sort, ownershipType, search },
    },
  }: Route = useRouter();

  const [limitBannerLastHidden, setLimitBannerLastHidden] = useLocalStorage<number>(
    "campaignList:limitBannerLastHidden"
  );

  const [pageSize, campaignsOption, countOption, dirty] = useSelector(
    (state: ApplicationStore) =>
      [
        state.campaignListUI.pageSize,
        state.campaignListUI.campaigns,
        state.campaignListUI.count,
        state.campaignListUI.dirty,
      ] as const
  );

  // These three URL parameters are required
  const paramsDefined = page && sort && ownershipType;
  const safeParams = useMemo(
    () => provideDefaults({ page, sort, ownershipType, search }),
    [page, sort, ownershipType, search]
  );

  // Handles redirecting to a default route when necessary params aren't
  // provided, otherwise, fetches the appropriate results page
  useEffect(() => {
    if (!paramsDefined) {
      // At least one of the necessary parameters is undefined
      dispatch(
        replace(
          `/campaign-list/${safeParams.page}/${safeParams.sort}/${safeParams.ownershipType}/${safeParams.search}`
        )
      );
    } else {
      let isCurrent = true;
      const doFetch = async () => {
        const paginatedCampaignsResp = await listCampaigns({
          ...safeParams,
          pageSize,
          isActive: true,
        });
        if (isCurrent) {
          dispatch(createSetPaginatedResponse(paginatedCampaignsResp.data));
        }
      };
      dispatch(createPurge());
      doFetch();
      return () => {
        isCurrent = false;
      };
    }

    return () => {
      dispatch(createPurge());
    };
  }, [paramsDefined, safeParams, pageSize, dispatch, dirty]);

  // We destructure with defaults so that a partial set of params can be passed
  // while current parameter values are preserved
  const onParamsChanged = ({
    page = safeParams.page,
    sort = safeParams.sort,
    ownershipType = safeParams.ownershipType,
    search = safeParams.search,
  }: Partial<SearchParams>) => {
    if (paramsDefined) {
      dispatch(push(`/campaign-list/${page}/${sort}/${ownershipType}/${search}`));
    }
  };

  const [onDebouncedParamsChanged, cancelDebouncedParamsChange] = useDebouncedCallback(
    ({
      page = safeParams.page,
      sort = safeParams.sort,
      ownershipType = safeParams.ownershipType,
      search = safeParams.search,
    }: Partial<SearchParams>) => {
      if (paramsDefined) {
        dispatch(push(`/campaign-list/${page}/${sort}/${ownershipType}/${search}`));
      }
    },
    1000
  );

  const onNewCampaignClicked = () => {
    dispatch(push(`/campaign-create`));
  };

  const shouldShowBanner = (limit: ExistingLimit) =>
    !limitBannerLastHidden && limit.used >= limit.limit - config.campaignLimitMinuend;

  const onClickCloseAlert = () => {
    setLimitBannerLastHidden(new Date().getTime());
  };

  return (
    <UserFeatureFlag featureName="campaigns">
      <LimitsProvider>
        <Box display="flex" flex={1} flexDirection="column" alignItems="stretch">
          <>
            <Box mb={2} borderColor="#e5e8e5" borderBottom="1px solid" bg="white">
              <Box display="flex" px={2} py={1} maxWidth="1172px" m="0 auto" alignItems="center">
                <Box mr={1} display="inline-block">
                  <SearchInput
                    data-intercom-target="search-by-name"
                    value={safeParams.search}
                    placeholder="Filter by name"
                    onChange={(search: string) =>
                      onDebouncedParamsChanged({ search, page: DEFAULTS.page })
                    }
                    onInputCleared={() => {
                      cancelDebouncedParamsChange();
                      onParamsChanged({ search: "", page: DEFAULTS.page });
                    }}
                    autoFocus
                  />
                </Box>
                <Box data-intercom-target="sort-projects" mr={1} display="inline-block">
                  <LabeledSelectT<CampaignSort>
                    options={sortOptions}
                    value={safeParams.sort}
                    label="Sort by:"
                    onChange={(s) => onParamsChanged({ sort: s, page: DEFAULTS.page })}
                  />
                </Box>
                <Box data-intercom-target="project-owner-filter" mr={1} display="inline-block">
                  <LabeledSelectT<CampaignOwnershipType>
                    options={ownershipOptions}
                    value={safeParams.ownershipType}
                    label="View:"
                    onChange={(o) => onParamsChanged({ ownershipType: o, page: DEFAULTS.page })}
                  />
                </Box>
                <FlexFiller />
                <Box alignment="right" justifySelf="flex-end">
                  <LimitsContextConsumer>
                    {(limitsOption) => (
                      <Button
                        data-intercom-target="create-new-project"
                        appearance="prominent"
                        intent="secondary"
                        iconBefore="plus"
                        onClick={onNewCampaignClicked}
                        disabled={!isWithinLimit(limitsOption, LimitDomain.Campaigns, "create")}
                        mr={2}
                        withBorder
                      >
                        New Campaign
                      </Button>
                    )}
                  </LimitsContextConsumer>
                </Box>
              </Box>
            </Box>
            <LimitMessage
              domain={LimitDomain.Campaigns}
              render={(limit) =>
                shouldShowBanner(limit) && (
                  <Flex mx={2} mb={2}>
                    <Alert status="warning" height="45px">
                      <AlertIcon />
                      <AlertDescription>
                        <Flex>
                          {limit.used === limit.limit ? (
                            <Text>
                              🎉&nbsp;&nbsp;You've reached the free-tier limit of
                              <Text fontWeight="bold"> {limit.limit} </Text>
                              campaigns.&nbsp;
                            </Text>
                          ) : (
                            <Text>
                              <Text fontWeight="bold"> {limit.used} </Text> of
                              <Text fontWeight="bold"> {limit.limit} </Text> campaigns used.&nbsp;
                            </Text>
                          )}
                          <UpgradePrompt />
                        </Flex>
                      </AlertDescription>
                      <CloseButton
                        position="absolute"
                        right="8px"
                        top="8px"
                        onClick={onClickCloseAlert}
                      />
                    </Alert>
                  </Flex>
                )
              }
            />
          </>
          {foldOption(
            campaignsOption,
            () => (
              <LoadingIcon />
            ),
            (campaigns) =>
              !!campaigns.length ? (
                <Box>
                  <Box display="flex" py={1} maxWidth="1172px" m="0 auto" alignItems="stretch">
                    <Box display="flex" flexDirection="column" width="100%">
                      <Box pt={0} pb={1} px={1} display="flex" flexWrap="wrap">
                        {campaigns.map((campaign, idx) => (
                          <CampaignCard campaign={campaign} key={idx} />
                        ))}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                      >
                        {foldOption(countOption, noEl, (count) => (
                          <>
                            <LimitMessage
                              domain={LimitDomain.Campaigns}
                              render={(limit) => (
                                <Box
                                  mx={2}
                                  mb={2}
                                  mt={1}
                                  flex="1"
                                  textAlign="left"
                                  alignSelf="left"
                                >
                                  <Text color="gray500">
                                    {limit.used} of {limit.limit} campaigns used. <UpgradePrompt />
                                  </Text>
                                </Box>
                              )}
                            />
                            {count > pageSize && (
                              <Pagination
                                display="inline-block"
                                currentPage={safeParams.page}
                                pageCount={Math.ceil(count / pageSize)}
                                onPageChange={(toPage: number) => onParamsChanged({ page: toPage })}
                                buttonAppearance="default"
                                label=""
                                firstAndLast={false}
                              />
                            )}
                          </>
                        ))}
                      </Box>
                    </Box>
                    <FlexFiller />
                  </Box>
                </Box>
              ) : !!(search && search.length) ? (
                <EmptyState title="No campaigns match your search" />
              ) : (
                <EmptyState title="Start a labeling campaign">
                  <Button
                    data-intercom-target="create-new-project"
                    appearance="prominent"
                    intent="primary"
                    iconBefore="plus"
                    onClick={onNewCampaignClicked}
                    withBorder
                  >
                    New Campaign
                  </Button>
                </EmptyState>
              )
          )}
        </Box>
        <AppFooter />
      </LimitsProvider>
    </UserFeatureFlag>
  );
};

export default Campaigns;
