import React, { useCallback } from "react";
import { ApplicationStore, SemanticLabelFeature, DrawingActionType, DeckPick } from "types";
import { MenuItem, MenuHeader } from "./styled";
import { Swatch, Text } from "@blasterjs/core";
import { foldOption, noop, noEl } from "lib";
import { useDispatch, useSelector } from "react-redux";
import { findFirst } from "fp-ts/es6/Array";
import { DrawingActionSourceFeature } from "common/modules/DrawingAction";
import { LabelClass } from "datamodel/labeClass";
import { createDrawingAction } from "pages/SegmentationTask/helpers";
import { createSetActionBuffer } from "state/ui-segmentation/actions";
import getClickedPolygon from "./helpers/getClickedPolygon";
import collapseLabelClassGroups from "pages/Campaigns/CampaignCard/helpers/collapseLabelClassGroups";

type Props = {
  pick: DeckPick;
  callback?: () => void;
};

const SegmentationReclassifyMenu = ({
  pick: { object: feature, coordinate },
  callback = noop,
}: Props) => {
  const dispatch = useDispatch();

  const [displayBuffer, actionBufferCache, labelClassGroupsOpt] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.displayBuffer,
        state.segmentationUI.actionBufferCache,
        state.segmentationUI.labelClassGroups,
      ] as const
  );

  const onClick = useCallback(
    (selectedLabelClass: LabelClass) => {
      const selectedShapeId = feature.properties.label;

      foldOption(
        findFirst((f: SemanticLabelFeature) => f.properties.label === selectedShapeId)(
          displayBuffer.features
        ),
        noop,
        (matchedFeature) => {
          const selectedPolygonOption = getClickedPolygon(coordinate, feature);
          foldOption(selectedPolygonOption, noop, (selectedPolygon) => {
            const selectedMultiPoly = {
              geometry: {
                type: "MultiPolygon",
                coordinates: [selectedPolygon.geometry.coordinates],
              },
              properties: {},
              type: "Feature",
            } as DrawingActionSourceFeature;

            dispatch(
              createSetActionBuffer([
                ...actionBufferCache,
                createDrawingAction(
                  selectedLabelClass,
                  selectedMultiPoly,
                  DrawingActionType.DrawAbove
                ),
              ])
            );
          });
        }
      );
      callback();
    },
    [actionBufferCache, callback, coordinate, dispatch, displayBuffer.features, feature]
  );

  return (
    <div style={{ maxHeight: "250px", overflowY: "scroll" }}>
      {foldOption(labelClassGroupsOpt, noEl, (labelClassGroups) => (
        <>
          <MenuHeader>Change classification to...</MenuHeader>
          {collapseLabelClassGroups(labelClassGroups)
            .filter((l) => feature.properties.label !== l.id)
            .map((l, idx) => (
              <MenuItem onClick={() => onClick(l)} key={idx}>
                <Swatch color={l.colorHexCode} mr={2} size="13px" />
                <Text>{l.name}</Text>
              </MenuItem>
            ))}
        </>
      ))}
    </div>
  );
};

export default SegmentationReclassifyMenu;
