import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

import Login from "./pages/Auth/Login";
import Logout from "./pages/Auth/Logout";
import SignUp from "./pages/Auth/SignUp";
import Callback from "./pages/Auth/Callback";
import SegmentationTask from "./pages/SegmentationTask";
import CampaignCreate from "pages/CampaignCreate";
import CampaignView from "pages/CampaignView";
import { UserFeatureFlag } from "components/FeatureFlags";
import CampaignList from "./pages/Campaigns";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";
import { ApplicationStore } from "types";

const Router = () => {
  const [isLoadingUser, isAuthenticated] = useSelector((state: ApplicationStore) => [
    state.newAuth.isLoading,
    state.newAuth.isAuthenticated,
  ]);
  const entity = useUserFeatureFlag("campaigns") ? "campaign" : "project";
  const rootRedirect = `/${entity}-list/:page?/:sort?/:ownershipType?/:search?`;

  if (isLoadingUser) return <></>;

  return (
    <>
      <Route
        exact
        path="/"
        render={() => <Redirect to={isAuthenticated ? rootRedirect : "/login"} />}
      />
      <Switch>
        <Route
          path="/campaign-list/:page?/:sort?/:ownershipType?/:search?"
          render={() => (
            <UserFeatureFlag featureName="campaigns">
              <AuthenticatedRoute
                path="/campaign-list/:page?/:sort?/:ownershipType?/:search?"
                component={CampaignList}
              />
            </UserFeatureFlag>
          )}
        />
        <AuthenticatedRoute
          exact
          path="/projects/:projectId/tasks/:taskId/:action/:projectType?"
          component={SegmentationTask}
        />
        <Route
          exact
          path="/campaign-create/:step?/:imageryTab?"
          render={() => (
            <UserFeatureFlag featureName="campaigns">
              <AuthenticatedRoute
                exact
                path="/campaign-create/:step?/:imageryTab?"
                component={CampaignCreate}
              />
            </UserFeatureFlag>
          )}
        />
        <Route
          path="/campaign/:campaignId"
          render={() => (
            <UserFeatureFlag featureName="campaigns">
              <AuthenticatedRoute path="/campaign/:campaignId" component={CampaignView} />
            </UserFeatureFlag>
          )}
        />
      </Switch>

      <Route path="/login" component={Login} />
      <Route path="/logout" component={Logout} />
      <Route path="/callback" component={Callback} />
      <Route path="/sign-up" component={SignUp} />
    </>
  );
};

export default Router;
