import styled from "styled-components";

export default styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  text-decoration: underline;
  cursor: pointer;
`;
