import { Project, ProjectStatus } from "datamodel/project";

const isProjectFailed = (project: Project): boolean =>
  project.status in
  [
    ProjectStatus.ImageIngestionFailure,
    ProjectStatus.TaskGridFailure,
    ProjectStatus.UnknownFailure,
  ];

export default isProjectFailed;
