import { Task, TaskStatus } from "datamodel/task";
import { SemanticLabelFeatureCollection } from "types";
import { putTaskValidation, putTaskLabels } from "http/task";
import { LabelCreateCollection } from "datamodel/label";
import getSegmentationFeatureCollection from "../Sidebar/helpers/getSegmentationFeatureCollection";

const replaceLabels = async (
  task: Task,
  displayBuffer: SemanticLabelFeatureCollection,
  nextStatus: TaskStatus
) => {
  const taskLabelCreateCollection: LabelCreateCollection = getSegmentationFeatureCollection(
    displayBuffer,
    nextStatus
  );

  const put = [TaskStatus.Unlabeled, TaskStatus.LabelingInProgress].includes(task.properties.status)
    ? putTaskLabels
    : putTaskValidation;

  await put(task, taskLabelCreateCollection);
};

export default replaceLabels;
