import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { TaskStatus } from "./task";

export const labelPropertiesCodec = t.type({
  createdAt: t.string,
  owner: t.string,
  projectId: t.string,
  taskId: t.string,
  classes: t.array(t.string),
  hitlVersionId: optionFromNullable(t.string),
});

export const labelCreatePropertiesCodec = t.type({
  classes: t.array(t.string),
  hitlVersionId: optionFromNullable(t.string),
});

export type LabelProperties = t.TypeOf<typeof labelPropertiesCodec>;

export type LabelCreateProperties = t.TypeOf<typeof labelCreatePropertiesCodec>;

export type LabelCreate = GeoJSON.Feature<GeoJSON.MultiPolygon, LabelCreateProperties>;

export type Label = GeoJSON.Feature<GeoJSON.MultiPolygon, LabelProperties> & {
  id: string;
};

export type LabelCollection = GeoJSON.FeatureCollection<GeoJSON.MultiPolygon, LabelProperties> & {
  features: Label[];
};

export type LabelCreateCollection = GeoJSON.FeatureCollection<
  GeoJSON.MultiPolygon,
  LabelCreateProperties
> & {
  features: LabelCreate[];
  nextStatus: TaskStatus;
};
