import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { createEnumType, Paginated } from "../types";

export enum TaskStatus {
  Unlabeled = "UNLABELED",
  LabelingInProgress = "LABELING_IN_PROGRESS",
  Labeled = "LABELED",
  ValidationInProgress = "VALIDATION_IN_PROGRESS",
  Validated = "VALIDATED",
  Flagged = "FLAGGED",
  Invalid = "INVALID",
  Split = "SPLIT",
}

export const taskStatusTypeCodec = createEnumType<TaskStatus>(TaskStatus, "TaskStatus");

export const TaskActionCodec = t.type({
  timestamp: t.string,
  fromStatus: taskStatusTypeCodec,
  toStatus: taskStatusTypeCodec,
  owner: t.string,
  note: optionFromNullable(t.string),
});

export const taskPropertiesCodec = t.type({
  createdAt: t.string,
  owner: t.string,
  modifiedAt: t.string,
  status: taskStatusTypeCodec,
  lockedBy: optionFromNullable(t.string),
  lockedOn: optionFromNullable(t.string),
  actions: t.array(TaskActionCodec),
  projectId: t.string,
});

export const TaskLabelCreatePropertiesCodec = t.type({
  label: t.string,
  taskId: optionFromNullable(t.string),
  owner: optionFromNullable(t.string),
  description: optionFromNullable(t.string),
  machineGenerated: optionFromNullable(t.boolean),
  confidence: optionFromNullable(t.number),
  labeledBy: optionFromNullable(t.string),
  verifiedBy: optionFromNullable(t.string),
});

export const taskNextStatusCodec = t.type({
  nextStatus: taskStatusTypeCodec,
  note: optionFromNullable(t.string),
});

export type TaskProperties = t.TypeOf<typeof taskPropertiesCodec>;
export type Task = GeoJSON.Feature<GeoJSON.Polygon, TaskProperties> & {
  id: string;
};
export type TaskCollection = GeoJSON.FeatureCollection<GeoJSON.Polygon, TaskProperties> & {
  features: Task[];
};
export type PaginatedTaskCollection = Paginated & TaskCollection;
export type TaskAction = t.TypeOf<typeof TaskActionCodec>;
export type TaskActionWithRelated = TaskAction & {
  userName: string;
  userProfileImageUri: string;
};
export type TaskNextStatus = t.TypeOf<typeof taskNextStatusCodec>;
