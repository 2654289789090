import { Flex, Spinner } from "@chakra-ui/react";
import React from "react";

const ImagesLoading = () => (
  <Flex height="500px" align="center" justify="center">
    <Spinner color="primary" size="xl" />
  </Flex>
);

export default ImagesLoading;
