import React, { useCallback } from "react";
import { ApplicationStore, SemanticLabelFeature, DrawingActionType, DeckPick } from "types";
import { MenuItem } from "./styled";
import { Icon, Text } from "@blasterjs/core";
import { foldOption, noop, seqOption } from "lib";
import { useDispatch, useSelector } from "react-redux";
import { findFirst } from "fp-ts/es6/Array";
import { DrawingActionSourceFeature } from "common/modules/DrawingAction";
import { createDrawingAction } from "pages/SegmentationTask/helpers";
import { createSetActionBuffer } from "state/ui-segmentation/actions";
import getClickedPolygon from "./helpers/getClickedPolygon";
import collapseLabelClassGroups from "pages/Campaigns/CampaignCard/helpers/collapseLabelClassGroups";
import { LabelClass } from "datamodel/labeClass";

type Props = {
  pick: DeckPick;
  callback?: () => void;
};

const SegmentationDeleteMenu = ({
  pick: { object: feature, coordinate },
  callback = noop,
}: Props) => {
  const dispatch = useDispatch();

  const [displayBuffer, actionBufferCache, labelClassGroupsOpt] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.displayBuffer,
        state.segmentationUI.actionBufferCache,
        state.segmentationUI.labelClassGroups,
      ] as const
  );

  const onClick = useCallback(() => {
    const selectedShapeLabelId = feature.properties.label;

    foldOption(
      findFirst((f: SemanticLabelFeature) => f.properties.label === selectedShapeLabelId)(
        displayBuffer.features
      ),
      noop,
      (matchedFeature) => {
        const selectedPolygonOption = getClickedPolygon(coordinate, matchedFeature);
        foldOption(
          seqOption(selectedPolygonOption, labelClassGroupsOpt),
          noop,
          ([selectedPolygon, labelClassGroups]) => {
            const selectedMultiPoly = {
              geometry: {
                type: "MultiPolygon",
                coordinates: [selectedPolygon.geometry.coordinates],
              },
              properties: {},
              type: "Feature",
            } as DrawingActionSourceFeature;

            const selectedLabelClassOption = findFirst(
              (l: LabelClass) => l.id === selectedShapeLabelId
            )(collapseLabelClassGroups(labelClassGroups));

            foldOption(selectedLabelClassOption, noop, (labelClass) => {
              const eraseAction = createDrawingAction(
                labelClass,
                selectedMultiPoly,
                DrawingActionType.Erase
              );
              dispatch(createSetActionBuffer([...actionBufferCache, eraseAction]));
            });
          }
        );
      }
    );
    callback();
  }, [
    actionBufferCache,
    callback,
    coordinate,
    dispatch,
    displayBuffer.features,
    feature.properties.label,
    labelClassGroupsOpt,
  ]);

  return (
    <MenuItem onClick={onClick} darken>
      <Icon mr={2} size="13px" name="trash" />
      <Text>Delete</Text>
    </MenuItem>
  );
};

export default SegmentationDeleteMenu;
