import React from "react";
import { Option } from "fp-ts/es6/Option";

import { Task, TaskStatus } from "datamodel/task";
import { TaskURLActionType } from "types";
import { Stack } from "@chakra-ui/react";
import {
  RestoreTask,
  StartLabeling,
  UnlockTask,
  ContinueLabeling,
  Validate,
  ContinueValidating,
} from "./Buttons";

interface Props {
  taskStatus: TaskStatus;
  taskOption: Option<Task>;
  onClickUrlAction: (actionType: TaskURLActionType) => void;
  callback: () => void;
}

const ValidatorMenuForTaskMap = ({ taskStatus, taskOption, onClickUrlAction, callback }: Props) => {
  return taskStatus === TaskStatus.Unlabeled ? (
    <StartLabeling onClickUrlAction={onClickUrlAction} />
  ) : taskStatus === TaskStatus.Invalid ? (
    <RestoreTask taskOption={taskOption} callback={callback} />
  ) : taskStatus === TaskStatus.LabelingInProgress ||
    taskStatus === TaskStatus.ValidationInProgress ? (
    <UnlockTask taskOption={taskOption} callback={callback} />
  ) : taskStatus === TaskStatus.Labeled || taskStatus === TaskStatus.Flagged ? (
    <Stack spacing={4} flex="1">
      <ContinueLabeling onClickUrlAction={onClickUrlAction} />
      <Validate onClickUrlAction={onClickUrlAction} />
    </Stack>
  ) : taskStatus === TaskStatus.Validated ? (
    <ContinueValidating onClickUrlAction={onClickUrlAction} />
  ) : (
    <></>
  );
};

export default ValidatorMenuForTaskMap;
