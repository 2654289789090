import * as T from "io-ts";
import { useParams } from "react-router";

function useDecodedParam<ApplicationType = any, EncodeTo = ApplicationType, DecodeFrom = unknown>(
  paramName: string,
  codec: T.Type<ApplicationType, EncodeTo, DecodeFrom>
) {
  const params = useParams<any>();
  return codec.decode(params[paramName]);
}

export default useDecodedParam;
