import React, { useState } from "react";
import { Box, Button, TextInput, Icon } from "@blasterjs/core";

type Props = {
  value: string | undefined;
  placeholder?: string;
  onChange: (value: string) => void;
  onInputCleared: () => void;
  autoFocus?: boolean;
};
const SearchInput = ({ value, placeholder, onChange, onInputCleared, autoFocus = true }: Props) => {
  const [searchValue, setSearchValue] = useState<string>(value || "");

  return (
    <Box data-intercom-target="project-search" position="relative" display="inline-flex">
      <TextInput
        width="225px"
        borderRadius="100px"
        py="12px"
        px="36px"
        placeholder={placeholder}
        value={searchValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchValue(e.currentTarget.value);
          onChange(e.currentTarget.value);
        }}
        borderColor="gray200"
        border="1px solid"
        lineHeight="16px"
        autoFocus
      />
      <Icon name="search" color="gray500" position="absolute" left="0" m="14px" />
      {!!searchValue.length && (
        <Button
          appearance="minimal"
          position="absolute"
          right="0"
          p="4px"
          borderRadius="100px"
          m="10px"
          onClick={() => {
            setSearchValue("");
            onInputCleared();
          }}
        >
          <Icon name="cross" color="gray500" />
        </Button>
      )}
    </Box>
  );
};
export default SearchInput;
