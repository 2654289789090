import React from "react";
import { Button, Box, Heading } from "@blasterjs/core";
import { Campaign } from "datamodel/campaign";
import { useHistory } from "react-router";
import FlexFiller from "components/FlexFiller";

const ErrorCard = ({ campaign }: { campaign: Campaign }) => {
  const history = useHistory();

  const onClick = () => {
    history.push(`/campaign/${campaign.id}`);
  };

  return (
    <Box display="flex" width="100%" height="100%" flexDirection="column" p={2} alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt="40px"
      >
        <Heading as="h5" width="100%" textAlign="center" color="danger">
          Failed to process.
        </Heading>
      </Box>
      <FlexFiller />
      <Button onClick={onClick} data-intercom-target="delete-failed-project">
        View details
      </Button>
    </Box>
  );
};

export default ErrorCard;
