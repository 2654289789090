import React, { ReactNode } from "react";
import { BoxProps, Box, Text, Flex, CloseButton } from "@chakra-ui/react";
import bytes from "bytes";

type Props = {
  file: File;
  statusText?: string;
  message?: string;
  children?: ReactNode;
  onClickClose?: () => void;
} & BoxProps;

const UploadItem = ({ file, statusText, message, onClickClose, children, ...rest }: Props) => (
  <Box display="flex" alignItems="center" bg="white" {...rest} maxWidth="100%">
    <Flex flex="1" alignItems="center" overflow="hidden">
      <Box flex="1" display="flex" flexDirection="column" minWidth="0">
        <Text color="textLight" mb={0} fontWeight="600" fontSize="16px">
          {file.name}
        </Text>
        <Box mt={1}>
          <Text color="gray500">
            {bytes.format(file.size, { decimalPlaces: 1 })}
            {!!statusText && <span> &middot; {statusText}</span>}
            {!!message && <span> &middot; {message}</span>}
          </Text>
        </Box>
      </Box>
      <Flex alignItems="center">
        {!!children && children}
        {!!onClickClose && <CloseButton ml="10px" size="md" onClick={onClickClose} />}
      </Flex>
    </Flex>
  </Box>
);

export default UploadItem;
