import React, { FunctionComponent } from "react";
import { Field } from "react-final-form";
import { Box } from "@blasterjs/core";
import { v4 as uuid } from "uuid";
import styled from "styled-components";
import { required } from "pages/CampaignCreate/helpers/validators";
import { themeGet } from "@styled-system/theme-get";

const ItemContainer = styled(Box)`
  overflow: visible;
  user-select: none;
  background: ${themeGet("textInput.colors.bg")};
  input {
    opacity: 0;
    position: absolute;
  }
  input[type="radio"]:checked + label {
    border: 2px solid ${themeGet("colors.primary")};
  }
`;

const ItemLabel = styled(Box)`
  display: block;
  padding: ${themeGet("space.2")};
  cursor: pointer;
  border: 2px solid ${themeGet("textInput.colors.borderColor")};
  border-radius: ${themeGet("textInput.radii.borderRadius")};
  height: 100%;
  width: 100%;
`;

type ConsumerProps = {
  value: string;
  [x: string]: any;
};

type InternalProps = {
  value: string;
  name: string;
  id: number | string;
  htmlFor: string;
  checked?: boolean;
};

export const RadioBlocksItem: FunctionComponent<ConsumerProps | InternalProps> = (props) => (
  <>
    {"name" in props && (
      <ItemContainer mb={2} mr={2}>
        <Field
          name={props.name}
          value={props.value}
          type="radio"
          component="input"
          id={props.id}
          checked={props.checked}
        />
        <ItemLabel as="label" {...props}>
          {props.children}
        </ItemLabel>
      </ItemContainer>
    )}
  </>
);

type Props = { name: string; children: React.ReactNode; [x: string]: any };
const RadioBlocks: FunctionComponent<Props> = ({ name, children, ...props }) => (
  <>
    {!!children && (
      <Box display="flex" alignItems="stretch" flexWrap="wrap" {...props}>
        <Field name={name} validate={required}>
          {(props) => (
            <>
              {React.Children.map(children, (c) => {
                // Ensures types are narrowed sufficiently
                if (!React.isValidElement<ConsumerProps>(c)) {
                  return null;
                }
                const id = uuid();
                return React.cloneElement(c as React.ReactElement<ConsumerProps | InternalProps>, {
                  htmlFor: `${id}`,
                  name: name,
                  id,
                  checked: props.input.value === c.props.value,
                });
              })}
            </>
          )}
        </Field>
      </Box>
    )}
  </>
);

export default RadioBlocks;
