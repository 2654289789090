import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { SpaceNetProjectConfig } from "./types";
import { Text, Box, HStack } from "@chakra-ui/react";
import PreviewMap from "./PreviewMap";

const Exponent = styled(Text)`
  display: inline;
  vertical-align: 50%;
  font-size: 0.9rem;
`;

type LabeledNumberProps = {
  label: string;
  children: ReactNode;
};

const LabeledNumber = ({ label, children }: LabeledNumberProps) => (
  <Box display="inline-flex" flexDirection="column" mr={3} color="textLight">
    <Box fontSize="12px">{label}</Box>
    <Box fontWeight="semibold">{children}</Box>
  </Box>
);

type Props = {
  spaceNetProject: SpaceNetProjectConfig;
  active: boolean;
  onClick: () => void;
};

const SpaceNetImageBox = ({
  spaceNetProject: { name, providers, resolution, area, size, aoi, tileLayers },
  active,
  onClick,
}: Props) => (
  <Box
    width="276px"
    textAlign="left"
    display="flex"
    flexDirection="column"
    height="100%"
    p={4}
    border="1px"
    borderColor="gray.200"
    boxShadow={active ? "outline" : "sm"}
    onClick={onClick}
    cursor="pointer"
  >
    <Box flex={1}>
      <Box mb="0.4rem">
        <Text fontWeight="600">{name}</Text>
      </Box>
      <Box mb={1} color="gray600">
        {providers.join(", ")}
      </Box>
    </Box>
    <Box textAlign="center" height="190px" width="100%" backgroundColor="gray.200">
      <PreviewMap aoi={aoi} tileLayers={tileLayers} />
    </Box>
    <HStack mt={6} spacing={5}>
      <LabeledNumber label="Resolution">{resolution}</LabeledNumber>
      <LabeledNumber label="Area">
        {area}
        <Exponent>2</Exponent>
      </LabeledNumber>
      <LabeledNumber label="Size">{size}</LabeledNumber>
    </HStack>
  </Box>
);

export default SpaceNetImageBox;
