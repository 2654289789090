import styled from "styled-components";
import { Button } from "@blasterjs/core";

export default styled(Button)`
  display: block;
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 2px;
  padding: 4px;
  border: none;
  border-bottom: 1px solid #ccc;
  color: black;
  text-align: center;
  &:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  & span {
    text-align: center;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0;
    }
  }
`;
