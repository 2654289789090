import { TaskURLActionType } from "types";

const textFroUrlAction = (taskURLActionType: TaskURLActionType): string => {
  switch (taskURLActionType) {
    case TaskURLActionType.Label:
      return "Labeling";
    case TaskURLActionType.Validate:
      return "Validating";
    default:
      throw new Error("Error: Invalid URL action");
  }
};

export default textFroUrlAction;
