import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { persistReducer } from "redux-persist";
import { connectRouter } from "connected-react-router";
import { History } from "history";

import preferencesReducer from "./preferences/reducer";
import segmentationUIReducer from "./ui-segmentation/reducer";
import loggingReducer from "./logging/reducer";
import newAuthReducer from "./newAuth/reducer";
import limitsReducer from "./ui-limits/reducer";
import campaignListUIReducer from "./ui-campaign-list/reducer";

const preferencesPersistConfig = {
  key: "preferences",
  storage,
};

const taskQueuePersistConfig = {
  key: "segmentationUI",
  storage: storageSession,
  whitelist: ["taskQueueParamsString", "taskQueueAction", "taskQueue", "taskQueueUpdatedAt"],
  stateReconciler: autoMergeLevel2,
};

const reducer = (history: History<any>) =>
  combineReducers({
    router: connectRouter(history),
    preferences: persistReducer(preferencesPersistConfig, preferencesReducer),
    segmentationUI: persistReducer<ReturnType<typeof segmentationUIReducer>>(
      taskQueuePersistConfig,
      segmentationUIReducer
    ),
    logging: loggingReducer,
    newAuth: newAuthReducer,
    limits: limitsReducer,
    campaignListUI: campaignListUIReducer,
  });

export default reducer;
