import { Limit, limitsCodec } from "datamodel/limits";
import axios, { AxiosResponse } from "axios";
import { decodeAxiosResponse } from "./transformers/base";
import getBaseRequestConfig from "./helpers/getBaseRequestConfig";

const decode = decodeAxiosResponse(limitsCodec);

export const getStub = async (): Promise<AxiosResponse<Limit[]>> =>
  decode(
    await Promise.resolve({
      status: 200,
      statusText: "ok",
      config: {},
      headers: [],
      data: [
        {
          domain: "campaigns",
          action: "create",
          objectId: null,
          used: 10,
          limit: 10,
        },
      ],
    })
  );

export const getUserLimits = async (): Promise<AxiosResponse<Limit[]>> =>
  decode(await axios.get(`/users/me/limits`, { ...getBaseRequestConfig() }));
