import bbox from "@turf/bbox";
import center from "@turf/center";
import { Feature, Polygon, Properties } from "@turf/helpers";
import { fromNullable, Option } from "fp-ts/es6/Option";
import { WebMercatorViewport } from "react-map-gl";

export type AOI =
  | {
      type: "MultiPolygon";
      coordinates: [number, number][][][];
    }
  | {
      type: "Polygon";
      coordinates: [number, number][][];
    };

type ViewPortOption = Option<{
  width: string;
  height: string;
  latitude: number;
  longitude: number;
  zoom: number;
  pitch: number;
  bearing: number;
  maxZoom: number;
  minZoom: number;
}>;

export const getViewportFromAoi = (aoi: AOI): ViewPortOption => {
  const bound = bbox(aoi);
  const centroid = center(aoi);

  const { zoom }: { zoom: number } = new WebMercatorViewport({
    width: 316,
    height: 300,
  }).fitBounds(
    [
      [bound[0], bound[1]],
      [bound[2], bound[3]],
    ],
    {
      padding: 25,
      offset: [0, 0],
    }
  );

  return fromNullable(
    centroid.geometry
      ? {
          width: "auto",
          height: "100%",
          latitude: centroid.geometry.coordinates[1],
          longitude: centroid.geometry.coordinates[0],
          zoom: zoom,
          pitch: 0,
          bearing: 0,
          maxZoom: 24,
          minZoom: 0,
        }
      : null
  );
};

export const getViewportFromPolygon = (aoi: Feature<Polygon, Properties>): ViewPortOption => {
  const centroid = center(aoi);
  return fromNullable(
    centroid.geometry
      ? {
          width: "auto",
          height: "100%",
          latitude: centroid.geometry.coordinates[1],
          longitude: centroid.geometry.coordinates[0],
          zoom: 1,
          pitch: 0,
          bearing: 0,
          maxZoom: 24,
          minZoom: 0,
        }
      : null
  );
};
