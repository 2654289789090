import React from "react";
import axios from "axios";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";
import { Blaster, Box } from "@blasterjs/core";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { ReactQueryDevtools } from "react-query/devtools";

import store, { history } from "./store";
import AppRouter from "./Router";
import Top from "./pages/Top";
import theme from "./theme";
import chakraTheme from "./chakra-theme";

import getWebGL2Error from "lib/getWebGL2Error";
import { foldOption } from "lib";
import WebGLNotDetected from "pages/WebGLNotDetected";
import { QueryClient, QueryClientProvider } from "react-query";
import { Global } from "@emotion/react";

const persistor = persistStore(store);

const webGLError = getWebGL2Error();

export const queryClient = new QueryClient({});

const mergedTheme = extendTheme({ ...theme, ...chakraTheme });

export type GroundworkTheme = typeof mergedTheme;

axios.interceptors.response.use(
  (v) => v,
  function (error) {
    if (error.response && error.response.status === 401) {
      history.push("/logout");
    } else {
      return Promise.reject(error);
    }
  }
);

const App = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
              <ChakraProvider theme={mergedTheme}>
                <Global
                  styles={{
                    body: {
                      WebkitFontSmoothing: "auto",
                      LineHeight: "1.3",
                      background: "inherit",
                    },
                  }}
                />
                <Blaster theme={theme}>
                  {foldOption(
                    webGLError,
                    () => (
                      <Box display="flex" flexDirection="column" minHeight="100%">
                        <Top />
                        <AppRouter />
                      </Box>
                    ),
                    (error) => (
                      <WebGLNotDetected errorMessage={error} />
                    )
                  )}
                </Blaster>
              </ChakraProvider>
            </ConnectedRouter>
          </PersistGate>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
};

export default App;
