import React from "react";
import { Box } from "@blasterjs/core";
import {
  TabList,
  TabPanels,
  Tabs,
  Tab as ChakraTab,
  TabPanel,
  TabProps,
  Stack,
} from "@chakra-ui/react";

import { Campaign, CampaignType } from "datamodel/campaign";
import Classifications from "./Classifications";
import GeneralSettings from "./GeneralSettings";

type Props = {
  campaign: Campaign;
};

const Tab = ({ children, ...rest }: TabProps) => (
  <ChakraTab
    fontSize="16px"
    fontWeight="semibold"
    textAlign="left"
    justifyContent="flex-start"
    color="gray500"
    _selected={{ color: "orange.500" }}
    {...rest}
  >
    {children}
  </ChakraTab>
);

const Settings = ({ campaign }: Props) => {
  return (
    <Box
      display="flex"
      alignItems="stretch"
      px={2}
      py={3}
      m="0 auto"
      maxWidth="1172px"
      width="100%"
      flexDirection="column"
    >
      <Tabs variant="unstyled">
        <Stack direction="row" spacing={9}>
          <TabList
            flexDirection="column"
            width="192px"
            justifyItems="flex-start"
            borderBottom="none"
          >
            <Tab>General settings</Tab>
            {campaign.campaignType !== CampaignType.Classification && <Tab>Classifications</Tab>}
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <GeneralSettings campaign={campaign} />
            </TabPanel>
            {campaign.campaignType !== CampaignType.Classification && (
              <TabPanel>
                <Classifications campaign={campaign} />
              </TabPanel>
            )}
          </TabPanels>
        </Stack>
      </Tabs>
    </Box>
  );
};

export default Settings;
