import React, { FunctionComponent, RefObject, useEffect } from "react";
import { Form, useFormState } from "react-final-form";
import arrayMutators from "final-form-arrays";
import styled from "styled-components";
import useStore from "../store";

interface Props {
  formRef: RefObject<HTMLFormElement>;
  onSubmit: (values: any) => Promise<void>;
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FormStateWatcher = () => {
  const setCanAdvance = useStore((state) => state.setCanAdvance);
  const { submitting, invalid } = useFormState();

  useEffect(() => {
    setCanAdvance(submitting || invalid);
  }, [setCanAdvance, submitting, invalid]);

  return null;
};

const Wizard: FunctionComponent<Props> = ({ onSubmit, children, formRef }) => {
  const step = useStore((state) => state.step);

  return (
    <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }}>
      {({ handleSubmit }) => (
        <>
          <FormStateWatcher />
          <StyledForm ref={formRef} onSubmit={handleSubmit} autoComplete="off">
            {React.Children.toArray(children)[step - 1]}
          </StyledForm>
        </>
      )}
    </Form>
  );
};

export default Wizard;
