import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Text, TextInput, Box, Select } from "@blasterjs/core";
import { Option } from "fp-ts/es6/Option";
import { useMutation } from "react-query";
import Pluralize from "react-pluralize";
import { useToast } from "@chakra-ui/react";

import { queryClient } from "App";
import { UserFeatureFlag } from "components/FeatureFlags";
import { ACRActionType } from "datamodel/permissions";
import GButton from "components/GButton";
import { Limit, LimitDomain } from "datamodel/limits";
import isWithinLimit from "pages/helpers/isWithinLimit";
import { Campaign } from "datamodel/campaign";
import { postShares } from "http/campaign";
import { foldOption, noop } from "lib";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationStore } from "types";
import { createTrackingAction, TrackingActionTypes } from "state/tracking/actions";
import pickLimit from "pages/helpers/pickLimit";

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

interface Props {
  campaign: Campaign;
  limitsOption: Option<Limit[]>;
  isShareLoading: boolean;
}

const MAX_LIMITED = 1;
const MAX_UNLIMITED = 20;

const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

const EmailForm = ({ campaign, limitsOption, isShareLoading }: Props) => {
  const [emailsRaw, setEmailsRaw] = useState<string>("");
  const [emailsToAdd, setEmailsToAdd] = useState<string[]>([]);
  const [sharedAction, setSharedAction] = useState<ACRActionType>(ACRActionType.Annotate);
  const [areEmailsValidated, setAreEmailsValidated] = useState<boolean>(false);
  const toast = useToast();

  const userOption = useSelector((state: ApplicationStore) => state.newAuth.user);

  const dispatch = useDispatch();

  const resetForm = useCallback(() => {
    setEmailsRaw("");
    setAreEmailsValidated(false);
    setEmailsToAdd([]);
  }, []);

  const isUnlimitedShare = useMemo(
    () =>
      foldOption(
        pickLimit(limitsOption, LimitDomain.Campaigns, "share", campaign.id),
        () => false,
        (limit) => limit.limit === Number.MAX_SAFE_INTEGER
      ),
    [limitsOption, campaign]
  );

  const { mutateAsync: addSharesMutation, ...addSharesQueryInfo } = useMutation(
    () => postShares(campaign, emailsToAdd, sharedAction, false),
    {
      onSuccess: () => {
        foldOption(userOption, noop, (user) => {
          dispatch(
            createTrackingAction(TrackingActionTypes.ProjectShared, {
              userId: user.sub,
              count: emailsToAdd.length,
            })
          );
        });
        queryClient.invalidateQueries(["campaign", campaign.id, "shares"]);
        queryClient.invalidateQueries(["limits"]);
        resetForm();
      },
      onError: () => {
        toast({
          title: "An error occurred.",
          description: "Unable to add new users to the campaign",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const onEmailsChange = (event: React.FormEvent<HTMLInputElement>) => {
    const emails = event.currentTarget.value;
    if (typeof emails === "string" && emails !== emailsRaw) {
      setEmailsRaw(emails);
      const splitAndTrimmed = emails.split(",").map((e) => e.trim());
      if (!isUnlimitedShare && splitAndTrimmed.length > MAX_LIMITED) {
        return setAreEmailsValidated(false);
      }
      const validEmails = splitAndTrimmed.filter(validateEmail);
      const isValidCount = isUnlimitedShare
        ? validEmails.length <= MAX_UNLIMITED
        : validEmails.length === MAX_LIMITED;
      setAreEmailsValidated(isValidCount);
      if (isValidCount) setEmailsToAdd(validEmails);
    } else if (emails !== emailsRaw) {
      resetForm();
    }
  };

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      addSharesMutation();
      return false;
    },
    [addSharesMutation]
  );

  const isLoading = isShareLoading || addSharesQueryInfo.isLoading;

  return isWithinLimit(limitsOption, LimitDomain.Campaigns, "share", campaign.id) ? (
    <Box width="575px" background="#eff0ef" py={3} px={2} mb={2} borderRadius="base">
      <Box mb={2}>
        <Text fontSize={3} fontWeight="bold" mb={1} color="#262224">
          Invite{" "}
          <Pluralize
            singular={"a collaborator"}
            plural={"collaborators"}
            count={isUnlimitedShare ? 2 : 1}
            showCount={false}
          />{" "}
          by email
        </Text>
      </Box>
      <Box mb={2}>
        <Text fontSize={2} color="textLight">
          Enter{" "}
          <Pluralize
            singular={"an email."}
            plural={"at most 20 emails delimited by comma at a time."}
            count={isUnlimitedShare ? 2 : 1}
            showCount={false}
          />{" "}
        </Text>
      </Box>
      <Box display="flex" flexDirection="row">
        <StyledForm onSubmit={onSubmit}>
          <TextInput
            data-intercom-target="share-email-input"
            placeholder="michael@dundermifflin.com"
            invalid={!!(emailsToAdd.length && !areEmailsValidated)}
            value={emailsRaw}
            onChange={onEmailsChange}
            disabled={isLoading}
            flex="1"
          />
          <UserFeatureFlag featureName="validation">
            <Select
              width="auto"
              ml={1}
              value={sharedAction}
              disabled={isLoading}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                setSharedAction(e.target.value as ACRActionType)
              }
            >
              <option value={ACRActionType.Annotate}>Labeler</option>
              <option value={ACRActionType.Validate}>Validator</option>
            </Select>
          </UserFeatureFlag>
          <GButton
            type="submit"
            colorScheme="primary"
            disabled={!areEmailsValidated}
            isLoading={isLoading}
            data-intercom-target="share-email-submit"
            ml={4}
          >
            Add
          </GButton>
        </StyledForm>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default EmailForm;
