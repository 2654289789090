import { Task, TaskStatus } from "datamodel/task";
import { Dispatch } from "redux";
import { getTask, putTaskStatus } from "http/task";
import { createSetLastError, createSetTask } from "state/ui-segmentation/actions";
import { Error } from "types";
import { AxiosResponse } from "axios";
import { fromNullable, none } from "fp-ts/es6/Option";

const unflagTask = async (task: Task, nextStatus: TaskStatus, dispatch: Dispatch) => {
  // We always want to return a promise even if we choose not to send a request
  // so we initialize with a resolved promise
  let requestPromise = Promise.resolve();

  if (task.properties.status === TaskStatus.Flagged) {
    // this will happen in the labeling UI, the labeler first flags it
    // then they are allowed to remove the flag
    // the task status in this case is indeed flagged
    // we need to set it back to unlabled and then labeling in progress programatically
    // so that the task unlocker both frontend and backend
    // will set the correct previous task status
    // ^^ this is a way to avoid messing with the task locker/unclocker logic
    // after such resetting, the user should stay on the same task, and thus we are
    // fetching and setting the task again
    requestPromise = putTaskStatus(task.properties.projectId, task.id, {
      nextStatus: TaskStatus.Unlabeled,
      note: none,
    })
      .then(() =>
        putTaskStatus(task.properties.projectId, task.id, {
          nextStatus,
          note: none,
        })
      )
      .then(() => getTask(task.properties.projectId, task.id))
      .then((resp: AxiosResponse<Task>) => {
        const taskOption = fromNullable(resp.data);
        dispatch(createSetTask(taskOption));
        return Promise.resolve();
      });
    requestPromise.catch((err: Error) =>
      dispatch(createSetLastError("Failed to update task when trying to remove flag", err))
    );
  } else if (task.properties.status === TaskStatus.ValidationInProgress) {
    // this will happen in the validation UI
    // the validator sees a task that is flagged on the task map
    // they click on it and it takes them to the validation UI
    // the UI sets a flagged task to validation in progress,
    // which is done by default in the task UI logic,
    // -- this is why the other case is the task status can be validation in progress
    // we only want to set it back to unlabeled and move on to the next task
    requestPromise = putTaskStatus(task.properties.projectId, task.id, {
      nextStatus,
      note: none,
    }).then(() => Promise.resolve()); // this then call is needed for type consistency...
    requestPromise.catch((err: Error) =>
      dispatch(createSetLastError("Failed to update task when trying to remove flag", err))
    );
  }

  return requestPromise;
};

export default unflagTask;
