import React, { useMemo } from "react";
import { FieldArray, FieldArrayRenderProps } from "react-final-form-arrays";
import { Option } from "fp-ts/es6/Option";

import { FieldGroupValue, DEFAULTS } from "../index";
import ClassGroupArray from "../classification/ClassGroupArray";
import FormSectionHeading from "components/FormSectionHeading";
import getClassGroupInitVal from "../helpers/getClassGroupInitVal";
import { CampaignType } from "datamodel/campaign";

const Classification = ({ projectTypeOption }: { projectTypeOption: Option<CampaignType> }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const classGroupInitVal = useMemo(() => getClassGroupInitVal(projectTypeOption), []);

  return (
    <>
      <FormSectionHeading
        title="Choose your classes"
        description="Create questions and answers for all of the things you would like to classify in your imagery."
      />
      <FieldArray name="classification" initialValue={classGroupInitVal}>
        {(fieldArrayProps: FieldArrayRenderProps<FieldGroupValue, HTMLElement>) => (
          <ClassGroupArray
            fieldArrayProps={fieldArrayProps}
            maxClassCount={DEFAULTS.maxClassCount}
          />
        )}
      </FieldArray>
    </>
  );
};

export default Classification;
