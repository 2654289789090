import get from "lodash.get";
import { isNonEmpty } from "fp-ts/es6/Array";
import { NonEmptyArray } from "fp-ts/es6/NonEmptyArray";
import { Dispatch, AnyAction } from "redux";

import { TaskStatus, Task, TaskAction, TaskActionWithRelated } from "datamodel/task";
import {
  createSetLastLabelingAction,
  createSetLastValidatingAction,
} from "state/ui-segmentation/actions";
import { getUsers } from "http/user";
import { AxiosResponse } from "axios";
import { ApiUser } from "datamodel/user";

interface UserInfo {
  [id: string]: { name: string; profileImageUri: string };
}

// get the latest transition based on from and to statuses
// for each userId
const getStatusTransition = (
  actions: NonEmptyArray<TaskAction>,
  fromStatus: TaskStatus,
  toStatus: TaskStatus
): TaskAction[] =>
  actions
    .filter((a) => a.owner !== "default" && a.fromStatus === fromStatus && a.toStatus === toStatus)
    .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

// get a map of user name and avatar url keyed by userId
const gatherUserInfo = (userResps: AxiosResponse<ApiUser>[]) =>
  userResps.reduce((acc, resp) => {
    const {
      data: { profileImageUri, id, name },
    } = resp;
    acc[id] = { name, profileImageUri };
    return acc;
  }, {} as UserInfo);

const cacheLastStatusTransition = <T extends AnyAction>(
  actions: NonEmptyArray<TaskAction>,
  fromStatus: TaskStatus,
  toStatus: TaskStatus,
  actionCreator: (payload: TaskActionWithRelated[]) => T,
  dispatch: Dispatch
) => {
  const filteredActions = getStatusTransition(actions, fromStatus, toStatus);
  getUsers(filteredActions.map((action) => action.owner)).then((userResps) => {
    const usersInfo = gatherUserInfo(userResps);
    dispatch(
      actionCreator(
        filteredActions.map((action) => ({
          ...action,
          userName: usersInfo[action.owner].name,
          userProfileImageUri: usersInfo[action.owner].profileImageUri,
        }))
      )
    );
  });
};

const fetchTaskParticipants = (task: Task, dispatch: Dispatch): void => {
  const actions: TaskAction[] = get(task, "properties.actions", []);
  if (isNonEmpty(actions)) {
    cacheLastStatusTransition(
      actions,
      TaskStatus.LabelingInProgress,
      TaskStatus.Labeled,
      createSetLastLabelingAction,
      dispatch
    );
    cacheLastStatusTransition(
      actions,
      TaskStatus.ValidationInProgress,
      TaskStatus.Validated,
      createSetLastValidatingAction,
      dispatch
    );
  }
};

export default fetchTaskParticipants;
