import React from "react";
import { Field, FieldRenderProps } from "react-final-form";
import { FieldArrayRenderProps } from "react-final-form-arrays";
import { BlockPicker, ColorResult } from "react-color";
import { Box, TextInput, Button, Icon, Tooltip, Swatch } from "@blasterjs/core";

import { FieldValue, FieldGroupValue } from "..";
import { getEmptyClass, errorFromValidate } from "../helpers";
import FormError from "components/FormError";
import ColorPickerButton from "components/ColorPickerButton";
import { COLORS } from "../helpers/getEmptyClass";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";

interface Props {
  fieldArrProps: FieldArrayRenderProps<FieldValue, HTMLElement>;
  maxClassCount: number;
}

interface AllValues {
  nonclassification?: FieldGroupValue[];
}

const ClassField = ({ fieldArrProps, maxClassCount }: Props) => {
  const hitlEnabled = useUserFeatureFlag("hitl");

  const validate = (value: string, idx: number, allValues: AllValues | undefined) => {
    if (allValues?.nonclassification) {
      return allValues.nonclassification.reduce(
        (acc, classGroup) => acc && classGroup.classes.filter((v) => v.name).length >= 1,
        true
      );
    }
    return !!value;
  };

  const showRemoveButton = (classIndex: number) => {
    const isDirty = !!fieldArrProps.fields.value[classIndex].name;
    const isLast = classIndex + 1 === fieldArrProps.fields.value.length;
    return (isLast && isDirty) || !isLast;
  };

  const getSelectedColor = () => fieldArrProps.fields.value.map((v) => v.colorHexCode);

  const onNameChange = (
    props: FieldRenderProps<string, HTMLElement>,
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    props.input.onChange(e);
    const l = fieldArrProps.fields.length || 0;
    if (!hitlEnabled && index === l - 1 && (maxClassCount === 0 || l < maxClassCount)) {
      fieldArrProps.fields.push(getEmptyClass(getSelectedColor()));
    }
  };

  const onClickRemoveClass = (classIndex: number) => {
    if (classIndex + 1 === fieldArrProps.fields.length) {
      fieldArrProps.fields.update(classIndex, getEmptyClass(getSelectedColor()));
    } else {
      fieldArrProps.fields.remove(classIndex);
    }
  };

  return (
    <>
      {fieldArrProps.fields.map((name, idx) => (
        <Box display="flex" flexDirection="row" alignItems="center" mt={1} key={idx}>
          <Field
            name={`${name}.name`}
            validate={(value, all) =>
              errorFromValidate(validate(value, idx, all), "At least one class is required")
            }
          >
            {(props) => (
              <TextInput
                data-intercom-target="class-name-input"
                maxWidth="280px"
                name={props.input.name}
                value={props.input.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onNameChange(props, e, idx)}
                onBlur={props.input.onBlur}
                onFocus={props.input.onFocus}
                placeholder="e.g., Tree Canopy"
                mr={2}
                invalid={props.meta.touched && props.meta.invalid}
                autocomplete="false"
                autoFocus={idx === 0}
              />
            )}
          </Field>
          <Field name={`${name}.colorHexCode`}>
            {(props) => (
              <ColorPickerButton
                data-intercom-target="select-color-input"
                iconAfter="caretDown"
                data-tooltip
                type="button"
              >
                <Swatch color={props.input.value} size="2.3rem" m="-3px" />
                <Tooltip
                  trigger="click"
                  placement="right"
                  closeOnClickOutside={true}
                  backgroundColor="transparent"
                  p="0"
                  hasArrow={false}
                  className="withPointer"
                >
                  <BlockPicker
                    triangle="hide"
                    color={props.input.value}
                    onChangeComplete={(color: ColorResult) => {
                      props.input.onChange(color.hex);
                    }}
                    colors={COLORS}
                  />
                </Tooltip>
              </ColorPickerButton>
            )}
          </Field>
          {showRemoveButton(idx) && (
            <Button
              type="button"
              borderRadius="0"
              appearance="minimal"
              onClick={() => onClickRemoveClass(idx)}
              p={1}
              ml={1}
            >
              <Icon name="cross" />
            </Button>
          )}
          <Box ml={1}>
            <FormError name={`${name}.name`} />
          </Box>
        </Box>
      ))}
    </>
  );
};

export default ClassField;
