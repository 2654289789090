import React, { useEffect, useCallback } from "react";
import { useToast } from "@chakra-ui/react";
import { pipe } from "fp-ts/es6/pipeable";
import { some } from "fp-ts/es6/Option";
import * as E from "fp-ts/es6/Either";

import { foldOption, noop } from "lib";
import createUpload from "./helpers/createUpload";
import uploadFile from "./helpers/uploadFile";
import validateTif from "../../helpers/validateTif";
import { UploadSequence, UploadStep } from "./types";
import useUploader from "./store";
import createProjectForUploadSequence from "./helpers/createProjectForUploadSequence";
import { queryClient } from "App";
import exceedStorageLimitPromise from "./helpers/exceedStorageLimitPromise";
import StorageAlert from "./StorageAlert";
import { AxiosError } from "axios";

interface UploadProps {
  sequence: UploadSequence;
  onModalOpen: () => void;
}

const FileUploader = ({ sequence, onModalOpen }: UploadProps) => {
  const toast = useToast();

  const { setOne, deleteOne, startNext } = useUploader();

  const onError = useCallback(
    (err: Error) => {
      const updatedSeq = { ...sequence, error: some(err), uploadStep: UploadStep.ERROR };
      setOne(sequence.id, () => updatedSeq);
    },
    [sequence, setOne]
  );

  const onProgress = useCallback(
    (progressEvent: any) => {
      const percent = Math.max(
        Math.ceil((progressEvent.loaded / progressEvent.total) * 100),
        sequence.uploadProgress
      );
      if (percent !== sequence.uploadProgress) {
        setOne(sequence.id, (s) => ({ ...sequence, ...s, uploadProgress: percent }));
      }
    },
    [setOne, sequence]
  );

  const onQuotaExceeded = useCallback(
    () =>
      toast({
        status: "warning",
        onCloseComplete: () => deleteOne(sequence.id),
        render: () => <StorageAlert onModalOpen={onModalOpen} />,
      }),
    [toast, deleteOne, onModalOpen, sequence.id]
  );

  useEffect(() => {
    let isCurrent = true;
    const createUploadIfValid = async () =>
      pipe(
        await validateTif(sequence.file),
        E.fold(onError, async (bandCount) => {
          const isSingleBand = bandCount === 1;
          if (bandCount > 3) {
            toast({
              title: "More than three bands detected",
              description:
                "It looks like this .tif has more than three bands. We'll render the first three as the RGB channels.",
            });
          } else if (isSingleBand) {
            toast({
              title: "Single band detected",
              description: "We've detected only 1 band and it will be rendered in grayscale.",
            });
          }

          const willExceedLimit = await exceedStorageLimitPromise(sequence.file.size);
          if (willExceedLimit) {
            onQuotaExceeded();
          } else {
            createUpload(sequence.file.name, sequence.file.size, isSingleBand)
              .then((upload) => {
                if (isCurrent) {
                  setOne(sequence.id, () => ({
                    ...sequence,
                    isSingleBand: isSingleBand,
                    upload: some(upload),
                    uploadStep: UploadStep.VALIDATED,
                  }));
                }
              })
              .catch((error: AxiosError) => {
                return onError(
                  new Error(error.response?.status === 400 ? "Invalid file" : "Unknown error")
                );
              });
          }
        })
      );

    sequence.uploadStep === UploadStep.START && createUploadIfValid();
    return () => {
      isCurrent = false;
    };
  }, [sequence, setOne, onError, toast, onQuotaExceeded]);

  // When the upload is created, retrieve the signed-url and start the upload
  useEffect(() => {
    let isCurrent = true;
    foldOption(sequence.upload, noop, (upload) => {
      if (isCurrent && sequence.uploadStep === UploadStep.VALIDATED) {
        setOne(sequence.id, () => ({
          ...sequence,
          upload: some(upload),
          uploadStep: UploadStep.UPLOADING,
        }));
        uploadFile(
          sequence,
          (e) => {
            onProgress(e);
          },
          (upload) => {
            setOne(sequence.id, () => ({
              ...sequence,
              upload: some(upload),
              uploadStep: UploadStep.UPLOADED,
            }));
            startNext();
          },
          (err) => {
            isCurrent && onError(err);
          }
        );
      }
    });
    return () => {
      isCurrent = false;
    };
  }, [onProgress, onError, sequence, setOne, startNext]);

  // When the upload is complete, create the associated project
  useEffect(() => {
    let isCurrent = true;
    if (isCurrent && sequence.uploadStep === UploadStep.UPLOADED) {
      createProjectForUploadSequence(sequence).then(() => {
        setTimeout(() => {
          setOne(sequence.id, () => ({
            ...sequence,
            uploadStep: UploadStep.COMPLETE,
          }));
          queryClient.invalidateQueries(["campaign-projects", sequence.campaignId]);
        }, 1000);
      });
    }
    return () => {
      isCurrent = false;
    };
  }, [sequence, setOne]);

  return <></>;
};

export default FileUploader;
