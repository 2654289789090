import { Dispatch } from "redux";
import { none } from "fp-ts/es6/Option";

import { createSetLastError, createSetExistingAnnotations } from "state/ui-segmentation/actions";
import { putTaskStatus, postTaskLabels, deleteTaskLabels, postTaskValidation } from "http/task";
import { Task, TaskStatus } from "datamodel/task";
import { Error } from "types";
import { LabelCreateCollection } from "datamodel/label";

const confirmClassificationTask = async (
  task: Task,
  labelObjectCreate: { [id: string]: string },
  dispatch: Dispatch
) => {
  let requestPromise = Promise.resolve();

  if (
    [TaskStatus.LabelingInProgress, TaskStatus.ValidationInProgress].includes(
      task.properties.status
    )
  ) {
    let nextStatus =
      task.properties.status === TaskStatus.ValidationInProgress
        ? TaskStatus.Validated
        : TaskStatus.Labeled;

    await deleteTaskLabels(task);

    const taskLabelCreateCollection: LabelCreateCollection = {
      nextStatus,
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            classes: Object.values(labelObjectCreate),
            hitlVersionId: none,
          },
          geometry: {
            type: "MultiPolygon",
            coordinates: [task.geometry.coordinates],
          },
        },
      ],
    };

    const post = nextStatus === TaskStatus.Labeled ? postTaskLabels : postTaskValidation;

    const newLabels = (await post(task, taskLabelCreateCollection)).data;

    dispatch(createSetExistingAnnotations(newLabels));

    requestPromise = putTaskStatus(task.properties.projectId, task.id, {
      nextStatus,
      note: none,
    }).then(() => Promise.resolve());

    requestPromise.catch((err: Error) =>
      dispatch(createSetLastError("Failed to update task when trying to confirm", err))
    );
  }
  return requestPromise;
};

export default confirmClassificationTask;
