import { foldOption, noop } from "lib";
import { Option } from "fp-ts/es6/Option";
import { Dispatch } from "redux";

import { createSetLayerPickerConfig } from "state/ui-segmentation/actions";
import { LayerPickerSection, LayerConfig } from "types";
import { Project } from "datamodel/project";

const setLayerPickerConfig = (projectOption: Option<Project>, dispatch: Dispatch): void => {
  foldOption(projectOption, noop, (project) => {
    const newOverlayLayersConfig = project.tileLayers.reduce((acc, tileLayer) => {
      if (tileLayer.overlay) {
        return [
          ...acc,
          {
            id: tileLayer.id,
            label: tileLayer.name,
            opacity: 1,
            enabled: tileLayer.default,
          },
        ];
      }
      return acc;
    }, [] as LayerConfig[]);

    dispatch(createSetLayerPickerConfig(LayerPickerSection.Overlay, newOverlayLayersConfig));
  });
};

export default setLayerPickerConfig;
