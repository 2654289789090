import { LabelClass } from "datamodel/labeClass";

const uiLabelClassesBuilder = (dbClassDef: LabelClass[]) =>
  dbClassDef.map((cls) => ({
    name: cls.name,
    colorHexCode: cls.colorHexCode,
    dbId: cls.id,
  }));

export default uiLabelClassesBuilder;
