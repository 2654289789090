import axios, { AxiosResponse } from "axios";
import qs from "qs";
import { Option } from "fp-ts/es6/Option";
import getBaseRequestConfig from "./helpers/getBaseRequestConfig";
import { decoder, decoderC, decoderO, encoder, encoderC } from "./transformers/geoJSON";
import { PaginatedQueryParams } from "../types";
import {
  TaskStatus,
  PaginatedTaskCollection,
  taskPropertiesCodec,
  Task,
  TaskCollection,
  TaskNextStatus,
  taskNextStatusCodec,
} from "datamodel/task";
import {
  Label,
  labelPropertiesCodec,
  LabelCollection,
  LabelCreateCollection,
  labelCreatePropertiesCodec,
  LabelCreate,
} from "datamodel/label";
import { UUID } from "io-ts-types";

const decode = decoder<Task>(taskPropertiesCodec);
const encode = encoder<Task>(taskPropertiesCodec);

export type RandomTaskParams = {
  projectId?: string;
  campaignId?: string;
  status?: TaskStatus;
};

export type TaskListRequestParams = PaginatedQueryParams & {
  status?: TaskStatus[];
  bbox?: string;
  format?: string;
  locked?: boolean;
  lockedBy?: string;
};

interface TaskLabelListQP {
  hitlVersionId?: string;
}

export const getTask = async (projectId: string, taskId: string): Promise<AxiosResponse<Task>> =>
  decode(
    await axios.get(`/projects/${projectId}/tasks/${taskId}`, {
      ...getBaseRequestConfig(),
    })
  );

export const getRandomTask = async (
  params: RandomTaskParams
): Promise<AxiosResponse<Option<Task>>> =>
  decoderO<Task>(taskPropertiesCodec)(
    await axios.get(`/tasks/random`, {
      ...getBaseRequestConfig(),
      params,
    })
  );

export const getRandomHitlTask = async (
  params: RandomTaskParams
): Promise<AxiosResponse<Option<Task>>> =>
  decoderO<Task>(taskPropertiesCodec)(
    await axios.get(`/tasks/hitl`, {
      ...getBaseRequestConfig(),
      params,
    })
  );

export const listTasks = async (
  projectId: string,
  params: TaskListRequestParams
): Promise<AxiosResponse<PaginatedTaskCollection>> =>
  decoderC<Task, PaginatedTaskCollection>(taskPropertiesCodec)(
    await axios.get(`/projects/${projectId}/tasks`, {
      ...getBaseRequestConfig(),
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    })
  );

export const listTasksForCampaign = async (
  campaignId: UUID,
  params: TaskListRequestParams
): Promise<AxiosResponse<PaginatedTaskCollection>> =>
  decoderC<Task, PaginatedTaskCollection>(taskPropertiesCodec)(
    await axios.get(`/campaigns/${campaignId}/tasks`, {
      ...getBaseRequestConfig(),
      params,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    })
  );

export const putTask = async (task: Task): Promise<AxiosResponse<"">> =>
  await axios.put(`/projects/${task.properties.projectId}/tasks/${task.id}`, encode(task), {
    ...getBaseRequestConfig(),
  });

export const lockTask = async (projectId: string, taskId: string): Promise<AxiosResponse<Task>> =>
  decode(
    await axios.post(`/projects/${projectId}/tasks/${taskId}/lock`, null, {
      ...getBaseRequestConfig(),
    })
  );

export const unlockTask = async (projectId: string, taskId: string): Promise<AxiosResponse<Task>> =>
  decode(
    await axios.delete(`/projects/${projectId}/tasks/${taskId}/lock`, {
      ...getBaseRequestConfig(),
    })
  );

export const listTaskLabels = async (
  task: Task,
  params: TaskLabelListQP = {}
): Promise<AxiosResponse<LabelCollection>> => {
  const resp = await axios.get(`/projects/${task.properties.projectId}/tasks/${task.id}/labels`, {
    ...getBaseRequestConfig(),
    params,
  });

  return decoderC<Label, LabelCollection>(labelPropertiesCodec)({
    ...resp,
    data: {
      type: "FeatureCollection",
      features: resp.data,
    },
  });
};

export const postTaskLabels = async (
  task: Task,
  labels: LabelCreateCollection
): Promise<AxiosResponse<LabelCollection>> =>
  decoderC<Label, LabelCollection>(labelPropertiesCodec)(
    await axios.post(
      `/projects/${task.properties.projectId}/tasks/${task.id}/labels`,
      encoderC<LabelCreate, LabelCreateCollection>(labelCreatePropertiesCodec)(labels),
      { ...getBaseRequestConfig() }
    )
  );

export const postTaskValidation = async (
  task: Task,
  labels: LabelCreateCollection
): Promise<AxiosResponse<LabelCollection>> =>
  decoderC<Label, LabelCollection>(labelPropertiesCodec)(
    await axios.post(
      `/projects/${task.properties.projectId}/tasks/${task.id}/validate/`,
      encoderC<LabelCreate, LabelCreateCollection>(labelCreatePropertiesCodec)(labels),
      { ...getBaseRequestConfig() }
    )
  );

export const putTaskLabels = async (
  task: Task,
  labels: LabelCreateCollection
): Promise<AxiosResponse<LabelCollection>> =>
  decoderC<Label, LabelCollection>(labelPropertiesCodec)(
    await axios.put(
      `/projects/${task.properties.projectId}/tasks/${task.id}/labels`,
      encoderC<LabelCreate, LabelCreateCollection>(labelCreatePropertiesCodec)(labels),
      { ...getBaseRequestConfig() }
    )
  );

export const putTaskValidation = async (
  task: Task,
  labels: LabelCreateCollection
): Promise<AxiosResponse<LabelCollection>> =>
  decoderC<Label, LabelCollection>(labelPropertiesCodec)(
    await axios.put(
      `/projects/${task.properties.projectId}/tasks/${task.id}/validate`,
      encoderC<LabelCreate, LabelCreateCollection>(labelCreatePropertiesCodec)(labels),
      { ...getBaseRequestConfig() }
    )
  );

export const deleteTaskLabels = async (task: Task): Promise<AxiosResponse<"">> =>
  await axios.delete(`/projects/${task.properties.projectId}/tasks/${task.id}/labels`, {
    ...getBaseRequestConfig(),
  });

export const splitTask = async (task: Task): Promise<AxiosResponse<TaskCollection>> => {
  const resp = await axios.post(
    `/projects/${task.properties.projectId}/tasks/${task.id}/split`,
    {},
    {
      ...getBaseRequestConfig(),
    }
  );

  return decoderC<Task, TaskCollection>(taskPropertiesCodec)(resp);
};

export const putTaskStatus = async (
  projctId: string,
  taskId: string,
  taskNextStatus: TaskNextStatus
): Promise<AxiosResponse<Task>> =>
  decode(
    await axios.put(
      `/projects/${projctId}/tasks/${taskId}/status`,
      taskNextStatusCodec.encode(taskNextStatus),
      getBaseRequestConfig()
    )
  );
