import { findFirst } from "fp-ts/es6/Array";
import booleanContains from "@turf/boolean-contains";
import { point } from "@turf/helpers";
import { SemanticLabelFeature } from "types";

const getClickedPolygon = (coordinate: number[], matchedFeature: SemanticLabelFeature) =>
  findFirst((p: GeoJSON.Feature<GeoJSON.Polygon, {}>) => booleanContains(p, point(coordinate)))(
    matchedFeature.geometry.coordinates.map(
      (coord) =>
        ({
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: coord,
          },
          properties: {},
        } as GeoJSON.Feature<GeoJSON.Polygon, {}>)
    )
  );

export default getClickedPolygon;
