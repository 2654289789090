import { EditableGeoJsonLayer } from "@nebula.gl/layers";

type Props = {
  modeHandlers: { [mode: string]: any };
  [a: string]: any;
};

const DRAG_TOLERANCE = 3;

export default class SegmentationGeoJsonLayer extends EditableGeoJsonLayer {
  // This "useless" constructor allows TS to not complain when we pass arguments
  // to the constructor of this class, which we need to properly configure the
  // underlying EditableGeoJsonLayer

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(config: Props) {
    super(config);
    // to enable draw cancelling, we need to give the mode-handlers a new method
    // that triggers some things at this level
    Object.values(config.modeHandlers).forEach(
      (h) =>
        (h.updateLayers = () => {
          this.updateTentativeFeature();
          this.updateEditHandles();
          this.setLayerNeedsUpdate();
        })
    );
  }

  movedEnoughForDrag(screenCoords1: number[], screenCoords2: number[]) {
    return (
      Math.abs(screenCoords1[0] - screenCoords2[0]) > DRAG_TOLERANCE ||
      Math.abs(screenCoords1[1] - screenCoords2[1]) > DRAG_TOLERANCE
    );
  }

  _onPointerUp(event: Object) {
    const screenCoords = this.getScreenCoords(event);
    const groundCoords = this.getGroundCoords(screenCoords);

    const { pointerDownPicks, pointerDownScreenCoords, pointerDownGroundCoords, isDragging } =
      this.state._editableLayerState;

    const pointerUpPicks = this.context.deck.pickMultipleObjects({
      x: screenCoords[0],
      y: screenCoords[1],
      layerIds: [this.props.id],
      radius: this.props.pickingRadius,
      depth: this.props.pickingDepth,
    });

    if (!pointerDownScreenCoords) {
      // This is a pointer up without a pointer down (e.g. user pointer downed elsewhere), so ignore
      return;
    }

    if (isDragging) {
      this.onStopDragging({
        picks: pointerDownPicks,
        pointerUpPicks,
        screenCoords,
        groundCoords,
        pointerDownScreenCoords,
        pointerDownGroundCoords,
        sourceEvent: event,
      });
    } else if (!this.movedEnoughForDrag(pointerDownScreenCoords, screenCoords)) {
      this.onLayerClick({
        picks: pointerDownPicks,
        screenCoords,
        groundCoords,
        sourceEvent: event,
      });
    }

    this.setState({
      _editableLayerState: {
        ...this.state._editableLayerState,
        pointerDownScreenCoords: null,
        pointerDownGroundCoords: null,
        pointerDownPicks: null,
        isDragging: false,
      },
    });
  }
}
