import { LayerConfig } from "types";

export const defaultImageryLayersConfig: LayerConfig[] = [
  {
    id: "projectImagery",
    label: "Imagery",
    opacity: 1,
    enabled: true,
  },
];

export const defaultBasemapLayersConfig: LayerConfig[] = [
  {
    id: "Aerial",
    label: "Aerial",
    opacity: 1,
    enabled: false,
  },
  {
    id: "Light",
    label: "Light",
    opacity: 1,
    enabled: true,
  },
];

export const taskMaskLayerConfig = (id: string) => ({
  id,
  label: "Task Boundaries",
  opacity: 0.75,
  enabled: true,
});

export const currentTaskLayerConfig = (id: string) => ({
  id,
  label: "Current task labels",
  opacity: 0.5,
  enabled: true,
});

export const adjacentTaskLayerConfig = (id: string) => ({
  id,
  label: "Other task labels",
  opacity: 0.5,
  enabled: true,
});
