import React, { useLayoutEffect, useRef } from "react";
import { Box } from "@blasterjs/core";
import lottie from "lottie-web";
import data from "./lottieData.json";

interface Props {
  width?: string | number;
  height?: string | number;
}

const BalloonAnimation = ({ width = "50px", height = "50px" }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const animation = lottie.loadAnimation({
        animationData: data,
        container: containerRef.current,
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: "xMinYMin slice",
          progressiveLoad: true,
        },
      });
      animation.setSubframe(false);
      return () => {
        animation.destroy();
      };
    }
  });

  return (
    <Box width={width} height={height}>
      <div ref={containerRef}></div>
    </Box>
  );
};

export default BalloonAnimation;
