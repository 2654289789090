import styled from "styled-components";
import { Box } from "@blasterjs/core";

type OverlayPageProps = {
  allowOverflow?: boolean;
  [x: string]: any;
};

const OverlayPage = styled(Box)<OverlayPageProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f7f8f7;
  display: flex;
  flex-direction: column;
  z-index: 2;
  overflow: ${(props) => (!!props.allowOverflow ? "auto" : "hidden")};
  transition: all 0.25s;
`;

export default OverlayPage;
