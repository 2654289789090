import React, { useEffect, useMemo, useState } from "react";
import { Swatch, Text, Button, Icon } from "@blasterjs/core";
import { useSelector, useDispatch } from "react-redux";
import { Option, some, none, exists } from "fp-ts/es6/Option";

import { foldOption, noEl } from "lib";
import { ApplicationStore } from "types";
import {
  createSetSelectedClass,
  createSetIsConfirmDisabled,
  createSetHiddenClassIds,
} from "state/ui-segmentation/actions";
import { StyledLabelSelect } from "../styled";
import { LabelClass } from "datamodel/labeClass";
import initializeLabelClassSelection from "../helpers/initializeLabelClassSelection";
import FlexFiller from "components/FlexFiller";
import { pipe } from "fp-ts/es6/pipeable";
import { UserFeatureFlag } from "components/FeatureFlags";
import collapseLabelClassGroups from "pages/Campaigns/CampaignCard/helpers/collapseLabelClassGroups";

const DetectionLabeling = () => {
  const dispatch = useDispatch();
  const [hoveredLabelOption, setHoveredLabelOption] = useState<Option<LabelClass>>(none);

  const [labelClassGroupsOpt, activeLabelClassOption, displayBuffer, hiddenClassIds] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.labelClassGroups,
        state.segmentationUI.selectedClass,
        state.segmentationUI.displayBuffer,
        state.segmentationUI.hiddenClassIds,
      ] as const
  );

  const isLabelActive = (label: LabelClass): boolean =>
    pipe(
      activeLabelClassOption,
      exists((activeLabelClass) => label.id === activeLabelClass.id)
    );

  const isLabelHovered = (label: LabelClass): boolean =>
    pipe(
      hoveredLabelOption,
      exists((h) => h.id === label.id)
    );

  const isLabelHidden = (label: LabelClass): boolean => hiddenClassIds.includes(label.id);

  const classCounts = useMemo(
    () =>
      displayBuffer.features.reduce((acc, a) => {
        const c = acc[a.properties.label] || 0;
        return {
          ...acc,
          [a.properties.label]: c + 1,
        };
      }, {} as { [key: string]: number }),
    [displayBuffer]
  );

  const onClickLabelSelect = (labelClass: LabelClass) => {
    dispatch(createSetHiddenClassIds(hiddenClassIds.filter((id) => id !== labelClass.id)));
    dispatch(createSetSelectedClass(some(labelClass)));
  };

  const onClickLabelVisibilityToggle = (
    labelClass: LabelClass,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    const next = isLabelHidden(labelClass)
      ? hiddenClassIds.filter((id) => id !== labelClass.id)
      : [...hiddenClassIds, labelClass.id];
    dispatch(createSetHiddenClassIds(next));
  };

  useEffect(() => {
    initializeLabelClassSelection(labelClassGroupsOpt, dispatch);
    dispatch(createSetIsConfirmDisabled(false));
  }, [labelClassGroupsOpt, dispatch]);

  return (
    <>
      {foldOption(labelClassGroupsOpt, noEl, (labelClassGroups) =>
        collapseLabelClassGroups(labelClassGroups)
          .sort((a, b) => (a.index > b.index ? 1 : -1))
          .map((label, idx) => (
            <StyledLabelSelect
              data-intercom-target={`label-${idx}`}
              isActive={isLabelActive(label)}
              key={idx}
              onClick={() => onClickLabelSelect(label)}
              onMouseEnter={() => setHoveredLabelOption(some(label))}
              onMouseLeave={() => setHoveredLabelOption(none)}
            >
              <Swatch color={label.colorHexCode} mr={2} size="13px" circle /> {label.name}
              <FlexFiller />
              <UserFeatureFlag featureName="hideLabelClasses">
                {!isLabelActive(label) && (
                  <Button
                    data-intercom-target="hide-class"
                    appearance="minimal"
                    m="-5px"
                    mr={1}
                    p="5px"
                    borderRadius="100px"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      onClickLabelVisibilityToggle(label, e)
                    }
                    opacity={isLabelHidden(label) || isLabelHovered(label) ? 1 : 0}
                  >
                    {isLabelHidden(label) ? <Icon name="eyeOff" /> : <Icon name="eye" />}
                  </Button>
                )}
              </UserFeatureFlag>
              <Text data-intercom-target="label-count" fontWeight="bold">
                {classCounts[label.id] || 0}
              </Text>
            </StyledLabelSelect>
          ))
      )}
    </>
  );
};

export default DetectionLabeling;
