import { Option, fromNullable } from "fp-ts/es6/Option";
import { Dispatch } from "redux";

import { Task } from "datamodel/task";
import { TaskUIMode, Error } from "types";
import { noop, foldOption } from "lib";
import {
  createSetMode,
  createSetLastError,
  createSetTask,
  createSetHasTaskLock,
} from "state/ui-segmentation/actions";
import { lockTask as lock } from "http/task";

const lockTask = (task: Task, dispatch: Dispatch, mode: TaskUIMode, silent: boolean) => {
  if ([TaskUIMode.Labeling, TaskUIMode.Validating].includes(mode)) {
    lock(task.properties.projectId, task.id)
      .then((resp) => {
        dispatch(createSetHasTaskLock(true));
        if (!silent) {
          dispatch(createSetTask(fromNullable(resp.data)));
        }
      })
      .catch((err: Error) => dispatch(createSetLastError("Failed to lock task", err)));
  }
};

/**
 * Handles assesing the current lock state of the task and applies the lock to
 * the task if necessary. Can also unlock a task if a lock is detected and the
 * UI is in an intermediate mode.
 */
const applyTaskLock = (
  taskOption: Option<Task>,
  userId: string,
  mode: TaskUIMode,
  dispatch: Dispatch,
  reclaim = false,
  silent = false
) => {
  // If the task is not locked by the current user but the mode is labeling or
  // validating, set locks and update the task in the store
  foldOption(taskOption, noop, (task) => {
    foldOption(
      task.properties.lockedOn,
      () => {
        // If the task is not locked and the task mode is now 'labeleing' or
        // 'validating', lock the task
        lockTask(task, dispatch, mode, silent);
      },
      (_) => {
        // If the task is locked, see who it is locked by
        foldOption(
          task.properties.lockedBy,
          // If lockedBy is none, we ignore the lock and treat
          // it as unlocked
          () => {},
          (lockedBy) => {
            if (lockedBy !== userId) {
              // If the task is locked by someone else, make sure the UI
              // mode is 'locked'
              dispatch(createSetMode(TaskUIMode.Locked));
            } else if (reclaim) {
              lockTask(task, dispatch, mode, silent);
            }
          }
        );
      }
    );
  });
};

export default applyTaskLock;
