import { Task, TaskStatus } from "datamodel/task";
import { LabelCollection } from "datamodel/label";
import { putTaskLabels, putTaskValidation } from "http/task";
import { Dispatch } from "redux";
import { createSetDisplayBuffer } from "state/ui-segmentation/actions";

const restoreLabels = async (task: Task, labelCollection: LabelCollection, dispatch: Dispatch) => {
  const put = [TaskStatus.Unlabeled, TaskStatus.LabelingInProgress].includes(task.properties.status)
    ? putTaskLabels
    : putTaskValidation;

  await put(task, { ...labelCollection, nextStatus: task.properties.status });
  dispatch(createSetDisplayBuffer({ type: "FeatureCollection", features: [] }));
};

export default restoreLabels;
