import React, { useMemo } from "react";
import { Field } from "react-final-form";
import { TextInput, Box, Text } from "@blasterjs/core";

import { requiredWithMessage } from "../../helpers/validators";
import RadioBlocks, { RadioBlocksItem } from "components/RadioBlocks";
import FormSectionHeading from "components/FormSectionHeading";
import Error from "components/FormError";
import { CampaignType } from "datamodel/campaign";
import { Image } from "@chakra-ui/react";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";
import HitlLimitBanner from "components/Hitl/LimitBanner";

interface LabelTypeOptionProps {
  heading: string;
  description: string;
  imagePath: string;
}

interface CampaignTypeConfig extends LabelTypeOptionProps {
  type: CampaignType;
  intercomTarget: string;
}

const LabelTypeOption = ({ heading, description, imagePath }: LabelTypeOptionProps) => (
  <Box width="276px" textAlign="left" display="flex" flexDirection="column" height="100%">
    <Box flex={1}>
      <Box mb={1}>
        <Text fontWeight="bold">{heading}</Text>
      </Box>
      <Box mb={1}>
        <Text color="gray600">{description}</Text>
      </Box>
    </Box>
    <Box textAlign="center">
      <Image
        alt={heading}
        height="150px"
        src={`${process.env.PUBLIC_URL}/${imagePath}`}
        display="inline-block"
      />
    </Box>
  </Box>
);

let typeConfigs: CampaignTypeConfig[] = [
  {
    heading: "Object detection",
    description:
      "This interface allows for polygonal labels that are exported as separate individual shapes. It's optimized for training object detection models.",
    imagePath: "object-detection-viz.png",
    intercomTarget: "object-detection-project",
    type: CampaignType.Detection,
  },
  {
    heading: "Semantic segmentation",
    description:
      "This interface allows for polygonal labels that are merged into a single multipolygon per class. It's optimized for training semantic segmentation models.",
    imagePath: "semantic-segmentation-viz.png",
    intercomTarget: "segmentation-project",
    type: CampaignType.Segmentation,
  },
  {
    heading: "Chip classification",
    description:
      "This interface allows for task-level classifications and treats each task like a flash card. It's optimized for training classification models that predict on “chips” the same size as the tasks.",
    imagePath: "classification-viz.png",
    intercomTarget: "classification-project",
    type: CampaignType.Classification,
  },
];

const NameAndType = () => {
  const hitlEnabled = useUserFeatureFlag("hitl");

  typeConfigs = useMemo(() => {
    if (hitlEnabled) {
      return typeConfigs.filter((config) => config.type === CampaignType.Segmentation);
    }
    return typeConfigs;
  }, [hitlEnabled]);

  return (
    <>
      <Box display="flex" flex={1} pt={3} px={1} flexDirection="column" m="0 auto" width="1024px">
        <Box
          maxWidth={"100%"}
          p={0}
          flex={1}
          alignSelf="stretch"
          display="flex"
          flexDirection="column"
          mr={3}
        >
          <FormSectionHeading title="Create a new labeling campaign" />
          <Box mb={4} display="flex" flexDirection="column">
            <Box as="label" htmlFor="name" mb={2}>
              <Text fontSize="1.6rem" fontWeight="bold">
                Campaign name
              </Text>
            </Box>
            <Box display="flex" flexDirection="column">
              <Field name="name" validate={requiredWithMessage("This field is required")}>
                {(props) => (
                  <TextInput
                    data-intercom-target="campaign-name-input"
                    name={props.input.name}
                    value={props.input.value}
                    onChange={props.input.onChange}
                    onBlur={props.input.onBlur}
                    onFocus={props.input.onFocus}
                    width="30%"
                    invalid={props.meta.touched && props.meta.invalid}
                    placeholder="My campaign name"
                    autoFocus
                  />
                )}
              </Field>
              <Box my={1}>
                <Error name="name" />
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box as="label" htmlFor="name" mb={2}>
              <Text fontSize="1.6rem" fontWeight="bold">
                Type of labels
              </Text>
            </Box>
            {hitlEnabled && (
              <HitlLimitBanner message="semantic segmentation is the only supported campaign type." />
            )}
            <RadioBlocks name="projectType" justifyContent="flex-start">
              {typeConfigs.map((config, idx) => (
                <RadioBlocksItem
                  data-intercom-target={config.intercomTarget}
                  value={config.type}
                  key={idx}
                >
                  <LabelTypeOption {...config} />
                </RadioBlocksItem>
              ))}
            </RadioBlocks>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default NameAndType;
