import { Dispatch } from "redux";
import { TaskUIMode, TaskURLActionType } from "types";
import {
  createSetLastError,
  createSetMode,
  createSetExistingAnnotations,
  createInitActionBuffer,
} from "state/ui-segmentation/actions";
import { Task } from "datamodel/task";
import { listTaskLabels } from "http/task";
import { pipe } from "fp-ts/es6/pipeable";
import { filterMap } from "fp-ts/es6/Array";
import transformLabelToDrawingAction from "./transformLabelToDrawingAction";
import { Label, LabelCollection } from "datamodel/label";
import { listHitlJobs } from "http/hitlJob";
import sortBy from "lodash.sortby";
import { HitlJob } from "datamodel/hitlJob";
import { none } from "fp-ts/es6/Option";
import { LabelGroup } from "datamodel/labelGroup";
import { Project } from "datamodel/project";
import { Campaign, CampaignType } from "datamodel/campaign";

const machineLabelTransformer = (machineLabels: LabelCollection): LabelCollection => ({
  type: "FeatureCollection",
  features: machineLabels.features.map((f) => ({
    ...f,
    properties: { ...f.properties, hitlVersionId: none },
  })) as Label[],
});

const persistLabelsToStore = (
  collection: LabelCollection,
  labelClassGroups: LabelGroup[],
  dispatch: Dispatch
) => {
  dispatch(createSetExistingAnnotations(collection));
  dispatch(
    createInitActionBuffer(
      pipe(collection.features, filterMap(transformLabelToDrawingAction(labelClassGroups)))
    )
  );
  return;
};

const initializeAnnotations = async (
  campaign: Campaign,
  project: Project,
  task: Task,
  labelClassGroups: LabelGroup[],
  action: TaskURLActionType,
  hitlEnabled: boolean,
  dispatch: Dispatch
) => {
  const isValidate = action === TaskURLActionType.Validate;
  const isSegmentation = campaign.campaignType === CampaignType.Segmentation;
  const existingLabelsP = listTaskLabels(task).then(async (resp) => {
    const { data: collection } = resp;
    const hasHumanLabels = !!collection.features.length;
    // When in validate UI, hitl is enabled, and does not have human labels
    // get the last HITL `RAN` job, list machine labels of this task, dispatch to store
    if (isValidate && hitlEnabled && !hasHumanLabels && isSegmentation) {
      const { id: projectId, campaignId } = project;
      const { data: jobs } = await listHitlJobs({ campaignId, projectId });
      if (!jobs.count) return persistLabelsToStore(collection, labelClassGroups, dispatch);
      const sorted: HitlJob[] = sortBy(jobs.results, "version");
      const { id: hitlVersionId } = sorted[sorted.length - 1];
      const { data: machineLabels } = await listTaskLabels(task, { hitlVersionId });
      const transformed = machineLabelTransformer(machineLabels);
      return persistLabelsToStore(transformed, labelClassGroups, dispatch);
    }
    // Otherwise, dispatch human labels to store
    return persistLabelsToStore(collection, labelClassGroups, dispatch);
  });
  let result = [existingLabelsP];

  return Promise.all(result).catch((err) => {
    dispatch(createSetLastError("Could not fetch existing labels", err));
    dispatch(createSetMode(TaskUIMode.Error));
  });
};

export default initializeAnnotations;
