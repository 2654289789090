import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@blasterjs/core";

import { TaskUIMode, SegmentationUIState } from "types";
import LoadingIcon from "components/LoadingIcon";
import Error from "./Error";
import SegmentationLabeling from "./SegmentationLabeling";
import Locked from "./Locked";
import ProjectComplete from "./ProjectComplete";
import ActionCompleted from "./ActionCompleted";
import DetectionLabeling from "./DetectionLabeling";
import ClassificationLabeling from "./ClassificationLabeling";
import { foldOption } from "lib";
import TaskMeta from "./TaskMeta";
import { CampaignType } from "datamodel/campaign";
import styled from "styled-components";
import FlaggedByUser from "./FlaggedByUser";
import Flagging from "./Flagging";
import Rejecting from "./Rejecting";
import Labeling from "./Labeling";
import ConfirmSplitTask from "./ConfirmSplitTask";
import TaskNotLabeled from "./TaskNotLabeled";
import TaskValidated from "./TaskValidated";

const StyledBox = styled(Box)`
  display: flex;
  border-right: 1px solid rgb(236, 236, 236);
  align-self: stretch;
  overflow-y: auto;
`;

const GetSidebar = ({ mode, projectType }: { mode: TaskUIMode; projectType: CampaignType }) => {
  switch (mode) {
    case TaskUIMode.Error:
      return <Error />;
    case TaskUIMode.Labeling:
    case TaskUIMode.Validating:
      switch (projectType) {
        case CampaignType.Detection:
          return (
            <Labeling>
              <DetectionLabeling />
            </Labeling>
          );
        case CampaignType.Classification:
          return (
            <Labeling>
              <ClassificationLabeling />
            </Labeling>
          );
        case CampaignType.Segmentation:
        default:
          return (
            <Labeling>
              <SegmentationLabeling />
            </Labeling>
          );
      }
    case TaskUIMode.Rejecting:
      return <Rejecting />;
    case TaskUIMode.LabelingCompleteByUser:
      return <ActionCompleted />;
    case TaskUIMode.LabelingNotPermitted:
      return <TaskValidated />;
    case TaskUIMode.Loading:
      return <LoadingIcon />;
    case TaskUIMode.Locked:
      return <Locked />;
    case TaskUIMode.ProjectComplete:
      return <ProjectComplete />;
    case TaskUIMode.ValidatingCompleteByUser:
      return <ActionCompleted />;
    case TaskUIMode.ValidatingNotPermitted:
      return <TaskNotLabeled />;
    case TaskUIMode.Flagging:
      return <Flagging />;
    case TaskUIMode.Flagged:
      return <FlaggedByUser />;
    case TaskUIMode.ConfirmSplitTask:
      return <ConfirmSplitTask />;
  }
};

const GetTaskMeta = ({ mode }: { mode: TaskUIMode }) => {
  const show = [
    TaskUIMode.Labeling,
    TaskUIMode.LabelingCompleteByUser,
    TaskUIMode.Validating,
    TaskUIMode.ValidatingCompleteByUser,
  ].includes(mode);
  return show ? <TaskMeta /> : null;
};

const Sidebar = () => {
  const [mode, campaignOption] = useSelector(
    (state: { segmentationUI: SegmentationUIState }) =>
      [state.segmentationUI.mode, state.segmentationUI.campaign] as const
  );

  return foldOption(
    campaignOption,
    () => (
      <StyledBox width="300px">
        <LoadingIcon />
      </StyledBox>
    ),
    (campaign) => (
      <StyledBox flexDirection="column" width="300px">
        <GetSidebar mode={mode} projectType={campaign.campaignType} />
        <GetTaskMeta mode={mode} />
      </StyledBox>
    )
  );
};

export default Sidebar;
