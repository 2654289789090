import React, { useEffect, useState } from "react";
import useRouter from "use-react-router";
import styled from "styled-components";
import { Box, Text, Divider, Button, Icon } from "@blasterjs/core";
import { Option, fromNullable, none, map } from "fp-ts/es6/Option";

import { Task, TaskStatus } from "datamodel/task";
import { getTask } from "http/task";
import { MenuPositionStyle } from "lib/calculateMenuPosition";
import FlexFiller from "components/FlexFiller";
import { TaskURLActionType } from "types";
import LabelerMenu from "components/map/menu/LabelerMenu";
import ValidatorMenuForTaskMap from "components/map/menu/ValidatorMenuForTaskMap";
import parseStatusName from "helpers/parseStatusName";
import getTaskStatusColor from "helpers/getTaskStatusColor";
import Swatch from "components/Swatch";
import { pipe } from "fp-ts/es6/pipeable";

export const StyledContextMenu = styled.div`
  position: absolute;
  width: 250px;
  background: white;
  border: 1px solid #ccc;
  z-index: 10000;
  box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.15);
`;

export interface Feature {
  layer: {
    id: string;
  };
  properties: {
    id: string;
    status: TaskStatus;
    owner: string;
    project_id: string;
  };
}

interface Props {
  canLabel: boolean;
  canValidate: boolean;
  position: MenuPositionStyle;
  taskFeature: Feature;
  onClickClose: () => void;
  onUpdate: () => void;
}

const TaskMapMenu = ({
  canLabel,
  canValidate,
  position,
  taskFeature,
  onClickClose,
  onUpdate,
}: Props) => {
  const { history } = useRouter();
  const [taskOption, setTaskOption] = useState<Option<Task>>(none);

  useEffect(() => {
    const fetchTask = async () => {
      const {
        properties: { id: taskId, project_id: projectId },
      } = taskFeature;
      const { data: task } = await getTask(projectId, taskId);
      setTaskOption(fromNullable(task));
    };

    fetchTask();
  }, [taskFeature]);

  const onClickUrlAction = (actionType: TaskURLActionType) => {
    history.push(
      `/projects/${taskFeature.properties.project_id}/tasks/${taskFeature.properties.id}/${actionType}`
    );
  };

  const ValidatorMenuCallback = () => {
    onUpdate();
    onClickClose();
  };

  return (
    <StyledContextMenu style={position}>
      <Box display="flex" flexDirection="row" m={1} justifyContent="flex-start" alignItems="center">
        <Swatch
          color={getTaskStatusColor(taskFeature.properties.status)}
          mr={1}
          size="1.4rem"
          border="1px solid #c4c4c4"
          borderRadius="3px"
        />
        <Text color="textLight" as="strong">
          {`${parseStatusName(taskFeature.properties.status)} task`}
        </Text>
        <FlexFiller />
        <Button appearance="minimal" p="0" onClick={onClickClose}>
          <Icon name="cross" />
        </Button>
      </Box>
      <Divider height="2px" color="#ebebeb" />
      <Box p={1} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
        {canLabel && !canValidate ? (
          // status should be from the freshly fetched task
          // which is fetched every time this context menu is opened
          <LabelerMenu
            taskStatusOption={pipe(
              taskOption,
              map((t) => t.properties.status)
            )}
            onClickUrlAction={onClickUrlAction}
          />
        ) : canValidate ? (
          <ValidatorMenuForTaskMap
            taskStatus={taskFeature.properties.status}
            taskOption={taskOption}
            onClickUrlAction={onClickUrlAction}
            callback={ValidatorMenuCallback}
          />
        ) : (
          <></>
        )}
      </Box>
    </StyledContextMenu>
  );
};

export default TaskMapMenu;
