import { Campaign } from "datamodel/campaign";
import { pipe } from "fp-ts/es6/function";
import { some, fold } from "fp-ts/es6/Option";
import { createTaskGrid, postProject } from "http/project";
import findQualifiedSpaceNetProject from "./findQualifiedSpaceNetProject";
import { ProjectStatus } from "datamodel/project";

const addSpaceNetProjectsToCampaign = async (projectIds: string[], campaign: Campaign) =>
  Promise.all(
    projectIds.map(async (id) =>
      pipe(
        findQualifiedSpaceNetProject(id),
        fold(
          () => {
            throw new Error("Invalid SpaceNet project id");
          },
          async (p) => {
            const projectReq = await postProject({
              name: p.name,
              taskSizePixels: 512,
              aoi: some(p.aoi),
              campaignId: campaign.id,
              status: ProjectStatus.Ready,
              tileLayers: p.tileLayers,
            });
            return await createTaskGrid(projectReq.data, p.taskSizeMeters);
          }
        )
      )
    )
  );

export default addSpaceNetProjectsToCampaign;
