import styled from "styled-components";
import { Label } from "@blasterjs/core";

export const LayerPicker = styled.div`
  position: absolute;
  top: 0px;
  right: 100%;
  margin-right: 8px;
  z-index: 10000;
  background-color: white;
  border-radius: 2px;
  font-size: 16px;
  padding: 10px;

  &:after {
    content: "";
    position: absolute;
    top: 12px;
    right: -10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent white;
  }

  input {
    cursor: pointer;
  }

  label {
    font-size: 14px;
  }
`;

export const BaseMapItem = styled.div`
  background: url(${(props) => props.preview});
  display: flex;
  width: 150px;
  height: 3rem;
  border-radius: 3px;
  border: 1px solid #ddd;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  border: ${(props) => (props.isSelected ? "1px solid blue" : "none")};
`;

export const ToggleLabel = styled(Label)`
  user-select: none;
  cursor: pointer;
  * {
    cursor: pointer;
  }
  display: block;
  width: 10em;
  pointer-events: auto;
`;
