import { Campaign } from "datamodel/campaign";
import sortClassGroupAndClass from "helpers/sortClassGroupAndClass";
import buildClassSummaryItem from "./buildClassSummaryItem";
import buildGroupSummaryItem from "./buildGroupSummaryItem";
import { GroupSummaryItem } from "./types";

const buildSummaryFromCampaign = (campaign: Campaign): GroupSummaryItem[] =>
  sortClassGroupAndClass(campaign.labelClassGroups).map((groupDef) =>
    buildGroupSummaryItem(
      groupDef,
      groupDef.labelClasses.map((classDef) => buildClassSummaryItem(classDef))
    )
  );

export default buildSummaryFromCampaign;
