import { Option } from "fp-ts/es6/Option";
import { TaskURLActionType } from "types";
import { Task, TaskStatus } from "datamodel/task";
import { RandomTaskParams, getRandomHitlTask } from "http/task";

const ACTION_STATUS_MAPPING = {
  [TaskURLActionType.Label]: TaskStatus.Unlabeled,
  [TaskURLActionType.Validate]: TaskStatus.Labeled,
};

const fetchHitlTask = async (
  actionType: TaskURLActionType,
  params: RandomTaskParams
): Promise<Option<Task>> => {
  const unstartedParams = {
    ...params,
    status: ACTION_STATUS_MAPPING[actionType],
  };

  try {
    return (await getRandomHitlTask(unstartedParams)).data;
  } catch (e) {
    // @TODO: figure out error boundaries. Throwing here will at least trigger
    // Rollbar.
    throw e;
  }
};

export default fetchHitlTask;
