import React, { ReactNode, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@blasterjs/core";
import { Option, some, none, exists } from "fp-ts/es6/Option";
import { pipe } from "fp-ts/es6/pipeable";

import { DrawingActionType } from "types";
import { createSetActiveDrawType } from "state/ui-segmentation/actions";

export type Props = {
  activeDrawTypeOption: Option<DrawingActionType>;
  mode: DrawingActionType;
  children: ReactNode;
  [x: string]: any;
};

const DrawModeButton = ({ activeDrawTypeOption, mode, children, ...restProps }: Props) => {
  const dispatch = useDispatch();

  const isActive = pipe(
    activeDrawTypeOption,
    exists((t) => t === mode)
  );

  const onClick = useCallback(
    () => dispatch(createSetActiveDrawType(isActive ? none : some(mode))),
    [dispatch, isActive, mode]
  );

  return (
    <Button
      appearance={isActive ? "prominent" : "minimal"}
      intent={isActive ? "primary" : ""}
      p={1}
      mr="0.4rem"
      onClick={onClick}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default DrawModeButton;
