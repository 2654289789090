import { LabelClassCreate } from "datamodel/labeClass";
import { some } from "fp-ts/es6/Option";

const labelClassCreateBuilder = (
  name: string,
  colorHexCode: string,
  index: number
): LabelClassCreate => ({
  name,
  colorHexCode,
  index,
  isActive: true,
  default: some(false),
  determinant: some(false),
});

export default labelClassCreateBuilder;
