const theme = {
  colors: {
    gray: {
      "50": "#F7F8F7",
      "100": "#DCDBDB",
      "200": "#C5C3C4",
      "300": "#AFACAD",
      "400": "#989596",
      "500": "#817E7F",
      "600": "#686465",
      "700": "#4E4B4C",
      "800": "#343233",
      "900": "#1A1919",
    },
    orange: {
      "50": "#FCF0E9",
      "100": "#F6D6C1",
      "200": "#EFBC99",
      "300": "#E9A271",
      "400": "#E3874A",
      "500": "#DD6D22",
      "600": "#B1571B",
      "700": "#854114",
      "800": "#582C0E",
      "900": "#2C1607",
    },
    blue: {
      "50": "#EAF5FA",
      "100": "#C5E3F1",
      "200": "#A0D1E9",
      "300": "#7BBFE0",
      "400": "#56ADD7",
      "500": "#319BCE",
      "600": "#277CA5",
      "700": "#1D5D7C",
      "800": "#143E52",
      "900": "#0A1F29",
    },
    red: {
      "50": "#FAEAEA",
      "100": "#F1C5C5",
      "200": "#E9A0A0",
      "300": "#E07B7B",
      "400": "#D75656",
      "500": "#CE3131",
      "600": "#A52727",
      "700": "#7C1D1D",
      "800": "#521414",
      "900": "#290A0A",
    },
    green: {
      "50": "#EAFBF0",
      "100": "#C4F3D5",
      "200": "#9DEBBA",
      "300": "#77E49F",
      "400": "#51DC84",
      "500": "#2BD469",
      "600": "#22AA54",
      "700": "#1A7F3F",
      "800": "#11552A",
      "900": "#092A15",
    },
    primary: "#3da1d1",
    secondary: "#f8f8f8",
    textBase: "#262224",
    textLight: "#666163",
    danger: "#d13d3d",
    gray100: "#F7F8F7",
    gray200: "#EDEEED",
    gray300: "#DCDDDC",
    gray400: "#C4C2C3",
    gray500: "#9E9B9C",
    gray600: "#7A7879",
    gray700: "#666163",
    gray800: "#474044",
    gray900: "#262224",
    link: "#3da1d1",
  },
  fonts: {
    body: "proxima-nova,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
    heading:
      "proxima-nova,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
    mono: "'Source Code Pro', monospace",
  },
  fontSizes: {
    xs: "1.2rem",
    sm: "1.4rem",
    md: "1.4rem",
    lg: "1.6rem",
    xl: "1.8rem",
    "2xl": "2rem",
    "3xl": "2.25rem",
    "4xl": "2.5rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  textStyles: {
    campaignOverviewSectionHeading: {
      fontSize: "18px",
      fontWeight: "600",
    },
  },
  components: {
    Popover: {
      baseStyle: {
        popper: {
          width: "fit-content",
          maxWidth: "fit-content",
        },
      },
    },
  },
};

export default theme;
