import { none, some } from "fp-ts/es6/Option";
import { PaginatedResponse, CampaignListState } from "types";
import { Campaign } from "datamodel/campaign";

export enum CampaignListActionTypes {
  SetPaginatedResponse = "CAMPAIGN_LIST_SET_PAGINATED_RESPONSE",
  SetDirty = "CAMPAIGN_LIST_SET_DIRTY",
  Purge = "CAMPAIGN_LIST_PURGE",
}

export interface SetPaginatedResponseAction {
  type: CampaignListActionTypes.SetPaginatedResponse;
  payload: PaginatedResponse<Campaign>;
}

export interface PurgeAction {
  type: CampaignListActionTypes.Purge;
}

export interface SetDirtyAction {
  type: CampaignListActionTypes.SetDirty;
  payload: boolean;
}

export type CampaignListActions = SetPaginatedResponseAction | PurgeAction | SetDirtyAction;

const INITIAL_STATE: CampaignListState = {
  count: none,
  hasPrevious: none,
  hasNext: none,
  pageSize: 8,
  campaigns: none,
  dirty: false,
};

const reducer = (
  state: CampaignListState = INITIAL_STATE,
  action: CampaignListActions
): CampaignListState => {
  switch (action.type) {
    case CampaignListActionTypes.SetPaginatedResponse:
      return {
        ...state,
        count: some(action.payload.count),
        hasPrevious: some(action.payload.hasPrevious),
        hasNext: some(action.payload.hasNext),
        campaigns: some(action.payload.results),
        dirty: false,
      };
    case CampaignListActionTypes.Purge:
      return INITIAL_STATE;
    case CampaignListActionTypes.SetDirty:
      return {
        ...state,
        dirty: action.payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
