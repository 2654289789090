import React, { useEffect, useMemo } from "react";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { Box, Heading, Icon } from "@blasterjs/core";
import { Badge, HStack, Stack, useToast, Text, Tooltip } from "@chakra-ui/react";
import { UUID } from "io-ts-types";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";

import Overview from "./Overview";
import Exports from "./Exports";
import { getCampaign } from "http/campaign";
import NavbarLink from "components/NavbarLink";
import FlexFiller from "components/FlexFiller";
import useDecodedParamQuery from "hooks/useDecodedParamQuery";
import useDecodedParam from "hooks/useDecodedParam";
import useEntityOwner from "hooks/useEntityOwner";
import LabelButton from "./LabelButton";
import ValidateButton from "./ValidateButton";
import Settings from "./Settings";
import Sharing from "./Sharing";
import PrivateRoute from "components/PrivateRoute";
import Issues from "./Issues";
import { TaskStatus } from "datamodel/task";
import AccessControlProvider from "components/AccessControl/AccessControlProvider";
import AccessLimiter from "components/AccessControl/AccessLimiter";
import { ACRActionType } from "datamodel/permissions";
import GButton from "components/GButton";
import { listHitlJobs } from "http/hitlJob";
import { queryClient } from "App";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";

const UnclickableButtons = () => (
  <Tooltip placement="auto" label="Prediction in progress">
    <Stack direction="row" spacing={4}>
      <GButton data-intercom-target="label-button" colorScheme="#fff" ml={1} disabled={true}>
        <Icon name="drawPolygon" mr={1} />
        Label
      </GButton>
      <GButton data-intercom-target="label-button" colorScheme="#fff" ml={1} disabled={true}>
        <Icon name="checkCircle" mr={1} />
        Validate
      </GButton>
    </Stack>
  </Tooltip>
);

const CampaignView = () => {
  const history = useHistory();
  const campaignIdResult = useDecodedParam("campaignId", UUID);
  const {
    isLoading,
    isError,
    data: campaign,
  } = useDecodedParamQuery(
    campaignIdResult,
    ["campaign"],
    (campaignId: UUID) => getCampaign(campaignId).then((resp) => resp.data),
    () => history.push("/")
  );

  const toast = useToast();
  const isOwner = useEntityOwner(campaign?.owner);
  const flaggedTasksCount = campaign?.taskStatusSummary[TaskStatus.Flagged];
  const hitlEnabled = useUserFeatureFlag("hitl");

  const { data: jobs } = useQuery(
    ["campaign-list-hitl-jobs", campaign?.id],
    () => campaign?.id && listHitlJobs({ campaignId: campaign?.id }),
    {
      refetchInterval: 60000,
      onSuccess: (resp) => {
        queryClient.setQueryData(["hitl-jobs", campaign?.id], resp?.data);
      },
      enabled: hitlEnabled && !!campaign?.id,
    }
  );

  // if no HITL job -> allow label/validation
  // otherwise, sllow label/validation when all jobs are done
  const allowTaskAccess = useMemo(() => {
    if (jobs !== undefined && !!jobs.data.count) {
      const ranJob = jobs.data.results.filter((job) => job.status === "RAN");
      return ranJob.length === jobs.data.count;
    }
    return true;
  }, [jobs]);

  useEffect(() => {
    if (isError) {
      toast({
        title: "An error occurred.",
        description: "Unable to fetch this campaign",
        status: "error",
        isClosable: true,
      });
      history.push("/");
    }
  }, [isError, toast, history]);

  return (
    <Box display="flex" flex={1} flexDirection="column">
      <Box display="flex" alignItems="stretch" px={2} m="0 auto" maxWidth="1172px" width="100%">
        <Box display="flex" flexDirection="column" alignItems="stretch" flex="1">
          <Box display="flex" alignItems="center" mt={2}>
            <Heading as="h1" fontWeight="semibold">
              {isLoading ? <Skeleton width="300px" /> : <>{campaign?.name}</>}
            </Heading>
            <FlexFiller />
            <Box alignment="right" justifySelf="flex-end">
              {!!campaign &&
                (allowTaskAccess ? (
                  <Stack direction="row" spacing={4}>
                    <LabelButton campaign={campaign} />
                    <ValidateButton campaign={campaign} />
                  </Stack>
                ) : (
                  <UnclickableButtons />
                ))}
            </Box>
          </Box>
          <Box display="flex" mt={2}>
            <NavbarLink data-intercom-target="overview-page" to="overview">
              Overview
            </NavbarLink>
            {campaign && (
              <AccessControlProvider campaignId={campaign.id}>
                <AccessLimiter requiredActions={[[ACRActionType.Validate]]}>
                  <NavbarLink data-intercom-target="issues-page" to="issues">
                    <HStack spacing={2} alignItems="center">
                      <Text>Issues</Text>
                      <Badge
                        borderRadius="99px"
                        px={3}
                        fontSize="13px"
                        fontWeight="semibold"
                        color="textLight"
                      >
                        {flaggedTasksCount}
                      </Badge>
                    </HStack>
                  </NavbarLink>
                </AccessLimiter>
              </AccessControlProvider>
            )}
            <NavbarLink data-intercom-target="exports-page" to="exports">
              Export data
            </NavbarLink>
            {isOwner && !hitlEnabled && (
              <NavbarLink data-intercom-target="sharing-page" to="sharing">
                Sharing
              </NavbarLink>
            )}
            {isOwner && (
              <NavbarLink data-intercom-target="settings-page" to="settings">
                Settings
              </NavbarLink>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        bg="white"
        borderColor="gray200"
        borderTop="1px solid"
        flex={1}
        display="flex"
        flexDirection="column"
      >
        {campaign && (
          <Switch>
            <Redirect exact from="/campaign/:campaignId" to="/campaign/:campaignId/overview" />
            <PrivateRoute path="/campaign/:campaignId/overview">
              <Overview campaign={campaign} />
            </PrivateRoute>
            <PrivateRoute path="/campaign/:campaignId/issues">
              <Issues campaign={campaign} />
            </PrivateRoute>
            <PrivateRoute path="/campaign/:campaignId/exports">
              <Exports campaign={campaign} />
            </PrivateRoute>
            <PrivateRoute path="/campaign/:campaignId/sharing">
              <Sharing campaign={campaign} />
            </PrivateRoute>
            <PrivateRoute path="/campaign/:campaignId/settings">
              <Settings campaign={campaign} />
            </PrivateRoute>
          </Switch>
        )}
      </Box>
    </Box>
  );
};

export default CampaignView;
