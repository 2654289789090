import * as d3c from "d3-color";
import { Option, none } from "fp-ts/es6/Option";

import { TaskGeoJsonLayerType, LayerConfig, SemanticLabelFeature } from "types";
import calculateLayerOpacity from "./calculateLayerOpacity";
import isLayerEnabled from "./isLayerEnabled";
import { foldOption } from "lib";

interface DetectionLayerProps {
  getLineWidth: number;
  highlightedObjectIndex: number;
  highlightColor: number[];
}

const createLayerProps = (
  layerId: string,
  layerType: TaskGeoJsonLayerType,
  vectorLayers: LayerConfig[],
  detectionLayerPropsO: Option<DetectionLayerProps> = none
) => {
  const opacity = calculateLayerOpacity(vectorLayers, layerId);
  const isEnabled = isLayerEnabled(vectorLayers, layerId);
  const layerProps = {
    id: layerId,
    getFillColor: (f: SemanticLabelFeature) => {
      if (layerType === TaskGeoJsonLayerType.Mask) {
        return [0, 0, 0, isEnabled ? Math.floor(0.8 * opacity) : 0];
      }
      const c = d3c.rgb(f.properties.color);
      return [c.r, c.g, c.b, isEnabled ? opacity : 0];
    },
    getLineColor: (f: SemanticLabelFeature) => {
      if (layerType === TaskGeoJsonLayerType.Mask) {
        return [255, 255, 255, isEnabled ? opacity : 0];
      }
      const c = d3c.rgb(f.properties.color);
      return [c.r, c.g, c.b, isEnabled ? Math.max(opacity, 96) : 0];
    },
    getLineWidth: layerType === TaskGeoJsonLayerType.Mask ? 0 : 2,
    lineWidthUnits: "pixels",
    updateTriggers: {
      getFillColor: [opacity, isEnabled],
      getLineColor: [opacity, isEnabled],
    },
  };
  return foldOption(
    detectionLayerPropsO,
    () => layerProps,
    (detectionLayerProps) => ({
      ...layerProps,
      ...detectionLayerProps,
    })
  );
};

export default createLayerProps;
