import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

type Props = {
  isActive?: boolean;
  isDisabled?: boolean;
} & BoxProps;

const Tab: React.FC<Props> = ({ isActive, isDisabled, ...props }) => (
  <Box
    borderTopRadius="2px"
    fontSize="14px"
    fontWeight="600"
    textDecoration="none"
    m="0"
    mb="-1px"
    px="0.8rem"
    py="1.2rem"
    zIndex="1"
    border="1px solid transparent"
    borderTop="3px solid"
    borderColor={isActive ? "gray.100" : "transparent"}
    borderBottomColor={isActive ? "white" : "transparent"}
    borderTopColor={isActive ? "orange.500" : "transparent"}
    filter={isActive ? "none" : "grayscale(1)"}
    background={isActive ? "white" : "none"}
    color={isActive ? "orange.500" : isDisabled ? "gray.700" : "gray.500"}
    cursor={isDisabled ? "not-allowed" : "pointer"}
    {...props}
  />
);

export default Tab;
