import React, { useCallback, useState } from "react";
import { Box, TextInput, Text, Heading } from "@blasterjs/core";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { useToast } from "@chakra-ui/react";

import { Campaign } from "datamodel/campaign";
import { updateCampaign, deleteCampaign } from "http/campaign";
import GButton from "components/GButton";
import { queryClient } from "App";

const DELETE_TARGET = "DELETE";

type Props = {
  campaign: Campaign;
};

const GeneralSettings = ({ campaign }: Props) => {
  const history = useHistory();
  const toast = useToast();

  const [nameBuffer, setNameBuffer] = useState<string>(campaign.name);
  const [deleteKey, setDeleteKey] = useState<string>("");

  const { mutateAsync: updateCampaignMutation, ...updateInfo } = useMutation(
    (campaign: Campaign) => updateCampaign(campaign),
    {
      onError: () => {
        toast({
          title: "An error occurred.",
          description: "Unable to update this campaign",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const { mutateAsync: deleteCampaignMutation, ...deleteInfo } = useMutation(
    (campaign: Campaign) => deleteCampaign(campaign.id),
    {
      onError: () => {
        toast({
          title: "An error occurred.",
          description: "Unable to update this campaign",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const isLoading = updateInfo.isLoading || deleteInfo.isLoading;

  const onSubmitRename = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      const campaignBuffer = { ...campaign, name: nameBuffer };
      updateCampaignMutation(campaignBuffer).then((response) => {
        response && queryClient.setQueryData(["campaign", campaign.id], campaignBuffer);
      });
      return false;
    },
    [campaign, nameBuffer, updateCampaignMutation]
  );

  const onSubmitDelete = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      deleteCampaignMutation(campaign).then((response) => {
        response && history.push("/");
      });
      return false;
    },
    [campaign, deleteCampaignMutation, history]
  );

  return (
    <Box display="flex" alignItems="stretch" width="100%" flexDirection="column">
      <Box p={0} mb={3} display="flex" flexDirection="column">
        <Heading as="h5" mb={1}>
          Campaign name
        </Heading>
        <Box p={0} display="inline-block">
          <form onSubmit={onSubmitRename}>
            <TextInput
              width="400px"
              value={nameBuffer}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNameBuffer(e.target.value)}
              disabled={isLoading}
            />
            <GButton
              type="submit"
              colorScheme="primary"
              disabled={isLoading}
              isLoading={updateInfo.isLoading}
              ml={2}
            >
              Rename
            </GButton>
          </form>
        </Box>
      </Box>
      <Box p={0}>
        <Heading as="h5" mb={1}>
          Delete this campaign
        </Heading>
        <Box maxWidth="500px" mb={2}>
          <Text color="textLight" lineHeight="1.3">
            Deleting a campaign is an irrecoverable operation. When a campaign is deleted, all
            associated labels and imagery are also deleted.
          </Text>
        </Box>
        <Box p={0} mb={2}>
          <Text fontSize={2} color="textLight">
            Type <Text fontWeight="bold">{DELETE_TARGET}</Text> below to confirm.
          </Text>
        </Box>
        <Box p={0} display="inline-block">
          <form onSubmit={onSubmitDelete}>
            <TextInput
              width="400px"
              value={deleteKey}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeleteKey(e.target.value)}
              disabled={isLoading}
            />
            <GButton
              type="submit"
              colorScheme="danger"
              isLoading={deleteInfo.isLoading}
              disabled={isLoading || deleteKey !== DELETE_TARGET}
              ml={2}
            >
              Delete permanently
            </GButton>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralSettings;
