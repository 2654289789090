import * as T from "io-ts";
import { useLocation } from "react-router-dom";

function useDecodedParam<ApplicationType = any, EncodeTo = ApplicationType, DecodeFrom = unknown>(
  paramName: string,
  codec: T.Type<ApplicationType, EncodeTo, DecodeFrom>
) {
  const params = new URLSearchParams(useLocation<any>().search);
  return codec.decode(params.get(paramName) as any);
}

export default useDecodedParam;
