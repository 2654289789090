import React, { ReactElement } from "react";
import Skeleton from "react-loading-skeleton";
import { Option, fold } from "fp-ts/es6/Option";
import { pipe } from "fp-ts/es6/pipeable";

interface ShowOrElseLoaderProps<T> {
  option: Option<T>;
  width: string;
  render: (b: T) => ReactElement;
}

const GetOrElseLoader = <T extends unknown>(props: ShowOrElseLoaderProps<T>) =>
  pipe(
    props.option,
    fold(() => <Skeleton width={props.width} />, props.render)
  );

export default GetOrElseLoader;
