import { LabelCreate, LabelCreateCollection } from "datamodel/label";
import { TaskStatus } from "datamodel/task";
import { none } from "fp-ts/es6/Option";
import { SemanticLabelFeature, SemanticLabelFeatureCollection } from "types";

const getSegmentationFeatureCollection = (
  displayBuffer: SemanticLabelFeatureCollection,
  nextStatus: TaskStatus
): LabelCreateCollection => {
  return {
    ...displayBuffer,
    nextStatus,
    features: displayBuffer.features.map(
      (f: SemanticLabelFeature): LabelCreate => ({
        ...f,
        properties: {
          classes: [f.properties.label],
          hitlVersionId: none,
        },
      })
    ),
  };
};

export default getSegmentationFeatureCollection;
