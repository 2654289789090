import { findFirst } from "fp-ts/es6/Array";
import { pipe } from "fp-ts/es6/function";
import { Option, some, map } from "fp-ts/es6/Option";
import projects from "common/spaceNetProjects";
import { QualifiedSpaceNetProject } from "../types";
import { v4 as uuid } from "uuid";

const findQualifiedSpaceNetProject = (id: string): Option<QualifiedSpaceNetProject> =>
  pipe(
    projects,
    findFirst((p) => p.id === id),
    map((p) => ({
      ...p,
      tileLayers: p.tileLayers.map((l) => ({
        id: uuid(),
        default: some(l.default),
        overlay: some(l.overlay),
        source: some(l.source),
        zxyUrl: some(l.url),
        name: l.name,
        projectId: p.id,
      })),
    }))
  );

export default findQualifiedSpaceNetProject;
