import { LabelGroup } from "datamodel/labelGroup";
import { ClassSummaryItem, GroupSummaryItem } from "./types";

const buildGroupSummaryItem = (
  groupDef: LabelGroup,
  classes: ClassSummaryItem[]
): GroupSummaryItem => ({
  groupId: groupDef.id,
  groupName: groupDef.name,
  index: groupDef.index,
  classes,
});

export default buildGroupSummaryItem;
