import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { _MapContext as MapContext, StaticMap, NavigationControl } from "react-map-gl";
import { DeckGL } from "deck.gl";
import { Box } from "@blasterjs/core";

import { generateViewport, generateMapStyle, maybeUpdateLayer } from "../helpers";
import { ApplicationStore, TaskUIMode, LayerPickerSection } from "types";
import { foldOption, seqOption } from "lib";
import LoadingIcon from "components/LoadingIcon";
import { createSetLayerPickerConfig } from "state/ui-segmentation/actions";
import MapControlsContainer from "../MapControlsContainer";
import SemanticLayerToggle from "components/map/control/SemanticLayerToggle/SemanticLayerToggle";
import { taskMaskLayerConfig } from "../helpers/layerConfigDefaults";

import setLayerPickerConfig from "../helpers/setLayerPickerConfig";

const taskMaskLayerId = "task-mask";

const ClassificationMap = () => {
  const dispatch = useDispatch();

  const [
    projectOption,
    taskOption,
    mode,
    imageryLayersConfig,
    basemapLayersConfig,
    vectorLayersConfig,
    overlayLayersConfig,
    idTokenOption,
  ] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.project,
        state.segmentationUI.task,
        state.segmentationUI.mode,
        state.segmentationUI.imageryLayersConfig,
        state.segmentationUI.basemapLayersConfig,
        state.segmentationUI.vectorLayersConfig,
        state.segmentationUI.overlayLayersConfig,
        state.newAuth.idToken,
      ] as const
  );

  useEffect(() => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Vector,
        vectorLayersConfig.length ? vectorLayersConfig : [taskMaskLayerConfig(taskMaskLayerId)]
      )
    );
  }, [vectorLayersConfig, dispatch]);

  useEffect(() => {
    if (!overlayLayersConfig.length) {
      setLayerPickerConfig(projectOption, dispatch);
    }
  }, [projectOption, overlayLayersConfig.length, dispatch]);

  const isLoading = mode === TaskUIMode.Loading;

  const { viewportOption } = useMemo(() => generateViewport(taskOption), [taskOption]);

  const onImageryLayerChange = (id: string, enabled: boolean, opacity: number) => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Imagery,
        imageryLayersConfig.map(maybeUpdateLayer(id, enabled, opacity))
      )
    );
  };

  const onBasemapChange = (id: string) => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Basemap,
        basemapLayersConfig.map((l) => ({ ...l, enabled: l.id === id }))
      )
    );
  };

  const onVectorLayerChange = (id: string, enabled: boolean, opacity: number) => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Vector,
        vectorLayersConfig.map(maybeUpdateLayer(id, enabled, opacity))
      )
    );
  };

  const onOverlayChange = (id: string, enabled: boolean, opacity: number) => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Overlay,
        overlayLayersConfig.map(maybeUpdateLayer(id, enabled, opacity))
      )
    );
  };

  const mapStyleOption = useMemo(
    () =>
      generateMapStyle(
        projectOption,
        imageryLayersConfig.concat(basemapLayersConfig, overlayLayersConfig, vectorLayersConfig),
        idTokenOption,
        taskOption
      ),
    [
      projectOption,
      imageryLayersConfig,
      basemapLayersConfig,
      overlayLayersConfig,
      vectorLayersConfig,
      idTokenOption,
      taskOption,
    ]
  );

  return isLoading ? (
    <Box display="flex" width="100%" height="100%">
      <LoadingIcon />
    </Box>
  ) : (
    foldOption(
      seqOption(viewportOption, mapStyleOption),
      () => (
        <Box display="flex" width="100%" height="100%">
          <LoadingIcon />
        </Box>
      ),
      ([viewport, mapStyle]) => (
        <>
          <DeckGL
            initialViewState={viewport}
            getCursor={() => "grab"}
            controller={true}
            ContextProvider={MapContext.Provider}
          >
            <MapControlsContainer>
              <NavigationControl showCompass={false} />
              <SemanticLayerToggle
                imageryLayers={{ onChange: onImageryLayerChange, layers: imageryLayersConfig }}
                vectorLayers={{ onChange: onVectorLayerChange, layers: vectorLayersConfig }}
                basemaps={{ onChange: onBasemapChange, layers: basemapLayersConfig }}
                overlayLayers={{ onChange: onOverlayChange, layers: overlayLayersConfig }}
              />
            </MapControlsContainer>
            <StaticMap mapStyle={mapStyle} width="100%" height="100%" />
          </DeckGL>
        </>
      )
    )
  );
};

export default ClassificationMap;
