import { Option } from "fp-ts/es6/Option";

import { KeyType, IconType } from "components/KeybindsToggle";
import { foldOption } from "lib";
import { Campaign, CampaignType } from "datamodel/campaign";

const createHotkeyModalContents = (campaignOption: Option<Campaign>) =>
  foldOption(
    campaignOption,
    () => [],
    (campaign) => {
      const mapInstructions = [
        {
          keysets: [
            [
              { type: KeyType.Text, name: "Ctrl" },
              { type: KeyType.Icon, name: IconType.MouseDefault },
            ],
            [
              { type: KeyType.Text, name: "⌘" },
              { type: KeyType.Icon, name: IconType.MouseDefault },
            ],
          ],
          action: "Drag to pan map",
        },
        {
          keysets: [
            [{ type: KeyType.Icon, name: IconType.ArrowUp }],
            [{ type: KeyType.Icon, name: IconType.ArrowDown }],
            [{ type: KeyType.Icon, name: IconType.ArrowLeft }],
            [{ type: KeyType.Icon, name: IconType.ArrowRight }],
          ],
          action: "Pan map",
        },
      ];
      if (campaign.campaignType === CampaignType.Classification) {
        const classSelectInstructions = [
          {
            keysets: [[{ type: KeyType.Text, name: "Tab" }]],
            action: "Next category",
          },
          {
            keysets: [
              [
                { type: KeyType.Text, name: "Shift" },
                { type: KeyType.Text, name: "Tab" },
              ],
            ],
            action: "Previous category",
          },
          {
            keysets: [
              [{ type: KeyType.Text, name: "1" }],
              [{ type: KeyType.Text, name: "2" }],
              [{ type: KeyType.Text, name: "..." }],
            ],
            action: "Select an option",
          },
          {
            keysets: [[{ type: KeyType.Text, name: "Enter" }]],
            action: "Confirm option selections",
          },
          {
            keysets: [[{ type: KeyType.Text, name: "Esc" }]],
            action: "Cancel selection changes",
          },
        ];
        return [...mapInstructions, ...classSelectInstructions];
      }
      const timeMachineInstructions = [
        {
          keysets: [
            [
              { type: KeyType.Text, name: "Ctrl" },
              { type: KeyType.Text, name: "Z" },
            ],
            [
              { type: KeyType.Text, name: "⌘" },
              { type: KeyType.Text, name: "Z" },
            ],
          ],
          action: "Undo",
        },
        {
          keysets: [
            [
              { type: KeyType.Text, name: "Ctrl" },
              { type: KeyType.Text, name: "Shift" },
              { type: KeyType.Text, name: "Z" },
            ],
            [
              { type: KeyType.Text, name: "⌘" },
              { type: KeyType.Text, name: "Shift" },
              { type: KeyType.Text, name: "Z" },
            ],
          ],
          action: "Redo",
        },
      ];

      const imageSwitchInstructions = [
        {
          keysets: [
            [
              { type: KeyType.Text, name: "Ctrl" },
              { type: KeyType.Text, name: "E" },
            ],
            [
              { type: KeyType.Text, name: "⌘" },
              { type: KeyType.Text, name: "E" },
            ],
          ],
          action: "Toggle imagery visibility",
        },
      ];
      return [...mapInstructions, ...timeMachineInstructions, ...imageSwitchInstructions];
    }
  );

export default createHotkeyModalContents;
