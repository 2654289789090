import * as React from "react";
import { Box, Heading, Text } from "@blasterjs/core";

type Props =
  | {
      title: string;
      description?: string;
      headerSize?: string;
    }
  | {
      title?: string;
      description: string;
      headerSize?: string;
    }
  | {
      title: string;
      description: string;
      headerSize?: string;
    };

const FormSectionHeading = ({ title, description, headerSize }: Props) => {
  return (
    <Box mb={3}>
      {!!title && (
        <Heading textAlign="left" as={headerSize || "h3"} mb={!!description ? 2 : 0}>
          {title}
        </Heading>
      )}
      {!!description && (
        <Box textAlign="left">
          <Text textAlign="left" color="gray600">
            {description}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default FormSectionHeading;
