import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Icon } from "@blasterjs/core";
import { Box, Stack, useToast, Text, useDisclosure } from "@chakra-ui/react";
import { useMutation, useQuery } from "react-query";
import { some, none, Option } from "fp-ts/es6/Option";

import { Campaign } from "datamodel/campaign";
import GButton from "components/GButton";
import { StacExportCreate, ExportAssetTypes, ExportStatus } from "datamodel/export";
import { listCampaignExports, postCampaignExport, SortMethod } from "http/export";
import { queryClient } from "App";
import StacExportItem from "./ExportItem";
import ExportCreateModalModal from "./ExportCreateModal";
import { ApplicationStore } from "types";
import { foldOption } from "lib";

const generateCampaignExport = (
  campaign: Campaign,
  exportAssetTypes: Option<ExportAssetTypes[]>
): StacExportCreate => ({
  name: `${campaign.name}-${Date.now()}`,
  license: {
    license: "proprietary",
    url: none,
  },
  campaignId: campaign.id,
  exportStatus: ExportStatus.NotExported,
  exportAssetTypes,
});

const Exports = ({ campaign }: { campaign: Campaign }) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [userOption] = useSelector((state: ApplicationStore) => [state.newAuth.user]);

  const isProUser = useMemo(
    () =>
      foldOption(
        userOption,
        () => false,
        (user) => user.groundworkProUser
      ),
    [userOption]
  );

  const { data: exports, ...exportsQueryInfo } = useQuery(
    ["campaign", campaign.id, "exports"],
    () =>
      listCampaignExports({
        campaignId: campaign.id,
        sort: SortMethod.NewestFirst,
        pageSize: 9999,
      }).then((resp) => resp.data),
    {
      refetchInterval: 10000,
      onError: () => {
        toast({
          title: "An error occurred.",
          description: "Unable to create an export for this campaign",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const { mutate: createCampaignExport, ...createCampaignExportInfo } = useMutation(
    (assetsOpt: Option<ExportAssetTypes[]>) =>
      postCampaignExport(generateCampaignExport(campaign, assetsOpt)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaign", campaign.id, "exports"]);
      },
      onError: () => {
        toast({
          title: "An error occurred.",
          description: "Unable to create an export for this campaign",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const onExport = (assets: ExportAssetTypes[]): void => {
    createCampaignExport(!!assets.length ? some(assets) : none);
  };

  const isLoading = exportsQueryInfo.isLoading || createCampaignExportInfo.isLoading;

  return (
    <>
      <Box
        m="0 auto"
        maxWidth="1172px"
        alignItems="strecth"
        width="100%"
        flex="1"
        display="flex"
        flexDirection="column"
        px={8}
        pt="32px"
        pb={8}
      >
        {!!exports?.count ? (
          <>
            <Stack direction="row" mb={8}>
              <GButton
                colorScheme="primary"
                leftIcon={<Icon name="plus" />}
                onClick={onOpen}
                disabled={isLoading}
              >
                New export
              </GButton>
            </Stack>
            <Stack direction="column" border="1px solid" borderColor="gray.300" spacing="0">
              <Box display="flex" alignItems="stretch" flex="1" flexDirection="row">
                <Box display="flex" flex="1.5" p={4}>
                  <Text fontSize="16px" fontWeight="600">
                    Timestamp
                  </Text>
                </Box>
                <Box display="flex" flex="1.5" p={4} pl={0}>
                  <Text fontSize="16px" fontWeight="600">
                    Assets
                  </Text>
                </Box>
                {isProUser && (
                  <Box display="flex" flex="1.5" p={4} pl={0}>
                    <Text fontSize="16px" fontWeight="600">
                      Expiration date
                    </Text>
                  </Box>
                )}
                <Box display="flex" flex="0.8" p={2} />
              </Box>
              {exports?.results.map((exp, index) => (
                <StacExportItem stacExport={exp} index={index} key={index} isProUser={isProUser} />
              ))}
            </Stack>
          </>
        ) : (
          <Stack flex="0.6" align="center" justify="center" direction="column" spacing={8}>
            <Text fontSize="18px">No exports have been created for this campaign yet</Text>
            <GButton
              colorScheme="primary"
              leftIcon={<Icon name="plus" />}
              onClick={onOpen}
              disabled={isLoading}
            >
              New export
            </GButton>
          </Stack>
        )}
      </Box>
      <ExportCreateModalModal
        isOpen={isOpen}
        onClose={onClose}
        isProUser={isProUser}
        onExport={onExport}
      />
    </>
  );
};

export default Exports;
