import React from "react";
import { TaskStatus } from "datamodel/task";
import { toPercent } from "lib";
import Skeleton from "react-loading-skeleton";
import { Campaign } from "datamodel/campaign";
import commaSeparateNumber from "lib/commaSeparateNumber";
import { Box, Text, Progress, ThemingProps, HStack } from "@chakra-ui/react";
import getTotalTaskCountFromSummary from "pages/helpers/getTotalTaskCountFromSummary";

type Props = {
  label: string;
  statuses: TaskStatus[];
  excludeStatuses: TaskStatus[];
  campaign: Campaign;
  showSkeleton?: boolean;
  colorScheme: ThemingProps["colorScheme"];
};

const CampaignProgressItem = ({
  label,
  statuses,
  excludeStatuses,
  campaign,
  colorScheme,
  showSkeleton = false,
}: Props) => {
  const total = getTotalTaskCountFromSummary(campaign.taskStatusSummary, excludeStatuses);

  const count = Object.entries(campaign.taskStatusSummary).reduce(
    (acc, [k, v]) => acc + (statuses.includes(k as TaskStatus) ? v : 0),
    0
  );

  return (
    <Box>
      <Box mb="2px">
        {showSkeleton ? (
          <Skeleton width="175px" />
        ) : (
          <Text>
            <Text as="span" fontWeight="600">
              {commaSeparateNumber(count)}
            </Text>
            <Text as="span" color="textLight">{` out of ${commaSeparateNumber(
              total
            )} tasks ${label}`}</Text>
          </Text>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        {showSkeleton ? (
          <HStack spacing={4}>
            <Progress width="215px" size="sm" borderRadius="1000px" />
            <Text color="textLight">
              <Skeleton width="25px" />%
            </Text>
          </HStack>
        ) : (
          <HStack spacing={4}>
            <Progress
              value={+toPercent(count, total, 1)}
              colorScheme={colorScheme}
              width="215px"
              size="sm"
              borderRadius="1000px"
            />
            <Text color="textLight">{toPercent(count, total, 1)}%</Text>
          </HStack>
        )}
      </Box>
    </Box>
  );
};

export default CampaignProgressItem;
