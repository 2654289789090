import React from "react";
import { TextArea } from "@blasterjs/core";
import { useSelector, useDispatch } from "react-redux";
import { isSome } from "fp-ts/es6/Option";
import useRouter from "use-react-router";

import { foldOption, noop, seqOption } from "lib";
import { TaskUIMode, TaskURLActionType, ApplicationStore } from "types";
import { createSetMode } from "state/ui-segmentation/actions";
import getTaskQueueNeighbors from "pages/SegmentationTask/helpers/getTaskQueueNeighbors";
import { RouteComponentProps } from "react-router-dom";
import rejectTask from "../helpers/rejectTask";
import pushRandomTask from "pages/SegmentationTask/helpers/pushRandomTask";
import { Box, Heading, Stack } from "@chakra-ui/react";
import GButton from "components/GButton";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";

type Route = RouteComponentProps<{
  action: TaskURLActionType;
}>;

const Rejecting = () => {
  const { location }: Route = useRouter();
  const dispatch = useDispatch();

  const [campaignOption, projectOption, taskOption, taskQueue, urlActionType] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.campaign,
        state.segmentationUI.project,
        state.segmentationUI.task,
        state.segmentationUI.taskQueue,
        state.segmentationUI.urlActionType,
      ] as const
  );

  let [getNote, setNote] = React.useState("");

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const nextTaskIdOption = getTaskQueueNeighbors(taskOption, taskQueue)[1];

  const hitlEnabled = useUserFeatureFlag("hitl");

  const onClickRetainLabels = () => {
    foldOption(taskOption, noop, (task) => {
      rejectTask(task, getNote, false, dispatch).then(() => onAfterConfirm());
    });
  };

  const onClickRemoveLabels = () => {
    foldOption(taskOption, noop, (task) => {
      rejectTask(task, getNote, true, dispatch).then(() => onAfterConfirm());
    });
  };

  const onClickCancel = () => {
    dispatch(createSetMode(TaskUIMode.Validating));
  };

  const onAfterConfirm = () => {
    foldOption(seqOption(projectOption, campaignOption), noop, ([project, campaign]) => {
      if (isSome(nextTaskIdOption)) {
        dispatch(createSetMode(TaskUIMode.ValidatingCompleteByUser));
      } else {
        pushRandomTask(location.search, urlActionType, hitlEnabled, project, campaign, dispatch, {
          projectId: project.id,
        });
      }
    });
  };

  return (
    <Box flex="1" alignSelf="flexStart" m={4}>
      <Heading as="h5" fontSize="18px" mt={6}>
        Reject task
      </Heading>
      <Box mt={8}>What's wrong with these labels?</Box>
      <Stack mt={8} spacing={4}>
        <TextArea name="note" width="100%" onChange={handleNoteChange} mb={1} autoFocus />
        <GButton
          data-intercom-target="confirm"
          colorScheme="primary"
          width="100%"
          onClick={onClickRetainLabels}
        >
          Reject
        </GButton>
        <GButton
          data-intercom-target="confirm"
          colorScheme="primary"
          width="100%"
          onClick={onClickRemoveLabels}
        >
          Reject and remove labels
        </GButton>
        <GButton
          data-intercom-target="confirm"
          colorScheme="#fff"
          width="100%"
          onClick={onClickCancel}
        >
          Cancel
        </GButton>
      </Stack>
    </Box>
  );
};

export default Rejecting;
