import { head } from "fp-ts/es6/Array";
import { Option } from "fp-ts/es6/Option";
import { Task, TaskAction } from "datamodel/task";

const getLatestTaskAction = (task: Task): Option<TaskAction> =>
  head(
    task.properties.actions.sort(
      (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    )
  );

export default getLatestTaskAction;
