import React from "react";
import { Box, Text, Icon } from "@blasterjs/core";

import { TaskStatus } from "datamodel/task";
import { Project } from "datamodel/project";
import { foldOption, noEl } from "lib";

type Props = {
  project: Project;
};

const TaskFlags = ({ project }: Props) =>
  foldOption(project.taskStatusSummary, noEl, (summary) => (
    <Box display="flex" alignItems="center">
      <Icon name="flag" color="#d35c28" mr={1} />
      <Box>
        <Text fontWeight="bold">{summary[TaskStatus.Flagged]}</Text>&nbsp;flagged
      </Box>
      <Box mx="4px">&middot;</Box>
      <Box>
        <Text fontWeight="bold">{summary[TaskStatus.Invalid]}</Text>&nbsp;deleted
      </Box>
    </Box>
  ));

export default TaskFlags;
