import { ACRActionType } from "datamodel/permissions";
import verifyActions from "helpers/verifyActions";
import { UUID } from "io-ts-types";
import { getCampaignActions } from "http/campaign";
import { useQuery } from "react-query";

/**
 * `requiredActions` contains lists of acceptable permission combinations ~ [[A and B and C...] OR [D and E and F]...]
 * `requiredActions` example:
 * [[ACRActionType.Validate], [ACRActionType.Annotate]] => if a user has
 * validate AND/OR annotate permissions AND/OR all (*) permissions, the content will be shown
 *
 * [[ACRActionType.Annotate, ACRActionType.Validate], [ACRActionType.Delete]] => if a user has
 * both Annotate AND Validate permissions AND/OR has a DELETE permission AND/OR has an ALL permission, the content will be shown
 */

const useCampaignAccessControl = (campaignId: UUID, requiredActions: ACRActionType[][]) =>
  useQuery(["use-campaign-access-control", campaignId], () =>
    getCampaignActions(campaignId).then((resp) => verifyActions(resp.data, requiredActions))
  );

export default useCampaignAccessControl;
