import { pipe } from "fp-ts/es6/pipeable";
import { map, Option, none } from "fp-ts/es6/Option";
import { findFirst } from "fp-ts/es6/Array";
import { identity } from "fp-ts/es6/function";

import { getDefaultBasemap } from "common/basemaps";
import { foldOption } from "lib";
import { HitlJob } from "datamodel/hitlJob";
import { Project } from "datamodel/project";
import { Tile } from "datamodel/tile";

const createMapStyleOption = (
  project: Project,
  tileBaseUrl: string,
  idTokenO: Option<string>,
  jobOpt: Option<HitlJob> = none
) =>
  pipe(
    project.tileLayers,
    findFirst((tms: Tile) => tms.default),
    map((tms: Tile) => {
      const projectTile = {
        type: "raster",
        tiles: [tms.zxyUrl],
        tileSize: 256,
        scheme: "xyz",
      };
      const highlightedTask = {
        type: "vector",
        tiles: [
          `${tileBaseUrl}/mvt/projects/${project.id}/tasks/{z}/{x}/{y}?token=${foldOption(
            idTokenO,
            () => "",
            identity
          )}`,
        ],
      };
      const projectTasks = {
        type: "vector",
        tiles: [
          `${tileBaseUrl}/mvt/projects/${project.id}/tasks/{z}/{x}/{y}?token=${foldOption(
            idTokenO,
            () => "",
            identity
          )}`,
        ],
      };
      const projectLabels = {
        type: "vector",
        tiles: [
          `${tileBaseUrl}/mvt/projects/${project.id}/labels/{z}/{x}/{y}?token=${foldOption(
            idTokenO,
            () => "",
            identity
          )}`,
        ],
      };
      const predictionLabels = (jobId: string) => {
        return {
          type: "vector",
          tiles: [
            `${tileBaseUrl}/mvt/projects/${
              project.id
            }/hitl-labels/${jobId}/{z}/{x}/{y}?token=${foldOption(idTokenO, () => "", identity)}`,
          ],
        };
      };

      const basemap = {
        id: "basemap",
        type: "raster",
        tiles: [getDefaultBasemap().url.replace("{s}", 1)],
        tileSize: 256,
      };

      let layers = [
        {
          id: "basemap",
          type: "raster",
          source: "basemap",
        },
        {
          id: "projectTile",
          type: "raster",
          source: "projectTile",
          fadeDuration: 0,
        },
      ];

      const defaultStyle = {
        version: 8,
        sources: {
          projectTile,
          projectTasks,
          highlightedTask,
          projectLabels,
          basemap,
        },
        layers,
      };

      return foldOption(
        jobOpt,
        () => defaultStyle,
        (job) => ({
          ...defaultStyle,
          sources: { ...defaultStyle.sources, predictionLabels: predictionLabels(job.id) },
        })
      );
    })
  );

export default createMapStyleOption;
