import { Dispatch } from "redux";
import { none } from "fp-ts/es6/Option";

import { Error } from "types";
import { getTask, putTaskStatus, unlockTask } from "http/task";
import { createSetLastError } from "state/ui-segmentation/actions";
import { foldOption } from "lib";
import { TaskStatus, Task } from "datamodel/task";
import { getLatestTaskAction } from ".";
import { AxiosResponse } from "axios";

const attemptToReleaseTask = (
  projectId: string,
  taskId: string,
  userId: string,
  dispatch: Dispatch
) =>
  // To make this method independent of shifting contexts between tasks, we will
  // fetch the task and then unlock and update as necessary
  getTask(projectId, taskId)
    .then(({ data: task }) => {
      return foldOption(
        task.properties.lockedBy,
        // Task isn't locked so updating will be allowed
        () => maybeRevertTaskStatus(task, dispatch),
        (lockedBy) => {
          if (lockedBy === userId) {
            // Task is locked but it's locked by the current user, update is
            // allowed
            return maybeRevertTaskStatus(task, dispatch).then(() =>
              unlockTask(projectId, taskId).catch((err: Error) =>
                dispatch(createSetLastError("Failed to unlock task when trying to release", err))
              )
            );
          }
          // If the task is locked by someone else we don't need to worry about
          // any of this so we can happily return a resolved promise
          return Promise.resolve();
        }
      );
    })
    .catch((err: Error) =>
      dispatch(createSetLastError("Failed to get task when trying to release", err))
    );

const maybeRevertTaskStatus = (task: Task, dispatch: Dispatch) => {
  let requestPromise: Promise<AxiosResponse<Task> | void> = Promise.resolve();
  if (
    [TaskStatus.LabelingInProgress, TaskStatus.ValidationInProgress].includes(
      task.properties.status
    )
  ) {
    const latestActionOption = getLatestTaskAction(task);
    const nextStatus = foldOption(
      latestActionOption,
      // If we can't find any actions for this task (should never happen), we
      // will assume it wasn't a re-opening situation
      () => {
        if (task.properties.status === TaskStatus.LabelingInProgress) {
          return TaskStatus.Unlabeled;
        }
        return TaskStatus.Labeled;
      },
      (action) => action.fromStatus
    );

    requestPromise = putTaskStatus(task.properties.projectId, task.id, {
      nextStatus,
      note: none,
    });

    requestPromise.catch((err: Error) =>
      dispatch(createSetLastError("Failed to update task when trying to release", err))
    );
  }
  return requestPromise;
};

export default attemptToReleaseTask;
