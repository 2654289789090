import React, { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { FaRobot } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { InteractiveMap, Layer, PointerEvent, ExtraState, LayerProps } from "react-map-gl";
import { findFirst } from "fp-ts/es6/Array";
import { Expression } from "mapbox-gl";
import { fromNullable, Option, none, some, map } from "fp-ts/es6/Option";
import { pipe } from "fp-ts/es6/pipeable";
import { Box, Stack, Text, Checkbox } from "@chakra-ui/react";

import { foldOption, noEl, calculateMenuPosition, seqOption, noop } from "lib";
import { TaskStatus } from "datamodel/task";
import { ApplicationStore } from "types";
import config from "config";
import { MenuPositionStyle } from "lib/calculateMenuPosition";
import createMapStyleOption from "./helpers/createMapStyleOption";
import { ACRActionType } from "datamodel/permissions";
import { Project } from "datamodel/project";
import useCampaignAccessControl from "hooks/useCampaignAccessControl";
import { Campaign, CampaignType } from "datamodel/campaign";
import calculateViewport, { TrueViewport } from "./helpers/calculateViewport";
import getTaskStatusColor from "helpers/getTaskStatusColor";
import parseStatusName from "helpers/parseStatusName";
import TaskMapMenu, { Feature } from "components/map/menu/TaskMapMenu";
import Swatch from "components/Swatch";
import { HitlJob, HITLJobStatus } from "datamodel/hitlJob";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";
import { postHitlJob } from "http/hitlJob";
import GButton from "components/GButton";
import FlexFiller from "components/FlexFiller";
import { createSetLimitsDirtyAction } from "state/ui-limits/actions";

const statuses = [
  TaskStatus.Unlabeled,
  TaskStatus.LabelingInProgress,
  TaskStatus.Labeled,
  TaskStatus.ValidationInProgress,
  TaskStatus.Validated,
  TaskStatus.Flagged,
  TaskStatus.Invalid,
];

type Props = {
  campaign: Campaign;
  project: Project;
  jobOpt: Option<HitlJob>;
  dirty: boolean;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

const TaskMap = ({ campaign, project, jobOpt, dirty, setDirty }: Props) => {
  const [idTokenO, predictionO] = useSelector(
    (state: ApplicationStore) => [state.newAuth.idToken, state.limits.predictionO] as const
  );
  const isNotClassificationProject = campaign.campaignType !== CampaignType.Classification;
  const [showLabels, setShowLabels] = useState(isNotClassificationProject);
  const [showPredictions, setShowPredictions] = useState(false);
  const [hitlJobInProgress, setHitlJobInProgress] = useState(false);
  const [showTasks, setShowTasks] = useState(true);
  const [menuPosition, setMenuPosition] = useState<Option<MenuPositionStyle>>(none);
  const [selectedTaskFeature, setSelectedTaskFeature] = useState<Option<Feature>>(none);
  const [viewport, setViewport] = useState<TrueViewport>({ width: "auto", height: "100%" });
  const { data: permissions } = useCampaignAccessControl(campaign.id, [
    [ACRActionType.Annotate],
    [ACRActionType.Validate],
  ]);
  const [layerKey, setLayerKey] = useState(new Date().getTime());
  const [hasValidatedTasks, setHasValidatedTasks] = useState(false);
  const [isUnderHitlCount, setIsUnderHitlCount] = useState(false);
  const hitlEnabled = useUserFeatureFlag("hitl");

  const dispatch = useDispatch();

  useEffect(() => {
    pipe(
      predictionO,
      map((prediction) => {
        setIsUnderHitlCount(prediction.used < prediction.limit);
      })
    );
  }, [predictionO]);

  useEffect(() => {
    foldOption(project.taskStatusSummary, noop, (taskStatusSummary) => {
      setHasValidatedTasks(!!taskStatusSummary.VALIDATED);
    });
  }, [project.taskStatusSummary]);

  useEffect(() => {
    foldOption(jobOpt, noop, (job) => {
      setShowPredictions(job.status === HITLJobStatus.Ran);
      setHitlJobInProgress(job.status !== HITLJobStatus.Ran);
    });
  }, [jobOpt]);

  const mapRef = useRef<InteractiveMap>(null);

  const mapWraperOpt = fromNullable(document.getElementsByClassName("overlays")[0]);

  const highlightedTaskLayerOpt = useMemo(
    () =>
      foldOption(
        selectedTaskFeature,
        () => none,
        (taskFeature) =>
          some({
            id: "highlightedTask",
            type: "line",
            source: "highlightedTask",
            "source-layer": "default",
            paint: {
              "line-color": "#fff736",
              "line-width": 4,
            },
            filter: ["==", "id", taskFeature.properties.id],
          })
      ),
    [selectedTaskFeature]
  );

  const tasksLayer = {
    id: "tasksLayer",
    type: "fill",
    source: "projectTasks",
    "source-layer": "default",
    paint: {
      "fill-color": [
        "match",
        ["get", "status"],
        TaskStatus.Unlabeled,
        getTaskStatusColor(TaskStatus.Unlabeled),
        TaskStatus.Labeled,
        getTaskStatusColor(TaskStatus.Labeled),
        TaskStatus.LabelingInProgress,
        getTaskStatusColor(TaskStatus.LabelingInProgress),
        TaskStatus.ValidationInProgress,
        getTaskStatusColor(TaskStatus.ValidationInProgress),
        TaskStatus.Flagged,
        getTaskStatusColor(TaskStatus.Flagged),
        TaskStatus.Invalid,
        getTaskStatusColor(TaskStatus.Invalid),
        TaskStatus.Validated,
        getTaskStatusColor(TaskStatus.Validated),
        "#000",
      ] as Expression,
      "fill-opacity": 0.5,
    },
    layout: {
      visibility: showTasks ? "visible" : "none",
    },
  } as LayerProps;

  const labelsLayer = {
    id: "labelsLayer",
    type: "fill",
    source: "projectLabels",
    "source-layer": "default",
    layout: {
      visibility: showLabels ? "visible" : "none",
    },
    paint: {
      "fill-color": ["get", "color_hex_code"] as Expression,
      "fill-opacity": 0.8,
    },
    filter: ["all"],
  } as LayerProps;

  const predictionsLayerOpt: Option<LayerProps> = useMemo(
    () =>
      foldOption(
        jobOpt,
        () => none,
        (job) =>
          some({
            id: "predictionsLayer",
            type: "fill",
            source: "predictionLabels",
            "source-layer": "default",
            paint: {
              "fill-color": ["get", "color_hex_code"] as Expression,
              "fill-opacity": 0.5,
            },
            layout: {
              visibility: hitlEnabled && showPredictions ? "visible" : "none",
            },
          } as LayerProps)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobOpt, hitlEnabled, showPredictions]
  );

  const mapStyleOption = useMemo(
    () => createMapStyleOption(project, config.api, idTokenO, jobOpt),
    // We intentionally include an extra dependency to trigger re-renders
    // when we know data has changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project, idTokenO, layerKey, jobOpt]
  );

  const onMapClick = (event: PointerEvent) => {
    const op = () => {
      if (showTasks) {
        setSelectedTaskFeature(
          findFirst((feature: Feature) => feature.layer.id === "tasksLayer")(event.features)
        );
        foldOption(
          seqOption(mapWraperOpt, fromNullable(event.offsetCenter)),
          noop,
          ([mapWraper, center]) => {
            setMenuPosition(
              some(
                calculateMenuPosition(
                  mapWraper.clientWidth,
                  mapWraper.clientHeight,
                  center.x,
                  center.y
                )
              )
            );
          }
        );
        return;
      }
      setSelectedTaskFeature(none);
      setMenuPosition(none);
      return;
    };
    // if there is no HITL job, delegate to the state of `showTasks`
    // if there is a HITL job and the status is `RAN`, delegate to the state of `showTasks`
    foldOption(
      jobOpt,
      () => op(),
      (job) => job.status === HITLJobStatus.Ran && op()
    );
  };

  const onPredictClick = async () => {
    const { data: job } = await postHitlJob({
      campaignId: campaign.id,
      projectId: project.id,
      status: HITLJobStatus.NotRun,
    });
    if (job.id) {
      setHitlJobInProgress(true);
      setDirty(!dirty);
      dispatch(createSetLimitsDirtyAction());
    }
  };

  const onClickCloseMenu = () => {
    setSelectedTaskFeature(none);
    setMenuPosition(none);
    return;
  };

  const getCursor = (mapState: ExtraState) => (mapState.isDragging ? "grabbing" : "pointer");

  const onViewportChange = useCallback((viewport: TrueViewport) => {
    setSelectedTaskFeature(none);
    setMenuPosition(none);
    setViewport(viewport);
  }, []);

  useEffect(() => {
    setViewport(calculateViewport(project));
  }, [project]);

  return (
    <>
      <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between">
        <Stack direction="row" spacing={8} align="center" mb={5}>
          <Text textStyle="campaignOverviewSectionHeading">Map</Text>
          <Stack direction="row" spacing={4} align="center">
            <Stack direction="row" spacing={1} align="center">
              <Checkbox
                size="lg"
                colorScheme="orange"
                borderColor="gray"
                isChecked={showTasks}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setShowTasks(e.target.checked)
                }
              />
              <Text fontSize="14px">Tasks</Text>
            </Stack>
            {isNotClassificationProject && (
              <Stack direction="row" spacing={1} align="center">
                <Checkbox
                  size="lg"
                  colorScheme="orange"
                  borderColor="gray"
                  isChecked={showLabels}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setShowLabels(e.target.checked)
                  }
                />
                <Text fontSize="14px">Labels</Text>
              </Stack>
            )}
            {isNotClassificationProject && hitlEnabled && (
              <Stack direction="row" spacing={1} align="center">
                <Checkbox
                  size="lg"
                  colorScheme="orange"
                  borderColor="gray"
                  isChecked={showPredictions}
                  isDisabled={hitlJobInProgress}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setShowPredictions(e.target.checked)
                  }
                />
                <Text fontSize="14px">
                  {hitlJobInProgress ? "Prediction in progress" : "Predictions"}
                </Text>
              </Stack>
            )}
          </Stack>
        </Stack>
        {hitlEnabled && isNotClassificationProject && (
          <Box>
            <Box display="flex" height="45px" justifyContent="flex-end">
              <FlexFiller />
              <GButton
                colorScheme="gray.50"
                leftIcon={<FaRobot />}
                isDisabled={!isUnderHitlCount || hitlJobInProgress || !hasValidatedTasks}
                onClick={onPredictClick}
              >
                Predict
              </GButton>
            </Box>
            {foldOption(predictionO, noEl, (predction) => (
              <Box>
                <Text>
                  {predction.used} out of {predction.limit} predictions used.
                </Text>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      {foldOption(mapStyleOption, noEl, (mapStyle) => (
        <Box width="100%" height="500px" position="relative">
          <InteractiveMap
            {...viewport}
            mapStyle={mapStyle}
            onClick={onMapClick}
            ref={mapRef}
            getCursor={getCursor}
            onViewportChange={onViewportChange}
            bearing={0}
            pitch={0}
            scrollZoom={true}
            dragPan={true}
          >
            {foldOption(
              predictionsLayerOpt,
              () => (
                <>
                  <Layer {...tasksLayer} />
                  <Layer {...labelsLayer} />
                </>
              ),
              (predictionsLayer) => (
                <>
                  <Layer {...tasksLayer} />
                  <Layer {...labelsLayer} />
                  <Layer {...predictionsLayer} />
                </>
              )
            )}
            {foldOption(highlightedTaskLayerOpt, noEl, (highlightedTaskLayer) => (
              <Layer {...highlightedTaskLayer} />
            ))}
          </InteractiveMap>
          {foldOption(
            seqOption(menuPosition, selectedTaskFeature),
            noEl,
            ([position, taskFeature]) =>
              permissions && (
                <TaskMapMenu
                  canLabel={permissions[0]}
                  canValidate={permissions[1]}
                  position={position}
                  taskFeature={taskFeature}
                  onClickClose={onClickCloseMenu}
                  onUpdate={() => setLayerKey(new Date().getTime())}
                />
              )
          )}
        </Box>
      ))}
      <Box m="0 auto" alignItems="center" justifyContent="stretch">
        {statuses.map((s, idx) => (
          <Box display="inline-flex" mx={2} alignItems="center" key={idx}>
            <Swatch
              color={getTaskStatusColor(s)}
              mr={1}
              size="1.4rem"
              border="1px solid #c4c4c4"
              borderRadius="3px"
            />
            <Text color="#000">{parseStatusName(s)}</Text>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default TaskMap;
