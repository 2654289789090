import { Task, TaskStatus } from "datamodel/task";
import { putTaskStatus, deleteTaskLabels } from "http/task";
import { Dispatch } from "redux";
import { createSetLastError } from "state/ui-segmentation/actions";
import { foldOption, noop } from "lib";
import { getLatestTaskAction } from "../../helpers";
import { Option, none, some } from "fp-ts/es6/Option";

const restoreTask = async (task: Task, dispatch: Dispatch) => {
  return foldOption(getLatestTaskAction(task), noop, async (action) => {
    try {
      let nextStatus: Option<TaskStatus> = none;
      //"unlabeled" or "labeling in progress" -> set back to "unlabeled" and any labels should be removed
      if (
        action.fromStatus === TaskStatus.Unlabeled ||
        action.fromStatus === TaskStatus.LabelingInProgress
      ) {
        await deleteTaskLabels(task);
        nextStatus = some(TaskStatus.Unlabeled);
        //"labeled" or "validation in progress" -> set back to "labeled" and any labels should be kept
      } else if (
        action.fromStatus === TaskStatus.ValidationInProgress ||
        action.fromStatus === TaskStatus.Labeled
      ) {
        nextStatus = some(TaskStatus.Labeled);
        //"validated" -> set back to "validated" and the labels should be kept
      } else if (action.fromStatus === TaskStatus.Validated) {
        nextStatus = some(TaskStatus.Validated);
      } else {
        nextStatus = some(action.fromStatus);
      }

      foldOption(nextStatus, noop, async (newStatus) => {
        await putTaskStatus(task.properties.projectId, task.id, {
          nextStatus: newStatus,
          note: action.note,
        });
      });
    } catch (err: unknown) {
      if (err instanceof Error) {
        dispatch(createSetLastError("Failed to update task when trying to delete task", err));
      }
    }
  });
};

export default restoreTask;
