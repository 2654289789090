import { Task, TaskStatus } from "datamodel/task";
import { putTaskStatus, deleteTaskLabels, unlockTask as unlock } from "http/task";
import { Dispatch } from "redux";
import { createSetLastError } from "state/ui-segmentation/actions";
import { foldOption, noop } from "lib";
import { getLatestTaskAction } from "../../helpers";
import { Option, none, some } from "fp-ts/es6/Option";

const unlockTask = async (task: Task, projectId: string, dispatch: Dispatch) => {
  try {
    return foldOption(getLatestTaskAction(task), noop, async (action) => {
      let nextStatus: Option<TaskStatus> = none;
      if (
        action.fromStatus === TaskStatus.Unlabeled ||
        action.fromStatus === TaskStatus.LabelingInProgress
      ) {
        await deleteTaskLabels(task);
        nextStatus = some(TaskStatus.Unlabeled);
      } else if (
        action.fromStatus === TaskStatus.ValidationInProgress ||
        action.fromStatus === TaskStatus.Labeled
      ) {
        nextStatus = some(TaskStatus.Labeled);
      } else if (action.fromStatus === TaskStatus.Validated) {
        nextStatus = some(TaskStatus.Validated);
      }

      return foldOption(nextStatus, noop, async (newStatus) => {
        await putTaskStatus(task.properties.projectId, task.id, {
          nextStatus: newStatus,
          note: none,
        });
        await unlock(projectId, task.id);
      });
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      dispatch(createSetLastError("Failed to update task when trying to unlock task", err));
    }
  }
};

export default unlockTask;
