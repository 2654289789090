import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { createSetMode } from "state/ui-segmentation/actions";
import { TaskUIMode, ApplicationStore } from "types";
import { foldOption, noop, seqOption } from "lib";
import pushRandomTask from "pages/SegmentationTask/helpers/pushRandomTask";
import useRouter from "use-react-router";
import unflagTask from "../helpers/unflagTask";
import { TaskStatus } from "datamodel/task";
import FlaggedNote from "../components/FlaggedNote";
import { VStack } from "@chakra-ui/react";
import GButton from "components/GButton";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";

const Flagged = () => {
  const dispatch = useDispatch();

  const { location } = useRouter();

  const [campaignOption, projectOption, taskOption, urlActionType] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.campaign,
        state.segmentationUI.project,
        state.segmentationUI.task,
        state.segmentationUI.urlActionType,
      ] as const
  );

  const hitlEnabled = useUserFeatureFlag("hitl");

  const onSkipClick = () => {
    dispatch(createSetMode(TaskUIMode.Loading));
    foldOption(seqOption(projectOption, campaignOption), noop, ([project, campaign]) => {
      pushRandomTask(location.search, urlActionType, hitlEnabled, project, campaign, dispatch, {
        projectId: project.id,
      });
    });
  };

  const onRemoveFlagClick = () => {
    dispatch(createSetMode(TaskUIMode.Loading));
    foldOption(taskOption, noop, (task) => {
      unflagTask(task, TaskStatus.LabelingInProgress, dispatch).then(() => {
        dispatch(createSetMode(TaskUIMode.Labeling));
      });
    });
  };

  return (
    <VStack flex="1" spacing={4} m={4}>
      <FlaggedNote />
      <VStack spacing={4} alignSelf="stretch">
        <GButton colorScheme="primary" width="100%" onClick={onSkipClick}>
          Label a different task
        </GButton>
        <GButton colorScheme="#fff" width="100%" onClick={onRemoveFlagClick}>
          Remove the flag
        </GButton>
      </VStack>
    </VStack>
  );
};

export default Flagged;
