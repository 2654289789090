import { FieldValue } from "../index";

export const COLORS = [
  "#0000FF",
  "#FF0000",
  "#00FF00",
  "#D5FF00",
  "#FF00F6",
  "#FE8900",
  "#A75740",
  "#777479",
  "#006401",
  "#009BFF",
];

const getNextColor = (selectedColors: string[]): string => {
  if (selectedColors.length) {
    const remaining = COLORS.filter((c) => !selectedColors.includes(c));
    return remaining.length ? remaining[0] : COLORS[0];
  }
  return COLORS[0];
};

const getEmptyClass = (selectedColors: string[] = [], name: string = ""): FieldValue => ({
  name,
  colorHexCode: getNextColor(selectedColors),
});

export default getEmptyClass;
