import React from "react";
import { Box, Text } from "@blasterjs/core";

import { Swatch } from "@blasterjs/core";
import { Skeleton } from "@chakra-ui/react";
import { GroupSummaryItem } from "hooks/useLabelClassSummary/helpers/types";
import commaSeparateNumber from "lib/commaSeparateNumber";

const FlatSummary = ({ summary }: { summary: GroupSummaryItem[] }) => (
  <Box>
    {summary.map((item) =>
      item.classes.map((labelClass, idx) => (
        <Box mb={1} display="flex" key={idx} alignItems="center">
          <Swatch color={labelClass.color} mr={1} size="1.4rem" />
          <Text color="textLight" flex="1" fontSize={2}>
            {labelClass.className}
          </Text>
          {!isNaN(parseInt(labelClass.count)) && (
            <Box>
              <Text color="textLight" flex="1" fontSize={2}>
                {commaSeparateNumber(parseInt(labelClass.count))}
              </Text>
            </Box>
          )}
          <Text fontWeight="bold" fontSize={2} ml={2} color="#32323c">
            <Skeleton width="20px" />
          </Text>
        </Box>
      ))
    )}
  </Box>
);

export default FlatSummary;
