import { RandomTaskParams } from "http/task";
import qs from "qs";
import urlForRandomTask from "pages/helpers/urlForRandomTask";
import { TaskURLActionType } from "types";
import { Task } from "datamodel/task";

const getTaskUrl = (
  task: Task,
  queryString: string,
  actionType: TaskURLActionType,
  fallbackParams: RandomTaskParams = {}
) => {
  const urlParams = qs.parse(queryString.substring(1));
  const hasParams = Object.values(urlParams).length > 0;
  const params = hasParams ? urlParams : fallbackParams;
  return urlForRandomTask(task.properties.projectId, task.id, actionType, params);
};

export default getTaskUrl;
