import { BoxProps, ButtonProps, Button, forwardRef, useToken } from "@chakra-ui/react";
import { shade, rgb, rgba, getLuminance, tint } from "polished";
import React, { ReactNode } from "react";

export type GButtonProps = {
  children?: ReactNode;
  colorScheme?: string;
} & Omit<BoxProps & ButtonProps, "color" | "background">;

const customReadableColor = (color: string, colorIfDark: string, colorIfLight: string) =>
  getLuminance(color) <= getLuminance("#999999") ? colorIfDark : colorIfLight;

const GButton = forwardRef<GButtonProps, "button">(
  ({ children, colorScheme = "primary", ...rest }: GButtonProps, ref) => {
    const [colorIfDark, _bg] = useToken("colors", ["gray.50", colorScheme]);

    const bg = _bg || colorScheme;
    const color = customReadableColor(bg, colorIfDark, rgb(50, 50, 60));

    const borderColor = rgba(shade(0.3, bg), 0.7);

    return (
      <Button
        ref={ref}
        position="relative"
        fontSize="14px"
        px="1.5em"
        py="1.4em"
        fontWeight="semibold"
        borderRadius="1000px"
        borderBottom="2px solid"
        borderColor={borderColor}
        color={color}
        _hover={{
          borderColor: rgba(borderColor, 1),
        }}
        _disabled={{
          color: rgba(color, 0.25),
          cursor: "not-allowed",
          backgroundColor: tint(0.15, bg),
          borderColor: rgba(tint(0.15, borderColor), 0.5),
        }}
        _active={{
          backgroundColor: shade(0.15, bg),
          borderColor: shade(0.15, bg),
        }}
        backgroundColor={bg}
        {...rest}
      >
        {children}
      </Button>
    );
  }
);

export default GButton;
