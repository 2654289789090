import React, { useCallback } from "react";
import { ApplicationStore, DrawingActionType, DeckPick } from "types";
import { MenuItem } from "./styled";
import { Icon, Text } from "@blasterjs/core";
import { foldOption, noop } from "lib";
import { useDispatch, useSelector } from "react-redux";
import { DrawingActionSourceFeature } from "common/modules/DrawingAction";
import { createDrawingAction } from "pages/SegmentationTask/helpers";
import { createSetActionBuffer } from "state/ui-segmentation/actions";
import { some } from "fp-ts/es6/Option";

type Props = {
  pick: DeckPick;
  callback?: () => void;
};

const DetectionDeleteMenu = ({ pick: { object: feature, coordinate }, callback = noop }: Props) => {
  const dispatch = useDispatch();

  const [actionBuffer, selectedClassOption] = useSelector(
    (state: ApplicationStore) =>
      [state.segmentationUI.actionBuffer, state.segmentationUI.selectedClass] as const
  );

  const onClick = useCallback(() => {
    const f = feature as DrawingActionSourceFeature;
    foldOption(selectedClassOption, noop, (c) => {
      dispatch(
        createSetActionBuffer([
          ...actionBuffer,
          createDrawingAction(
            c,
            f,
            DrawingActionType.Delete,
            some({
              type: "Feature",
              properties: {},
              geometry: {
                type: "Point",
                coordinates: coordinate as [number, number],
              },
            })
          ),
        ])
      );
    });
    callback();
  }, [actionBuffer, callback, coordinate, dispatch, feature, selectedClassOption]);

  return (
    <MenuItem onClick={onClick} darken>
      <Icon mr={2} size="13px" name="trash" />
      <Text>Delete</Text>
    </MenuItem>
  );
};

export default DetectionDeleteMenu;
