import { Option, none } from "fp-ts/es6/Option";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { Auth0User } from "datamodel/user";

export type NewAuthState = {
  client: Option<Auth0Client>;
  isLoading: boolean;
  isAuthenticated: boolean;
  user: Option<Auth0User>;
  idToken: Option<string>;
};

export enum AuthActionTypes {
  SetClient = "NEW_AUTH_SET_CLIENT",
  SetIsLoading = "NEW_AUTH_SET_IS_LOADING",
  SetIsAuthenticated = "NEW_AUTH_SET_IS_AUTHENTICATED",
  SetUser = "NEW_AUTH_SET_USER",
  SetIdToken = "NEW_AUTH_SET_ID_TOKEN",
}

export interface SetClientAction {
  type: AuthActionTypes.SetClient;
  payload: Option<Auth0Client>;
}

export interface SetIsLoadingAction {
  type: AuthActionTypes.SetIsLoading;
  payload: boolean;
}

export interface SetIsAuthenticatedAction {
  type: AuthActionTypes.SetIsAuthenticated;
  payload: boolean;
}

export interface SetUserAction {
  type: AuthActionTypes.SetUser;
  payload: Option<any>;
}

export interface SetIdTokenAction {
  type: AuthActionTypes.SetIdToken;
  payload: Option<string>;
}

export type AuthActions =
  | SetClientAction
  | SetIsLoadingAction
  | SetIsAuthenticatedAction
  | SetUserAction
  | SetIdTokenAction;

const INITIAL_STATE: NewAuthState = {
  client: none,
  isLoading: true,
  isAuthenticated: false,
  user: none,
  idToken: none,
};

const reducer = (state: NewAuthState = INITIAL_STATE, action: AuthActions): NewAuthState => {
  switch (action.type) {
    case AuthActionTypes.SetClient:
      return {
        ...state,
        client: action.payload,
      };
    case AuthActionTypes.SetIsLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    case AuthActionTypes.SetIsAuthenticated:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case AuthActionTypes.SetUser:
      return {
        ...state,
        user: action.payload,
      };
    case AuthActionTypes.SetIdToken:
      return {
        ...state,
        idToken: action.payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
