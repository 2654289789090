import React, { useEffect, useState } from "react";
import { ProgressBar, Box, Text } from "@blasterjs/core";
import { TaskStatus } from "datamodel/task";
import { foldOption, toPercent, seqOption, noop } from "lib";
import { Option, none, some } from "fp-ts/es6/Option";
import Skeleton from "react-loading-skeleton";
import { Project } from "datamodel/project";
import commaSeparateNumber from "lib/commaSeparateNumber";
import getTotalTaskCountFromSummary from "pages/helpers/getTotalTaskCountFromSummary";

type Props = {
  project: Project;
  label: string;
  statuses: TaskStatus[];
  excludeStatuses: TaskStatus[];
  showSkeleton?: boolean;
};

const ProgressSkeleton: React.FC = () => (
  <Box>
    <Box mb="2px">
      <Skeleton width="175px" />
    </Box>
    <Box display="flex" alignItems="center">
      <ProgressBar mr={2} width="215px" height="4px" borderRadius="1000px" bg="#e5e8e5" />
      <Text color="textLight">
        <Skeleton width="25px" />%
      </Text>
    </Box>
  </Box>
);

const ProjectProgressItem = ({ project, label, statuses, excludeStatuses }: Props) => {
  const [totalOption, setTotalOption] = useState<Option<number>>(none);
  const [countOption, setCountOption] = useState<Option<number>>(none);

  useEffect(() => {
    foldOption(project.taskStatusSummary, noop, (taskStatusSummary) => {
      const count = Object.entries(taskStatusSummary).reduce(
        (acc, [k, v]) => acc + (statuses.includes(k as TaskStatus) ? v : 0),
        0
      );
      const total = getTotalTaskCountFromSummary(taskStatusSummary, excludeStatuses);
      setCountOption(some(count));
      setTotalOption(some(total));
    });
  }, [project, excludeStatuses, statuses]);

  return foldOption(
    seqOption(countOption, totalOption),
    () => <ProgressSkeleton />,
    ([count, total]) => (
      <Box>
        <Box mb="2px">
          <Text fontWeight="600">{commaSeparateNumber(count)}</Text>
          <Text color="textLight">{` out of ${commaSeparateNumber(total)} tasks ${label}`}</Text>
        </Box>
        <Box display="flex" alignItems="center">
          <ProgressBar
            value={count}
            max={total}
            mr={2}
            width="215px"
            height="4px"
            borderRadius="1000px"
            bg="#e5e8e5"
          />{" "}
          <Text color="textLight">{toPercent(count, total, 1)}%</Text>
        </Box>
      </Box>
    )
  );
};

export default ProjectProgressItem;
