import axios, { AxiosResponse } from "axios";
import { UUID } from "io-ts-types";
import * as T from "io-ts";

import { decodeAxiosResponse } from "./transformers/base";
import getBaseRequestConfig from "./helpers/getBaseRequestConfig";
import { LabelGroupSummary, labelGroupSummaryCodec } from "datamodel/labelGroup";
import {
  LabelClass,
  LabelClassCreate,
  labelClassCodec,
  labelClassCreateCodec,
} from "datamodel/labeClass";

export const updateCampaignLabelClass = async (
  id: string,
  labelClassGroupId: string,
  labelClassId: string,
  labelClass: LabelClass
): Promise<AxiosResponse<number>> =>
  await axios.put(
    `/campaigns/${id}/label-groups/${labelClassGroupId}/label-classes/${labelClassId}`,
    labelClassCodec.encode(labelClass),
    getBaseRequestConfig()
  );

export const updateCampaignLabelClasses = async (
  id: string,
  labelClasses: LabelClass[]
): Promise<AxiosResponse<number>[]> =>
  Promise.all(labelClasses.map((lc) => updateCampaignLabelClass(id, lc.labelGroupId, lc.id, lc)));

export const postCampaignLabelClass = async (
  id: string,
  labelClassGroupId: string,
  labelClassCreate: LabelClassCreate
): Promise<AxiosResponse<"">> =>
  await axios.post(
    `/campaigns/${id}/label-groups/${labelClassGroupId}/label-classes`,
    labelClassCreateCodec.encode(labelClassCreate),
    getBaseRequestConfig()
  );

export const postCampaignLabelClasses = async (
  id: string,
  labelClassGroupId: string,
  labelClassCreates: LabelClassCreate[]
): Promise<AxiosResponse<"">[]> =>
  Promise.all(labelClassCreates.map((lcc) => postCampaignLabelClass(id, labelClassGroupId, lcc)));

export const getCampaignLabelClassSummary = async (
  id: UUID
): Promise<AxiosResponse<LabelGroupSummary[]>> =>
  decodeAxiosResponse(T.array(labelGroupSummaryCodec))(
    await axios.get(`/campaigns/${id}/label-class-summary`, {
      ...getBaseRequestConfig(),
    })
  );
