import { LabelClass } from "datamodel/labeClass";
import { ClassSummaryItem } from "./types";

const buildClassSummaryItem = (
  labelClassDef: LabelClass,
  count: string = ""
): ClassSummaryItem => ({
  classId: labelClassDef.id,
  className: labelClassDef.name,
  index: labelClassDef.index,
  color: labelClassDef.colorHexCode,
  count,
});

export default buildClassSummaryItem;
