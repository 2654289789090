import OverlayPage from "components/OverlayPage";
import { push, replace } from "connected-react-router";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import createCampaign from "./helpers/createCampaign";
import ClassCreation from "./Steps/ClassCreation";
import NameAndType from "./Steps/NameAndType";
import useStore from "./store";
import TopBar from "./TopBar";
import { ImagerySelectionTabs } from "./types";
import Wizard from "./Wizard";
import FullScreenLoadingAnimation from "components/FullScreenLoadingAnimation";

interface RouteParams {
  step: string | undefined;
  imageryTab?: string | undefined;
}

interface SafeParams {
  step: number;
  imageryTab: ImagerySelectionTabs;
}

const MIN_STEP = 1;
const MAX_STEP = 2;

const makeParamsSafe = ({ step, imageryTab }: RouteParams): SafeParams => {
  const _safeStep = (step && parseInt(step)) || MIN_STEP;
  const safeStep = _safeStep > MAX_STEP || _safeStep < MIN_STEP ? MIN_STEP : _safeStep;
  const safeImageryTab =
    imageryTab && Object.values(ImagerySelectionTabs).includes(imageryTab as ImagerySelectionTabs)
      ? (imageryTab as ImagerySelectionTabs)
      : ImagerySelectionTabs.Upload;
  return {
    step: safeStep,
    imageryTab: safeImageryTab,
  };
};

const CampaignCreate = () => {
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const [
    lastStepCompleted,
    isLoading,
    purge,
    setImageryTab,
    setIsLoading,
    setStep,
    setLastStepCompleted,
  ] = useStore((state) => [
    state.lastStepCompleted,
    state.isLoading,
    state.purge,
    state.setImageryTab,
    state.setIsLoading,
    state.setStep,
    state.setLastStepCompleted,
  ]);

  const params = useParams<RouteParams>();
  const { step, imageryTab } = makeParamsSafe(params);

  // Purge state on unmount
  useEffect(
    () => () => {
      purge();
    },
    [purge]
  );

  // we always want to silently replace url when the santized step param changes
  // we also want to ensure the user cannot advance beyond the next step
  useEffect(() => {
    // default value is 0
    if (!lastStepCompleted) {
      dispatch(replace(`/campaign-create/1/${imageryTab}`));
    } else {
      const allowedStep = step <= lastStepCompleted + 1 ? step : lastStepCompleted + 1;
      dispatch(replace(`/campaign-create/${allowedStep}/${imageryTab}`));
    }
  }, [step, lastStepCompleted, dispatch, imageryTab]);

  useEffect(() => {
    setImageryTab(imageryTab);
  }, [setImageryTab, imageryTab]);

  useEffect(() => {
    setStep(step);
  }, [setStep, step]);

  const onSubmitForm = useCallback(
    (values: any) =>
      createCampaign(values)
        .then((campaign) => {
          dispatch(push(`/campaign/${campaign.id}`));
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoading(false);
        }),
    [dispatch, setIsLoading]
  );

  const onSubmit = (values: any) => {
    if (step === MAX_STEP) {
      setIsLoading(true);
      return onSubmitForm(values);
    } else {
      setLastStepCompleted(step);
      dispatch(push(`/campaign-create/${step + 1}/${imageryTab}`));
    }
    return Promise.resolve();
  };

  return (
    <>
      <OverlayPage allowOverflow>
        <TopBar maxStep={MAX_STEP} formRef={formRef} />
        <Wizard onSubmit={onSubmit} formRef={formRef}>
          <NameAndType />
          <ClassCreation />
        </Wizard>
      </OverlayPage>
      {isLoading && <FullScreenLoadingAnimation />}
    </>
  );
};

export default CampaignCreate;
