import * as t from "io-ts";

export const apiUserCodec = t.type({
  id: t.string,
  name: t.string,
  email: t.string,
  profileImageUri: t.string,
});

export enum Auth0FeatureFlagKey {
  FlagHitl = "flag_hitl",
  FlagAutoSave = "flag_autosave",
}

export enum Auth0FeatureFlagCountKey {
  FlagHitlCount = "flag_hitl_count",
}

type Auth0FeatureFlag = Partial<Record<Auth0FeatureFlagKey, boolean>>;

type Auth0FeatueFlagHitlCount = Partial<Record<Auth0FeatureFlagCountKey, number | undefined>>;

export type Auth0User = {
  sub: string;
  picture: string;
  name: string;
  email: string;
  groundworkProUser: boolean;
} & Auth0FeatureFlag &
  Auth0FeatueFlagHitlCount;

export type ApiUser = t.TypeOf<typeof apiUserCodec>;
