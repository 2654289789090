import { DrawingActionType } from "types";
import { Label } from "datamodel/label";
import { Option, map, none } from "fp-ts/es6/Option";
import { pipe } from "fp-ts/es6/pipeable";
import { findFirst } from "fp-ts/es6/Array";
import DrawingAction from "common/modules/DrawingAction";
import collapseLabelClassGroups from "pages/Campaigns/CampaignCard/helpers/collapseLabelClassGroups";
import { LabelGroup } from "datamodel/labelGroup";

const transformLabelToDrawingAction =
  (labelClassGroups: LabelGroup[]) =>
  (label: Label): Option<DrawingAction> => {
    const labelClasses = collapseLabelClassGroups(labelClassGroups);
    return pipe(
      labelClasses,
      findFirst((ld) => label.properties.classes.includes(ld.id)),
      map((labelDefinition) => {
        return new DrawingAction(
          label,
          labelDefinition.colorHexCode,
          labelDefinition.id,
          DrawingActionType.DrawAbove,
          none
        );
      })
    );
  };

export default transformLabelToDrawingAction;
