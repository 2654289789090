import React from "react";
import { Option, none, some } from "fp-ts/es6/Option";
import { Text, Box, Heading } from "@blasterjs/core";
import { useToast } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { fromArray } from "fp-ts/es6/NonEmptyArray";

import { LimitDomain, ExistingLimit } from "datamodel/limits";
import { getShares } from "http/campaign";
import { foldOption, noEl } from "lib";
import LimitsProvider from "components/Limits/LimitsProvider";
import { LimitsContextConsumer } from "components/Limits/LimitsContext";
import LimitMessage from "components/Limits/LimitMessage";
import UpgradePrompt from "components/Limits/UpgradePrompt";
import { ACRActionType, UserThinWithActionType } from "datamodel/permissions";
import UserRow from "./UserRow";
import { Campaign } from "datamodel/campaign";
import EmailForm from "./EmailForm";

const combineShares = (shares: UserThinWithActionType[]): Option<Array<UserThinWithActionType>> =>
  foldOption(
    fromArray(shares),
    () => none,
    (sharesNE) =>
      some(
        Object.values(
          sharesNE.reduce(
            (acc, share) => ({
              ...acc,
              [share.id]:
                acc[share.id]?.actionType === ACRActionType.Validate ? acc[share.id] : share,
            }),
            { [sharesNE[0].id]: sharesNE[0] }
          )
        )
      )
  );

type Props = {
  campaign: Campaign;
};

const shouldShowBanner = (limit: ExistingLimit) => limit.used >= limit.limit;

const Sharing = ({ campaign }: Props) => {
  const toast = useToast();

  const { data: shares, ...sharesQueryInfo } = useQuery(
    ["campaign", campaign.id, "shares"],
    () => getShares(campaign).then((resp) => combineShares(resp.data)),
    {
      onError: () => {
        toast({
          title: "An error occurred.",
          description: "Unable to fetch sharing information",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  return (
    <LimitsProvider>
      <Box
        m="0 auto"
        maxWidth="1172px"
        alignItems={"flex-start"}
        width="100%"
        display="flex"
        flexDirection="column"
        flex="1"
        px={2}
        pt={3}
        pb={4}
      >
        <LimitMessage
          domain={LimitDomain.Campaigns}
          action="share"
          objectId={campaign.id}
          render={(limit) =>
            shouldShowBanner(limit) && (
              <Box display="flex" bg="#fff8d9" border="1px solid #e6dfc3" mb={3} px={2} py={2}>
                <Box flex="1">
                  Shared with <Text fontWeight="bold">{limit.used}</Text>/
                  <Text fontWeight="bold">{limit.limit}</Text> users. <UpgradePrompt />
                </Box>
              </Box>
            )
          }
        />
        <LimitsContextConsumer>
          {(limits) => (
            <EmailForm
              limitsOption={limits}
              isShareLoading={sharesQueryInfo.isLoading}
              campaign={campaign}
            />
          )}
        </LimitsContextConsumer>

        {!sharesQueryInfo.isLoading && (
          <>
            <Heading textAlign="left" as="h5" mb={1}>
              {shares &&
                foldOption(
                  shares,
                  () => <>Not currently shared with any users</>,
                  () => <>Currently shared with</>
                )}
            </Heading>
            <Box>
              <LimitMessage
                domain={LimitDomain.Campaigns}
                action="share"
                objectId={campaign.id}
                render={(limit) => (
                  <Box mb={1} display="flex">
                    <Box flex="1">
                      <Text color="gray500">
                        {limit.used}/{limit.limit} user limit. <UpgradePrompt />
                      </Text>
                    </Box>
                  </Box>
                )}
              />
              <Box mt={3}>
                {shares &&
                  foldOption(shares, noEl, (s) =>
                    s.map((sharedUser, idx) => (
                      <UserRow campaign={campaign} userShare={sharedUser} key={idx} />
                    ))
                  )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </LimitsProvider>
  );
};

export default Sharing;
