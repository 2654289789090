import { TaskURLActionType } from "types";
import { RandomTaskParams } from "http/task";
import qs from "qs";

const urlForRandomTask = (
  projectId: string,
  taskId: string,
  actionType: TaskURLActionType,
  params: RandomTaskParams = {}
) => `/projects/${projectId}/tasks/${taskId}/${actionType}?${qs.stringify(params)}`;

export default urlForRandomTask;
