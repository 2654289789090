import { Option, exists, filterMap } from "fp-ts/es6/Option";
import { Limit, LimitDomain } from "datamodel/limits";
import { pipe } from "fp-ts/es6/pipeable";
import { findFirst } from "fp-ts/es6/Array";

const pickLimit = (
  limitsOption: Option<Limit[]>,
  domain: LimitDomain,
  action: string,
  objectId: string
): Option<Limit> =>
  pipe(
    limitsOption,
    filterMap(
      findFirst(
        (l: Limit) =>
          l.domain === domain &&
          (action ? l.action === action : true) &&
          (objectId ? exists((oid: string) => oid === objectId)(l.objectId) : true)
      )
    )
  );

export default pickLimit;
