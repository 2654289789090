import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@blasterjs/core";
import { createSetActionBuffer } from "state/ui-segmentation/actions";
import { ApplicationStore, DrawingActionType } from "types";
import { foldOption, noop, seqOption } from "lib";
import { createDrawingAction } from "pages/SegmentationTask/helpers";
import { DrawingActionSourceFeature } from "common/modules/DrawingAction";

const ClearTaskButton = () => {
  const dispatch = useDispatch();
  const [actionBuffer, activeClassOption, taskOption] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.actionBuffer,
        state.segmentationUI.selectedClass,
        state.segmentationUI.task,
      ] as const
  );

  const onClick = useCallback(
    () =>
      foldOption(seqOption(activeClassOption, taskOption), noop, ([labelClass, task]) => {
        const geojson: DrawingActionSourceFeature = {
          ...task,
          properties: {},
          geometry: { type: "MultiPolygon", coordinates: [task.geometry.coordinates] },
        };
        dispatch(
          createSetActionBuffer([
            ...actionBuffer,
            createDrawingAction(labelClass, geojson, DrawingActionType.Clear),
          ])
        );
      }),
    [actionBuffer, activeClassOption, taskOption, dispatch]
  );

  return (
    <Button appearance="minimal" p={1} mr="0.4rem" onClick={onClick} iconBefore="trash">
      Remove all labels
    </Button>
  );
};

export default ClearTaskButton;
