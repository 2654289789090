import React, { useState } from "react";
import { FieldArrayRenderProps } from "react-final-form-arrays";
import { Field, FieldRenderProps } from "react-final-form";
import { Box, TextInput, Button, Text } from "@blasterjs/core";

import { FieldValue, FieldGroupValue } from "..";
import { getEmptyClass, errorFromValidate } from "../helpers";

interface Props {
  fieldArrProps: FieldArrayRenderProps<FieldValue, HTMLElement>;
  index: number;
}

interface AllValues {
  classification?: FieldGroupValue[];
}

const ClassFieldArray = ({ fieldArrProps, index }: Props) => {
  const [displayMsg, setDisplayMsg] = useState<boolean>(false);

  const onClassNameChange = (
    props: FieldRenderProps<string, HTMLElement>,
    e: React.ChangeEvent<HTMLInputElement>,
    classIndex: number
  ) => {
    props.input.onChange(e);
    const l = fieldArrProps.fields.length || 0;
    if (classIndex === l - 1) {
      fieldArrProps.fields.push(getEmptyClass());
    }
  };

  const onClickRemoveClass = (classIndex: number) => {
    if (classIndex + 1 === fieldArrProps.fields.length) {
      fieldArrProps.fields.update(classIndex, getEmptyClass());
    } else {
      fieldArrProps.fields.remove(classIndex);
    }
  };

  const showRemoveButton = (classIndex: number) => {
    const fieldLength = fieldArrProps.fields && fieldArrProps.fields.length;
    if (fieldLength && fieldLength > 2) {
      return classIndex + 1 !== fieldLength;
    }
  };

  const validate = (value: string, all: AllValues | undefined, idx: number) => {
    let result = !!value;
    if (all?.classification) {
      const validClassNameLength =
        all.classification[index] && all.classification[index].classes.filter((v) => v.name).length;
      if (validClassNameLength >= 2) {
        result = true;
      }
    }

    setDisplayMsg(!result);
    return result;
  };

  return (
    <>
      {fieldArrProps.fields.map((name, idx) => {
        return (
          <Field
            name={`${name}.name`}
            key={idx}
            validate={(value, all) =>
              errorFromValidate(validate(value, all, idx), "An answer is required")
            }
          >
            {(props) => (
              <>
                <Box m={0} display="flex" flexDirection="row">
                  <TextInput
                    mt={1}
                    maxWidth="340px"
                    data-intercom-target="class-name-input"
                    name={props.input.name}
                    value={props.input.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      onClassNameChange(props, e, idx)
                    }
                    onBlur={props.input.onBlur}
                    onFocus={props.input.onFocus}
                    placeholder="e.g., Yes/No/etc."
                    mr={2}
                    invalid={props.meta.touched && props.meta.invalid}
                    autocomplete="false"
                  ></TextInput>
                  {showRemoveButton(idx) && (
                    <Button
                      type="button"
                      borderRadius="0"
                      appearance="minimal"
                      onClick={() => onClickRemoveClass(idx)}
                      p={1}
                      iconBefore="cross"
                    ></Button>
                  )}
                </Box>
              </>
            )}
          </Field>
        );
      })}
      {displayMsg && (
        <Box mt={1}>
          <Text color="danger" fontWeight={600}>
            Requires at least two answers
          </Text>
        </Box>
      )}
    </>
  );
};

export default ClassFieldArray;
