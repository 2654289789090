import { Option, none, fromNullable } from "fp-ts/es6/Option";
import bbox from "@turf/bbox";
import bboxPolygon from "@turf/bbox-polygon";
import center from "@turf/center";

import { foldOption } from "lib";
import { Task } from "datamodel/task";
import WebMercatorViewport from "viewport-mercator-project";
import mask from "lib/geojsonMask";

const generateViewport = (taskOption: Option<Task>) =>
  foldOption(
    taskOption,
    () => ({
      viewportOption: none,
      taskMaskOption: none,
    }),
    (task) => {
      const bound = bbox(task);
      const centroid = center(bboxPolygon(bound));
      const { zoom } = new WebMercatorViewport({
        width: 1000,
        height: 600,
      }).fitBounds(
        [
          [bound[0], bound[1]],
          [bound[2], bound[3]],
        ],
        {
          padding: 25,
          offset: [0, 0],
        }
      );
      const viewport = {
        latitude: centroid.geometry && centroid.geometry.coordinates[1],
        longitude: centroid.geometry && centroid.geometry.coordinates[0],
        zoom,
        pitch: 0,
        bearing: 0,
        maxZoom: 24,
        minZoom: 0,
      };

      return {
        viewportOption: fromNullable(viewport),
        taskMaskOption: fromNullable(mask(task)),
      };
    }
  );

export default generateViewport;
