import React, { useCallback, useEffect } from "react";
import { useDisclosure, VisuallyHidden } from "@chakra-ui/react";

import FileUploader from "./FileUploader";
import useUploader from "./store";
import { isActive } from "./helpers/filters";
import UpgradeModal from "components/UpgradeModal";

const Uploader = () => {
  const sequences = useUploader((state) => state.sequences);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const warnWhenActive = useCallback(
    (e: BeforeUnloadEvent) => {
      if (!!sequences.filter(isActive).length) {
        e.preventDefault();
        e.returnValue = true;
      }
    },
    [sequences]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", warnWhenActive);
    return () => {
      window.removeEventListener("beforeunload", warnWhenActive);
    };
  }, [warnWhenActive]);

  const activeUploads = sequences.filter(isActive);

  return (
    <>
      <VisuallyHidden>
        {activeUploads.map((s) => (
          <FileUploader sequence={s} key={s.id} onModalOpen={onOpen} />
        ))}
      </VisuallyHidden>
      <UpgradeModal onClose={onClose} isOpen={isOpen} />
    </>
  );
};

export default Uploader;
