import React from "react";
import { Field } from "react-final-form";
import { Text } from "@blasterjs/core";

const FormError = ({ name }: { name: string }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? (
        <Text fontWeight="bold" color="danger">
          {error}
        </Text>
      ) : null
    }
  />
);

export default FormError;
