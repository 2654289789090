import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { themeGet as tg } from "@styled-system/theme-get";

export default styled(NavLink)`
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  color: #5c5c5c;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  margin: 0;
  margin-bottom: -1px;
  margin-right: 2px;
  padding: 1.2rem 0.8rem;
  z-index: 1;
  border: 1px solid transparent;
  border-top: 3px solid transparent;
  &.active {
    background: white;
    color: ${tg("colors.orange")};
    font-weight: 600;
    border: 1px solid #dcdcdc;
    border-bottom: 1px solid white;
    border-top: 3px solid ${tg("colors.orange")};
  }
  &:first-child {
    margin-left: 0;
  }
`;
