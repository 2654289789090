import { pipe } from "fp-ts/es6/function";
import { Either, fold } from "fp-ts/es6/Either";
import * as T from "io-ts";
import { QueryKey, useQuery } from "react-query";

function useDecodedParamQuery<ApplicationType = any, ResultType = any>(
  paramDecodingResult: Either<T.Errors, ApplicationType>,
  keys: QueryKey[],
  queryFn: (data: ApplicationType) => Promise<ResultType>,
  onDecodeFailure: (errors: T.Errors) => void
) {
  type R = { errors: T.Errors; value: ApplicationType | undefined };
  const structured = pipe(
    paramDecodingResult,
    fold(
      (errors) => {
        return { value: undefined, errors } as R;
      },
      (decoded) => ({ value: decoded, errors: [] })
    )
  );

  structured.errors.length && onDecodeFailure(structured.errors);

  return useQuery(
    [...keys, structured.value],
    () => structured.value && queryFn(structured.value),
    {
      enabled: !structured.errors.length,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchInterval: 10000,
      keepPreviousData: false,
    }
  );
}

export default useDecodedParamQuery;
