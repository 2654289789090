import { Campaign, getTypeString } from "datamodel/campaign";
import React, { memo } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Box, Stack } from "@chakra-ui/react";
import commaSeparateNumber from "lib/commaSeparateNumber";
import { TaskStatus } from "datamodel/task";
import getTotalTaskCountFromSummary from "pages/helpers/getTotalTaskCountFromSummary";

dayjs.extend(localizedFormat);

type Props = {
  campaign: Campaign;
};

type Detail = {
  label: string;
  value: string;
};

const excludeStatuses = [TaskStatus.Invalid, TaskStatus.Split];

const getDetails = (campaign: Campaign): Detail[] => {
  return [
    {
      label: "Creation date",
      value: dayjs(campaign.createdAt).format("LL"),
    },
    {
      label: "Type",
      value: getTypeString(campaign),
    },
    {
      label: "Number of tasks",
      value: commaSeparateNumber(
        getTotalTaskCountFromSummary(campaign.taskStatusSummary, excludeStatuses)
      ),
    },
  ];
};

const CampaignDetails = ({ campaign }: Props) => (
  <>
    {getDetails(campaign).map((d, idx) => (
      <Stack spacing={2} fontSize="14px" direction="row" key={idx}>
        <Box flex="1">{d.label}</Box>
        <Box fontWeight="600">{d.value}</Box>
      </Stack>
    ))}
  </>
);

export default memo(CampaignDetails);
