import styled, { css } from "styled-components";
import themeGet from "@styled-system/theme-get";

interface Props {
  isActive?: boolean;
  isHidden?: boolean;
  [x: string]: any;
}

export const StyledLabelSelect = styled.div<Props>`
  text-align: left;
  align-self: stretch;
  margin: 0 ${themeGet("space.2")} 0 0;
  padding: ${themeGet("space.1")} ${themeGet("space.2")};
  border: 1px solid ${(props: Props) => (props.isActive ? "#dcdcdc" : "transparent")};
  border-radius: 0;
  border-top-right-radius: ${themeGet("radii.base")};
  border-bottom-right-radius: ${themeGet("radii.base")};
  border-left: none;
  font-size: 1.4rem;
  font-family: ${themeGet("button.base.fonts.font", "fonts.body")};
  font-weight: semibold;
  background: ${(props: Props) => (props.isActive ? "white" : css`none`)};
  opacity: ${(props: Props) => (props.isHidden ? 0.66 : 1)}
  color: ${(props: Props) =>
    props.isActive ? "#262224" : props.isHidden ? themeGet("colors.gray500") : "#666163"};
  display: flex;
  align-items: center;
  cursor: pointer;
`;
