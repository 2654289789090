import React, { ReactNode } from "react";
import { Button } from "@blasterjs/core";
import { useSelector, useDispatch } from "react-redux";
import { filterMap, exists, isSome, map } from "fp-ts/es6/Option";
import useRouter from "use-react-router";

import { foldOption, noop, noEl, seqOption } from "lib";
import { TaskUIMode, TaskURLActionType, ApplicationStore } from "types";
import { createSetMode } from "state/ui-segmentation/actions";
import getTaskQueueNeighbors from "pages/SegmentationTask/helpers/getTaskQueueNeighbors";
import initializeAnnotations from "pages/SegmentationTask/helpers/initializeAnnotations";
import cancelTask from "../helpers/cancelTask";
import { TaskStatus } from "datamodel/task";
import { getLatestTaskTransition, isFlaggingAction, isRejectingAction } from "../../helpers";
import { RouteComponentProps } from "react-router-dom";
import { pipe } from "fp-ts/es6/function";
import BlockableBox from "components/BlockableBox";
import { createTrackingAction, TrackingActionTypes } from "state/tracking/actions";
import pushRandomTask from "pages/SegmentationTask/helpers/pushRandomTask";
import FlaggedNote from "../components/FlaggedNote";
import RejectionNote from "../components/RejectionNote";
import unflagTask from "../helpers/unflagTask";
import deleteTask from "../helpers/deleteTask";
import config from "config";
import confirmClassificationTask from "../helpers/confirmClassificationTask";
import confirmTask from "../helpers/confirmTask";
import { some } from "fp-ts/lib/Option";
import { isReopeningAction } from "pages/SegmentationTask/helpers/getLatestTaskTransition";
import restoreLabels from "pages/SegmentationTask/helpers/restoreLabels";
import isTaskInProgress from "../helpers/isTaskInProgress";
import { VStack, Box } from "@chakra-ui/react";
import useReactRouter from "use-react-router";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";
import { Campaign, CampaignType } from "datamodel/campaign";

type Route = RouteComponentProps<{
  action: TaskURLActionType;
}>;

type Props = {
  children: ReactNode;
};

const Labeling = ({ children }: Props) => {
  const { match, location }: Route = useRouter();
  const dispatch = useDispatch();

  const {
    match: {
      params: { action },
    },
  } = useReactRouter<{ projectId: string; taskId: string; action: TaskURLActionType }>();

  const hitlEnabled = useUserFeatureFlag("hitl");

  const [
    campaignOption,
    projectOption,
    taskOption,
    taskQueue,
    displayBuffer,
    labelObjectCreateOption,
    urlActionType,
    isConfirmDisabled,
    mode,
    existingAnnotationsOption,
    labelClassGroupsOpt,
  ] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.campaign,
        state.segmentationUI.project,
        state.segmentationUI.task,
        state.segmentationUI.taskQueue,
        state.segmentationUI.displayBuffer,
        state.segmentationUI.labelObjectCreate,
        state.segmentationUI.urlActionType,
        state.segmentationUI.isConfirmDisabled,
        state.segmentationUI.mode,
        state.segmentationUI.existingAnnotations,
        state.segmentationUI.labelClassGroups,
      ] as const
  );

  const actionType = match.params.action;

  const nextTaskIdOption = getTaskQueueNeighbors(taskOption, taskQueue)[1];

  const latestTaskTransition = pipe(
    taskOption,
    filterMap((task) => getLatestTaskTransition(task))
  );

  const isValidating = actionType === TaskURLActionType.Validate;

  const showSplitButton =
    map((campaign: Campaign) => campaign.campaignType)(campaignOption) !==
      some(CampaignType.Classification) && !isValidating;

  const isFlagged = pipe(
    latestTaskTransition,
    exists((action) => isFlaggingAction(action))
  );

  const isRejected = pipe(
    latestTaskTransition,
    exists((action) => isRejectingAction(action))
  );

  const isReopened = pipe(
    latestTaskTransition,
    exists((action) => isReopeningAction(action))
  );

  const onClickFlag = () => {
    dispatch(createSetMode(TaskUIMode.Flagging));
  };

  const onClickConfirm = () =>
    foldOption(
      seqOption(taskOption, projectOption, campaignOption),
      noop,
      ([task, project, campaign]) => {
        dispatch(createSetMode(TaskUIMode.Loading));
        dispatch(createTrackingAction(TrackingActionTypes.LabelingTaskConfirmed));
        if (campaign.campaignType === CampaignType.Classification) {
          foldOption(labelObjectCreateOption, noop, (labelObjectCreate) => {
            confirmClassificationTask(task, labelObjectCreate, dispatch).then(() => {
              onAfterConfirm();
            });
          });
        } else if (!isTaskInProgress(task)) {
          onAfterConfirm();
        } else {
          confirmTask(task, displayBuffer, hitlEnabled).then(() => onAfterConfirm());
        }
      }
    );

  const onAfterConfirm = () => {
    foldOption(seqOption(campaignOption, projectOption), noop, ([campaign, project]) => {
      if (isSome(nextTaskIdOption)) {
        match.params.action === TaskURLActionType.Label
          ? dispatch(createSetMode(TaskUIMode.LabelingCompleteByUser))
          : dispatch(createSetMode(TaskUIMode.ValidatingCompleteByUser));
      } else {
        pushRandomTask(location.search, urlActionType, hitlEnabled, project, campaign, dispatch, {
          projectId: project.id,
        });
      }
    });
  };

  const onClickCancel = () => {
    foldOption(
      seqOption(
        projectOption,
        campaignOption,
        taskOption,
        labelClassGroupsOpt,
        existingAnnotationsOption
      ),
      noop,
      ([project, campaign, task, labelClassGroups, labelCollection]) => {
        dispatch(createSetMode(TaskUIMode.Loading));
        restoreLabels(task, labelCollection, dispatch).then(() =>
          cancelTask(task, dispatch).then(() => {
            initializeAnnotations(
              campaign,
              project,
              task,
              labelClassGroups,
              action,
              hitlEnabled,
              dispatch
            );
            actionType === TaskURLActionType.Label
              ? dispatch(createSetMode(TaskUIMode.LabelingCompleteByUser))
              : dispatch(createSetMode(TaskUIMode.ValidatingCompleteByUser));
          })
        );
      }
    );
  };

  const onClickSplitTask = () => {
    dispatch(createSetMode(TaskUIMode.ConfirmSplitTask));
  };

  const onClickDelete = () => {
    foldOption(taskOption, noop, (task) => deleteTask(task, dispatch).then(() => onAfterConfirm()));
  };

  const onClickReject = () => {
    dispatch(createSetMode(TaskUIMode.Rejecting));
  };

  const onClickRemoveFlag = () => {
    foldOption(taskOption, noop, (task) => {
      dispatch(createSetMode(TaskUIMode.Loading));
      unflagTask(task, TaskStatus.Unlabeled, dispatch).then(() => onAfterConfirm());
    });
  };

  return (
    <VStack spacing={4} flex="1">
      <Box m={4}>
        {foldOption(latestTaskTransition, noEl, (action) => {
          if (isFlagged && mode === TaskUIMode.Validating) {
            return <FlaggedNote action={action} />;
          } else if (isRejected) {
            return <RejectionNote action={action} />;
          } else {
            return null;
          }
        })}
      </Box>
      <BlockableBox
        preventInteractions={isFlagged && mode === TaskUIMode.Validating}
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        alignSelf="stretch"
      >
        {children}
      </BlockableBox>
      <VStack spacing={2} alignSelf="stretch" p={4}>
        {isFlagged && mode === TaskUIMode.Validating ? (
          <>
            <Button
              intent="secondary"
              background="white"
              data-intercom-target="remove-flag"
              appearance="prominent"
              width="100%"
              withBorder
              onClick={onClickRemoveFlag}
            >
              <Box>Remove flag</Box>
            </Button>
            <Button
              data-intercom-target="delete-task"
              appearance="prominent"
              intent="danger"
              width="100%"
              withBorder
              mt={1}
              onClick={onClickDelete}
            >
              <Box>Delete task</Box>
            </Button>
          </>
        ) : (
          <>
            <Button
              data-intercom-target="confirm"
              appearance="prominent"
              intent="primary"
              width="100%"
              disabled={isConfirmDisabled}
              withBorder
              onClick={onClickConfirm}
              mb={1}
            >
              <Box>Confirm</Box>
            </Button>
            {isReopened && (
              <Button
                data-intercom-target="cancel"
                appearance="minimal"
                width="100%"
                onClick={onClickCancel}
              >
                <Box>Cancel</Box>
              </Button>
            )}
            {config.featureFlags.taskFlagging && actionType === TaskURLActionType.Label && (
              <Button
                data-intercom-target="flag-task"
                appearance="minimal"
                width="100%"
                onClick={onClickFlag}
              >
                <Box>Flag this task</Box>
              </Button>
            )}
            {showSplitButton && (
              <Button
                data-intercom-target="split"
                appearance="minimal"
                width="100%"
                onClick={onClickSplitTask}
              >
                <Box>Split</Box>
              </Button>
            )}
            {actionType === TaskURLActionType.Validate && (
              <>
                <Button
                  data-intercom-target="reject-task"
                  appearance="minimal"
                  width="100%"
                  onClick={onClickReject}
                >
                  <Box>Reject task</Box>
                </Button>
                <Button
                  data-intercom-target="delete-task"
                  appearance="minimal"
                  width="100%"
                  onClick={onClickDelete}
                >
                  <Box>Delete task</Box>
                </Button>
              </>
            )}
          </>
        )}
      </VStack>
    </VStack>
  );
};

export default Labeling;
