import { LabelClass } from "datamodel/labeClass";
import labelClassCreateBuilder from "./labelClassCreateBuilder";
import { ClassUpdate } from "./updateClasses";

export interface UILabelClass {
  name: string;
  colorHexCode: string;
  dbId: string;
}

export interface UILabelClasses {
  classes: UILabelClass[];
}

const groupUpdateAndPost = (data: UILabelClasses, dbClassDef: LabelClass[]) => {
  let toPostIndex = 0;
  return data.classes.reduce(
    (acc, datum) => {
      const matched = dbClassDef.find((clsDef) => clsDef.id === datum.dbId);
      const newName = datum.name.trim();
      if (matched) {
        toPostIndex++;
        return {
          ...acc,
          toUpdate: [
            ...acc.toUpdate,
            {
              ...matched,
              name: newName || matched.name,
              colorHexCode: datum.colorHexCode,
            } as LabelClass,
          ],
        };
      } else {
        if (!newName.length) return acc;
        const lcc = labelClassCreateBuilder(datum.name, datum.colorHexCode, toPostIndex);
        toPostIndex++;
        return {
          ...acc,
          toPost: [...acc.toPost, lcc],
        };
      }
    },
    { toUpdate: [], toPost: [] } as ClassUpdate
  );
};

export default groupUpdateAndPost;
