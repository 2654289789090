import { pipe } from "fp-ts/es6/pipeable";
import { findFirst, filter } from "fp-ts/es6/Array";
import { Option, map } from "fp-ts/lib/Option";
import { v4 as uuid } from "uuid";
import * as polygonArea from "area-polygon";

import { DrawingActionSourceFeature } from "common/modules/DrawingAction";

type WandPoint = { x: number; y: number };
type MagicWandResult = {
  initialCount?: number;
  inner: boolean;
  label: number;
  points: WandPoint[];
}[];
type Unprojector = ([x, y]: [number, number]) => [number, number];
type HoleSizeThreshold = number;

const wandResultToGeoJSON = (
  result: MagicWandResult,
  transformer: Unprojector,
  threshold: HoleSizeThreshold
): Option<DrawingActionSourceFeature> =>
  pipe(
    result,
    findFirst((r) => !r.inner),
    map((outer) => ({
      id: uuid(),
      type: "Feature",
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            outer,
            ...pipe(
              result,
              filter((r) => r.inner)
            ),
          ]
            .filter((cs) => cs.points.length > 3)
            .filter((cs) => {
              const a = polygonArea(cs.points, true);
              // inner rings all have negative areas. we filter out if the area
              // is below the threshold in square pixels (canvas pixels)
              return a > 0 || a < -threshold;
            })
            .map((cs) =>
              cs.points.map((p) =>
                transformer([p.x / window.devicePixelRatio, p.y / window.devicePixelRatio])
              )
            ),
        ],
      },
      properties: {},
    }))
  );

export default wandResultToGeoJSON;
