import { useEffect, useState } from "react";
import { Option, none, some } from "fp-ts/es6/Option";
import sortBy from "lodash.sortby";
import { useQuery } from "react-query";

import { HitlJob } from "datamodel/hitlJob";
import useUserFeatureFlag from "./useUserFeatureFlag";
import { listHitlJobs } from "http/hitlJob";
import { queryClient } from "App";

const useHitlJob = (projectId: string, campaignId: string) => {
  const [jobOpt, setJobOpt] = useState<Option<HitlJob>>(none);
  const [dirty, setDirty] = useState(true);

  const hitlEnabled = useUserFeatureFlag("hitl");

  const { data: jobs, refetch } = useQuery(
    ["project-list-hitl-jobs", projectId],
    () => {
      if (!hitlEnabled) return undefined;
      return listHitlJobs({ campaignId, projectId });
    },
    {
      refetchInterval: 60000,
      onSuccess: (resp) => {
        queryClient.setQueryData(["hitl-jobs", projectId], resp?.data);
      },
      enabled: hitlEnabled,
    }
  );

  useEffect(() => {
    refetch();
  }, [dirty, refetch]);

  useEffect(() => {
    if (jobs && !!jobs.data.count) {
      const sorted = sortBy(jobs.data.results, "version");
      const lastJob = sorted[sorted.length - 1];
      setJobOpt(some(lastJob));
    }
  }, [jobs, jobs?.data?.count]);

  return { jobOpt, dirty, setDirty };
};

export default useHitlJob;
