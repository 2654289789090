import React from "react";
import { useSelector } from "react-redux";
import { SegmentationUIState } from "types";
import { foldOption } from "lib";
import { VStack } from "@chakra-ui/react";
import { MdError } from "react-icons/md";
import MessageBox from "../components/MessageBox";

const Error = () => {
  const lastError = useSelector(
    (state: { segmentationUI: SegmentationUIState }) => state.segmentationUI.lastError
  );

  return (
    <VStack spacing={8} flex="1" m={4}>
      <MessageBox
        icon={MdError}
        iconColor="red.500"
        title="An error ocurred"
        message={foldOption(
          lastError,
          () => "An unknown error ocurred. Please try again in a few moments.",
          (errorText) => errorText
        )}
      />
    </VStack>
  );
};

export default Error;
