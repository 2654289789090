export interface MenuPositionStyle {
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}

const calculateMenuPosition = (
  containerWidth: number,
  containerHeight: number,
  eventOffsetPointX: number,
  eventOffsetPointY: number
) => {
  let positionBuffer: MenuPositionStyle = {};

  const halfPoint = {
    x: containerWidth / 2,
    y: containerHeight / 2,
  };

  if (eventOffsetPointX > halfPoint.x) {
    positionBuffer.right = `${containerWidth - eventOffsetPointX}px`;
  } else {
    positionBuffer.left = `${eventOffsetPointX}px`;
  }

  if (eventOffsetPointY > halfPoint.y) {
    positionBuffer.bottom = `${containerHeight - eventOffsetPointY}px`;
  } else {
    positionBuffer.top = `${eventOffsetPointY}px`;
  }

  return positionBuffer;
};

export default calculateMenuPosition;
