import React, { useCallback } from "react";
import DropZone from "components/Uploader/DropZone";
import { DropTargetMonitor } from "react-dnd";
import { Campaign } from "datamodel/campaign";
import useUploader from "components/Uploader/store";
import { useToast } from "@chakra-ui/react";

type Props = {
  campaign: Campaign;
  children: React.ReactNode;
};

const ImageDropZone = ({ campaign, children }: Props) => {
  const toast = useToast();
  const uploadSequences = useUploader((state) =>
    state.sequences.filter((u) => u.campaignId === campaign.id)
  );
  const { addByFiles } = useUploader();

  const onFiles = useCallback(
    (filesToUpload: File[]) => {
      toast.closeAll();
      const verifyType = (file: File) => file.type === "image/tiff";
      const verifyUnique = (file: File, files: File[]) =>
        !files.find((f) => f.size === file.size && f.name === file.name);
      // We only accept a single file of type image/tiff
      // We then split incoming files into unique and duplicate lists
      const [selectionUniqueFiles, selectionDuplicateFiles] = filesToUpload
        .filter(verifyType)
        .reduce(
          (acc, cur) =>
            verifyUnique(cur, acc[0]) ? [[...acc[0], cur], acc[1]] : [acc[0], [...acc[1], cur]],
          [[], []] as File[][]
        );
      // We then make sure the unique incoming files are also unique among the existing files
      const [uniqueFiles, duplicateFiles] = selectionUniqueFiles.reduce(
        (acc, cur) =>
          verifyUnique(cur, [...uploadSequences.map((u) => u.file), ...acc[0]])
            ? [[...acc[0], cur], acc[1]]
            : [acc[0], [...acc[1], cur]],
        [[], selectionDuplicateFiles] as File[][]
      );
      if (uniqueFiles.length) {
        addByFiles(campaign.id, uniqueFiles);
      }
      if (duplicateFiles.length) {
        toast({
          title: "Duplicate files detected",
          description: `${duplicateFiles
            .map((f) => f.name)
            .join(", ")}. Only one of each file will be uploaded.`,
        });
      } else if (!uniqueFiles.length) {
        toast({
          title: "No valid files",
          description: "None of those files appears to be a .tif. Please try again.",
        });
      }
    },
    [campaign.id, addByFiles, toast, uploadSequences]
  );

  // Handle files from drag-and-drop
  const onDrop = useCallback(
    (monitor: DropTargetMonitor) => {
      if (monitor) {
        onFiles(monitor.getItem().files);
      }
    },
    [onFiles]
  );

  // Handle files from file-input
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files) {
        onFiles(Array.from(files));
      }
    },
    [onFiles]
  );

  return (
    <DropZone onDrop={onDrop} onChange={onChange}>
      {children}
    </DropZone>
  );
};

export default ImageDropZone;
