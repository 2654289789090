import { DrawingActionType } from "types";
import { Option, none } from "fp-ts/es6/Option";
import DrawingAction, { DrawingActionSourceFeature } from "common/modules/DrawingAction";
import { LabelClass } from "datamodel/labeClass";

const createDrawingAction = (
  labelClass: LabelClass,
  feature: DrawingActionSourceFeature,
  drawingActionType: DrawingActionType,
  targetOption: Option<GeoJSON.Feature<GeoJSON.Point, {}>> = none
): DrawingAction =>
  new DrawingAction(
    feature,
    labelClass.colorHexCode,
    labelClass.id,
    drawingActionType,
    targetOption
  );

export default createDrawingAction;
