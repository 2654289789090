import React from "react";
import sortBy from "lodash.sortby";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Box, Button, Icon } from "@chakra-ui/react";
import { isNone } from "fp-ts/es6/Option";

import { ExportAssetTypes, StacExport } from "datamodel/export";
import { foldOption, noop } from "lib";
import { FaDownload } from "react-icons/fa";

dayjs.extend(localizedFormat);

interface Props {
  stacExport: StacExport;
  index: number;
  isProUser: boolean;
}

interface AssetMap {
  name: string;
  order: number;
}

const TileLayerAsset = "Tile layers";

const StacExportItem = ({ stacExport, index, isProUser }: Props) => {
  const onClickDownload = () => {
    foldOption(stacExport.downloadUrl, noop, (url) => {
      window.open(url);
    });
  };

  const getAssets = () =>
    foldOption(
      stacExport.exportAssetTypes,
      () => TileLayerAsset,
      (assets) => {
        const assetMap = [TileLayerAsset, ...assets].reduce((acc, asset) => {
          if (asset === TileLayerAsset) return [...acc, { name: TileLayerAsset, order: 1 }];
          if (asset.toUpperCase() === ExportAssetTypes.SignedUrl)
            return [...acc, { name: "Download URLs", order: 2 }];
          if (asset.toUpperCase() === ExportAssetTypes.Cog)
            return [...acc, { name: "Images", order: 3 }];
          return acc;
        }, [] as AssetMap[]);
        return sortBy(assetMap, "order")
          .map((a) => a.name)
          .join(", ");
      }
    );

  const isDownloadExpired = () =>
    foldOption(
      stacExport.expiration,
      () => false,
      (expiration) => dayjs().isAfter(dayjs(expiration))
    );

  return (
    <Box
      display="flex"
      alignItems={"stretch"}
      flex={1}
      flexDirection="row"
      background={index % 2 ? "#fff" : "#f7f8f7"}
      borderTop="1px solid #e5e6e5"
      p={4}
    >
      <Box display="flex" flex="1.5" alignItems="center" fontSize="16px">
        {dayjs(stacExport.createdAt).format("LLL")}
      </Box>
      <Box display="flex" flex="1.5" alignItems="center" fontSize="16px">
        {getAssets()}
      </Box>
      {isProUser && (
        <Box display="flex" flex="1.5" alignItems="center" fontSize="16px">
          {foldOption(
            stacExport.expiration,
            () => "N/A",
            (expiration) => dayjs(expiration).format("LLL")
          )}
        </Box>
      )}
      <Box display="flex" flex="0.8" justifyContent="flex-end">
        <Button
          colorScheme="primary"
          variant="ghost"
          fontSize="15px"
          color="primary"
          isLoading={isNone(stacExport.downloadUrl)}
          onClick={onClickDownload}
          alignItems="center"
          isDisabled={isDownloadExpired()}
        >
          <Icon as={FaDownload} mr={2} />
          {isDownloadExpired() ? "Expired" : "Download"}
        </Button>
      </Box>
    </Box>
  );
};

export default StacExportItem;
