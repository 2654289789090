import { TaskStatus } from "datamodel/task";

const getTaskStatusColor = (status: TaskStatus): string => {
  const taskColor = {
    [TaskStatus.Unlabeled]: "#ffffff",
    [TaskStatus.LabelingInProgress]: "#8C46FF",
    [TaskStatus.Labeled]: "#00f9f6",
    [TaskStatus.ValidationInProgress]: "#fe2ab9",
    [TaskStatus.Validated]: "#009e75",
    [TaskStatus.Flagged]: "#e3541c",
    [TaskStatus.Invalid]: "#2c2c2c",
    [TaskStatus.Split]: "#000000",
  };
  return taskColor[status];
};

export default getTaskStatusColor;
