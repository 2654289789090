import styled from "styled-components";
import { Text, Card } from "@blasterjs/core";

export const LinkCard = styled(Card)`
  color: inherit;
  text-decoration: none;
  transition: all 0.125s;
  &:hover {
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
      0 1px 1px rgba(16, 22, 26, 0.2);
  }
`;

export const UnderlineOnHoverText = styled(Text)`
  &:hover {
    text-decoration: underline;
    cursor: inherit;
  }
`;
