import React, { useRef, useState, useMemo } from "react";
import bbox from "@turf/bbox";
import center from "@turf/center";
import { pipe } from "fp-ts/es6/function";
import { Option, none, some, map, fromNullable } from "fp-ts/es6/Option";
import { foldOption, seqOption, noEl } from "lib";
import { findFirst } from "fp-ts/es6/Array";
import ReactMapGL from "react-map-gl";
import WebMercatorViewport from "viewport-mercator-project";
import styled from "@emotion/styled";
import { TileConfig } from "datamodel/tile";

type Props = {
  aoi: GeoJSON.MultiPolygon | GeoJSON.Polygon;
  tileLayers: TileConfig[];
};

const MapImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const PreviewMap = ({ aoi, tileLayers }: Props) => {
  const imageryMapRef = useRef<ReactMapGL>(null);

  const [imageryMapDataUrlOption, setImageryMapDataUrlOption] = useState<Option<string>>(none);

  const viewportOption = useMemo(() => {
    const bound = bbox(aoi);
    const centroid = center(aoi);

    const { zoom }: { zoom: number } = new WebMercatorViewport({
      width: 270,
      height: 190,
    }).fitBounds(
      [
        [bound[0], bound[1]],
        [bound[2], bound[3]],
      ],
      {
        padding: 25,
        offset: [0, 0],
      }
    );

    return fromNullable(
      centroid.geometry
        ? {
            width: "auto",
            height: "100%",
            latitude: centroid.geometry.coordinates[1],
            longitude: centroid.geometry.coordinates[0],
            zoom: zoom + 4,
            pitch: 0,
            bearing: 0,
            maxZoom: 24,
            minZoom: 0,
          }
        : null
    );
  }, [aoi]);

  const mapStyleOption = useMemo(() => {
    const tms = findFirst((tms: TileConfig) => tms.default)(tileLayers);
    return pipe(
      tms,
      map((tms) => ({
        version: 8,
        sources: {
          projectTile: {
            type: "raster",
            tiles: [tms.url],
            tileSize: 256,
            scheme: "xyz",
          },
        },
        layers: [
          {
            id: "projectTile",
            type: "raster",
            source: "projectTile",
            fadeDuration: 0,
          },
        ],
      }))
    );
  }, [tileLayers]);

  const onImageryMapLoad = () => {
    const map = imageryMapRef.current && imageryMapRef.current.getMap();
    if (map) {
      map.once("idle", () => setImageryMapDataUrlOption(some(map.getCanvas().toDataURL())));
    }
  };

  return foldOption(
    imageryMapDataUrlOption,
    () =>
      foldOption(seqOption(viewportOption, mapStyleOption), noEl, ([viewport, mapStyle]) => (
        <ReactMapGL
          ref={imageryMapRef}
          {...viewport}
          mapStyle={mapStyle}
          getCursor={() => "default"}
          onLoad={() => onImageryMapLoad()}
        ></ReactMapGL>
      )),
    (imageryMapDataUrl) => <MapImage src={imageryMapDataUrl} />
  );
};

export default PreviewMap;
