import { DrawingActionType, SemanticLabelFeature } from "types";
import { Option } from "fp-ts/es6/Option";

export type DrawingActionSourceFeature = GeoJSON.Feature<
  GeoJSON.MultiPolygon,
  GeoJSON.GeoJsonProperties
> & {
  id: string;
};

export default class DrawingAction {
  constructor(
    public geoJSON: DrawingActionSourceFeature,
    public color: string,
    public label: string,
    public actionType: DrawingActionType,
    public targetOption: Option<GeoJSON.Feature<GeoJSON.Point, {}>>
  ) {}

  toSemanticLabelFeature(): SemanticLabelFeature {
    return {
      ...this.geoJSON,
      id: this.geoJSON.id,
      properties: {
        label: this.label,
        color: this.color,
      },
    };
  }

  clone(params: {
    geoJSON?: DrawingActionSourceFeature;
    color?: string;
    label?: string;
    actionType?: DrawingActionType;
  }): DrawingAction {
    const safeParams = {
      ...{
        geoJSON: this.geoJSON,
        color: this.color,
        label: this.label,
        actionType: this.actionType,
        targetOption: this.targetOption,
      },
      ...params,
    };
    return new DrawingAction(
      safeParams.geoJSON,
      safeParams.color,
      safeParams.label,
      safeParams.actionType,
      safeParams.targetOption
    );
  }

  get coordinates() {
    return this.geoJSON.geometry.coordinates;
  }
}
