import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isNone } from "fp-ts/es6/Option";
import { Box, Button, Icon, Text, Divider, Tooltip } from "@blasterjs/core";
import useRouter from "use-react-router";
import styled from "styled-components";
import { push } from "connected-react-router";
import qs from "qs";
import { some } from "fp-ts/es6/Option";

import { foldOption, seqOption, noop } from "lib";
import GetOrElseLoader from "components/GetOrElseLoader";
import FlexFiller from "components/FlexFiller";
import { KeybindsToggle } from "components/KeybindsToggle";
import textForUrlAction from "./helpers/textForUrlAction";
import urlForExit from "./helpers/urlForExit";
import { attemptToReleaseTask, createHotkeyModalContent } from "../helpers";
import { createSetMode } from "state/ui-segmentation/actions";
import { createTrackingAction, TrackingActionTypes } from "state/tracking/actions";
import { Project } from "datamodel/project";
import { RandomTaskParams } from "http/task";
import urlForRandomTask from "pages/helpers/urlForRandomTask";
import { ApplicationStore, TaskUIMode } from "types";
import getTaskQueueNeighbors from "../helpers/getTaskQueueNeighbors";
import restoreLabels from "../helpers/restoreLabels";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";
import getNextTaskOption from "../helpers/getNextTaskOption";

const StyledBox = styled(Box)`
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);
  background: white;
  display: flex;
  z-index: 1;
  align-items: center;
  height: 60px;
`;

const normalizeParams = (search: string, projectId: string) => {
  const fallbackParams: RandomTaskParams = { projectId: projectId };
  const urlParams = qs.parse(search.substring(1));
  const hasParams = Object.values(urlParams).length > 0;
  return hasParams ? urlParams : fallbackParams;
};

const TopBar = () => {
  const { location } = useRouter();
  const dispatch = useDispatch();

  const [
    campaignOption,
    projectOption,
    taskOption,
    urlActionType,
    isDrawingActive,
    mode,
    taskQueue,
    userOption,
    existingLabelsOption,
  ] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.campaign,
        state.segmentationUI.project,
        state.segmentationUI.task,
        state.segmentationUI.urlActionType,
        state.segmentationUI.isDrawingActive,
        state.segmentationUI.mode,
        state.segmentationUI.taskQueue,
        state.newAuth.user,
        state.segmentationUI.existingAnnotations,
      ] as const
  );

  const hitlEnabled = useUserFeatureFlag("hitl");

  const [previousTaskOption, nextTaskOption] = useMemo(
    () => getTaskQueueNeighbors(taskOption, taskQueue),
    [taskOption, taskQueue]
  );

  const isLoading = mode === TaskUIMode.Loading;
  const isProjectComplete = mode === TaskUIMode.ProjectComplete;

  const onClickClose = () => {
    foldOption(seqOption(projectOption, userOption, taskOption), noop, ([project, user, task]) => {
      const url = urlForExit(
        normalizeParams(location.search, task.properties.projectId),
        some(project.campaignId.toString())
      );
      dispatch(createSetMode(TaskUIMode.Loading));
      attemptToReleaseTask(task.properties.projectId, task.id, user.sub, dispatch).then(() => {
        dispatch(push(url));
      });
    });
  };

  const onClickPrevious = () =>
    foldOption(seqOption(projectOption, previousTaskOption), noop, ([project, task]) => {
      const url = urlForRandomTask(
        task.properties.projectId,
        task.id,
        urlActionType,
        normalizeParams(location.search, task.properties.projectId)
      );
      dispatch(push(url));
    });

  const onClickNext = () =>
    foldOption(seqOption(projectOption, nextTaskOption), noop, ([project, task]) => {
      const url = urlForRandomTask(
        task.properties.projectId,
        task.id,
        urlActionType,
        normalizeParams(location.search, task.properties.projectId)
      );
      dispatch(push(url));
    });

  const onClickSkip = () => {
    // Skipping requires manually updating the task status _before_ searching
    // for a new task. We prioritize in-progress tasks when selecting a new task
    // to label or validate. As such, if we don't ensure we have reverted the
    // current task's status before selection the query for next task will always find the
    // current task if no other tasks are in-progress.
    foldOption(
      seqOption(projectOption, campaignOption, userOption, taskOption, existingLabelsOption),
      noop,
      async ([project, campaign, user, task, labelCollection]) => {
        dispatch(createSetMode(TaskUIMode.Loading));
        dispatch(createTrackingAction(TrackingActionTypes.LabelingTaskSkipped));

        await restoreLabels(task, labelCollection, dispatch);

        const nextTaskOption = await getNextTaskOption(
          urlActionType,
          hitlEnabled,
          project,
          campaign,
          normalizeParams(location.search, task.properties.projectId)
        );

        await attemptToReleaseTask(task.properties.projectId, task.id, user.sub, dispatch);

        foldOption(
          nextTaskOption,
          () => {
            dispatch(createSetMode(TaskUIMode.ProjectComplete));
          },
          (task) => {
            const nextUrl = urlForRandomTask(
              task.properties.projectId,
              task.id,
              urlActionType,
              normalizeParams(location.search, task.properties.projectId)
            );
            dispatch(push(nextUrl));
          }
        );
      }
    );
  };

  const keyBindingInstructions = useMemo(
    () => createHotkeyModalContent(campaignOption),
    [campaignOption]
  );

  return (
    <StyledBox>
      <Box position="absolute" left="0" right="0" textAlign="center">
        <Text
          fontSize={2}
          maxWidth="200px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          mx={1}
        >
          <GetOrElseLoader
            option={projectOption}
            width="125px"
            render={(project: Project) => <>{project.name}</>}
          />
        </Text>
      </Box>
      <Button appearance="minimal" onClick={onClickClose} data-tooltip borderRadius="0" p={2}>
        <Icon name="cross" size="22px" color="orange" />
        <Tooltip trigger="hover" bg="grayLight1" placement="bottom">
          Back to Overview
        </Tooltip>
      </Button>
      <Divider vertical={true} height="27px" mr={1} />
      <Text fontSize={2} mx={1} fontWeight="bold">
        {!isLoading && textForUrlAction(urlActionType)}
      </Text>
      <FlexFiller />
      <KeybindsToggle bindings={keyBindingInstructions} />
      <Button
        data-intercom-target="back-button"
        disabled={isLoading || isDrawingActive || isNone(previousTaskOption)}
        appearance="minimal"
        px="8px"
        fontWeight="normal"
        onClick={onClickPrevious}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Icon name="caretLeft" mr="0.4rem" />
        </Box>
        <Box>Back</Box>
      </Button>
      <Divider vertical={true} height="24px" mx={1} />
      <Button
        data-intercom-target="next-button"
        disabled={isLoading || isDrawingActive || isNone(nextTaskOption)}
        appearance="minimal"
        px="8px"
        fontWeight="normal"
        onClick={onClickNext}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box>Next</Box>
        <Box>
          <Icon name="caretRight" ml="0.4rem" />
        </Box>
      </Button>
      <Divider vertical={true} height="24px" mx={1} />
      <Button
        data-intercom-target="skip-button"
        disabled={isLoading || isDrawingActive || isProjectComplete}
        appearance="minimal"
        px="8px"
        fontWeight="normal"
        title="Start a different task"
        onClick={onClickSkip}
        iconAfter="skip"
      >
        Skip
      </Button>
    </StyledBox>
  );
};

export default TopBar;
