import React from "react";
import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  useClipboard,
  Text,
} from "@chakra-ui/react";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Task } from "datamodel/task";
import urlForRandomTask from "pages/helpers/urlForRandomTask";
import { TaskURLActionType } from "types";
import config from "config";

type Props = {
  task: Task;
};
const IssueCardMenu = ({ task }: Props) => {
  const labelingUrl = urlForRandomTask(
    task.properties.projectId,
    task.id,
    TaskURLActionType.Label,
    {
      projectId: task.properties.projectId,
    }
  );

  const validationUrl = urlForRandomTask(
    task.properties.projectId,
    task.id,
    TaskURLActionType.Validate,
    {
      projectId: task.properties.projectId,
    }
  );

  const { hasCopied: hasCopiedLabelUrl, onCopy: onCopyLabelUrl } = useClipboard(
    `${config.baseUrl}${labelingUrl}`
  );
  const { hasCopied: hasCopiedValidationUrl, onCopy: onCopyValidationUrl } = useClipboard(
    `${config.baseUrl}${validationUrl}`
  );

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        variant="ghost"
        icon={<Icon as={IoEllipsisHorizontalSharp} />}
        fontSize="14px"
        color="textLight"
      />
      <Portal>
        <MenuList border="1px" borderColor="gray.300" color="textLight">
          <MenuItem as={Link} to={labelingUrl} tabIndex={0} textDecoration="none">
            Label
          </MenuItem>
          <MenuItem as={Link} to={validationUrl} tabIndex={0}>
            Validate
          </MenuItem>
          <MenuDivider />
          {/* There seems to be an issue with the menu cursor, hence the Text workaround */}
          <MenuItem onClick={onCopyLabelUrl} cursor="pointer">
            <Text as="div" opacity={hasCopiedLabelUrl ? 0 : 1}>
              Copy Labeling URL
            </Text>
            <Text as="div" position="absolute" opacity={hasCopiedLabelUrl ? 1 : 0}>
              Copied!
            </Text>
          </MenuItem>
          <MenuItem onClick={onCopyValidationUrl} cursor="pointer">
            <Text as="div" opacity={hasCopiedValidationUrl ? 0 : 1}>
              Copy Validation URL
            </Text>
            <Text as="div" position="absolute" opacity={hasCopiedValidationUrl ? 1 : 0}>
              Copied!
            </Text>
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};

export default IssueCardMenu;
