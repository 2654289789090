import isEqual from "lodash.isequal";

import LabelingGeoJsonLayer from "./LabelingGeoJsonLayer";
import { DrawingActionSourceFeature } from "common/modules/DrawingAction";
import { DrawPolygonHandler } from ".";

interface LayerData {
  type: string;
  features: DrawingActionSourceFeature[];
}

interface OnEditParams {
  updatedData: LayerData;
}

type onEditFunctionType = ({ updatedData }: OnEditParams) => void;

interface EditHandle {
  positionIndexes: number[];
}

const drawHandlerMode = "rf-drawPolygon";

const createLabelingGeoJsonLayer = (onEdit: onEditFunctionType, drawHandler: DrawPolygonHandler) =>
  new LabelingGeoJsonLayer({
    id: `shape-layer`,
    data: {
      type: "FeatureCollection",
      features: [],
    },
    mode: drawHandlerMode,
    selectedFeatureIndexes: [],
    onEdit,
    modeHandlers: { [drawHandlerMode]: drawHandler },
    modeConfig: {
      enableSnapping: true,
    },
    lineWidthUnits: "pixels",
    pickingDepth: 1000,
    getTentativeLineWidth: () => 1,
    getTentativeLineColor: () => [10, 255, 253, 255],
    getEditHandlePointRadius: () => 10,
    getEditHandlePointColor: (handle: EditHandle): number[] => {
      const isFirstPoint =
        (handle.positionIndexes.length === 1 && handle.positionIndexes[0] === 0) ||
        isEqual(handle.positionIndexes, [0, 0]);
      if (isFirstPoint) {
        return [0x0, 0x74, 0xcb, 0xff];
      }
      return [0, 0, 0, 0];
    },
  });

export default createLabelingGeoJsonLayer;
