import { Box, Button, Icon, Input, InputProps } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

type Props = Omit<InputProps, "onChange" | "value" | "placeholder"> & {
  value: string | undefined;
  placeholder?: string;
  onChange: (value: string) => void;
  onInputCleared: () => void;
  autoFocus?: boolean;
};
const SearchInput = ({
  value,
  placeholder,
  onChange,
  onInputCleared,
  autoFocus = true,
  isDisabled = false,
  ...rest
}: Props) => {
  const [searchValue, setSearchValue] = useState<string>(value || "");

  return (
    <Box data-intercom-target="project-search" position="relative" display="inline-flex">
      <Input
        width="225px"
        borderRadius="100px"
        py="12px"
        px="36px"
        placeholder={placeholder}
        value={searchValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchValue(e.currentTarget.value);
          onChange(e.currentTarget.value);
        }}
        borderColor="gray.100"
        border="1px solid"
        lineHeight="16px"
        autoFocus={autoFocus}
        height="auto"
        isDisabled={isDisabled}
        {...rest}
      />
      <Icon as={FaSearch} color="gray.200" position="absolute" left="0" m="14px" />
      {!!searchValue.length && (
        <Button
          variant="ghost"
          position="absolute"
          right="0"
          p="4px"
          borderRadius="100px"
          m="10px"
          onClick={() => {
            setSearchValue("");
            onInputCleared();
          }}
          zIndex="1"
          isDisabled={isDisabled}
        >
          <Icon as={IoMdClose} color="gray.200" />
        </Button>
      )}
    </Box>
  );
};
export default SearchInput;
