import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import bbox from "@turf/bbox";
import center from "@turf/center";
import { polygonToLine } from "@turf/polygon-to-line";
import ReactMapGL, { WebMercatorViewport } from "react-map-gl";
import { findFirst } from "fp-ts/es6/Array";
import { pipe } from "fp-ts/es6/function";
import { map } from "fp-ts/es6/Option";

import { Project } from "datamodel/project";
import { Task } from "datamodel/task";
import { ApplicationStore } from "types";
import seqOption from "lib/seqOption";
import config from "config";
import foldOption from "lib/foldOption";
import { Icon, IconButton, VStack } from "@chakra-ui/react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Tile } from "datamodel/tile";
import mask from "lib/geojsonMask";

type Props = {
  project: Project;
  task: Task;
};

const IssueCardMap = ({ project, task }: Props) => {
  const [idTokenOption] = useSelector(
    (state: ApplicationStore) => [state.newAuth.idToken] as const
  );
  const [zoomOffset, setZoomOffset] = useState(0);

  const viewport = useMemo(() => {
    const bound = bbox(task);
    const centroid = center(task);

    const { zoom }: { zoom: number } = new WebMercatorViewport({
      width: 374,
      height: 308,
    }).fitBounds(
      [
        [bound[0], bound[1]],
        [bound[2], bound[3]],
      ],
      {
        padding: 25,
        offset: [0, 0],
      }
    );

    return {
      width: "auto",
      height: "100%",
      latitude: centroid?.geometry?.coordinates[1],
      longitude: centroid?.geometry?.coordinates[0],
      zoom: zoom + zoomOffset,
      pitch: 0,
      bearing: 0,
      maxZoom: 24,
      minZoom: 0,
    };
  }, [task, zoomOffset]);

  const mapStyleOption = useMemo(() => {
    const tms = findFirst((tms: Tile) => tms.default)(project.tileLayers);
    return pipe(
      seqOption(tms, idTokenOption),
      map(([tms, token]) => ({
        version: 8,
        sources: {
          projectTile: {
            type: "raster",
            tiles: [tms.zxyUrl],
            tileSize: 256,
            scheme: "xyz",
          },
          taskMask: {
            type: "geojson",
            data: mask(task),
          },
          taskOutline: {
            type: "geojson",
            data: polygonToLine(task),
          },
          projectLabels: {
            type: "vector",
            tiles: [`${config.api}/mvt/projects/${project.id}/labels/{z}/{x}/{y}?token=${token}`],
          },
        },
        layers: [
          {
            id: "projectTile",
            type: "raster",
            source: "projectTile",
            fadeDuration: 0,
          },
          {
            id: "taskMaskLayer",
            type: "fill",
            source: "taskMask",
            paint: {
              "fill-color": "rgb(0,0,0)",
              "fill-opacity": 0.25,
            },
          },
          {
            id: "taskOutlineLayer",
            type: "line",
            source: "taskOutline",
            paint: {
              "line-color": "#ffff99",
              "line-opacity": 0.5,
              "line-width": 2,
              "line-offset": -1,
            },
          },
          {
            id: "projectLabelsLayer",
            type: "fill",
            source: "projectLabels",
            "source-layer": "default",
            paint: {
              "fill-color": ["get", "color_hex_code"],
              "fill-opacity": 0.5,
            },
          },
        ],
      }))
    );
  }, [project, task, idTokenOption]);

  return foldOption(
    mapStyleOption,
    () => <></>,
    (mapStyle) => (
      <>
        <ReactMapGL {...viewport} mapStyle={mapStyle} getCursor={() => "default"} />
        <VStack
          position="absolute"
          bottom="0"
          right="0"
          m={4}
          __css={{
            "> *:first-of-type:not(:last-of-type)": { borderBottomRadius: 0 },
            "> *:not(:first-of-type):not(:last-of-type)": { borderRadius: 0 },
            "> *:not(:first-of-type):last-of-type": { borderTopRadius: 0 },
          }}
        >
          <IconButton
            aria-label="zoom in"
            icon={<Icon as={FaPlus} />}
            size="lg"
            onClick={() => setZoomOffset((prevOffset) => prevOffset + 0.5)}
          />
          <IconButton
            aria-label="zoom out"
            icon={<Icon as={FaMinus} />}
            size="lg"
            onClick={() => setZoomOffset((prevOffset) => prevOffset - 0.5)}
          />
        </VStack>
      </>
    )
  );
};

export default IssueCardMap;
