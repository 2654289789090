import { fromNullable } from "fp-ts/es6/Option";
import { foldOption } from "lib";

import { LayerConfig } from "types";

const isLayerEnabled = (layers: LayerConfig[], id: string) =>
  foldOption(
    fromNullable(layers.find((layer) => layer.id === id)),
    () => false,
    (layer) => layer.enabled
  );

export default isLayerEnabled;
