import React, { useState, FunctionComponent } from "react";
import { LayerPicker, ToggleLabel } from "../LayerToggle/LayerToggleContents";
import { Checkbox, Radio, RangeInput, Text, Box } from "@blasterjs/core";
import styled from "styled-components";

import ControlButton from "../ControlButton";
import { LayerConfig } from "types";

interface SemanticPickerToggleProps {
  onClick: (event: React.MouseEvent) => void;
}

interface ExclusiveLayerGroupProps {
  layerGroup: LayerGroupConfig;
}

interface AdjustableLayerProps {
  layer: LayerConfig;
  onChange: (id: string, enabled: boolean, opacity: number) => void;
}

interface LayerGroupConfig {
  layers: LayerConfig[];
  onChange: (id: string, enabled: boolean, opacity: number) => void;
}

interface LayerPickerMenu {
  readonly imageryLayers: LayerGroupConfig;
  readonly vectorLayers: LayerGroupConfig;
  readonly basemaps: LayerGroupConfig;
  readonly overlayLayers: LayerGroupConfig;
}

const StyledContainer = styled.div`
  margin-top: 5px;
`;

const MapboxControlContainer: FunctionComponent = ({ children, ...props }) => (
  <StyledContainer
    data-intercom-target="layer-picker"
    {...props}
    className="mapboxgl-ctrl mapboxgl-ctrl-group"
  >
    {children}
  </StyledContainer>
);

const SemanticPickerToggle = ({ onClick }: SemanticPickerToggleProps) => {
  return (
    <ControlButton
      onClick={(e: React.MouseEvent) => onClick(e)}
      title="Show/hide base-layer controls"
      iconBefore="project"
    />
  );
};

const ExclusiveLayerGroup = ({ layerGroup }: ExclusiveLayerGroupProps) => (
  <div>
    {layerGroup.layers.map((layer) => (
      <ToggleLabel key={layer.id}>
        <Radio
          mr={1}
          uncheckedColor={"gray500"}
          checked={layer.enabled}
          value={layer.id}
          onChange={() => layerGroup.onChange(layer.id, !layer.enabled, layer.opacity)}
        />
        {layer.label}
      </ToggleLabel>
    ))}
  </div>
);

const AdjustableLayer = ({ layer, onChange }: AdjustableLayerProps) => (
  <Box mb={1}>
    <ToggleLabel key={layer.id}>
      <Checkbox
        uncheckedColor={"gray500"}
        mr={1}
        checked={layer.enabled}
        onChange={() => {
          onChange(layer.id, !layer.enabled, layer.opacity);
        }}
      />
      {layer.label}
    </ToggleLabel>
    <RangeInput
      min={0}
      max={1}
      step={0.1}
      value={layer.opacity}
      onChange={(e: React.FormEvent<HTMLInputElement>) => {
        onChange(layer.id, layer.enabled, parseFloat(e.currentTarget.value));
      }}
      disabled={!layer.enabled}
    />
  </Box>
);

const SemanticLayerToggle = ({
  imageryLayers,
  vectorLayers,
  basemaps,
  overlayLayers,
}: LayerPickerMenu) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <MapboxControlContainer>
      <SemanticPickerToggle onClick={handleClick} />
      {isOpen ? (
        <LayerPicker className="test1">
          {vectorLayers.layers.map((layer) => (
            <AdjustableLayer layer={layer} onChange={vectorLayers.onChange} key={layer.id} />
          ))}
          {overlayLayers.layers.map((layer) => (
            <AdjustableLayer layer={layer} onChange={overlayLayers.onChange} key={layer.id} />
          ))}
          {imageryLayers.layers.map((layer) => (
            <AdjustableLayer layer={layer} onChange={imageryLayers.onChange} key={layer.id} />
          ))}
          <Text mt={1} fontWeight={"bold"}>
            Basemap
          </Text>
          <ExclusiveLayerGroup layerGroup={basemaps} />
        </LayerPicker>
      ) : null}
    </MapboxControlContainer>
  );
};

export default SemanticLayerToggle;
