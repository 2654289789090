import React from "react";
import { useSelector } from "react-redux";
import { TaskURLActionType, ApplicationStore } from "types";
import useRouter from "use-react-router";
import { foldOption, noop } from "lib";
import { Stack, VStack } from "@chakra-ui/react";
import GButton from "components/GButton";
import { FaThumbsUp } from "react-icons/fa";
import MessageBox from "../components/MessageBox";

const ProjectComplete = () => {
  const { history } = useRouter();

  const [projectOption, urlActionType] = useSelector(
    (state: ApplicationStore) =>
      [state.segmentationUI.project, state.segmentationUI.urlActionType] as const
  );

  const message =
    urlActionType === TaskURLActionType.Label
      ? "There are currently no more tasks to be labeled in this campaign"
      : "There are currently no more tasks to be validated in this campaign";

  const onDoneClick = () =>
    foldOption(projectOption, noop, (project) => {
      history.push(`/campaign/${project.campaignId.toString()}/overview`);
    });

  return (
    <VStack spacing={8} flex="1" m={4}>
      <MessageBox
        icon={FaThumbsUp}
        iconColor="blue.500"
        title="No tasks available"
        message={message}
      />
      <Stack spacing={4} alignSelf="stretch">
        <GButton colorScheme="primary" width="100%" onClick={onDoneClick}>
          Back to the overview
        </GButton>
      </Stack>
    </VStack>
  );
};

export default ProjectComplete;
