import { Option } from "fp-ts/es6/Option";
import { RandomTaskParams } from "http/task";
import { foldOption } from "lib";

const urlForExit = (params: RandomTaskParams, campaignIdOpt: Option<string>) => {
  return foldOption(
    campaignIdOpt,
    () => {
      if (params.projectId) {
        return `/projects/${params.projectId}`;
      }
      return `/campaign-list`;
    },
    (campaignId) => `/campaign/${campaignId}/overview`
  );
};

export default urlForExit;
