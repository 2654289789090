import uniq from "lodash.uniq";
import sortBy from "lodash.sortby";
import { LabelGroup } from "datamodel/labelGroup";

const getSortField = (len: number, idx: number[]): string =>
  len === uniq(idx).length ? "index" : "name";

// if all indices are unique, then sort by index, else sort by name
const sortClassGroupAndClass = (groups: LabelGroup[]) =>
  sortBy(
    groups.map((group) => ({
      ...group,
      labelClasses: sortBy(
        group.classes,
        getSortField(
          group.classes.length,
          group.classes.map((cls) => cls.index)
        )
      ),
    })),
    getSortField(
      groups.length,
      groups.map((group) => group.index)
    )
  );

export default sortClassGroupAndClass;
