import React from "react";
import { Box, Heading } from "@blasterjs/core";
import styled from "styled-components";

import { Skeleton } from "@chakra-ui/skeleton";

const HoverText = styled(Box)`
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  :hover {
    opacity: 1;
  }
`;

const SkeletonCard = () => (
  <Box display="flex" alignItems="stretch">
    <Skeleton width="100%" height="100%" />
    <HoverText
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Heading as="h4" textAlign="center" color="white">
        No images yet
      </Heading>
    </HoverText>
  </Box>
);

export default SkeletonCard;
