import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { some, none } from "fp-ts/es6/Option";

import { ApplicationStore, LabelObjectCreate } from "types";
import { foldOption, noEl, noop, seqOption } from "lib";
import {
  createSetLabelObjectCreate,
  createSetIsConfirmDisabled,
} from "state/ui-segmentation/actions";
import Category from "./ClassGroup";
import collapseLabelClassGroups from "pages/Campaigns/CampaignCard/helpers/collapseLabelClassGroups";

const Sidebar = () => {
  const [taskOption, existingAnnotationsOption, labelObjectCreateOption, labelClassGroupsOpt] =
    useSelector(
      (state: ApplicationStore) =>
        [
          state.segmentationUI.task,
          state.segmentationUI.existingAnnotations,
          state.segmentationUI.labelObjectCreate,
          state.segmentationUI.labelClassGroups,
        ] as const
    );

  const [currentIdx, setCurrentIdx] = useState<number>(0);
  const [showHotkeyBlock, setShowHotkeyBlock] = useState<boolean>(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createSetLabelObjectCreate(none));
  }, [taskOption, dispatch]);

  useEffect(
    () =>
      foldOption(
        seqOption(labelClassGroupsOpt, existingAnnotationsOption),
        () => {
          dispatch(createSetLabelObjectCreate(none));
        },
        ([labelClassGroups, existingAnnotations]) => {
          const labelClasses = collapseLabelClassGroups(labelClassGroups);
          const existingSelections = existingAnnotations.features.length
            ? existingAnnotations.features[0].properties.classes
            : [];
          const selectedLabelClasses = labelClasses.filter((l) =>
            existingSelections.includes(l.id)
          );

          dispatch(
            createSetLabelObjectCreate(
              some(
                labelClassGroups.reduce((acc, group) => {
                  const existingSelection = selectedLabelClasses.find(
                    (l) => l.labelGroupId === group.id
                  );
                  return {
                    ...acc,
                    [group.id]: (existingSelection && existingSelection.id) || "",
                  };
                }, {} as LabelObjectCreate)
              )
            )
          );
        }
      ),
    [labelClassGroupsOpt, taskOption, existingAnnotationsOption, dispatch]
  );

  // Update state of the confirm button
  useEffect(() => {
    foldOption(
      labelObjectCreateOption,
      () => {
        dispatch(createSetIsConfirmDisabled(true));
      },
      (labelObjectCreate) => {
        // all fields of the labelObjectCreate are fulfilled when all values are
        // non-empty strings
        const isAllFieldsFulfilled = Object.values(labelObjectCreate).reduce(
          (acc, lbc) => acc && lbc !== "",
          true
        );
        dispatch(createSetIsConfirmDisabled(!isAllFieldsFulfilled));
      }
    );
    // clean up local state when data updates or when component unmounts
    return () => {
      dispatch(createSetIsConfirmDisabled(true));
    };
  }, [dispatch, labelObjectCreateOption]);

  const onChange = (groupId: string, classId: string, currentIdx: number) => {
    foldOption(labelObjectCreateOption, noop, (labelObjectCreate) => {
      dispatch(
        createSetLabelObjectCreate(
          some({
            ...labelObjectCreate,
            [groupId]: classId,
          })
        )
      );
    });
    setCurrentIdx(currentIdx + 1);
  };

  const onRadioClick = () => {
    setShowHotkeyBlock(false);
  };

  return (
    <>
      {foldOption(labelClassGroupsOpt, noEl, (labelClassGroups) =>
        labelClassGroups.map((labelClassGroup, idx) => (
          <Category
            key={idx}
            group={labelClassGroup}
            idx={idx}
            currentIdx={currentIdx}
            onChange={onChange}
            setCurrentIdx={setCurrentIdx}
            labelObjectCreateOpt={labelObjectCreateOption}
            onRadioClick={onRadioClick}
            showHotkeyBlock={showHotkeyBlock}
          />
        ))
      )}
    </>
  );
};

export default Sidebar;
