import React from "react";
import { Callout } from "@blasterjs/core";

import { CampaignType } from "datamodel/campaign";
import GroupSummary from "./GroupSummary";
import FlatSummary from "./FlatSummary";
import LoadingIcon from "components/LoadingIcon";
import { GroupSummaryItem } from "../../../../hooks/useLabelClassSummary/helpers/types";

interface Props {
  type: CampaignType;
  summary: GroupSummaryItem[];
  loading: boolean;
}

const ClassSummary = ({ type, summary, loading }: Props) => {
  return loading ? (
    <LoadingIcon />
  ) : summary.length ? (
    type === CampaignType.Classification ? (
      <GroupSummary summary={summary} />
    ) : (
      <FlatSummary data-intercom-target="project-labels-legend" summary={summary} />
    )
  ) : (
    <Callout p={1}>
      There seems to be a problem with your campaign and we can't find any class definition
    </Callout>
  );
};

export default ClassSummary;
