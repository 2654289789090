import {
  SetPaginatedResponseAction,
  CampaignListActionTypes,
  PurgeAction,
  SetDirtyAction,
} from "./reducer";
import { PaginatedResponse } from "types";
import { Campaign } from "datamodel/campaign";

export const createSetPaginatedResponse = (
  payload: PaginatedResponse<Campaign>
): SetPaginatedResponseAction => ({
  type: CampaignListActionTypes.SetPaginatedResponse,
  payload,
});

export const createPurge = (): PurgeAction => ({
  type: CampaignListActionTypes.Purge,
});

export const createSetDirty = (): SetDirtyAction => ({
  type: CampaignListActionTypes.SetDirty,
  payload: true,
});
