import bbox from "@turf/bbox";
import bboxPolygon from "@turf/bbox-polygon";
import center from "@turf/center";
import { Feature, featureCollection, Point } from "@turf/helpers";
import { basemaps, getDefaultBasemap } from "common/basemaps";
import { Project } from "datamodel/project";
import { isSome, some } from "fp-ts/es6/Option";
import { AOI, getViewportFromPolygon } from "./getViewportFromAoi";
import isProjectReady from "./isProjectReady";

const getMiniMapViews = (allProjects: Project[]) => {
  const aois = allProjects.reduce((acc, project) => {
    if (isProjectReady(project) && isSome(project.aoi)) {
      return [...acc, project.aoi.value];
    }
    return acc;
  }, [] as AOI[]);
  const aoiFc = featureCollection(
    aois.map((aoi) => ({
      type: "Feature",
      geometry: {
        type: aoi.type,
        coordinates: aoi.coordinates,
      },
      properties: {},
    }))
  );
  const centroids = aois.reduce((acc: Feature<Point>[], aoi: AOI) => {
    const centroid = center(aoi).geometry?.coordinates;
    if (centroid) {
      return [
        ...acc,
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: centroid,
          },
        } as Feature<Point>,
      ];
    }
    return acc;
  }, [] as Feature<Point>[]);
  const aoiCentroidsFc = featureCollection(centroids);
  const defaultBasemap = getDefaultBasemap();
  const basemapDef = Object.values(basemaps.layers).find((def) => def.id === "light_no_labels");
  const url = (basemapDef && basemapDef.url) || defaultBasemap.url;
  const tiles = ["1", "2", "3"].map((d) => url.replace("{s}", d));
  const viewportOpt = getViewportFromPolygon(bboxPolygon(bbox(aoiFc)));
  const styleOpt = some({
    version: 8,
    sources: {
      baseMapSource: {
        type: "raster",
        tiles,
        tileSize: 256,
        scheme: "xyz",
      },
      projectCentroid: {
        type: "geojson",
        data: aoiCentroidsFc,
      },
    },
    layers: [
      {
        id: "baseMap",
        type: "raster",
        source: "baseMapSource",
      },
      {
        id: "projectCentroid",
        type: "circle",
        source: "projectCentroid",
        paint: {
          "circle-radius": 12,
        },
      },
    ],
  });
  return { viewportOpt, styleOpt };
};
export default getMiniMapViews;
