import React from "react";
import { Box, Spinner, Fade } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { ApplicationStore } from "types";

const AutosaveIndicator = () => {
  const [isAutosavingInProgress] = useSelector((state: ApplicationStore) => [
    state.segmentationUI.isAutosavingInProgress,
  ]);

  return (
    <Box display="inline-flex" alignItems="center">
      <Fade in={isAutosavingInProgress}>
        <Spinner color="primary" />
      </Fade>
    </Box>
  );
};

export default AutosaveIndicator;
