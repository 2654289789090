import { Dispatch } from "redux";
import { TaskURLActionType, Error, TaskUIMode } from "types";
import { Option, fromNullable } from "fp-ts/es6/Option";
import { noop, foldOption } from "lib";
import {
  createSetLastError,
  createSetTask,
  createSetUrlActionType,
  createSetMode,
  createSetHasTaskLock,
} from "state/ui-segmentation/actions";
import { getTask } from "http/task";
import { Task, TaskStatus } from "datamodel/task";
import { AxiosResponse } from "axios";
import initializeAnnotations from "./initializeAnnotations";
import { Campaign } from "datamodel/campaign";
import { Project } from "datamodel/project";
import { LabelGroup } from "datamodel/labelGroup";

const handleUnlockedTask = (task: Task, action: TaskURLActionType, dispatch: Dispatch) => {
  dispatch(createSetHasTaskLock(true));
  if (action === TaskURLActionType.Label) {
    switch (task.properties.status) {
      case TaskStatus.Unlabeled: {
        dispatch(createSetMode(TaskUIMode.Labeling));
        return;
      }

      case TaskStatus.LabelingInProgress: {
        dispatch(createSetMode(TaskUIMode.Labeling));
        return;
      }

      case TaskStatus.Labeled: {
        dispatch(createSetMode(TaskUIMode.LabelingCompleteByUser));
        return;
      }

      case TaskStatus.ValidationInProgress:
      case TaskStatus.Validated: {
        // Note: this isn't a good fit UX wise
        dispatch(createSetMode(TaskUIMode.LabelingNotPermitted));
        return;
      }

      case TaskStatus.Flagged: {
        dispatch(createSetMode(TaskUIMode.Flagged));
        return;
      }
    }
  } else if (action === TaskURLActionType.Validate) {
    // - labeled or validation in progress -> Validating
    // - validated and current user is last validator -> ValidatedByUser
    // - anything else -> ValidatingNotPermitted
    if (
      [TaskStatus.Labeled, TaskStatus.ValidationInProgress, TaskStatus.Flagged].includes(
        task.properties.status
      )
    ) {
      dispatch(createSetMode(TaskUIMode.Validating));
      return;
    } else if (task.properties.status === TaskStatus.Validated) {
      dispatch(createSetMode(TaskUIMode.ValidatingCompleteByUser));
      return;
    } else {
      dispatch(createSetMode(TaskUIMode.ValidatingNotPermitted));
    }
  }
};

/**
 * Initialize the segmentation-ui state by fetching the relevant project and task, and
 * setting the current UI mode based on project and task statuses
 */
const initializeStateFromUrlParams = (
  campaign: Campaign,
  project: Project,
  taskId: string,
  action: TaskURLActionType,
  userId: string,
  labelClassGroups: LabelGroup[],
  hitlEnabled: boolean,
  dispatch: Dispatch
) => {
  getTask(project.id, taskId)
    .then((resp: AxiosResponse<Task>) => {
      const taskOption = fromNullable(resp.data);
      dispatch(createSetTask(taskOption));
      return taskOption;
    })
    .then((taskOption: Option<Task>) =>
      foldOption(taskOption, noop, async (task) => {
        await initializeAnnotations(
          campaign,
          project,
          task,
          labelClassGroups,
          action,
          hitlEnabled,
          dispatch
        );
        foldOption(
          task.properties.lockedOn,
          // If none, not locked
          () => handleUnlockedTask(task, action, dispatch),

          // If locked, check who it is locked by
          // If we want to implement a max lock age this would be
          // where we would add the logic for that. Instead we don't
          // actually use the lockedOn other than to determine if
          // the task if probably locked
          (_) =>
            foldOption(
              task.properties.lockedBy,
              // If lockedBy is none, we ignore the lock and treat
              // it as unlocked
              () => handleUnlockedTask(task, action, dispatch),

              // If lockedBy is present, we check to see if the
              // current user locked it, in which case we use the
              // task status to determine the correct UI state.
              // Otherwise, we set the UI state to locked mode
              (lockedBy) => {
                if (lockedBy !== userId) {
                  dispatch(createSetMode(TaskUIMode.Locked));
                } else {
                  handleUnlockedTask(task, action, dispatch);
                }
              }
            )
        );
      })
    )
    .catch((err: Error) => {
      dispatch(createSetLastError("Failed to fetch task", err));
      dispatch(createSetMode(TaskUIMode.Error));
    });

  dispatch(createSetUrlActionType(action));
};

export default initializeStateFromUrlParams;
