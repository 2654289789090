import { chain } from "fp-ts/Either";
import { Either, left, right } from "fp-ts/es6/Either";
import { pipe } from "fp-ts/lib/function";
import { fromBlob, GeoTIFFImage } from "geotiff";

type BandCount = number;

async function getFirstPageFromTiffFile(file: File): Promise<Either<Error, GeoTIFFImage>> {
  try {
    const tiff = await fromBlob(file);
    const image = await tiff.getImage(); // returns first page by default
    return right(image);
  } catch {
    return left(new Error("Unable to decode file as .tif"));
  }
}

function hasProjection(image: GeoTIFFImage): Either<Error, GeoTIFFImage> {
  try {
    // This will throw if image has no affine transformation
    image.getOrigin();
    return right(image);
  } catch {
    return left(new Error("The provided .tif has no geographic projection information."));
  }
}

const getNumberOfBands =
  (minBandCount: number) =>
  (image: GeoTIFFImage): Either<Error, number> => {
    const bandCount = image.getSamplesPerPixel();
    return bandCount === 1 || bandCount >= minBandCount
      ? right(bandCount)
      : left(
          new Error(
            `The provided .tif has an unsupported number of bands (must be 1 or at least ${minBandCount}`
          )
        );
  };

const validateTif = async (
  file: File,
  minBandCount: number = 3
): Promise<Either<Error, BandCount>> => {
  if (file.size > 5e9) {
    // If the file is larger than 5GB we reject it
    return left(new Error("File is too large. All files must be smaller than 5GB"));
  }

  return pipe(
    await getFirstPageFromTiffFile(file),
    chain(hasProjection),
    chain(getNumberOfBands(minBandCount))
  );
};

export default validateTif;
