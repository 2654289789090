import React, { ReactNode, useContext, useMemo } from "react";
import { pipe } from "fp-ts/es6/pipeable";
import { exists, isSome } from "fp-ts/es6/Option";
import * as H from "history";

import { ACRActionType } from "datamodel/permissions";
import AccessControlContext from "./AccessControlContext";
import verifyActions from "./helpers/verifyActions";
import { Redirect } from "react-router";

/**
 * `requiredActions` example:
 * [[ACRActionType.Validate], [ACRActionType.Annotate]] => if a user has
 * validate and/or annotate permissions and/or all (*) permissions, the content will be shown
 */

interface Props {
  requiredActions: ACRActionType[][];
  children: ReactNode;
  otherwise: H.LocationDescriptor;
}

const AccessLimiterRedirect = ({ requiredActions, children, otherwise }: Props) => {
  const actionsOption = useContext(AccessControlContext);

  const result = useMemo(
    () =>
      pipe(
        actionsOption,
        exists((actions) => verifyActions(actions, requiredActions))
      ),
    [actionsOption, requiredActions]
  );

  // We use isSome here to ensure we don't redirect before the actions have been
  // fetched and checked
  return (
    <>{isSome(actionsOption) && (result ? children : <Redirect to={otherwise} push={false} />)}</>
  );
};

export default AccessLimiterRedirect;
