import React, { useEffect, useState } from "react";
import { ProgressBar, Box, Text } from "@blasterjs/core";
import { TaskStatus } from "datamodel/task";
import { foldOption, toPercent, seqOption } from "lib";
import { Option, none, some } from "fp-ts/es6/Option";
import Skeleton from "react-loading-skeleton";
import AccessControlProvider from "components/AccessControl/AccessControlProvider";
import AccessLimiter from "components/AccessControl/AccessLimiter";
import { ACRActionType } from "datamodel/permissions";
import { Campaign } from "datamodel/campaign";

const labeledStatuses = [TaskStatus.Labeled, TaskStatus.ValidationInProgress, TaskStatus.Validated];
const validatedStatuses = [TaskStatus.Validated];
const excludeStatuses = [TaskStatus.Flagged, TaskStatus.Invalid];

const ProgressIndicators = ({ campaign }: { campaign: Campaign }) => {
  const [labeledCountOption, setLabeledCountOption] = useState<Option<number>>(none);
  const [validatedCountOption, setValidatedCountOption] = useState<Option<number>>(none);
  const [totalCountOption, setTotalCountOption] = useState<Option<number>>(none);

  useEffect(() => {
    const labeledCount = Object.entries(campaign.taskStatusSummary).reduce(
      (acc, [k, v]) => acc + (labeledStatuses.includes(k as TaskStatus) ? v : 0),
      0
    );
    const validatedCount = Object.entries(campaign.taskStatusSummary).reduce(
      (acc, [k, v]) => acc + (validatedStatuses.includes(k as TaskStatus) ? v : 0),
      0
    );
    const labeledTotal = Object.entries(campaign.taskStatusSummary).reduce(
      (acc, [k, v]) => acc + (!excludeStatuses.includes(k as TaskStatus) ? v : 0),
      0
    );
    setLabeledCountOption(some(labeledCount));
    setValidatedCountOption(some(validatedCount));
    setTotalCountOption(some(labeledTotal));
  }, [campaign]);

  return foldOption(
    seqOption(labeledCountOption, validatedCountOption, totalCountOption),
    () => (
      <Box>
        <Box mb={1}>
          <Skeleton width="175px" />
        </Box>
        <Box display="flex" alignItems="center">
          <ProgressBar flex="1" mr={2} />{" "}
          <Text color="textLight">
            <Skeleton width="25px" />%
          </Text>
        </Box>
      </Box>
    ),
    ([labeledCount, validatedCount, total]) => (
      <Box display="flex" mt="6px">
        <Box display="flex" alignItems="center">
          <ProgressBar
            value={labeledCount}
            max={total}
            mr={1}
            width="75px"
            height="4px"
            borderRadius="1000px"
            bg="#e5e8e5"
            title={`${toPercent(labeledCount, total, 1)}% labeled`}
          />
          <Text fontSize="1" width="30px" color="textLight">
            {toPercent(labeledCount, total, 0)}%
          </Text>
        </Box>
        <AccessControlProvider campaignId={campaign.id}>
          <AccessLimiter requiredActions={[[ACRActionType.Validate]]}>
            <Box>
              <Box display="flex" alignItems="center">
                <ProgressBar
                  value={validatedCount}
                  max={total}
                  mr={1}
                  ml={1}
                  width="75px"
                  height="4px"
                  borderRadius="1000px"
                  color="#4b3b40"
                  bg="#e5e8e5"
                  title={`${toPercent(validatedCount, total, 1)}% validated`}
                />
                <Text fontSize="1" width="30px" color="textLight">
                  {toPercent(validatedCount, total, 0)}%
                </Text>
              </Box>
            </Box>
          </AccessLimiter>
        </AccessControlProvider>
      </Box>
    )
  );
};

export default ProgressIndicators;
