import { Task } from "datamodel/task";
import { foldOption } from "lib";
import { Option, some, none } from "fp-ts/es6/Option";
import { findIndex } from "fp-ts/es6/Array";

const getTaskQueueNeighbors = (taskOption: Option<Task>, taskQueue: Task[]) =>
  foldOption(
    taskOption,
    () => [none, none],
    (task) =>
      foldOption(
        findIndex((t: Task) => t.id === task.id)(taskQueue),
        () => [none, none],
        (index) => {
          // The task queue is ordered such that newer tasks have lower indices
          const prev = index < taskQueue.length - 1 ? some(taskQueue[index + 1]) : none;
          const next = index > 0 ? some(taskQueue[index - 1]) : none;
          return [prev, next];
        }
      )
  );

export default getTaskQueueNeighbors;
