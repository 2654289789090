import React, { useLayoutEffect, useRef } from "react";
import { Box } from "@blasterjs/core";
import lottie from "lottie-web";
import data from "./lottieData.json";

const BalloonAnimationShort = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      const animation = lottie.loadAnimation({
        animationData: data,
        container: containerRef.current,
        loop: true,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: "xMinYMin slice",
          progressiveLoad: true,
        },
      });
      animation.setSubframe(false);
      return () => {
        animation.destroy();
      };
    }
  });

  return (
    <Box width="120px" height="120px">
      <div ref={containerRef}></div>
    </Box>
  );
};

export default BalloonAnimationShort;
