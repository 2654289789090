import { Campaign } from "datamodel/campaign";
import sortClassGroupAndClass from "helpers/sortClassGroupAndClass";
import buildClassSummaryItem from "./buildClassSummaryItem";
import buildGroupSummaryItem from "./buildGroupSummaryItem";
import { GroupSummaryItem } from "./types";
import { LabelGroupSummary } from "datamodel/labelGroup";

const buildSummaryFromCounts = (
  campaign: Campaign,
  groupSummary: LabelGroupSummary[]
): GroupSummaryItem[] =>
  sortClassGroupAndClass(campaign.labelClassGroups)
    .reduce((acc, groupDef) => {
      const matchedGroup = groupSummary.find((gs) => gs.groupId === groupDef.id);
      return matchedGroup
        ? [
            ...acc,
            buildGroupSummaryItem(
              groupDef,
              groupDef.labelClasses
                .map((classdef) =>
                  buildClassSummaryItem(
                    classdef,
                    matchedGroup.classSummaries
                      .find((classSummary) => classSummary.classId === classdef.id)
                      ?.count.toString() || "0"
                  )
                )
                .sort((a, b) => a.index - b.index)
            ),
          ]
        : acc;
    }, [] as GroupSummaryItem[])
    .sort((a, b) => a.index - b.index);

export default buildSummaryFromCounts;
