import axios, { AxiosResponse } from "axios";
import * as t from "io-ts";

import { decodeAxiosResponse } from "./transformers/base";
import getBaseRequestConfig from "./helpers/getBaseRequestConfig";
import {
  LabelGroup,
  LabelGroupCreate,
  labelGroupCodec,
  labelGroupCreateCodec,
} from "datamodel/labelGroup";

export const postCampaignLabelGroup = async (
  id: string,
  labelGroupCreate: LabelGroupCreate
): Promise<AxiosResponse<LabelGroup>> =>
  decodeAxiosResponse(labelGroupCodec)(
    await axios.post(
      `/campaigns/${id}/label-groups/`,
      labelGroupCreateCodec.encode(labelGroupCreate),
      getBaseRequestConfig()
    )
  );

export const postCampaignLabelGroups = async (
  id: string,
  labelGroupCreates: LabelGroupCreate[]
): Promise<AxiosResponse<LabelGroup>[]> =>
  Promise.all(labelGroupCreates.map((lcg) => postCampaignLabelGroup(id, lcg)));

export const listCampaignLabelGroups = async (id: string): Promise<AxiosResponse<LabelGroup[]>> =>
  decodeAxiosResponse(t.array(labelGroupCodec))(
    await axios.get(`campaigns/${id}/label-groups`, getBaseRequestConfig())
  );
