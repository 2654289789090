import { CampaignType } from "datamodel/campaign";
import { LabelGroupCreate } from "datamodel/labelGroup";
import { some } from "fp-ts/es6/Option";

import { FieldGroupValue } from "pages/CampaignCreate/Steps/ClassCreation";

const buildLabelClassGroupCreate = (values: any, projectType: CampaignType): LabelGroupCreate[] => {
  let key = "classification";
  if (projectType !== CampaignType.Classification) {
    key = "nonclassification";
  }
  return values[key].map(
    (classGroup: FieldGroupValue, groupIdx: number) =>
      ({
        name: classGroup.name || "default",
        index: groupIdx,
        classes: classGroup.classes
          .filter((c: { name: string; colorHexCode: string }) => c.name)
          .map((c: { name: string; colorHexCode: string }, idx: number) => ({
            ...c,
            default: some(false),
            determinant: some(false),
            index: idx,
            isActive: true,
          })),
        isActive: true,
      } as LabelGroupCreate)
  ) as LabelGroupCreate[];
};

export default buildLabelClassGroupCreate;
