import { AxiosRequestConfig } from "axios";
import config from "config";
import store from "store";
import { ApplicationStore } from "types";
import { foldOption } from "lib";

const getBaseRequestConfig = (): AxiosRequestConfig => {
  // @ts-ignore TS (after upgrade) now has more complaints about our taskQueue code
  const state: ApplicationStore = store.getState();

  return foldOption(
    state.newAuth.idToken,
    () => ({}),
    (token) => ({
      baseURL: config.api,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
};

export default getBaseRequestConfig;
