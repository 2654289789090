import { TypeC, TypeOf, Type } from "io-ts";
import { PathReporter } from "io-ts/lib/PathReporter";
import { foldEither } from "lib";
import { AxiosResponse } from "axios";

export function decoderFor<A = any, O = A, I = unknown>(c: Type<A, O, I>) {
  return (data: any): TypeOf<typeof c> => {
    const result = c.decode(data);
    return foldEither(
      result,
      () => {
        const e = PathReporter.report(result)
          .join("\n\n\n")
          .concat(` when decoding ${JSON.stringify(data)}`);
        throw new Error(e);
      },
      (decoded) => decoded
    );
  };
}

export const decodeT =
  <T extends {}>(c: TypeC<any>) =>
  (data: any): T => {
    const result = c.decode(data);
    return foldEither(
      result,
      () => {
        const e = PathReporter.report(result).join("\n\n\n");
        throw new Error(e);
      },
      (decoded) => decoded
    );
  };

export const decodeAxiosResponseT =
  <T extends {}>(c: TypeC<any>) =>
  (resp: AxiosResponse<any>): AxiosResponse<T> => ({
    ...resp,
    data: decodeT<T>(c)(resp.data),
  });

export function decodeAxiosResponse<A = any, O = A, I = unknown>(c: Type<A, O, I>) {
  return (resp: AxiosResponse<any>): AxiosResponse<TypeOf<typeof c>> => ({
    ...resp,
    data: decoderFor(c)(resp.data),
  });
}
