import React from "react";
import styled from "styled-components";
import { Box, Button, Icon, Text, Divider } from "@blasterjs/core";
import { themeGet as tg } from "@styled-system/theme-get";
import FlexFiller from "components/FlexFiller";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import useStore from "../store";

const Dot = styled.span<{ isActive: boolean }>`
  height: 8px;
  width: 8px;
  background-color: ${(props) => (props.isActive ? tg("colors.orange") : "#d8d8d8")};
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;

const DotBox = styled(Box)`
  pointer-events: none;
`;

interface Props {
  maxStep: number;
  formRef: React.RefObject<HTMLFormElement>;
}

const TopBar = ({ maxStep, formRef }: Props) => {
  const dispatch = useDispatch();
  const [canAdvance, step, imageryTab, isLoading] = useStore((state) => [
    state.canAdvance,
    state.step,
    state.imageryTab,
    state.isLoading,
  ]);

  const onClickClose = () => {
    dispatch(push(`/campaign-list`));
  };

  const onClickPrevious = () => {
    dispatch(push(`/campaign-create/${step - 1}/${imageryTab}`));
  };

  const onClickNext = () => {
    return (
      formRef.current && formRef.current.dispatchEvent(new Event("submit", { cancelable: true }))
    );
  };

  return (
    <Box
      height="60px"
      display="flex"
      alignSelf="stretch"
      alignItems="center"
      bg="white"
      py={1}
      borderBottom="1px solid rgb(236, 236, 236)"
    >
      <Box position="absolute" left="0" right="0" textAlign="center">
        <Text
          fontSize={2}
          maxWidth="200px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          mx={1}
        ></Text>
      </Box>
      <Button appearance="minimal" onClick={onClickClose} borderRadius="0" p={2} data-tooltip>
        <Icon name="cross" size="22px" color="orange" />
      </Button>
      <Divider vertical={true} height="27px" mr={1} color="#dbdad9" />
      <Text fontSize={2} mx={1} fontWeight="bold">
        New campaign
      </Text>
      <FlexFiller />
      <DotBox position="absolute" left="0" right="0" textAlign="center">
        {Array.from({ length: maxStep }, (x, i) => i + 1).map((i) => (
          <Dot isActive={i === step} key={i}></Dot>
        ))}
      </DotBox>
      <FlexFiller />
      {step !== 1 && (
        <Button
          data-intercom-target="back"
          ml={1}
          appearance="prominent"
          intent="secondary"
          onClick={onClickPrevious}
          fontWeight="600"
          disabled={isLoading}
          withBorder
        >
          Back
        </Button>
      )}
      <Button
        data-intercom-target="continue"
        ml={1}
        mr={2}
        disabled={isLoading || !!canAdvance}
        fontWeight="600"
        appearance="prominent"
        intent="primary"
        onClick={onClickNext}
        withBorder
      >
        {step === maxStep ? "Finish" : "Continue"}
      </Button>
    </Box>
  );
};
export default TopBar;
