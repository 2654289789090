import { ACRActionType } from "datamodel/permissions";

const verifyActions = (userActions: ACRActionType[], requiredActions: ACRActionType[][]) =>
  requiredActions.map(
    (actionSet) =>
      userActions.includes(ACRActionType.All) ||
      actionSet.length === actionSet.filter((a) => userActions.includes(a)).length
  );

export default verifyActions;
