import React, { useEffect, useCallback, useState } from "react";
import useReactRouter from "use-react-router";
import { useDispatch, useSelector } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { map } from "fp-ts/lib/Option";
import { pipe } from "fp-ts/es6/pipeable";
import { Box } from "@blasterjs/core";

import config from "config";
import OverlayPage from "components/OverlayPage";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import MapPane from "./MapPane";
import { LayerPickerSection, ApplicationStore, TaskURLActionType, TaskUIMode } from "types";
import {
  attemptToReleaseTask,
  applyTaskLock,
  initializeStateFromUrlParams,
  addInProgressStatus,
} from "./helpers";
import { foldOption, noEl, noop, seqOption } from "lib";
import {
  createUndo,
  createRedo,
  createSetLayerPickerConfig,
  createPurge,
  createPurgeTask,
  createSetDrawingInProgress,
  createSetTaskQueue,
  createSetIsAutosavingInProgress,
  createSetLabelClassGroups,
} from "state/ui-segmentation/actions";
import initializeProjectFromUrlParams from "./helpers/initializeProjectFromUrlParams";
import { replace } from "connected-react-router";
import styled from "styled-components";
import { cons, findFirst } from "fp-ts/es6/Array";
import { Task } from "datamodel/task";
import { some, exists, isSome } from "fp-ts/es6/Option";
import AccessControlProvider from "components/AccessControl/AccessControlProvider";
import AccessLimiterRedirect from "components/AccessControl/AccessLimiterRedirect";
import { ACRActionType } from "datamodel/permissions";
import replaceLabels from "./helpers/replaceLabels";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";
import useImageSwitchHotkey from "hooks/useImageSwitchHotkey";
import sortClassGroupAndClass from "helpers/sortClassGroupAndClass";
import { listCampaignLabelGroups } from "http/labelGroup";

const TASK_QUEUE_TTL_MS = 2.592e8;

const INTERVAL = config.taskLockReclaimInterval;

const StyledBox = styled(Box)`
  display: flex;
  flex: 1;
  overflow-y: auto;
`;

const SegmentationTask = () => {
  const dispatch = useDispatch();
  const {
    match: {
      params: { projectId, taskId, action },
    },
    location,
  } = useReactRouter<{ projectId: string; taskId: string; action: TaskURLActionType }>();

  useHotkeys("escape", (event) => {
    event.preventDefault();
    dispatch(createSetDrawingInProgress(false));
  });

  useHotkeys("ctrl+z, command+z", (event) => {
    event.preventDefault();
    dispatch(createUndo());
  });

  useHotkeys("ctrl+shift+z, command+shift+z", (event) => {
    event.preventDefault();
    dispatch(createRedo());
  });

  useImageSwitchHotkey();

  const [
    userOption,
    campaignOption,
    projectOption,
    taskOption,
    hasTaskLock,
    mode,
    taskQueue,
    taskQueueActionOption,
    taskQueueParamsString,
    taskQueueUpdatedAt,
    drawingInProgress,
    displayBuffer,
    lastActionAtOption,
    labelClassGroupsOpt,
  ] = useSelector(
    (state: ApplicationStore) =>
      [
        state.newAuth.user,
        state.segmentationUI.campaign,
        state.segmentationUI.project,
        state.segmentationUI.task,
        state.segmentationUI.hasTaskLock,
        state.segmentationUI.mode,
        state.segmentationUI.taskQueue,
        state.segmentationUI.taskQueueAction,
        state.segmentationUI.taskQueueParamsString,
        state.segmentationUI.taskQueueUpdatedAt,
        state.segmentationUI.drawingInProgress,
        state.segmentationUI.displayBuffer,
        state.segmentationUI.lastActionAtOption,
        state.segmentationUI.labelClassGroups,
      ] as const
  );

  const naiveAutoSavingEnabled = useUserFeatureFlag("naiveAutoSaving");
  const hitlEnabled = useUserFeatureFlag("hitl");
  const [autosaveLatch, setAutosaveLatch] = useState(false);

  useEffect(() => {
    // When moving from task-to-task, we want to make sure the lock is removed
    // and that the status is reverted if necessary (in the case of skipping
    // or in the case of exiting while working on a reopened task). We can
    // compare the URL value (`taskId`) to the task in the state
    // (`taskOption`) to determine if the effect is being triggered due to a
    // new task since the url change.
    foldOption(seqOption(userOption, taskOption), noop, ([user, task]) => {
      if (task.id !== taskId || task.properties.projectId !== projectId) {
        attemptToReleaseTask(task.properties.projectId, task.id, user.sub, dispatch);
      }
    });
  }, [projectId, taskId, taskOption, userOption, dispatch]);

  useEffect(() => {
    initializeProjectFromUrlParams(projectId, dispatch);
    dispatch(createSetLayerPickerConfig(LayerPickerSection.Overlay, []));
    return () => {
      dispatch(createPurge());
    };
  }, [projectId, dispatch]);

  useEffect(() => {
    pipe(
      projectOption,
      map((project) => {
        const fetchGroups = async () => {
          const { data: groups } = await listCampaignLabelGroups(project.campaignId);
          dispatch(createSetLabelClassGroups(some(sortClassGroupAndClass(groups))));
        };
        fetchGroups();
        return;
      })
    );
  }, [projectOption, dispatch]);

  useEffect(() => {
    // Use the URL params to populate the segmentation UI state
    foldOption(
      seqOption(projectOption, campaignOption, userOption, labelClassGroupsOpt),
      noop,
      ([project, campaign, user, labelClassGroups]) => {
        initializeStateFromUrlParams(
          campaign,
          project,
          taskId,
          action,
          user.sub,
          labelClassGroups,
          hitlEnabled,
          dispatch
        );
      }
    );
    return () => {
      dispatch(createPurgeTask());
    };
  }, [
    projectOption,
    campaignOption,
    labelClassGroupsOpt,
    taskId,
    action,
    userOption,
    hitlEnabled,
    dispatch,
  ]);

  useEffect(
    () => () => {
      // This effect does nothing until the cleanup stage. We use this cleanup
      // stage which is triggered on exiting the segmentation UI to remove any
      // remaining task lock
      foldOption(seqOption(userOption, projectOption), noop, ([user, project]) => {
        attemptToReleaseTask(project.id, taskId, user.sub, dispatch);
      });
    },
    [projectOption, taskId, userOption, dispatch]
  );

  useEffect(() => {
    // We wait for the task in the segmentation state to be populated before
    // locking the task
    foldOption(userOption, noop, (user) => applyTaskLock(taskOption, user.sub, mode, dispatch));

    let interval: NodeJS.Timeout;
    const callback = () => {
      foldOption(userOption, noop, (user) =>
        applyTaskLock(taskOption, user.sub, mode, dispatch, true, true)
      );
    };
    interval = setInterval(callback, INTERVAL);
    return () => {
      !!interval && clearInterval(interval);
    };
  }, [taskOption, userOption, mode, dispatch]);

  useEffect(() => {
    foldOption(taskOption, noop, (task) => {
      // We wait for a lock on the task before possibly adding it to the queue
      // If the task is already in the queue, do nothing, otherwise add it
      const matchParams = location.search.substring(1) === taskQueueParamsString;
      const matchAction = exists((t: TaskURLActionType) => t === action)(taskQueueActionOption);
      const notExpired = new Date().getTime() - taskQueueUpdatedAt < TASK_QUEUE_TTL_MS;

      foldOption(
        findFirst((t: Task) => t.id === task.id)(taskQueue),
        () => {
          const q = matchParams && matchAction && notExpired ? taskQueue : [];
          dispatch(createSetTaskQueue(cons(task, q), some(action), location.search.substring(1)));
        },
        () => {
          if (!(matchParams && matchAction && notExpired)) {
            dispatch(createSetTaskQueue([task], some(action), location.search.substring(1)));
          }
        }
      );
    });
    addInProgressStatus(taskOption, hasTaskLock, mode, dispatch);
  }, [
    taskOption,
    hasTaskLock,
    dispatch,
    taskQueue,
    action,
    mode,
    location.search.substring,
    location.search,
    taskQueueActionOption,
    taskQueueParamsString,
    taskQueueUpdatedAt,
  ]);

  useEffect(() => {
    foldOption(
      seqOption(projectOption, taskOption, campaignOption),
      noop,
      ([project, task, campaign]) => {
        if (task.id === taskId && project.id === task.properties.projectId) {
          dispatch(
            replace(
              `/projects/${project.id}/tasks/${
                task.id
              }/${action}/${campaign.campaignType.toLowerCase()}${location.search}`
            )
          );
        }
      }
    );
  }, [projectOption, campaignOption, taskOption, action, dispatch, location.search, taskId]);

  useEffect(() => {
    if (
      autosaveLatch &&
      naiveAutoSavingEnabled &&
      (mode === TaskUIMode.Labeling || mode === TaskUIMode.Validating)
    ) {
      foldOption(taskOption, noop, async (task) => {
        setAutosaveLatch(false);
        dispatch(createSetIsAutosavingInProgress(true));
        await replaceLabels(task, displayBuffer, task.properties.status);
        dispatch(createSetIsAutosavingInProgress(false));
      });
    }
  }, [autosaveLatch, naiveAutoSavingEnabled, taskOption, displayBuffer, mode, dispatch]);

  useEffect(() => {
    setAutosaveLatch(isSome(lastActionAtOption));
  }, [lastActionAtOption]);

  const onMouseUp = useCallback(
    (evt: React.MouseEvent) => {
      // We can safely type cast because we know this event is being sourced
      // from a DOM element (OverlayPage)
      const target = evt.target as Element;
      const inMap = target.id === "deckgl-overlay";

      if (drawingInProgress && !inMap) {
        dispatch(createSetDrawingInProgress(!drawingInProgress));
      }
    },
    [dispatch, drawingInProgress]
  );

  return foldOption(campaignOption, noEl, (campaign) => (
    <AccessControlProvider campaignId={campaign.id}>
      <AccessLimiterRedirect
        requiredActions={[
          action === TaskURLActionType.Validate
            ? [ACRActionType.Validate]
            : [ACRActionType.Annotate],
        ]}
        otherwise="/"
      >
        <OverlayPage onMouseUp={onMouseUp}>
          <Topbar />
          <StyledBox>
            <Sidebar />
            <MapPane />
          </StyledBox>
        </OverlayPage>
      </AccessLimiterRedirect>
    </AccessControlProvider>
  ));
};

export default SegmentationTask;
