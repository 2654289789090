import React, { useState } from "react";
import { Button, Icon, Heading, Box, Card } from "@blasterjs/core";
import UpgradeSelection from "./UpgradeSelection";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import LinkButton from "components/LinkButton";
import { IntercomAPI } from "react-intercom";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled(Card)`
  position: fixed;
  top: 50vh;
  left: 50vw;
  background: ${themeGet("colors.offWhite")};
  z-index: 1000;
  transform: translateX(-50%) translateY(-50%);
  padding: 1em;
  color: ${themeGet("colors.black")};
`;

interface UpgradeModalProps {
  display: boolean;
  onHide: () => void;
}

const UpgradeModal = ({ display, onHide }: UpgradeModalProps) => {
  return display ? (
    <>
      <Backdrop onClick={() => onHide()} />
      <ModalContent>
        <Button appearance="minimal" onClick={() => onHide()} top={2} right={2} position="absolute">
          <Icon name="cross" size="2em" />
        </Button>
        <Box p={1}>
          <Heading as="h4">Upgrade to get the most from GroundWork</Heading>
        </Box>
        <UpgradeSelection />
      </ModalContent>
    </>
  ) : null;
};

const UpgradePrompt = () => {
  const [showing, setShowing] = useState(false);
  const onUpgradeClick = () => {
    setShowing(true);
    IntercomAPI("trackEvent", "upgrade-button-clicked");
  };
  return (
    <>
      <LinkButton onClick={onUpgradeClick} data-intercom-target="upgrade-now" type="button">
        Upgrade now
      </LinkButton>
      <UpgradeModal display={showing} onHide={() => setShowing(false)} />
    </>
  );
};

export default UpgradePrompt;
