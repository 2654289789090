import { none, Option } from "fp-ts/es6/Option";
import { LimitsState, PredictionState } from "types";

export enum LimitActionTypes {
  SetPrediction = "LIMIT_SET_PREDICTION",
  SetDirty = "LIMIT_SET_DIRTY",
}

export interface SetDirtyAction {
  type: LimitActionTypes.SetDirty;
  payload: {};
}

export interface SetPredictionAction {
  type: LimitActionTypes.SetPrediction;
  payload: Option<PredictionState>;
}

export type LimitsActions = SetDirtyAction | SetPredictionAction;

const INITIAL_STATE: LimitsState = {
  dirty: false,
  predictionO: none,
};

const reducer = (state: LimitsState = INITIAL_STATE, action: LimitsActions) => {
  switch (action.type) {
    case LimitActionTypes.SetDirty:
      return { ...state, dirty: !state.dirty };
    case LimitActionTypes.SetPrediction:
      return { ...state, predictionO: action.payload };
    default:
      return { ...state };
  }
};

export default reducer;
