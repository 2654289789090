import React from "react";
import { TaskAction } from "datamodel/task";
import { foldOption } from "lib";
import MessageBox from "./MessageBox";
import { FaFlag } from "react-icons/fa";

type Props = {
  action?: TaskAction;
};

const FlaggedNote = ({ action }: Props) =>
  action ? (
    foldOption(
      action.note,
      () => <MessageBox icon={FaFlag} iconColor="orange.500" title="Flagged task" />,
      (note) => (
        <MessageBox icon={FaFlag} iconColor="orange.500" title="Flagged task" message={note} />
      )
    )
  ) : (
    <MessageBox icon={FaFlag} iconColor="orange.500" title="Flagged task" />
  );

export default FlaggedNote;
