import { Box, HStack, Icon, Text, ThemeTypings, VStack } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";

type Props = {
  message?: string;
  title: string;
  icon?: IconType;
  iconColor?: ThemeTypings["colors"];
};

const MessageBox = ({ message, title, icon, iconColor }: Props) => {
  return (
    <Box
      boxShadow="sm"
      border="1px solid"
      borderColor="gray.100"
      bg="White"
      p={5}
      alignSelf="stretch"
    >
      <VStack spacing={3} alignItems="flex-start">
        <HStack spacing={3} alignItems="center">
          {icon && <Icon as={icon} color={iconColor || undefined} fontSize="18px" />}
          <Text fontWeight="semibold">{title}</Text>
        </HStack>
        {message && (
          <Text color="textLight" textAlign="left">
            {message}
          </Text>
        )}
      </VStack>
    </Box>
  );
};

export default MessageBox;
