import React from "react";
import { Text } from "@blasterjs/core";
import { Option } from "fp-ts/es6/Option";

import { foldOption, noEl } from "lib";
import { Task, TaskStatus } from "datamodel/task";
import { TaskURLActionType } from "types";
import { Stack } from "@chakra-ui/react";
import {
  StartLabeling,
  RestoreTask,
  UnlockTask,
  ContinueLabeling,
  Validate,
  ContinueValidating,
} from "./Buttons";

interface Props {
  taskOption: Option<Task>;
  onClickUrlAction: (actionType: TaskURLActionType) => void;
  callback: () => void;
  urlActionType: TaskURLActionType;
}

const ValidatorMenuForTaskUI = ({
  taskOption,
  onClickUrlAction,
  callback,
  urlActionType,
}: Props) => {
  const TaskUnlabeled = () => <StartLabeling onClickUrlAction={onClickUrlAction} />;

  const TaskInvalid = () => <RestoreTask taskOption={taskOption} callback={callback} />;

  const TaskInProgress = () => <UnlockTask taskOption={taskOption} callback={callback} />;

  const TaskRead = () => (
    <Stack spacing={4} flex="1">
      {urlActionType === TaskURLActionType.Label && (
        <ContinueLabeling onClickUrlAction={onClickUrlAction} />
      )}
      {urlActionType === TaskURLActionType.Validate && (
        <Validate onClickUrlAction={onClickUrlAction} />
      )}
    </Stack>
  );

  const TaskValidated = () => <ContinueValidating onClickUrlAction={onClickUrlAction} />;

  return foldOption(taskOption, noEl, (task) =>
    task.properties.status === TaskStatus.Unlabeled && urlActionType === TaskURLActionType.Label ? (
      <TaskUnlabeled />
    ) : task.properties.status === TaskStatus.Invalid ? (
      <TaskInvalid />
    ) : (task.properties.status === TaskStatus.LabelingInProgress &&
        urlActionType === TaskURLActionType.Label) ||
      (task.properties.status === TaskStatus.ValidationInProgress &&
        urlActionType === TaskURLActionType.Validate) ? (
      <TaskInProgress />
    ) : task.properties.status === TaskStatus.Labeled ||
      task.properties.status === TaskStatus.Flagged ? (
      <TaskRead />
    ) : task.properties.status === TaskStatus.Validated &&
      urlActionType === TaskURLActionType.Validate ? (
      <TaskValidated />
    ) : (
      <Text>No action available due to mismatching task queue and task status.</Text>
    )
  );
};

export default ValidatorMenuForTaskUI;
