import React, { ReactNode, useMemo } from "react";
import { StyledContextMenu, MenuActions } from "./styled";
import { MenuPositionStyle } from "lib/calculateMenuPosition";
import { calculateMenuPosition } from "lib";

type PositionerProps = {
  children: ReactNode;
  e: MouseEvent;
  el: HTMLElement;
};

const MenuPositioner = ({ children, e, el }: PositionerProps) => {
  const position: MenuPositionStyle = useMemo(
    () => (e ? calculateMenuPosition(el.offsetWidth, el.offsetHeight, e.offsetX, e.offsetY) : {}),
    [e, el.offsetHeight, el.offsetWidth]
  );

  return (
    <>
      {children && (
        <StyledContextMenu style={position}>
          <MenuActions>{children}</MenuActions>
        </StyledContextMenu>
      )}
    </>
  );
};

export default MenuPositioner;
