import React from "react";
import { Icon, Button, Box, Text, Card } from "@blasterjs/core";
import { IntercomAPI } from "react-intercom";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import FlexFiller from "components/FlexFiller";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";

const StyledCard = styled(Card)`
  margin-right: ${themeGet("space.2")};
  &:last-child {
    margin-right: 0;
  }
`;

const StubButton = styled(Button)`
  cursor: not-allowed;
`;

interface SubscriptionType {
  title: string;
  description: string;
  perks: string[];
  children: React.ReactNode;
}

interface SubscriptionCardProps {
  subscription: SubscriptionType;
}

const onContactUsClick = () => {
  IntercomAPI("showNewMessage", "I am interested in a Pro subscription.");
  IntercomAPI("trackEvent", "upgrade-form-accessed");
};

const SubscriptionCard = ({ subscription: sub }: SubscriptionCardProps) => {
  return (
    <StyledCard
      display="flex"
      flexDirection="column"
      p={4}
      width="300px"
      height="380px"
      background="white"
    >
      <Box pb={1}>
        <Text fontSize={3} fontWeight="600">
          {sub.title}
        </Text>
      </Box>
      <Text pb={2} fontSize="1.6rem" color="textLight" fontWeight="600">
        {sub.description}
      </Text>
      {sub.perks.map((perk, idx) => (
        <Box pb={1} display="flex" alignItems="center" key={idx}>
          <Icon name="check" color="primary" size="2rem" mr={1} />
          <Text color="#5b6259">{perk}</Text>
        </Box>
      ))}
      <FlexFiller />
      {sub.children}
    </StyledCard>
  );
};

const LimitsUpgradeSelection = () => {
  const entity = useUserFeatureFlag("campaigns") ? "campaign" : "project";
  const subscriptions: SubscriptionType[] = [
    {
      title: "FREE",
      description: "Anyone can build a custom training dataset for free. The free tier gives you:",
      perks: [`10 ${entity}s`, "10GB of data", `5 collaborators per ${entity}`],
      children: (
        <StubButton appearance="prominent" intent="secondary" fontWeight="600" withBorder>
          You have this plan
        </StubButton>
      ),
    },
    {
      title: "PRO",
      description:
        "For just $10k/year, Groundwork enables data labeling teams to collaborate on annotating large data sets and validate labels to ensure you have high quality, accurate training data. Pro users get:",
      perks: [`50 ${entity}s`, "50GB of data", `50 collaborators per ${entity}`],
      children: (
        <Button appearance="prominent" intent="primary" onClick={onContactUsClick} withBorder>
          Contact Us
        </Button>
      ),
    },
  ];
  return (
    <Box display="flex" flexDirection="row">
      {subscriptions.map((sub, idx) => (
        <SubscriptionCard subscription={sub} key={idx} />
      ))}
    </Box>
  );
};

export default LimitsUpgradeSelection;
