import { SemanticLabelFeatureCollection } from "types";
import { Task, TaskStatus } from "datamodel/task";
import { putTaskLabels, putTaskValidation } from "http/task";
import getSegmentationFeatureCollection from "./getSegmentationFeatureCollection";
import getTaskNextStatus from "./getTaskNextStatus";

const confirmHitlLabels = async (task: Task, displayBuffer: SemanticLabelFeatureCollection) => {
  // For HITL projects, we need to fast track the labels created via the ui (i.e. initial labels)
  // directly to VALIDATED
  await putTaskLabels(task, getSegmentationFeatureCollection(displayBuffer, TaskStatus.Labeled));

  return putTaskValidation(
    task,
    getSegmentationFeatureCollection(displayBuffer, TaskStatus.Validated)
  );
};

const confirmTask = async (
  task: Task,
  displayBuffer: SemanticLabelFeatureCollection,
  hitlEnabled: boolean = false
) => {
  // we don't wait for label put
  // but we need to wait for task status update
  // otherwise, the task status clean up step will be confused
  const nextStatus = getTaskNextStatus(task);
  const put = nextStatus === TaskStatus.Labeled ? putTaskLabels : putTaskValidation;

  if (hitlEnabled && nextStatus === TaskStatus.Labeled) {
    confirmHitlLabels(task, displayBuffer);
  }

  return put(task, getSegmentationFeatureCollection(displayBuffer, nextStatus));
};

export default confirmTask;
