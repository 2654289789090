const coordsToTolerance = (
  start: [number, number],
  end: [number, number],
  baseTolerance: number,
  sensitivity: number
) =>
  baseTolerance +
  Math.abs(Math.sqrt(Math.pow(end[1] - start[1], 2) + Math.pow(end[0] - start[0], 2))) /
    sensitivity;

export default coordsToTolerance;
