import { Option } from "fp-ts/es6/Option";

import { PredictionState } from "types";
import { LimitActionTypes, SetPredictionAction, SetDirtyAction } from "./reducer";

export const createSetLimitsDirtyAction = (): SetDirtyAction => ({
  type: LimitActionTypes.SetDirty,
  payload: {},
});

export const createSetPredictionAction = (
  payload: Option<PredictionState>
): SetPredictionAction => ({
  type: LimitActionTypes.SetPrediction,
  payload,
});
