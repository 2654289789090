import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { createEnumType, geoJSONMultiPolygonCodec, geoJSONPolygonCodec } from "types";
import { tileCodec, tileCreateCodec } from "./tile";
import { taskStatusTypeCodec } from "./task";
import { UUID } from "io-ts-types";

export enum ProjectStatus {
  Waiting = "WAITING",
  Queued = "QUEUED",
  Processing = "PROCESSING",
  Ready = "READY",
  TaskGridFailure = "TASK_GRID_FAILURE",
  ImageIngestionFailure = "IMAGE_INGESTION_FAILURE",
  UnknownFailure = "UNKNOWN_FAILURE",
}

export const projectStatusCodec = createEnumType<ProjectStatus>(ProjectStatus, "ProgressStage");

export const projectCreateCodec = t.type({
  name: t.string,
  taskSizePixels: t.number,
  aoi: optionFromNullable(t.union([geoJSONMultiPolygonCodec, geoJSONPolygonCodec])),
  campaignId: UUID,
  status: projectStatusCodec,
  tileLayers: t.array(tileCreateCodec),
});

export const projectCodec = t.type({
  id: t.string,
  createdAt: t.string,
  owner: t.string,
  name: t.string,
  taskSizeMeters: optionFromNullable(t.string),
  taskSizePixels: t.number,
  aoi: optionFromNullable(t.union([geoJSONMultiPolygonCodec, geoJSONPolygonCodec])),
  campaignId: UUID,
  status: projectStatusCodec,
  isActive: t.boolean,
  tileLayers: t.array(tileCodec),
  taskStatusSummary: optionFromNullable(t.record(taskStatusTypeCodec, t.number)),
});

export type ProjectCreate = t.TypeOf<typeof projectCreateCodec>;

export type Project = t.TypeOf<typeof projectCodec>;
