import { ReactNode, useContext, useMemo } from "react";
import { pipe } from "fp-ts/es6/pipeable";
import { exists, isNone } from "fp-ts/es6/Option";

import { ACRActionType } from "datamodel/permissions";
import AccessControlContext from "./AccessControlContext";
import verifyActions from "./helpers/verifyActions";

/**
 * `requiredActions` contains lists of acceptable permission combinations ~ [[A and B and C...] OR [D and E and F]...]
 * `requiredActions` example:
 * [[ACRActionType.Validate], [ACRActionType.Annotate]] => if a user has
 * validate AND/OR annotate permissions AND/OR all (*) permissions, the content will be shown
 *
 * [[ACRActionType.Annotate, ACRActionType.Validate], [ACRActionType.Delete]] => if a user has
 * both Annotate AND Validate permissions AND/OR has a DELETE permission AND/OR has an ALL permission, the content will be shown
 */

interface Props {
  requiredActions: ACRActionType[][];
  children: ReactNode | ((isLoading: boolean) => ReactNode);
}

const AccessLimiter = ({ requiredActions, children }: Props) => {
  const actionsOption = useContext(AccessControlContext);

  const result = useMemo(
    () =>
      pipe(
        actionsOption,
        exists((actions) => verifyActions(actions, requiredActions))
      ),
    [actionsOption, requiredActions]
  );

  if (typeof children === "function") {
    return children(isNone(actionsOption));
  }
  return result && children;
};

export default AccessLimiter;
