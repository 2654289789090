import React, { FormEvent, useRef } from "react";
import useComponentSize from "@rehooks/component-size";
import { LabelBox, StyledSelect } from "./styled";
import { Box } from "@chakra-ui/react";

type Props<T> = {
  label: string;
  value: T;
  onChange: (sort: T) => void;
  options: SelectOptions;
};

export type SelectOptions = [string | number, string][];

const C = <T extends unknown>({ label, value, options, onChange }: Props<T>) => {
  const labelRef = useRef<HTMLDivElement>(null);
  const { width: labelWidth } = useComponentSize(labelRef);
  return (
    <Box
      display="inline-flex"
      border="1px solid"
      position="relative"
      borderColor="gray.100"
      color="textLight"
      borderRadius="1000"
    >
      <LabelBox ref={labelRef}>{label}</LabelBox>
      <StyledSelect
        labelSize={labelWidth}
        value={value}
        onChange={(e: FormEvent<HTMLSelectElement>) => onChange(e.currentTarget.value as T)}
      >
        {options.map((o, idx) => (
          <option key={idx} value={o[0]}>
            {o[1]}
          </option>
        ))}
      </StyledSelect>
    </Box>
  );
};

export default C;
