import { Task, TaskStatus } from "datamodel/task";
import { Dispatch } from "redux";
import { putTaskStatus, deleteTaskLabels } from "http/task";
import { createSetLastError } from "state/ui-segmentation/actions";
import { none, some } from "fp-ts/lib/Option";

const rejectTask = async (task: Task, note: string, removeLabels: boolean, dispatch: Dispatch) => {
  try {
    if (removeLabels) {
      await deleteTaskLabels(task);
    }

    await putTaskStatus(task.properties.projectId, task.id, {
      note: note === "" ? none : some(note),
      nextStatus: TaskStatus.Unlabeled,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      dispatch(createSetLastError("Failed to update task when trying to reject task", err));
    }
  }
};

export default rejectTask;
