import { RouteProps, SearchParams, DEFAULTS } from "..";

const provideDefaults = ({ page, sort, ownershipType, search }: RouteProps): SearchParams => ({
  page: page && ~~+page >= 1 ? ~~+page : DEFAULTS.page,
  sort: sort || DEFAULTS.sort,
  ownershipType: ownershipType || DEFAULTS.ownershipType,
  search: search || DEFAULTS.search,
});

export default provideDefaults;
