import { CampaignType } from "datamodel/campaign";
import { Campaign } from "datamodel/campaign";
import { postCampaign } from "http/campaign";
import { postCampaignLabelGroups } from "http/labelGroup";
import { buildLabelClassGroupCreate } from "pages/helpers";

const createCampaign = async (values: any): Promise<Campaign> => {
  const { data: campaign } = await postCampaign({
    name: values.name,
    campaignType: values.projectType,
  });
  await postCampaignLabelGroups(
    campaign.id,
    buildLabelClassGroupCreate(values, values.projectType as CampaignType)
  );

  return campaign;
};

export default createCampaign;
