import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";

import { ApplicationStore, LayerConfig, LayerPickerSection } from "types";
import { defaultImageryLayersConfig } from "pages/SegmentationTask/MapPane/helpers/layerConfigDefaults";
import { pipe } from "fp-ts/es6/pipeable";
import { findFirst } from "fp-ts/es6/Array";
import { map } from "fp-ts/es6/Option";
import { createSetLayerPickerConfig } from "state/ui-segmentation/actions";
import { maybeUpdateLayer } from "pages/SegmentationTask/MapPane/helpers";

const useImageSwitchHotkey = () => {
  const dispatch = useDispatch();
  const imageryLayersConfig = useSelector(
    (state: ApplicationStore) => state.segmentationUI.imageryLayersConfig
  );

  useHotkeys(
    "ctrl+e, command+e",
    (event) => {
      event.preventDefault();
      defaultImageryLayersConfig.forEach((defaultConfig) => {
        pipe(
          findFirst((config: LayerConfig) => config.id === defaultConfig.id)(imageryLayersConfig),
          map((config) =>
            dispatch(
              createSetLayerPickerConfig(
                LayerPickerSection.Imagery,
                imageryLayersConfig.map(
                  maybeUpdateLayer(config.id, !config.enabled, config.opacity)
                )
              )
            )
          )
        );
      });
    },
    [imageryLayersConfig]
  );
};

export default useImageSwitchHotkey;
