import { UploadWithFile } from "datamodel/upload";
import { ImagerySelectionTabs, UploadError } from "./types";
import create from "zustand";
import { combine } from "zustand/middleware";

const initialState = {
  lastStepCompleted: 0,
  step: 1,
  uploads: [] as UploadWithFile[],
  uploadErrors: [] as UploadError[],
  imageryTab: ImagerySelectionTabs.Upload,
  files: [] as File[],
  canAdvance: false,
  isLoading: false,
};

const useStore = create(
  combine(initialState, (set) => ({
    setStep: (step: number) => set({ step }),
    setLastStepCompleted: (step: number) => set({ lastStepCompleted: step }),
    setImageryTab: (tab: ImagerySelectionTabs) => set({ imageryTab: tab }),
    setUploadsWithPrev: (setter: (prev: UploadWithFile[]) => UploadWithFile[]) =>
      set((state) => ({ uploads: setter(state.uploads) })),
    setFilesWithPrev: (setter: (prev: File[]) => File[]) =>
      set((state) => ({ files: setter(state.files) })),
    setErrorWithPrev: (setter: (prev: UploadError[]) => UploadError[]) =>
      set((state) => ({ uploadErrors: setter(state.uploadErrors) })),
    setCanAdvance: (canAdvance: boolean) => set({ canAdvance }),
    setIsLoading: (isLoading: boolean) => set({ isLoading }),
    purge: () => set(initialState),
  }))
);

export default useStore;
