import React from "react";
import { Box } from "@blasterjs/core";
import { Link } from "react-router-dom";
import styled from "styled-components";
import FlexFiller from "components/FlexFiller";
import { Image } from "@chakra-ui/react";
import UserMenu from "./UserMenu";

const StyledBox = styled(Box)`
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);
  background: white;
  display: flex;
  z-index: 1;
  align-items: center;
  height: 60px;
`;

const TopBar = () => (
  <StyledBox px={2} py={0}>
    <Link to="/" className="logo-link">
      <Image
        alt="logo"
        title="Logo"
        height="24px"
        src={`${process.env.PUBLIC_URL}/groundwork_logo.svg`}
      />
    </Link>
    <FlexFiller />
    <UserMenu />
  </StyledBox>
);

export default TopBar;
