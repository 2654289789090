import { UploadStatus, UploadType, UploadCreate } from "datamodel/upload";
import { some } from "fp-ts/es6/Option";
import { postUpload } from "http/upload";

const createUpload = async (file: string, size: number, singleBand: boolean) => {
  // We have no guarantees about config value types so we need to narrow types
  const upload: UploadCreate = {
    uploadStatus: UploadStatus.Created,
    uploadType: UploadType.Local,
    fileUrl: file,
    generateTasks: true,
    bytesUploaded: some(size),
    singleBand: singleBand,
  };
  const resp = await postUpload(upload);
  return resp.data;
};

export default createUpload;
