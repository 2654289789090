import { Center, Wrap, VStack } from "@chakra-ui/react";
import { Campaign } from "datamodel/campaign";
import useDecodedQueryParamOrElse from "hooks/useDecodedQueryParamOrElse";
import { NumberFromString } from "io-ts-types";
import React, { useCallback } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router";
import { listTasksForCampaign } from "http/task";
import { Task, TaskStatus } from "datamodel/task";
import EmptyState from "components/EmptyState";
import IssueCard from "./IssueCard";
import SkeletonIssueCard from "./SkeletonIssueCard";
import { queryClient } from "App";
import PaginationControls from "components/PaginationControls";
import AccessControlProvider from "components/AccessControl/AccessControlProvider";
import AccessLimiter from "components/AccessControl/AccessLimiter";
import { ACRActionType } from "datamodel/permissions";

export type QueryParams = Partial<{
  p: number;
}>;

type Props = {
  campaign: Campaign;
};

const PAGE_SIZE = 6;

const UnauthedIssues = ({ campaign }: Props) => {
  const page = useDecodedQueryParamOrElse("p", NumberFromString, 1);
  const history = useHistory();
  const location = useLocation();

  const updateQueryParams = useCallback(
    ({ p = page }: QueryParams) => {
      history.push(`${location.pathname}?p=${p}`);
    },
    [history, location.pathname, page]
  );

  const { data: tasks, isLoading } = useQuery(["campaign-flagged-tasks", campaign.id, page], () =>
    listTasksForCampaign(campaign.id, { page, pageSize: PAGE_SIZE, status: [TaskStatus.Flagged] })
  );

  const invalidatePage = useCallback(() => {
    queryClient.invalidateQueries(["campaign-flagged-tasks", campaign.id, page]);
    queryClient.invalidateQueries(["campaign", campaign.id]);
  }, [campaign.id, page]);

  return (
    <VStack
      m="0 auto"
      maxWidth="1174px"
      alignItems="center"
      width="100%"
      display="flex"
      flex="1"
      px={2}
      pt="26px"
      pb={4}
      spacing={10}
    >
      {isLoading ? (
        <Wrap spacing={7} alignItems="stretch" alignSelf="flex-start">
          {[...Array(3)].map((_, i) => (
            <SkeletonIssueCard key={i} />
          ))}
        </Wrap>
      ) : !!tasks?.data.count ? (
        <Wrap spacing={7} alignItems="stretch" alignSelf="flex-start">
          {tasks.data.features.map((task, i) => (
            <IssueCard
              key={i}
              campaign={campaign}
              task={task as Task}
              invalidate={invalidatePage}
            />
          ))}
        </Wrap>
      ) : (
        <EmptyState title="There are no tasks that require your attention" />
      )}
      <Center>
        <PaginationControls
          isLoading={isLoading}
          pageObject={tasks?.data}
          onChange={(page) => updateQueryParams({ p: page })}
        />
      </Center>
    </VStack>
  );
};

const Issues = ({ campaign }: Props) => (
  <AccessControlProvider campaignId={campaign.id}>
    <AccessLimiter requiredActions={[[ACRActionType.Validate]]}>
      <UnauthedIssues campaign={campaign} />
    </AccessLimiter>
  </AccessControlProvider>
);

export default Issues;
