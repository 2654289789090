import * as t from "io-ts";
import { UUID } from "io-ts-types";
import { createEnumType } from "types";

export enum HITLJobStatus {
  NotRun = "NOTRUN",
  ToRun = "TORUN",
  Running = "RUNNING",
  Ran = "RAN",
  Failed = "FAILED",
}

export const hitlJobStatusCodec = createEnumType<HITLJobStatus>(HITLJobStatus, "HITLJobStatus");

export const hitlJobCreateCodec = t.type({
  campaignId: t.string,
  projectId: t.string,
  status: hitlJobStatusCodec,
});

export const hitlJobCodec = t.intersection([
  t.type({
    id: UUID,
    createdAt: t.string,
    owner: t.string,
    modifiedAt: t.string,
    version: t.number,
  }),
  hitlJobCreateCodec,
]);

export type HitlJobCreate = t.TypeOf<typeof hitlJobCreateCodec>;

export type HitlJob = t.TypeOf<typeof hitlJobCodec>;
