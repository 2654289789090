import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Box, Card } from "@blasterjs/core";

import { ApplicationStore } from "types";
import { foldOption, noop } from "lib";
import { fromNullable } from "fp-ts/es6/Option";
import LinkButton from "components/LinkButton";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { deleteShare } from "http/campaign";
import { getUser } from "http/user";
import { ApiUser } from "datamodel/user";
import { Campaign } from "datamodel/campaign";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import { useToast } from "@chakra-ui/react";

// @ts-ignore
const StyledLinkButton = styled(LinkButton)`
  color: ${themeGet("colors.link")};
  text-decoration: none;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
  &:disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
`;

const coalesceEmail = (user: ApiUser) => user.email || user.name;

type Props = {
  campaign: Campaign;
};

const SharingControls = ({ campaign }: Props) => {
  const toast = useToast();
  const history = useHistory();
  const userOption = useSelector((state: ApplicationStore) => state.newAuth.user);

  const { data: owner } = useQuery(["user", campaign.owner], () =>
    getUser(campaign.owner).then((resp) => resp.data)
  );

  const { mutate: unshareMutation, isLoading } = useMutation(
    (userId: string) => deleteShare(campaign, userId),
    {
      onSuccess: () => {
        history.replace("/");
      },
      onError: () => {
        toast({
          title: "An error occurred.",
          description: `
            There was a problem trying to remove you from this project.
            Please try again.
            If this problem persists, contact us.
          `,
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const onUnshareClick = useCallback(() => {
    foldOption(userOption, noop, async (user) => {
      unshareMutation(user.sub);
    });
  }, [unshareMutation, userOption]);

  return (
    <Card mb={2} borderColor="gray100" borderWidth="2px">
      <Box fontWeight="600" mb={2}>
        {foldOption(
          fromNullable(owner),
          () => "This project has been shared with you.",
          (projectOwner) =>
            `This project has been shared with you by ${coalesceEmail(projectOwner)}.`
        )}
      </Box>
      <Box>
        <StyledLinkButton onClick={onUnshareClick} disabled={isLoading}>
          Remove me from this project
        </StyledLinkButton>
      </Box>
    </Card>
  );
};
export default SharingControls;
