import React, { useMemo } from "react";
import { HStack, Radio, RadioGroup, VStack, Box, Text } from "@chakra-ui/react";
import { Option } from "fp-ts/es6/Option";
import styled from "styled-components";

import { Hotkey } from "components/Hotkey";
import { LabelGroup } from "datamodel/labelGroup";
import FlexFiller from "components/FlexFiller";
import { LabelObjectCreate } from "types";
import { foldOption } from "lib";

const QuestionText = styled(Text)`
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  font-weight: 600;
`;

interface Props {
  group: LabelGroup;
  idx: number;
  currentIdx: number;
  labelObjectCreateOpt: Option<LabelObjectCreate>;
  setCurrentIdx: React.Dispatch<React.SetStateAction<number>>;
  showHotkeyBlock: boolean;
  onChange: (gId: string, cId: string, currentIdx: number) => void;
  onRadioClick: () => void;
}

const Category = ({
  group,
  idx,
  currentIdx,
  onChange,
  setCurrentIdx,
  showHotkeyBlock,
  labelObjectCreateOpt,
  onRadioClick,
}: Props) => {
  const selectedClassId = useMemo(
    () =>
      foldOption(
        labelObjectCreateOpt,
        () => "",
        (labelObjectCreate: LabelObjectCreate) => labelObjectCreate[group.id]
      ),
    [labelObjectCreateOpt, group]
  );

  const onRadioChange = (classId: string) => {
    onChange(group.id, classId, currentIdx);
  };

  return (
    <Box
      textAlign="left"
      p="10px 16px 10px 16px"
      data-intercom-target={`class-group-${idx}`}
      background={idx === currentIdx ? "gray200" : "none"}
    >
      <Box>
        <QuestionText data-intercom-target="category-name">{group.name}</QuestionText>
        {idx === currentIdx + 1 && showHotkeyBlock ? (
          <Hotkey
            label="tab"
            key="tab"
            action={() => setCurrentIdx(currentIdx + 1)}
            isVisible={false}
            isDisabled={false}
          />
        ) : null}
        {idx === currentIdx - 1 && showHotkeyBlock ? (
          <Hotkey
            label="shift+tab"
            key="shift+tab"
            action={() => setCurrentIdx(currentIdx - 1)}
            isVisible={false}
            isDisabled={false}
          />
        ) : (
          <></>
        )}
      </Box>
      <RadioGroup onChange={onRadioChange} value={selectedClassId}>
        <VStack align="flex-start">
          {group.classes.map((label, labelIdx) => (
            <HStack width="100%" key={labelIdx} data-intercom-target="class-item">
              <Radio
                value={label.id}
                size="lg"
                colorScheme="orange"
                borderColor="gray400"
                onClick={onRadioClick}
                isFocusable={false}
              >
                {label.name}
              </Radio>
              <FlexFiller />
              <Box>
                {idx === currentIdx && showHotkeyBlock ? (
                  <Hotkey
                    label={`${labelIdx + 1}`}
                    key={`${labelIdx + 1}`}
                    action={() => {
                      onChange(group.id, label.id, currentIdx);
                    }}
                    isVisible={true}
                    isDisabled={false}
                  />
                ) : (
                  <></>
                )}
              </Box>
            </HStack>
          ))}
        </VStack>
      </RadioGroup>
    </Box>
  );
};

export default Category;
