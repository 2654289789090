import { LOGGING_INIT_ROLLBAR, LOGGING_INFO, LOGGING_ERROR } from "./types";

const INITIAL_STATE = {
  rollbar: null,
  infoMessageQueue: [],
  errorMessageQueue: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGGING_INIT_ROLLBAR:
      return flushQueues({
        ...state,
        rollbar: action.payload.rollbar,
      });
    case LOGGING_INFO:
      return flushQueues({
        ...state,
        infoMessageQueue: [...state.infoMessageQueue, ...action.payload],
      });
    case LOGGING_ERROR:
      return flushQueues({
        ...state,
        errorMessageQueue: [...state.errorMessageQueue, ...action.payload],
      });
    default:
      return state;
  }
};

function flushQueues(state) {
  if (state.rollbar) {
    // send error messages
    state.infoMessageQueue.forEach((message) => state.rollbar.info(message));
    state.errorMessageQueue.forEach((message) => state.rollbar.error(message));
    return {
      ...state,
      infoMessageQueue: [],
      errorMessageQueue: [],
    };
  } else if (state.infoMessageQueue.length > 500 || state.errorMessageQueue.length > 500) {
    return {
      ...state,
      infoMessageQueue: state.infoMessageQueue.slice(0, 500),
      errorMessageQueue: state.errorMessageQueue.slice(0, 500),
    };
  } else {
    return state;
  }
}

export default reducer;
