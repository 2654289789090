import React from "react";
import styled from "styled-components";
import { Card } from "@blasterjs/core";
import { useHotkeys } from "react-hotkeys-hook";

const HotkeyCard = styled(Card)`
  background: #fff;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 10px;
  border: solid #ccc;
  border-width: 1px 1px 2px;
  color: #5c6ba3;
  float: right;
  padding: 2px;
  width: 20px;
  height: 20px;
  text-align: center;
`;

interface Props {
  label: string;
  isVisible: boolean;
  isDisabled: boolean;
  action: () => void;
}

export const Hotkey = ({ label, action, isDisabled, isVisible }: Props) => {
  useHotkeys(
    label,
    (event) => {
      event.preventDefault();
      if (!isDisabled) {
        action();
      }
    },
    [isDisabled, action]
  );

  return isVisible ? (
    <HotkeyCard color={isDisabled ? "gray400" : "black"}>{label}</HotkeyCard>
  ) : (
    <></>
  );
};

export default Hotkey;
