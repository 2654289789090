import React from "react";
import { Icon } from "@blasterjs/core";
import {
  Stack,
  Wrap,
  Text,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Portal,
  Show,
} from "@chakra-ui/react";
import { Branded } from "io-ts";
import { UUIDBrand } from "io-ts-types";
import { Option, none, some } from "fp-ts/es6/Option";
import { HTML5Backend as Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { AxiosResponse } from "axios";

import { StatusFilter, PaginatedResponse } from "types";
import GButton from "components/GButton";
import ProjectCard from "./ProjectCard";
import LabeledSelectT, { SelectOptions } from "pages/Campaigns/LabeledSelectT";
import { Campaign } from "datamodel/campaign";
import ImagesLoading from "../ImagesLoading";
import EmptyState from "components/EmptyState";
import { foldOption, noEl } from "lib";
import ProjectView from "./ProjectView";
import ImageDropZone from "./ImageDropZone";
import UploadTrigger from "components/Uploader/UploadTrigger";
import { Project } from "datamodel/project";
import { QueryParams } from "pages/CampaignView/Overview";
import SpacenetImageModal from "components/SpaceNetImageryModal";
import PaginationControls from "components/PaginationControls";
import SearchInput from "components/SearchInput";
import { useDebouncedCallback } from "use-debounce/lib";

const statusOptions: SelectOptions = [
  [StatusFilter.All, "all images"],
  [StatusFilter.LabelingIncomplete, "unlabeled images"],
  [StatusFilter.Flagged, "flagged images"],
  [StatusFilter.ValidationIncomplete, "unvalidated images"],
];

interface ImagesProps {
  campaign: Campaign;
  isOwner: boolean;
  page: number;
  statusFilter: StatusFilter;
  nameFilter: string;
  isLoading: boolean;
  maxPage: number | undefined;
  projects: AxiosResponse<PaginatedResponse<Project>> | undefined;
  updateQueryParams: ({ p, f, s, name }: QueryParams) => void;
}

interface ImageListProps extends ImagesProps {
  selectedProjectOption: Option<Branded<string, UUIDBrand>>;
}

const Images = ({
  campaign,
  isOwner,
  projects,
  updateQueryParams,
  page,
  statusFilter,
  nameFilter,
  isLoading,
  maxPage,
}: ImagesProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const defaultFilterActive = statusFilter === StatusFilter.All;

  const onChangeStatusFilter = (filterValue: StatusFilter) =>
    updateQueryParams({ f: filterValue, p: 1 });

  const onOpenImageCard = (id: string) => {
    updateQueryParams({ s: some(id) });
  };

  const onChangeNameFilter = (name: string) => {
    updateQueryParams({ name });
  };

  const [onDebouncedChangeNameFilter, cancelDebouncedChangeNameFilter] = useDebouncedCallback(
    onChangeNameFilter,
    1000
  );

  if (isLoading) {
    return <ImagesLoading />;
  }

  return (
    <>
      <Stack direction="column" spacing={8}>
        <Stack direction="row" spacing={8} align="center">
          <Text textStyle="campaignOverviewSectionHeading" flex="1">
            Images
          </Text>
          <SearchInput
            data-intercom-target="search-images-by-name"
            value={nameFilter}
            placeholder="Filter by name"
            onChange={(search: string) => onDebouncedChangeNameFilter(search)}
            onInputCleared={() => {
              cancelDebouncedChangeNameFilter();
              onChangeNameFilter("");
            }}
            autoFocus
          />
          <Stack direction="row" spacing={4} align="center">
            <LabeledSelectT<StatusFilter>
              options={statusOptions}
              value={statusFilter}
              label="View:"
              onChange={onChangeStatusFilter}
            />
          </Stack>
          {isOwner && (
            <Menu>
              <Show above="md">
                <MenuButton
                  as={GButton}
                  colorScheme="gray.50"
                  rightIcon={<Icon name="caretDown" />}
                >
                  Add images
                </MenuButton>
              </Show>
              <Show below="md">
                <MenuButton colorScheme="gray.50" as={GButton}>
                  <Icon name="plus" style={{ minWidth: "auto" }} />
                </MenuButton>
              </Show>
              <Portal>
                <MenuList border="1px" borderColor="gray.300">
                  <UploadTrigger campaignId={campaign.id}>
                    <MenuItem icon={<Icon name="upload" size="14px" />}>Upload images</MenuItem>
                  </UploadTrigger>
                  <MenuItem icon={<Icon name="plus" />} onClick={onOpen}>
                    Add Spacenet images
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          )}
        </Stack>
        {projects && (
          <>
            {!!projects.data.results.length ? (
              <>
                <Wrap spacing={7}>
                  {projects.data.results.map((project) => (
                    <ProjectCard
                      campaign={campaign}
                      project={project}
                      key={project.id}
                      onOpen={() => onOpenImageCard(project.id)}
                    />
                  ))}
                </Wrap>
                <Center>
                  <PaginationControls
                    isLoading={isLoading}
                    pageObject={projects.data}
                    onChange={(p) => updateQueryParams({ p })}
                  />
                </Center>
              </>
            ) : defaultFilterActive ? (
              isOwner ? (
                <EmptyState title="Drag and drop geotiff files to add them to this campaign" />
              ) : (
                <EmptyState title="There are no images in this campaign yet" />
              )
            ) : (
              <EmptyState title="No images match the selected filter" />
            )}
          </>
        )}
      </Stack>
      <SpacenetImageModal onClose={onClose} isOpen={isOpen} campaign={campaign} />
    </>
  );
};

const ImageList = ({
  campaign,
  isOwner,
  projects,
  updateQueryParams,
  page,
  maxPage,
  isLoading,
  statusFilter,
  nameFilter,
  selectedProjectOption,
}: ImageListProps) => {
  const images = (
    <Images
      campaign={campaign}
      isOwner={isOwner}
      projects={projects}
      page={page}
      maxPage={maxPage}
      statusFilter={statusFilter}
      nameFilter={nameFilter}
      isLoading={isLoading}
      updateQueryParams={updateQueryParams}
    />
  );
  return (
    <>
      {isOwner ? (
        <DndProvider backend={Backend}>
          <ImageDropZone campaign={campaign}>{images}</ImageDropZone>
        </DndProvider>
      ) : (
        images
      )}
      {foldOption(selectedProjectOption, noEl, (projectId) => (
        <ProjectView
          campaign={campaign}
          projectId={projectId}
          onClose={() => updateQueryParams({ s: none })}
        />
      ))}
    </>
  );
};
export default ImageList;
