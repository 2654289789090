import React from "react";
import { Icon } from "@blasterjs/core";
import { useQuery } from "react-query";

import { Campaign } from "datamodel/campaign";
import fetchRandomTask from "pages/helpers/fetchRandomTask";
import { TaskURLActionType } from "types";
import AccessLimiter from "components/AccessControl/AccessLimiter";
import { ACRActionType } from "datamodel/permissions";
import AccessControlProvider from "components/AccessControl/AccessControlProvider";
import GButton from "components/GButton";
import { isNone } from "fp-ts/es6/Option";
import { foldOption, noop } from "lib";
import { useHistory } from "react-router";
import urlForRandomTask from "pages/helpers/urlForRandomTask";
import { queryClient } from "App";

type Props = {
  campaign: Campaign;
};

const ValidateButton = ({ campaign }: Props) => {
  const history = useHistory();

  const { data: nextTaskOption } = useQuery(
    ["campaign-validate-next"],
    () =>
      fetchRandomTask(TaskURLActionType.Validate, {
        campaignId: campaign.id,
      }),
    {
      cacheTime: 0,
      refetchInterval: 30000,
      onSuccess: (taskOption) => {
        foldOption(taskOption, noop, (task) => {
          queryClient.setQueryData(["task", task.id], task);
        });
      },
    }
  );

  const onClick = () => {
    nextTaskOption &&
      foldOption(nextTaskOption, noop, (task) =>
        history.push(
          urlForRandomTask(task.properties.projectId, task.id, TaskURLActionType.Validate, {
            campaignId: campaign.id,
          })
        )
      );
  };

  return (
    <AccessControlProvider campaignId={campaign.id}>
      <AccessLimiter requiredActions={[[ACRActionType.Validate]]}>
        <GButton
          data-intercom-target="label-button"
          colorScheme="#ffffff"
          ml={1}
          disabled={!nextTaskOption || isNone(nextTaskOption)}
          onClick={onClick}
        >
          <Icon name="checkCircle" mr={1} />
          Validate
        </GButton>
      </AccessLimiter>
    </AccessControlProvider>
  );
};

export default ValidateButton;
