import React from "react";
import { Box } from "@chakra-ui/react";
import { NativeTypes } from "react-dnd-html5-backend";
import { useDrop, DropTargetMonitor } from "react-dnd";

export interface DropZoneProps {
  onDrop: (monitor: DropTargetMonitor) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
}

const DropZone = ({ onDrop, onChange, children = <></> }: DropZoneProps) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      if (onDrop) {
        onDrop(monitor);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const showOver: boolean = canDrop && isOver;

  return (
    <div ref={drop}>
      <Box
        width="100%"
        bg="white"
        border="2px solid"
        borderColor={showOver ? "primary" : "transparent"}
        backgroundColor={showOver ? "gray.50" : "none"}
        minHeight="220px"
        display="flex"
        flexDirection="column"
        mb={1}
      >
        {children}
      </Box>
    </div>
  );
};

export default DropZone;
