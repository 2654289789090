import React from "react";
import { Box, HStack, Skeleton, Spinner, Text } from "@chakra-ui/react";
import { pipe } from "fp-ts/es6/function";
import { map } from "fp-ts/es6/Option";
import Pluralize from "react-pluralize";

import { Project, ProjectStatus } from "datamodel/project";
import { foldOption, seqOption } from "lib";
import commaSeparateNumber from "lib/commaSeparateNumber";
import getTotalTaskCountFromSummary from "pages/helpers/getTotalTaskCountFromSummary";
import { TaskStatus } from "datamodel/task";

type Props = {
  project: Project;
};

const excludeStatuses = [TaskStatus.Invalid, TaskStatus.Split];

const taskCount = (project: Project) =>
  pipe(
    project.taskStatusSummary,
    map((s) => getTotalTaskCountFromSummary(s, excludeStatuses).toString())
  );

const flaggedTaskCount = (project: Project) =>
  pipe(
    project.taskStatusSummary,
    map((s) => s.FLAGGED)
  );

const deletedTaskCount = (project: Project) =>
  pipe(
    project.taskStatusSummary,
    map((s) => s.INVALID)
  );

const StatusLine = ({ project }: Props) => {
  const isReady = project.status === ProjectStatus.Ready;

  return (
    <Box color="textLight" fontSize="13px">
      {isReady ? (
        foldOption(
          seqOption(taskCount(project), flaggedTaskCount(project), deletedTaskCount(project)),
          () => <Skeleton width="75px" />,
          ([count, flagged, deleted]) => (
            <HStack>
              <Text as="span">
                {commaSeparateNumber(parseInt(count))}
                &nbsp;
                <Pluralize singular={"task"} plural={"tasks"} count={count} showCount={false} />
              </Text>
              {!!flagged && <Text as="span">&middot; {commaSeparateNumber(flagged)} flagged</Text>}
              {!!deleted && <Text as="span">&middot; {commaSeparateNumber(deleted)} deleted</Text>}
            </HStack>
          )
        )
      ) : (
        <HStack alignItems="center">
          <Text>Processing</Text>
          <Spinner size="sm" color="blue.500" speed="1.5s" />
        </HStack>
      )}
    </Box>
  );
};

export default StatusLine;
