import { Dispatch } from "react";
import { Option } from "fp-ts/es6/Option";
import * as Nel from "fp-ts/es6/NonEmptyArray";
import polygonClipping from "polygon-clipping";

import { foldOption, seqOption, noop } from "lib";
import LabelingGeoJsonLayer from "./LabelingGeoJsonLayer";
import { DrawingActionType } from "types";
import { Task } from "datamodel/task";
import DrawingAction, { DrawingActionSourceFeature } from "common/modules/DrawingAction";
import { DrawPolygonHandler } from ".";
import { createSetActionBuffer, createSetDrawingInProgress } from "state/ui-segmentation/actions";
import { createDrawingAction } from "pages/SegmentationTask/helpers";
import createLabelingGeoJsonLayer from "./createLabelingGeoJsonLayer";
import { LabelClass } from "datamodel/labeClass";

interface LayerData {
  type: string;
  features: DrawingActionSourceFeature[];
}

interface OnEditParams {
  updatedData: LayerData;
}

const createSegmentationDrawLayer = (
  taskOption: Option<Task>,
  activeDrawingTypeOption: Option<DrawingActionType>,
  selectedClassOption: Option<LabelClass>,
  actionBuffer: DrawingAction[],
  isLabeling: boolean,
  isRightClick: boolean,
  drawHandler: DrawPolygonHandler,
  dispatch: Dispatch<any>
) => {
  // We only want to create and use a drawing layer if the appropriate mode is
  // active. Otherwise, we return void to remove any map interactions
  if (isLabeling && !isRightClick) {
    return foldOption(
      seqOption(taskOption, activeDrawingTypeOption, selectedClassOption),
      noop,
      ([task, activeDrawingType, selectedClass]): LabelingGeoJsonLayer | void => {
        if (
          activeDrawingType === DrawingActionType.NoOp ||
          activeDrawingType === DrawingActionType.MagicWand
        ) {
          return;
        }
        const onEdit = ({ updatedData }: OnEditParams) =>
          foldOption(Nel.fromArray(updatedData.features), noop, ([f]) => {
            dispatch(createSetDrawingInProgress(false));
            const clippedFeatureCoordinates = polygonClipping.intersection(
              task.geometry.coordinates,
              f.geometry.coordinates
            );
            const clippedFeature = {
              ...f,
              geometry: { ...f.geometry, coordinates: clippedFeatureCoordinates },
            };
            dispatch(
              createSetActionBuffer([
                ...actionBuffer,
                createDrawingAction(selectedClass, clippedFeature, activeDrawingType),
              ])
            );
          });

        return createLabelingGeoJsonLayer(onEdit, drawHandler);
      }
    );
  }
  return;
};

export default createSegmentationDrawLayer;
