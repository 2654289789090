import { Auth0FeatureFlagKey, Auth0User } from "datamodel/user";

type UserFeaturePredicate = (u: Auth0User) => boolean;

export const ROOT_URL = `${window.location.protocol}//${window.location.host}`;

export type FeatureFlags = {
  taskFlagging: boolean;
  spacenetProjectCreation: boolean;
};

export type UserFeatures = {
  magicWand: UserFeaturePredicate;
  hideLabelClasses: UserFeaturePredicate;
  validation: UserFeaturePredicate;
  campaigns: UserFeaturePredicate;
  naiveAutoSaving: UserFeaturePredicate;
  hitl: UserFeaturePredicate;
};

type AuthenticationConfig = {
  domain: string;
  clientID: string;
  redirectUri: string;
  audience: string;
  responseType: string;
  scope: string;
};

type Config = {
  authentication: AuthenticationConfig;
  baseUrl: string;
  rollbarAccessToken: string;
  loginRoute: string;
  afterLoginRoute: string;
  afterLogoutRoute: string;
  api: string;
  tiles: string;
  drawingPrecision: number;
  projectStatusPollInterval: number;
  taskLockReclaimInterval: number;
  taskUnlockDuration: number;
  featureFlags: FeatureFlags;
  userFeatures: UserFeatures;
  campaignLimitMinuend: number;
  intercomAppId: string;
};

const HITL_FEATURE = (u: Auth0User) => !!u[Auth0FeatureFlagKey.FlagHitl];

const APPROVED_FEATURE = () => true;

const config: Config = {
  authentication: {
    domain: process.env.REACT_APP_AUTH_DOMAIN || "",
    clientID: process.env.REACT_APP_AUTH_CLIENT_ID || "",
    redirectUri: `${ROOT_URL}/callback`,
    audience: process.env.REACT_APP_AUTH_AUDIENCE || "",
    responseType: "token id_token",
    scope: "openid profile",
  },
  baseUrl: `${ROOT_URL}`,
  rollbarAccessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || "",
  loginRoute: "/login",
  afterLoginRoute: "/",
  afterLogoutRoute: "/",
  api: process.env.REACT_APP_API_URL || "/api",
  tiles: process.env.REACT_APP_TILE_URL || "",
  drawingPrecision: 7,
  projectStatusPollInterval: 10000,
  taskLockReclaimInterval: 30000,
  taskUnlockDuration: 120000,
  featureFlags: {
    taskFlagging: true,
    spacenetProjectCreation: true,
  },
  userFeatures: {
    magicWand: APPROVED_FEATURE,
    hideLabelClasses: APPROVED_FEATURE,
    validation: APPROVED_FEATURE,
    campaigns: APPROVED_FEATURE,
    naiveAutoSaving: APPROVED_FEATURE,
    hitl: HITL_FEATURE,
  },
  campaignLimitMinuend: 3,
  intercomAppId: process.env.REACT_APP_INTERCOM_APP_ID || "",
};

export default config;
