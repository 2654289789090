import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Icon } from "@blasterjs/core";
import { Stack, Text, Tooltip } from "@chakra-ui/react";
import { Option, isNone, map, filterMap, fold } from "fp-ts/es6/Option";
import { pipe } from "fp-ts/es6/pipeable";
import Moment from "react-moment";
import Skeleton from "react-loading-skeleton";

import { foldOption, noop } from "lib";
import { Task } from "datamodel/task";
import { TaskURLActionType } from "types";
import { getTask } from "http/task";
import restoreTask from "pages/SegmentationTask/Sidebar/helpers/restoreTask";
import unlockTask from "pages/SegmentationTask/Sidebar/helpers/unlockTask";
import GButton from "components/GButton";
import config from "config";

interface OnClickCallBack {
  onClickUrlAction: (actionType: TaskURLActionType) => void;
}

interface TaskWithCallback {
  taskOption: Option<Task>;
  callback: () => void;
}

export const StartLabeling = ({ onClickUrlAction }: OnClickCallBack) => (
  <GButton
    width="100%"
    colorScheme="gray.50"
    leftIcon={<Icon name="drawPolygon" mr={1} />}
    onClick={() => onClickUrlAction(TaskURLActionType.Label)}
  >
    Start labeling
  </GButton>
);

export const RestoreTask = ({ taskOption, callback }: TaskWithCallback) => {
  const dispatch = useDispatch();
  return (
    <GButton
      colorScheme="gray.50"
      width="100%"
      disabled={isNone(taskOption)}
      leftIcon={<Icon name="retry" mr={1} />}
      onClick={() =>
        pipe(
          taskOption,
          map(async (task) => {
            await restoreTask(task, dispatch);
            callback();
          })
        )
      }
    >
      Restore Task
    </GButton>
  );
};

export const UnlockTask = ({ taskOption, callback }: TaskWithCallback) => {
  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  // check last action and status when the context menu is opened
  useEffect(() => {
    pipe(
      taskOption,
      filterMap((task) => task.properties.lockedOn),
      map((lockedOn) => {
        const then = new Date(lockedOn).getTime();
        const now = new Date().getTime();
        setIsDisabled(now - then < config.taskUnlockDuration);
        return;
      })
    );
  }, [taskOption]);

  const onClickUnlock = () => {
    // do a pre-flight check for last action and timestamp
    if (!isDisabled) {
      foldOption(taskOption, noop, async (task) => {
        const {
          id: taskId,
          properties: { projectId },
        } = task;
        const { data: taskNow } = await getTask(projectId, taskId);
        foldOption(taskNow.properties.lockedOn, noop, async (lockedOn) => {
          const then = new Date(lockedOn).getTime();
          const now = new Date().getTime();
          if (now - then >= config.taskUnlockDuration) {
            await unlockTask(taskNow, projectId, dispatch);
            callback();
          } else {
            setIsDisabled(true);
          }
        });
      });
    }
  };

  return (
    <Tooltip
      hasArrow
      placement="right-start"
      label="You can unlock this task once no one has been working on it for two minutes"
      bg="gray.100"
      color="black"
    >
      <Stack direction="column" justifyContent="space-between" alignItems="center" width="100%">
        <Text mb={1}>
          Locked{" "}
          {pipe(
            taskOption,
            filterMap((t) => t.properties.lockedOn),
            fold(
              () => <Skeleton width="60px" />,
              (lockedOn) => <Moment fromNow>{lockedOn}</Moment>
            )
          )}
        </Text>
        <GButton
          colorScheme="gray.50"
          width="100%"
          disabled={isDisabled}
          leftIcon={<Icon name="unlocked" mr={1} />}
          onClick={onClickUnlock}
        >
          Unlock Task
        </GButton>
      </Stack>
    </Tooltip>
  );
};

export const ContinueLabeling = ({ onClickUrlAction }: OnClickCallBack) => (
  <GButton
    colorScheme="gray.50"
    width="100%"
    leftIcon={<Icon name="drawPolygon" mr={1} />}
    onClick={() => onClickUrlAction(TaskURLActionType.Label)}
  >
    Continue Labeling
  </GButton>
);

export const Validate = ({ onClickUrlAction }: OnClickCallBack) => (
  <GButton
    colorScheme="gray.50"
    width="100%"
    leftIcon={<Icon name="checkCircle" mr={1} />}
    onClick={() => onClickUrlAction(TaskURLActionType.Validate)}
  >
    Validate
  </GButton>
);

export const ContinueValidating = ({ onClickUrlAction }: OnClickCallBack) => (
  <GButton
    colorScheme="gray.50"
    width="100%"
    leftIcon={<Icon name="checkCircle" mr={1} />}
    onClick={() => onClickUrlAction(TaskURLActionType.Validate)}
  >
    Continue Validating
  </GButton>
);
