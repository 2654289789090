import React from "react";
import { Icon } from "@blasterjs/core";
import { useQuery } from "react-query";

import fetchRandomTask from "pages/helpers/fetchRandomTask";
import { TaskURLActionType } from "types";
import AccessLimiter from "components/AccessControl/AccessLimiter";
import { ACRActionType } from "datamodel/permissions";
import AccessControlProvider from "components/AccessControl/AccessControlProvider";
import GButton, { GButtonProps } from "components/GButton";
import { isNone } from "fp-ts/es6/Option";
import { useHistory } from "react-router";
import { foldOption, noop } from "lib";
import urlForRandomTask from "pages/helpers/urlForRandomTask";
import { queryClient } from "App";
import { Project } from "datamodel/project";
import { Campaign } from "datamodel/campaign";

type Props = {
  project: Project;
  campaign: Campaign;
} & Omit<GButtonProps, "children">;

const UnauthedLabelButton = ({ project, ...rest }: Props) => {
  const history = useHistory();
  const { data: nextTaskOption, isLoading } = useQuery(
    ["project-label-next", project.id],
    () =>
      fetchRandomTask(TaskURLActionType.Label, {
        projectId: project.id,
      }),
    {
      refetchInterval: 30000,
      onSuccess: (taskOption) => {
        foldOption(taskOption, noop, (task) => {
          queryClient.setQueryData(["task", task.id], task);
        });
      },
    }
  );

  const onClick = () => {
    nextTaskOption &&
      foldOption(nextTaskOption, noop, (task) =>
        history.push(
          urlForRandomTask(task.properties.projectId, task.id, TaskURLActionType.Label, {
            projectId: task.properties.projectId,
          })
        )
      );
  };

  return (
    <GButton
      data-intercom-target="label-button"
      colorScheme="primary"
      ml={1}
      disabled={!nextTaskOption || isNone(nextTaskOption)}
      onClick={onClick}
      isLoading={isLoading}
      {...rest}
    >
      <Icon name="drawPolygon" mr={1} />
      Label
    </GButton>
  );
};

const LabelButton = ({ project, campaign, ...rest }: Props) => (
  <AccessControlProvider campaignId={campaign.id}>
    <AccessLimiter requiredActions={[[ACRActionType.Annotate]]}>
      <UnauthedLabelButton project={project} campaign={campaign} {...rest} />
    </AccessLimiter>
  </AccessControlProvider>
);

export default LabelButton;
