import React, { useEffect, useState } from "react";
import { LimitsContextProvider } from "./LimitsContext";
import { fromNullable, isSome, none, some, Option, map } from "fp-ts/es6/Option";
import { getStub, getUserLimits } from "http/limits";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationStore } from "types";
import { pipe } from "fp-ts/es6/pipeable";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";
import { listHitlJobs } from "http/hitlJob";
import { LimitDomain, Limit } from "datamodel/limits";
import { createSetPredictionAction } from "state/ui-limits/actions";
import { foldOption } from "lib";

const DEBUG = 0;

const getLimits = DEBUG ? getStub : getUserLimits;

const createPredictionLimit = (used: number, limit: number): Limit =>
  ({
    domain: LimitDomain.Predictions,
    action: "create",
    objectId: none,
    used,
    limit,
  } as Limit);

export const LimitsProvider: React.FC = (props) => {
  const [idTokenO, userO, isLimitDirty] = useSelector(
    (state: ApplicationStore) =>
      [state.newAuth.idToken, state.newAuth.user, state.limits.dirty] as const
  );
  const { data: objectLimits } = useQuery(["limits"], () => getLimits().then((resp) => resp.data), {
    enabled: isSome(idTokenO),
  });
  const [limitsO, setLimitsO] = useState<Option<Limit[]>>(none);

  const hitlEnabled = useUserFeatureFlag("hitl");

  const dispatch = useDispatch();

  useEffect(() => {
    if (!hitlEnabled) {
      return setLimitsO(fromNullable(objectLimits));
    }
    pipe(
      userO,
      map(async (user) => {
        const {
          data: { count: used },
        } = await listHitlJobs({ owner: user.sub });
        const limit = user.flag_hitl_count || 0;
        const predictionLimits = createPredictionLimit(used, limit);
        dispatch(createSetPredictionAction(some({ used, limit })));
        foldOption(
          fromNullable(objectLimits),
          () => setLimitsO(some([predictionLimits])),
          (l) => setLimitsO(some([...l, predictionLimits]))
        );
      })
    );
  }, [userO, objectLimits, hitlEnabled, isLimitDirty, dispatch]);

  return <LimitsContextProvider value={limitsO}>{props.children}</LimitsContextProvider>;
};

export default LimitsProvider;
