import { fromNullable } from "fp-ts/es6/Option";
import { foldOption } from "lib";

import { LayerConfig } from "types";

const calculateLayerOpacity = (layers: LayerConfig[], id: string) => {
  const layer = fromNullable(layers.find((layer) => layer.id === id));
  return foldOption(
    layer,
    () => 127,
    (layer) => Math.floor(255 * layer.opacity)
  );
};

export default calculateLayerOpacity;
