import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { createSetMode } from "state/ui-segmentation/actions";
import { TaskURLActionType, TaskUIMode, ApplicationStore } from "types";
import { createTrackingAction, TrackingActionTypes } from "state/tracking/actions";
import { foldOption, noop, seqOption } from "lib";
import pushRandomTask from "pages/SegmentationTask/helpers/pushRandomTask";
import useRouter from "use-react-router";
import { Stack, VStack } from "@chakra-ui/react";
import GButton from "components/GButton";
import MessageBox from "../components/MessageBox";
import { FaCheckCircle } from "react-icons/fa";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";

const ActionCompleted = () => {
  const dispatch = useDispatch();

  const { location } = useRouter();

  const [campaignOption, projectOption, urlActionType] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.campaign,
        state.segmentationUI.project,
        state.segmentationUI.urlActionType,
      ] as const
  );

  const title =
    urlActionType === TaskURLActionType.Label ? "Task already labeled" : "Task already validated";

  const message =
    urlActionType === TaskURLActionType.Label
      ? "This task has already been labeled. Start a different task or reopen this task to make changes."
      : "This task has already been validated. Start a different task or reopen this task to make changes.";

  const buttonText =
    urlActionType === TaskURLActionType.Label
      ? "Label a different task"
      : "Validate a different task";

  const reopenMode =
    urlActionType === TaskURLActionType.Label ? TaskUIMode.Labeling : TaskUIMode.Validating;

  const hitlEnabled = useUserFeatureFlag("hitl");

  const onSkipClick = () => {
    dispatch(createSetMode(TaskUIMode.Loading));
    foldOption(seqOption(projectOption, campaignOption), noop, ([project, campaign]) => {
      pushRandomTask(location.search, urlActionType, hitlEnabled, project, campaign, dispatch, {
        projectId: project.id,
      });
    });
  };

  const onReopenClick = () => {
    dispatch(createSetMode(reopenMode));
    dispatch(createTrackingAction(TrackingActionTypes.LabelingTaskReopened));
  };

  return (
    <VStack spacing={8} flex="1" m={4}>
      <MessageBox icon={FaCheckCircle} iconColor="blue.500" title={title} message={message} />
      <Stack spacing={4} alignSelf="stretch">
        <GButton colorScheme="primary" width="100%" onClick={onSkipClick}>
          {buttonText}
        </GButton>
        <GButton colorScheme="#fff" width="100%" onClick={onReopenClick}>
          Reopen this task
        </GButton>
      </Stack>
    </VStack>
  );
};

export default ActionCompleted;
