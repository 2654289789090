import { TaskStatus } from "datamodel/task";

const parseStatusName = (status: TaskStatus): string =>
  status
    .split("_")
    .map((str, idx) => {
      const legend = str.toLocaleLowerCase();
      return idx === 0 ? legend.charAt(0).toUpperCase() + legend.slice(1) : legend;
    })
    .join(" ");

export default parseStatusName;
