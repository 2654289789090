import React from "react";
import { Box } from "@blasterjs/core";

const MapControlsContainer = ({ children }: { children: React.ReactNode[] }) => (
  <Box
    display="flex"
    position="absolute"
    right="1em"
    top="1em"
    zIndex="1"
    flexDirection="column"
    alignItems="flex-end"
  >
    {children}
  </Box>
);

export default MapControlsContainer;
