import React from "react";
import { Box, Card, Icon } from "@blasterjs/core";
import OverlayPage from "components/OverlayPage";

type Props = {
  errorMessage: string;
};

const WebGLNotDetected = ({ errorMessage }: Props) => (
  <OverlayPage alignItems="center" justifyContent="center">
    <Card boxShadow={2} borderColor="orange" textAlign="center" maxWidth="500px" m={2}>
      <Box>
        <Icon name="close" size="48px" color="orange" />
      </Box>
      <Box mt={4} fontSize={3}>
        Groundwork requires WebGL 2.0 to provide an optimal labeling experience.
      </Box>
      <Box mt={3} color="textLight">
        {errorMessage}
      </Box>
    </Card>
  </OverlayPage>
);

export default WebGLNotDetected;
