import { Option, none } from "fp-ts/es6/Option";
import sortBy from "lodash.sortby";

import { Task } from "datamodel/task";
import fetchRandomTask from "pages/helpers/fetchRandomTask";
import { TaskURLActionType } from "types";
import { Project } from "datamodel/project";
import { listHitlJobs } from "http/hitlJob";
import { HITLJobStatus } from "datamodel/hitlJob";
import fetchHitlTask from "pages/helpers/fetchHitlTask";
import { RandomTaskParams } from "http/task";
import { Campaign, CampaignType } from "datamodel/campaign";

const getNextTaskOption = async (
  urlActionType: TaskURLActionType,
  hitlEnabled: boolean,
  project: Project,
  campaign: Campaign,
  params: RandomTaskParams
) => {
  let nextTaskOption: Option<Task> = none;
  nextTaskOption = await fetchRandomTask(urlActionType, params);

  // if user can perform HITL,
  // project is not classification,
  // this is doing validation,
  // and there is a recently `RAN` job, fetch task by priority score
  if (
    urlActionType === TaskURLActionType.Validate &&
    hitlEnabled &&
    campaign.campaignType !== CampaignType.Classification
  ) {
    const { data: jobs } = await listHitlJobs({
      campaignId: project.campaignId,
      projectId: project.id,
    });
    if (jobs && !!jobs.count) {
      const sorted = sortBy(jobs.results, "version");
      const lastJob = sorted[sorted.length - 1];
      if (lastJob.status === HITLJobStatus.Ran) {
        nextTaskOption = await fetchHitlTask(urlActionType, {
          projectId: project.id,
          campaignId: project.campaignId,
        });
      }
    }
  }
  return nextTaskOption;
};

export default getNextTaskOption;
