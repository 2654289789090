import { MapController } from "deck.gl";

const EVENT_TYPES = {
  WHEEL: ["wheel"],
  PAN: ["panstart", "panmove", "panend"],
  PINCH: ["pinchstart", "pinchmove", "pinchend"],
  DOUBLE_TAP: ["doubletap"],
  KEYBOARD: ["keydown"],
};

export default class DrawingMapController extends MapController {
  overrideDragPan = false;
  dragRotate = false;
  dragPan = false;

  constructor(ControllerState, options) {
    super(ControllerState, options);
    this.overrideDragPan = false;
  }
  // Override control key - it's used to allow panning instead of rotating
  isFunctionKeyPressed(event) {
    const { srcEvent } = event;
    return Boolean(srcEvent.altKey || srcEvent.shiftKey);
  }
  setProps(props) {
    super.setProps(props);
    this.toggleEvents(EVENT_TYPES.PAN, true);
  }
  /* Event handlers */
  // Handler for the `panstart` event.
  _onPanStart(event) {
    const { srcEvent } = event;
    // Only pan if a modifier key is being held
    if (Boolean(srcEvent.ctrlKey || srcEvent.metaKey) || this.isFunctionKeyPressed(event)) {
      this.overrideDragPan = true;
    } else {
      this.overrideDragPan = false;
    }
    return super._onPanStart(event);
  }
  _onPanMove(event) {
    // TODO: figure out how to pass information into the controller so it
    //       knows when the pan tool is activated. Currently we can't seem to
    //       do it without hitting a state bug
    if (!this.overrideDragPan) {
      return false;
    }
    const pos = this.getCenter(event);
    const newControllerState = this.controllerState.pan({ pos });
    this.updateViewport(
      newControllerState,
      { transitionDuration: 0 },
      {
        isDragging: true,
        isPanning: true,
      }
    );
    return true;
  }
  _onRotateStart() {
    return false;
  }
  _onPanRotate() {
    return false;
  }
}
