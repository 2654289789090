import * as MW from "magic-wand-tool";
import polygonClipping from "polygon-clipping";
import { some, none } from "fp-ts/es6/Option";

import { foldOption } from "lib";
import wandResultToGeoJSON from "./wandResultToGeoJSON";
import { Task } from "datamodel/task";

const computeWandResult = (
  position: [number, number],
  task: Task,
  deck: any,
  map: any,
  tolerance: number,
  blurRadius: number,
  simplifyTolerance: number,
  simplifyCount: number
) => {
  const viewport = deck.viewports[0];
  const canvas: HTMLCanvasElement = map.getCanvas();
  const tempCanvas = document.createElement("canvas");
  const tempCtx = tempCanvas.getContext("2d");

  if (tempCtx) {
    tempCtx.canvas.width = canvas.width;
    tempCtx.canvas.height = canvas.height;
    tempCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height);

    return foldOption(
      wandResultToGeoJSON(
        MW.simplifyContours(
          MW.traceContours(
            MW.gaussBlurOnlyBorder(
              MW.floodFill(
                {
                  data: tempCtx.getImageData(0, 0, canvas.width, canvas.height).data,
                  width: canvas.width,
                  height: canvas.height,
                  bytes: 4,
                },
                Math.floor(position[0] * window.devicePixelRatio),
                Math.floor(position[1] * window.devicePixelRatio),
                tolerance,
                null,
                true
              ),
              blurRadius
            )
          ),
          simplifyTolerance,
          simplifyCount
        ),
        viewport.unproject,
        24
      ),
      () => none,
      (feature) => {
        if (feature.geometry.coordinates[0].length) {
          return some({
            ...feature,
            geometry: {
              ...feature.geometry,
              coordinates: polygonClipping.intersection(
                task.geometry.coordinates,
                feature.geometry.coordinates
              ),
            },
          });
        }
        return none;
      }
    );
  }
  return none;
};

export default computeWandResult;
