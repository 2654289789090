import { getUserLimits } from "http/limits";
import { some, map, fold } from "fp-ts/es6/Option";
import { pipe } from "fp-ts/es6/pipeable";
import { identity } from "fp-ts/es6/function";

import pickLimit from "pages/helpers/pickLimit";
import { LimitDomain } from "datamodel/limits";

const exceedStorageLimitPromise = async (size: number) => {
  const { data: limit } = await getUserLimits();
  return pipe(
    pickLimit(some(limit), LimitDomain.Storage, "create", ""),
    map((lim) => lim.used + size > lim.limit),
    fold(() => false, identity)
  );
};

export default exceedStorageLimitPromise;
