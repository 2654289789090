import GoogleTagManager from "@redux-beacon/google-tag-manager";
import { LOCATION_CHANGE } from "connected-react-router";
import { createMiddleware, EventsMap, EventDefinition } from "redux-beacon";
import { TrackingActionTypes, trackingActionLabels } from "state/tracking/actions";

// To add new tracking events, in state/tracking/actions.ts:
// - add an entry to the `TrackingActionTypes` enum
// - add an entry to the `trackingActionLabels` object
// The label is sent as the `hitType` of the GTM event

// In spots that you want to emit a tracking event do something like:
// `dispatch(createTrackingAction(TrackingActionTypes.LabelingTaskConfirmed))`

const gtm = GoogleTagManager();

const locationChangeEvent: EventDefinition = () => ({
  hitType: "Location change",
});

const trackingEvent: EventDefinition = (action) => ({
  hitType: trackingActionLabels[action.type],
  payload: action.payload,
});

// This results in an object of redux actions each mapped to the `trackingEvent`
// definition so that as new events are added they don't need to be manually
// added to the `eventsMap`
const trackingEventsMap = Object.values(TrackingActionTypes).reduce(
  (acc, actionType) => ({ ...acc, [actionType]: trackingEvent }),
  {}
);

const eventsMap: EventsMap = {
  ...trackingEventsMap,
  [LOCATION_CHANGE]: locationChangeEvent,
};

export default createMiddleware(eventsMap, gtm);
