import { SpaceNetProjectConfig } from "components/SpaceNetImageryModal/types";
import config from "config";

export const projects: SpaceNetProjectConfig[] = [
  {
    id: "0",
    aoi: {
      type: "Polygon",
      coordinates: [
        [
          [4.374715089797973, 51.92051594285549],
          [4.374704360961914, 51.91737935008505],
          [4.375176429748535, 51.91735288001532],
          [4.374790191650391, 51.911052559562634],
          [4.374146461486816, 51.911052559562634],
          [4.37406063079834, 51.908140348039346],
          [4.367022514343262, 51.9081932990253],
          [4.3670654296875, 51.91070839895001],
          [4.3674516677856445, 51.91070839895001],
          [4.367580413818359, 51.91613523993714],
          [4.367022514343262, 51.91613523993714],
          [4.3670654296875, 51.920449772275056],
          [4.364748001098633, 51.92058211333842],
          [4.331982135772705, 51.92070121996187],
          [4.331767559051514, 51.920621815581356],
          [4.331767559051514, 51.92035713329856],
          [4.3294501304626465, 51.92035713329856],
          [4.329428672790527, 51.915817589268464],
          [4.327433109283447, 51.915830824757855],
          [4.327433109283447, 51.91309099526836],
          [4.330265522003174, 51.91302481374426],
          [4.330308437347412, 51.91074810992453],
          [4.333999156951904, 51.9107216359454],
          [4.3340206146240225, 51.90919935589747],
          [4.334900379180908, 51.90919935589747],
          [4.334771633148193, 51.90374519857575],
          [4.331681728363037, 51.903758437623516],
          [4.331681728363037, 51.90361280788357],
          [4.319515228271484, 51.90374519857575],
          [4.31962251663208, 51.906379692208475],
          [4.316446781158447, 51.90639293047973],
          [4.3166184425354, 51.911304059880834],
          [4.310030937194824, 51.91131729670068],
          [4.310009479522705, 51.90610168761015],
          [4.313013553619385, 51.90608844925302],
          [4.3129706382751465, 51.901163509696836],
          [4.3320894241333, 51.9010708309271],
          [4.33211088180542, 51.900422074185386],
          [4.333226680755615, 51.90039559411938],
          [4.333248138427734, 51.89638368381369],
          [4.333376884460449, 51.89635720136738],
          [4.333376884460449, 51.896185065086065],
          [4.331338405609131, 51.89621154763379],
          [4.331338405609131, 51.893391068610626],
          [4.334192276000977, 51.89332485805614],
          [4.334192276000977, 51.8930335304574],
          [4.334750175476074, 51.8930335304574],
          [4.334771633148193, 51.89181523094459],
          [4.334235191345215, 51.89181523094459],
          [4.334149360656738, 51.89128552520214],
          [4.334127902984619, 51.88879582457737],
          [4.331252574920654, 51.88880906803067],
          [4.331252574920654, 51.88827932685468],
          [4.3297505378723145, 51.88827932685468],
          [4.3297505378723145, 51.886570869023444],
          [4.326446056365967, 51.88659735723736],
          [4.326446056365967, 51.88363058024516],
          [4.330136775970458, 51.88364382522044],
          [4.330136775970458, 51.88333918980169],
          [4.32910680770874, 51.88332594473664],
          [4.32910680770874, 51.88100799825453],
          [4.341466426849365, 51.880796065755774],
          [4.341466426849365, 51.879325756537455],
          [4.340543746948242, 51.87931251028993],
          [4.340500831604004, 51.876173039551816],
          [4.333655834197998, 51.87623927537532],
          [4.331166744232178, 51.87615979237539],
          [4.331166744232178, 51.874411130830175],
          [4.314708709716797, 51.874437626209456],
          [4.314687252044678, 51.86949596792441],
          [4.3302977085113525, 51.8694495959051],
          [4.330286979675293, 51.8687407605159],
          [4.330013394355773, 51.868737448174784],
          [4.330008029937744, 51.86719055821097],
          [4.330013394355773, 51.86641543702228],
          [4.333877116441726, 51.866365749311036],
          [4.333867728710175, 51.864996003125434],
          [4.333874434232712, 51.86487509245025],
          [4.334499388933182, 51.86487343613734],
          [4.334499388933182, 51.864830371981185],
          [4.333901256322861, 51.864830371981185],
          [4.333899915218353, 51.86479227673174],
          [4.333697408437729, 51.864788964099844],
          [4.3336719274520865, 51.858885466473915],
          [4.334707260131836, 51.8588788403399],
          [4.334691166877747, 51.854694241815885],
          [4.336225390434265, 51.854684301688614],
          [4.336209297180176, 51.85402162158601],
          [4.3364399671554565, 51.85401168131017],
          [4.336445331573485, 51.853935472455845],
          [4.338778853416443, 51.853925532161],
          [4.338768124580382, 51.85334899130222],
          [4.397578239440918, 51.85321645212954],
          [4.397610425949096, 51.85388577095961],
          [4.4015586376190186, 51.85387251721801],
          [4.401590824127197, 51.85460809397341],
          [4.402856826782227, 51.85460809397341],
          [4.402974843978881, 51.85844482643643],
          [4.40301775932312, 51.85877613513782],
          [4.403146505355835, 51.85877613513782],
          [4.4033074378967285, 51.86379185080668],
          [4.402974843978881, 51.86378522539526],
          [4.402996301651, 51.86410986940698],
          [4.404401779174805, 51.864103244042404],
          [4.404584169387817, 51.869376725492444],
          [4.4045090675354, 51.869376725492444],
          [4.4045090675354, 51.8695224661997],
          [4.404122829437256, 51.8695224661997],
          [4.40426230430603, 51.87347053475168],
          [4.404058456420898, 51.87347715873651],
          [4.404069185256958, 51.87355664647842],
          [4.402438402175903, 51.87356989442174],
          [4.402449131011963, 51.873947459165805],
          [4.403564929962158, 51.873954083080406],
          [4.403564929962158, 51.87409318506122],
          [4.40427303314209, 51.87409980895434],
          [4.404444694519043, 51.87925290212767],
          [4.40426230430603, 51.87925290212767],
          [4.4043803215026855, 51.88296170391877],
          [4.404723644256592, 51.88296170391877],
          [4.404863119125366, 51.882981571675835],
          [4.405034780502319, 51.888027697608756],
          [4.404927492141723, 51.88803431944905],
          [4.404970407485962, 51.88872960725233],
          [4.404734373092651, 51.88873622898922],
          [4.404723644256592, 51.88878920284926],
          [4.4046592712402335, 51.88878920284926],
          [4.404734373092651, 51.89090148463458],
          [4.403243064880371, 51.89092134888235],
          [4.4032323360443115, 51.89113323364559],
          [4.405195713043213, 51.89111999087716],
          [4.405352622270584, 51.89667581726351],
          [4.403117001056671, 51.89668657568292],
          [4.4031257182359695, 51.89716449517637],
          [4.405283555388451, 51.8971409096583],
          [4.405407607555389, 51.90129590760765],
          [4.4042328000068665, 51.90130583743524],
          [4.4040074944496155, 51.901300872521695],
          [4.404028952121735, 51.90188010872956],
          [4.405474662780762, 51.90186024932608],
          [4.405745565891266, 51.910413874792894],
          [4.40570667386055, 51.910414702110664],
          [4.405763000249863, 51.91214955391309],
          [4.4058917462825775, 51.91214459019808],
          [4.405992329120636, 51.91544285785602],
          [4.405421018600463, 51.915450302881425],
          [4.405277520418166, 51.91544699398141],
          [4.405308365821838, 51.91643220819116],
          [4.405941367149353, 51.916428072156535],
          [4.406048655509949, 51.91972850662253],
          [4.4058313965797415, 51.9197334694992],
          [4.405836760997772, 51.92010733796283],
          [4.405426383018494, 51.92011560935379],
          [4.405426383018494, 51.92014704062563],
          [4.403999447822571, 51.920175163323904],
          [4.403999447822571, 51.92034720768259],
          [4.400952458381653, 51.92036044183673],
          [4.400957822799683, 51.92041999548202],
          [4.374715089797973, 51.92051594285549],
        ],
        [
          [4.37152862548828, 51.87076786752731],
          [4.37152862548828, 51.86965495734191],
          [4.371957778930664, 51.86960196093186],
          [4.3718719482421875, 51.86472602404717],
          [4.372601509094238, 51.864699522946],
          [4.37255859375, 51.862102339294395],
          [4.37929630279541, 51.861996328613316],
          [4.379382133483887, 51.8647525251327],
          [4.3801116943359375, 51.8647525251327],
          [4.3801116943359375, 51.86666056226118],
          [4.379510879516602, 51.866687062207],
          [4.379596710205078, 51.86883350596841],
          [4.379210472106934, 51.86883350596841],
          [4.379253387451172, 51.870741369985815],
          [4.37152862548828, 51.87076786752731],
        ],
      ],
    },
    name: "Rotterdam, the Netherlands",
    providers: ["Capella Space SAR", "WorldView-2"],
    resolution: "30cm",
    area: "120km",
    taskSizeMeters: 256,
    size: "3.2GB",
    tileLayers: [
      {
        name: "Optical",
        url: `${config.tiles}/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=s3://groundwork-production-data-us-east-1/spacenet/rotterdam_rgb_cog.tif&rescale=0,90`,
        default: true,
        overlay: false,
        source: "s3://groundwork-production-data-us-east-1/spacenet/rotterdam_rgb_cog.tif",
      },
    ],
  },
  {
    id: "1",
    aoi: {
      type: "Polygon",
      coordinates: [
        [
          [37.705936431884766, 55.79529845202839],
          [37.70602226257324, 55.74711534735536],
          [37.621307373046875, 55.746994573402674],
          [37.62216567993164, 55.61558902526749],
          [37.64963150024414, 55.61539513584262],
          [37.649803161621094, 55.60938408809366],
          [37.78249740600586, 55.60928712815964],
          [37.78249740600586, 55.79539495156544],
          [37.705936431884766, 55.79529845202839],
        ],
      ],
    },
    name: "Moscow, Russia",
    providers: ["WorldView-3"],
    resolution: "30cm",
    area: "1,353km",
    taskSizeMeters: 400,
    size: "87.9GB",
    tileLayers: [
      {
        name: "Optical",
        url: `${config.tiles}/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=s3://groundwork-production-data-us-east-1/spacenet/moscow_rgb_cog.tif&rescale=20,170`,
        default: true,
        overlay: false,
        source: "s3://groundwork-production-data-us-east-1/spacenet/moscow_rgb_cog.tif",
      },
    ],
  },
  {
    id: "2",
    aoi: {
      type: "Polygon",
      coordinates: [
        [
          [72.81729698181152, 19.114110314478513],
          [72.81736135482788, 19.108879365802096],
          [72.81186819076538, 19.108869228144012],
          [72.81187891960144, 19.077713271003066],
          [72.80638575553894, 19.077708201219615],
          [72.80639380216599, 19.05174127090582],
          [72.80089795589447, 19.051738735616983],
          [72.80090197920799, 19.020579646731456],
          [72.79540948569775, 19.02057774490797],
          [72.79540747404099, 18.994613925507505],
          [72.7899169921875, 18.994611389346954],
          [72.78990626335144, 18.963461888698628],
          [72.78440237045288, 18.96347203523756],
          [72.784423828125, 18.932271048407028],
          [72.78991296887398, 18.93226470563458],
          [72.7899169921875, 18.927074968513978],
          [72.79543161392212, 18.927062282574312],
          [72.79543161392212, 18.932263437080067],
          [72.80091404914856, 18.932258362861905],
          [72.80093014240265, 18.93745935558171],
          [72.80641794204712, 18.937454281521404],
          [72.80640184879303, 18.94266018631978],
          [72.81188428401947, 18.942657649368673],
          [72.81186819076538, 18.92711302632725],
          [72.80633211135864, 18.92709272882792],
          [72.80639111995697, 18.916687398685326],
          [72.80089795589447, 18.916689936030835],
          [72.80090197920799, 18.906290302165868],
          [72.79540881514549, 18.90628903341422],
          [72.79538869857788, 18.890695349102117],
          [72.80639514327048, 18.890695349102117],
          [72.80639380216599, 18.88550051528136],
          [72.81738549470901, 18.88550051528136],
          [72.8173865005374, 18.890695349102117],
          [72.82287999987602, 18.89069408023223],
          [72.82288402318954, 18.895890021754056],
          [72.82837718725204, 18.895890021754056],
          [72.82837316393852, 18.901088973968342],
          [72.83936083316803, 18.901088339572794],
          [72.83936083316803, 18.906282689655868],
          [72.84488081932068, 18.906266195882985],
          [72.84487009048462, 18.91147307232008],
          [72.85036325454712, 18.911462922621155],
          [72.85034582018852, 18.921871749546817],
          [72.85584300756454, 18.921871115230097],
          [72.8558886051178, 18.94782533882587],
          [72.86137104034424, 18.94782533882587],
          [72.8613817691803, 18.968585812272973],
          [72.86686420440674, 18.96861625095238],
          [72.86684274673462, 18.994598708543656],
          [72.89405107498169, 18.994588563900322],
          [72.89403632283211, 19.06453130992116],
          [72.8575086593628, 19.064551590666486],
          [72.85763740539551, 19.114110314478513],
          [72.81729698181152, 19.114110314478513],
        ],
      ],
    },
    name: "Mumbai, India",
    providers: ["WorldView-3"],
    resolution: "30cm",
    area: "1,021km",
    taskSizeMeters: 400,
    size: "22.5GB",
    tileLayers: [
      {
        name: "Optical",
        url: `${config.tiles}/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=s3://groundwork-production-data-us-east-1/spacenet/mumbai_rgb_cog.tif&rescale=10,30`,
        default: true,
        overlay: false,
        source: "s3://groundwork-production-data-us-east-1/spacenet/mumbai_rgb_cog.tif",
      },
    ],
  },
  {
    id: "3",
    aoi: {
      type: "Polygon",
      coordinates: [
        [
          [-66.02005362510681, 18.46920925689246],
          [-66.0199624300003, 18.385799840710387],
          [-65.97993850708008, 18.385799840710387],
          [-65.97993314266205, 18.469204168773874],
          [-66.02005362510681, 18.46920925689246],
        ],
      ],
    },
    name: "San Juan, Puerto Rico",
    providers: ["WorldView-3"],
    resolution: "30cm",
    area: "285km",
    taskSizeMeters: 400,
    size: "6.3GB",
    tileLayers: [
      {
        name: "Optical",
        url: `${config.tiles}/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=s3://groundwork-production-data-us-east-1/spacenet/san_juan_rgb_cog.tif&rescale=10,90`,
        default: true,
        overlay: false,
        source: "s3://groundwork-production-data-us-east-1/spacenet/san_juan_rgb_cog.tif",
      },
    ],
  },
  {
    id: "6",
    aoi: {
      type: "Polygon",
      coordinates: [
        [
          [-43.857421875, -22.76363756059097],
          [-43.857421875, -23.11525943457816],
          [-43.417840003967285, -23.11525943457816],
          [-43.417882919311516, -22.763597987964335],
          [-43.857421875, -22.76363756059097],
        ],
      ],
    },
    name: "Rio de Janeiro, Brazil",
    providers: ["WorldView-2"],
    resolution: "50cm",
    area: "2,544km",
    taskSizeMeters: 700,
    size: "5.3GB",
    tileLayers: [
      {
        name: "Optical",
        url: `${config.tiles}/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=s3://groundwork-production-data-us-east-1/spacenet/rio_rgb_cog.tif&rescale=0,30`,
        default: true,
        overlay: false,
        source: "s3://groundwork-production-data-us-east-1/spacenet/rio_rgb_cog.tif",
      },
    ],
  },
];

export default projects;
