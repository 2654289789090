import React, { useCallback, useEffect } from "react";
import { Box } from "@blasterjs/core";
import useDecodedQueryParam from "hooks/useDecodedQueryParam";
import { useHistory, useLocation } from "react-router";
import { fromEither, Option } from "fp-ts/es6/Option";
import { NumberFromString, UUID } from "io-ts-types";
import * as t from "io-ts";
import { useQuery } from "react-query";

import { TaskStatus } from "datamodel/task";
import CampaignProgressItem from "./CampaignProgressItem";
import ClassSummary from "./ClassSummary";
import SharingControls from "./SharingControls";
import TaskFlags from "./TaskFlags";
import config from "config";
import { UserFeatureFlag } from "components/FeatureFlags";
import AccessLimiter from "components/AccessControl/AccessLimiter";
import { ACRActionType } from "datamodel/permissions";
import { Stack, Text, Flex, Divider, Badge } from "@chakra-ui/react";
import { Campaign } from "datamodel/campaign";
import AccessControlProvider from "components/AccessControl/AccessControlProvider";
import useEntityOwner from "hooks/useEntityOwner";
import CampaignDetails from "./CampaignDetails";
import ImageList from "./ImageList";
import useLabelClassSummary from "hooks/useLabelClassSummary/useLabelClassSummary";
import useUploader from "components/Uploader/store";
import UploadItem from "components/Uploader/UploadItemContainer";
import { isActiveOrFailed, isWaiting } from "components/Uploader/helpers/filters";
import useDecodedQueryParamOrElse from "hooks/useDecodedQueryParamOrElse";
import { StatusFilter, statusFilterCodec } from "types";
import { foldOption } from "lib";
import { listProjects } from "http/campaign";
import { ProjectSort } from "http/project";

type Props = {
  campaign: Campaign;
};

export type QueryParams = Partial<{
  p: number;
  f: StatusFilter;
  s: Option<string>;
  name: string;
}>;

const statusMapping = {
  [StatusFilter.LabelingIncomplete]: [
    TaskStatus.Unlabeled,
    TaskStatus.LabelingInProgress,
  ] as TaskStatus[],
  [StatusFilter.Flagged]: [TaskStatus.Flagged] as TaskStatus[],
  [StatusFilter.ValidationIncomplete]: [
    TaskStatus.Unlabeled,
    TaskStatus.LabelingInProgress,
    TaskStatus.Labeled,
  ] as TaskStatus[],
} as const;

const PAGE_SIZE = 6;

const Overview = ({ campaign }: Props) => {
  const isOwner = useEntityOwner(campaign.owner);

  const history = useHistory();
  const location = useLocation();

  const page = useDecodedQueryParamOrElse("p", NumberFromString, 1);
  const statusFilter = useDecodedQueryParamOrElse("f", statusFilterCodec, StatusFilter.All);
  const selectedProjectOption = fromEither(useDecodedQueryParam("s", UUID));
  const nameFilter = useDecodedQueryParamOrElse("name", t.string, "");

  const updateQueryParams = useCallback(
    ({ p = page, f = statusFilter, s = selectedProjectOption, name = nameFilter }: QueryParams) => {
      const path = foldOption(
        s,
        () => `${location.pathname}?p=${p}&f=${f}&name=${name}`,
        (uuid) => `${location.pathname}?p=${p}&f=${f}&s=${uuid}&name=${name}`
      );
      history.push(path);
    },
    [history, location.pathname, page, statusFilter, selectedProjectOption, nameFilter]
  );

  const extraRequestParams =
    statusFilter !== StatusFilter.All
      ? {
          taskStatusesInclude: statusMapping[statusFilter],
        }
      : {};

  const searchParams = nameFilter
    ? {
        search: nameFilter.trim(),
      }
    : {};

  const { data: projects, isLoading } = useQuery(
    ["campaign-projects", campaign.id, statusFilter, page, nameFilter],
    () =>
      listProjects(campaign.id, {
        pageSize: PAGE_SIZE,
        page,
        sort: ProjectSort.NewestFirst,
        ...extraRequestParams,
        isActive: true,
        ...searchParams,
      }),
    {
      keepPreviousData: true,
      refetchInterval: 30000,
    }
  );

  const maxPage = projects && Math.ceil(projects.data.count / projects.data.pageSize);
  const projectCount = projects?.data.count;

  useEffect(() => {
    if (maxPage && page > maxPage) {
      updateQueryParams({ p: maxPage });
    }
  }, [maxPage, page, updateQueryParams]);

  const { summary, loadingSummary } = useLabelClassSummary(campaign, undefined, projectCount);

  const activeUploads = useUploader((state) =>
    state.sequences.filter((s) => s.campaignId === campaign.id).filter(isActiveOrFailed)
  );
  const numWaitingUploads = activeUploads.filter(isWaiting).length;

  return (
    <Box
      m="0 auto"
      maxWidth="1174px"
      alignItems={"flex-start"}
      width="100%"
      display="flex"
      flex="1"
      px={2}
      pt="26px"
      pb={4}
    >
      <Flex
        maxWidth={"100%"}
        p={0}
        flex={1}
        alignSelf="stretch"
        direction="column"
        mr="38px"
        alignItems="stretch"
        justifyContent="flex-start"
      >
        <ImageList
          campaign={campaign}
          isOwner={isOwner}
          projects={projects}
          page={page}
          maxPage={maxPage}
          isLoading={isLoading}
          statusFilter={statusFilter}
          nameFilter={nameFilter}
          updateQueryParams={updateQueryParams}
          selectedProjectOption={selectedProjectOption}
        />
      </Flex>
      <Stack
        direction="column"
        divider={<Divider color="#f7f8f7" size="2px" height="2px" />}
        spacing={6}
        width="350px"
      >
        <Stack direction="column" data-intercom-target="project-progress" spacing={8}>
          <Text textStyle="campaignOverviewSectionHeading">Progress</Text>
          <Stack direction="column" spacing={2}>
            <CampaignProgressItem
              label="labeled"
              colorScheme="gray"
              statuses={[
                TaskStatus.Labeled,
                TaskStatus.Flagged,
                TaskStatus.ValidationInProgress,
                TaskStatus.Validated,
              ]}
              excludeStatuses={[TaskStatus.Invalid, TaskStatus.Split]}
              campaign={campaign}
            />
            <UserFeatureFlag featureName="validation">
              <AccessControlProvider campaignId={campaign.id}>
                <AccessLimiter requiredActions={[[ACRActionType.Validate]]}>
                  {(isLoading) => (
                    <Box mt={1}>
                      <CampaignProgressItem
                        label="validated"
                        colorScheme="blue"
                        statuses={[TaskStatus.Validated]}
                        excludeStatuses={[TaskStatus.Invalid, TaskStatus.Split]}
                        campaign={campaign}
                        showSkeleton={isLoading}
                      />
                    </Box>
                  )}
                </AccessLimiter>
              </AccessControlProvider>
            </UserFeatureFlag>
            {config.featureFlags.taskFlagging && <TaskFlags campaign={campaign} />}
          </Stack>
        </Stack>
        <Stack direction="column" data-intercom-target="project-classifications" spacing={8}>
          <Text textStyle="campaignOverviewSectionHeading">Classifications</Text>
          <Stack direction="column" spacing={1}>
            <ClassSummary type={campaign.campaignType} summary={summary} loading={loadingSummary} />
          </Stack>
        </Stack>
        <Stack direction="column" spacing={8} data-intercom-target="project-details">
          <Text textStyle="campaignOverviewSectionHeading">Details</Text>
          {!isOwner && (
            <SharingControls data-intercom-target="project-sharing-controls" campaign={campaign} />
          )}
          <Stack direction="column" spacing={1}>
            <CampaignDetails campaign={campaign} />
          </Stack>
        </Stack>
        {isOwner && (
          <Stack direction="column" spacing={8} data-intercom-target="project-uploads">
            <Stack direction="row" alignItems="center" spacing={6}>
              <Text textStyle="campaignOverviewSectionHeading">Uploads</Text>
              {!!activeUploads.length && (
                <Badge
                  borderRadius="99px"
                  px={3}
                  fontSize="13px"
                  fontWeight="semibold"
                  color="textLight"
                >
                  {activeUploads.length}
                </Badge>
              )}
            </Stack>
            <Stack
              direction="column"
              spacing={4}
              divider={<Divider orientation="horizontal" color="gray.200" />}
            >
              {activeUploads.length ? (
                <>
                  {activeUploads.map((s) => (
                    <UploadItem sequence={s} key={s.id} />
                  ))}
                </>
              ) : (
                <>No active uploads</>
              )}
            </Stack>
            {numWaitingUploads > 0 && <Text>Plus {numWaitingUploads} more...</Text>}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default Overview;
