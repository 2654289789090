import { AxiosResponse } from "axios";
import { TypeC, Type, TypeOf } from "io-ts";
import { PaginatedResponse } from "types";
import { decoderFor, decodeT } from "./base";

export const decoder =
  <T extends {}>(c: TypeC<any>) =>
  (resp: AxiosResponse<PaginatedResponse<any>>): AxiosResponse<PaginatedResponse<T>> => ({
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(decodeT<T>(c)),
    },
  });

export function decodePaginated<A = any, O = A, I = unknown>(c: Type<A, O, I>) {
  return (resp: AxiosResponse<any>): AxiosResponse<PaginatedResponse<TypeOf<typeof c>>> => ({
    ...resp,
    data: {
      ...resp.data,
      results: resp.data.results.map(decoderFor(c)),
    },
  });
}
