import { TaskStatus } from "datamodel/task";

const getTotalTaskCountFromSummary = (
  summary: Record<TaskStatus, number>,
  excludedStatuses: TaskStatus[]
): number =>
  Object.entries(summary).reduce(
    (acc, [k, v]) => acc + (!excludedStatuses.includes(k as TaskStatus) ? v : 0),
    0
  );

export default getTotalTaskCountFromSummary;
