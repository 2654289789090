import { Option } from "fp-ts/es6/Option";
import { Auth0Client } from "@auth0/auth0-spa-js";
import {
  SetClientAction,
  AuthActionTypes,
  SetIsLoadingAction,
  SetUserAction,
  SetIsAuthenticatedAction,
  SetIdTokenAction,
} from "./reducer";

export const createSetClient = (payload: Option<Auth0Client>): SetClientAction => ({
  type: AuthActionTypes.SetClient,
  payload,
});

export const createSetIsLoading = (payload: boolean): SetIsLoadingAction => ({
  type: AuthActionTypes.SetIsLoading,
  payload,
});

export const createSetIsAuthenticated = (payload: boolean): SetIsAuthenticatedAction => ({
  type: AuthActionTypes.SetIsAuthenticated,
  payload,
});

export const createSetUser = (payload: Option<any>): SetUserAction => ({
  type: AuthActionTypes.SetUser,
  payload,
});

export const createSetIdToken = (payload: Option<string>): SetIdTokenAction => ({
  type: AuthActionTypes.SetIdToken,
  payload,
});
