import polygonClipping from "polygon-clipping";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { DrawingActionType, SemanticLabelFeatureCollection } from "types";
import { isNotNullOrUndefined, foldOption } from "lib";
import DrawingAction from "common/modules/DrawingAction";

// Given an action, a list of other actions, and some options, generate a new
// action list that incorporates the action accordingly
const apply = (action: DrawingAction, prevActions: DrawingAction[]): DrawingAction[] => {
  const erase =
    action.actionType === DrawingActionType.Erase || action.actionType === DrawingActionType.Clear;
  // This reduce function builds up a list of `DrawingAction`s that have the
  // current `action` applied. It returns a tuple where the first element is the
  // built-up list and the second element is either a `DrawingAction` that should
  // be appended or `null`, which is then filtered out.
  const [actions, tail] = prevActions.reduce(
    (acc: [DrawingAction[], DrawingAction | null], a) => {
      // We only use the second element of the tuple after the reduce is complete
      const builtUp = acc[0];
      const matchingLabels = a.label === action.label;

      if (action.actionType === DrawingActionType.Erase) {
        // Erase should only affect drawing actions that have the same label
        if (matchingLabels) {
          return [
            [
              ...builtUp,
              // Rather than combine the results of the difference calc into a
              // single drawing action, we create a new drawing action for each
              // one. This allows us to target the resulting shapes of the difference
              // calculation individually when doing things like deleting and
              // re-classifying.
              // Each resulting drawing action retains the same id as it's
              // source drawing action.
              ...polygonClipping.difference(a.coordinates, action.coordinates).map((c) =>
                a.clone({
                  geoJSON: {
                    ...a.geoJSON,
                    geometry: {
                      ...a.geoJSON.geometry,
                      coordinates: [c],
                    },
                  },
                })
              ),
            ],
            null,
          ];
        } else {
          // If the labels don't match, pass the drawing action through
          return [[...builtUp, a], null];
        }
      } else if (action.actionType === DrawingActionType.Clear) {
        return [[], null];
      }
      // Both Delete and Reclassify drawing actions have the additional `target`
      // property which is a geoJSON point representing where the user's click
      // was.
      if (action.actionType === DrawingActionType.Delete) {
        // If the mouse click was on the current drawing action shape, remove
        // it from the list of actions, effectively deleting it
        return foldOption(
          action.targetOption,
          () => [[...builtUp, a], null],
          (target) =>
            booleanPointInPolygon(target, a.geoJSON) && action.geoJSON.id === a.geoJSON.id
              ? [builtUp, null]
              : [[...builtUp, a], null]
        );
      }
      if (action.actionType === DrawingActionType.Reclassify) {
        // If the mouse click was on the current drawing action shape, replace
        // it with the incoming drawing action which is essentially a copy
        // with the desired class
        return foldOption(
          action.targetOption,
          () => [[...builtUp, a], null],
          (target) => {
            return booleanPointInPolygon(target, a.geoJSON) && action.geoJSON.id === a.geoJSON.id
              ? [[...builtUp, action.clone({ actionType: a.actionType })], null]
              : [[...builtUp, a], null];
          }
        );
      }
      return [[...builtUp, a], action];
    },
    [[], erase ? null : action]
  );
  return [...actions, tail].filter(isNotNullOrUndefined);
};

export const merge = (actions: DrawingAction[]) => {
  return actions
    .reduce((acc: DrawingAction[], action: DrawingAction) => apply(action, acc), [])
    .filter((a: DrawingAction) => a.geoJSON.geometry.coordinates.length);
};

export const transform = (actions: DrawingAction[]): SemanticLabelFeatureCollection => ({
  type: "FeatureCollection",
  features: actions.map((a: DrawingAction) => a.toSemanticLabelFeature()),
});
