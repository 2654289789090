import createAuth0Client from "@auth0/auth0-spa-js";
import type { Auth0Client } from "@auth0/auth0-spa-js";
import config from "config";
import { Dispatch } from "redux";
import {
  createSetClient,
  createSetUser,
  createSetIsAuthenticated,
  createSetIsLoading,
  createSetIdToken,
} from "./actions";
import { some, none } from "fp-ts/es6/Option";
import { replace } from "connected-react-router";

const AUTH_TARGET_PATH = "authTargetPath";

const proUserClaim = "https://app.rasterfoundry.com;groundworkProUser";

export const createClient = async (dispatch: Dispatch) => {
  const auth0Client = await createAuth0Client({
    domain: config.authentication.domain,
    client_id: config.authentication.clientID,
    redirect_uri: config.authentication.redirectUri,
    audience: config.authentication.audience,
  });

  dispatch(createSetClient(some(auth0Client)));

  return auth0Client;
};

export const handleRedirectCallback = async (client: Auth0Client, dispatch: Dispatch) => {
  await client.handleRedirectCallback();
  const user = await client.getUser();
  const claims = await client.getIdTokenClaims();
  const nextPath = window.localStorage.getItem(AUTH_TARGET_PATH) || config.afterLoginRoute;
  window.localStorage.removeItem(AUTH_TARGET_PATH);
  if (user !== undefined) {
    dispatch(
      createSetUser(
        some({
          ...user,
          ...{
            groundworkProUser: user[proUserClaim] || false,
          },
        })
      )
    );
  }
  dispatch(createSetIdToken(some(claims.__raw)));
  dispatch(createSetIsAuthenticated(true));
  dispatch(replace(nextPath));
};

export const handleExistingSession = async (client: Auth0Client, dispatch: Dispatch) => {
  const isAuthenticated = await client.isAuthenticated();
  if (isAuthenticated) {
    const user = await client.getUser();
    const claims = await client.getIdTokenClaims();
    if (user !== undefined) {
      dispatch(
        createSetUser(some({ ...user, ...{ groundworkProUser: claims[proUserClaim] || false } }))
      );
    }

    dispatch(createSetIdToken(some(claims.__raw)));
  } else {
    dispatch(createSetUser(none));
  }
  dispatch(createSetIsAuthenticated(isAuthenticated));
};

export const initializeAuth0Client = async (dispatch: Dispatch) => {
  const auth0Client = await createClient(dispatch);

  if (window.location.search.includes("code=")) {
    await handleRedirectCallback(auth0Client, dispatch);
  } else {
    await handleExistingSession(auth0Client, dispatch);
  }
  dispatch(createSetIsLoading(false));
};

export const saveTargetPath = () => {
  const path = window.location.pathname.replace("/app", "");
  if (path !== "/login") {
    window.localStorage.setItem(AUTH_TARGET_PATH, path);
  }
};
