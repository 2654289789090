import { HStack, Button, Icon, Skeleton } from "@chakra-ui/react";
import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Paginated } from "types";

type Props = {
  isLoading?: boolean;
  pageObject?: Paginated;
  pageNeighbors?: number;
  onChange: (p: number) => void;
};

type InnerProps = {
  pageObject: Paginated;
  pageNeighbors?: number;
  onChange: (p: number) => void;
};

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

type PaginationDisplay = {
  pageArray: (number | null)[];
  lowerBoundarySize: number;
  upperBoundarySize: number;
};

const getUsedSlots = (pd: PaginationDisplay) =>
  pd.pageArray.length + +!!pd.lowerBoundarySize + +!!pd.upperBoundarySize;

const getPaginationDisplay = (pageObject: Paginated, pageNeighbors: number) => {
  const display: PaginationDisplay = {
    pageArray: [],
    lowerBoundarySize: 0,
    upperBoundarySize: 0,
  };

  const pageCount = Math.ceil(pageObject.count / pageObject.pageSize);
  const allPages = range(1, pageCount);
  const maxSlots = 5 + 2 * pageNeighbors;

  if (pageCount <= maxSlots) {
    display.pageArray = allPages;
  } else {
    allPages.forEach((p) => {
      if (
        p === 1 ||
        p === pageCount ||
        (p >= pageObject.page - pageNeighbors && p <= pageObject.page + pageNeighbors)
      ) {
        display.pageArray.push(p);
      } else if (p < pageObject.page) {
        display.lowerBoundarySize += 1;
      } else {
        display.upperBoundarySize += 1;
      }
    });

    let usedSlots = getUsedSlots(display);
    let radius = pageNeighbors;

    while (usedSlots < maxSlots) {
      if (display.lowerBoundarySize) {
        display.pageArray = [...display.pageArray, pageObject.page - radius - 1];
        display.lowerBoundarySize -= 1;
        usedSlots = getUsedSlots(display);
      }

      if (usedSlots < maxSlots && display.upperBoundarySize) {
        display.pageArray = [...display.pageArray, pageObject.page + radius + 1];
        display.upperBoundarySize -= 1;
        usedSlots = getUsedSlots(display);
      }

      radius += 1;
    }

    if (display.lowerBoundarySize === 1) {
      display.pageArray.push(1);
      display.lowerBoundarySize -= 1;
    }

    if (display.upperBoundarySize === 1) {
      display.pageArray.push(pageCount - 2);
      display.upperBoundarySize -= 1;
    }

    // We know we have pageArray is a number[] at this point since we only insert nulls later
    display.pageArray = display.pageArray.sort((a, b) => (a as number) - (b as number));

    if (display.lowerBoundarySize) {
      display.pageArray.splice(1, 0, null);
    }

    if (display.upperBoundarySize) {
      display.pageArray.splice(display.pageArray.length - 1, 0, null);
    }
  }

  return display.pageArray;
};

const InnerPaginationControls = ({ pageObject, pageNeighbors = 1, onChange }: InnerProps) => {
  const paginationDisplay = getPaginationDisplay(pageObject, pageNeighbors);

  return (
    <HStack spacing={4} align="center">
      <Button
        colorScheme="gray"
        aria-label="previous-page"
        onClick={() => onChange(pageObject.page - 1)}
        disabled={!pageObject.hasPrevious}
        leftIcon={<Icon as={FaAngleLeft} />}
        size="lg"
        fontSize="14px"
        variant="ghost"
      >
        Prev
      </Button>
      {paginationDisplay.map((page, i) =>
        page !== null ? (
          <Button
            key={i}
            variant={pageObject.page === page ? "solid" : "outline"}
            colorScheme={pageObject.page === page ? "blue" : "gray"}
            onClick={() => onChange(page)}
          >
            {page}
          </Button>
        ) : (
          <Button variant="ghost" colorScheme="gray" pointerEvents="none" color="gray.600" key={i}>
            ...
          </Button>
        )
      )}
      <Button
        colorScheme="gray"
        aria-label="next-page"
        onClick={() => onChange(pageObject.page + 1)}
        disabled={!pageObject.hasNext}
        rightIcon={<Icon as={FaAngleRight} />}
        size="lg"
        fontSize="14px"
        variant="ghost"
        color="gray.600"
      >
        Next
      </Button>
    </HStack>
  );
};

const PaginationControls = ({ isLoading, pageObject, ...rest }: Props) => {
  if (isLoading || !pageObject) {
    return <Skeleton width="150px" />;
  }
  return <InnerPaginationControls pageObject={pageObject} {...rest} />;
};

export default PaginationControls;
