import { foldOption } from "lib";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ApplicationStore } from "types";

function useEntityOwner(value: string | undefined) {
  const userOption = useSelector((state: ApplicationStore) => state.newAuth.user);
  const isOwner = useMemo(
    () =>
      foldOption(
        userOption,
        () => false,
        (user) => user.sub === value
      ),
    [userOption, value]
  );

  return isOwner;
}
export default useEntityOwner;
