import React, { useEffect, useMemo, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { _MapContext as MapContext, StaticMap, NavigationControl } from "react-map-gl";
import { DeckGL, GeoJsonLayer } from "deck.gl";
import { Box, Divider } from "@blasterjs/core";
import * as d3c from "d3-color";
import { some } from "fp-ts/es6/Option";

import {
  DrawPolygonHandler,
  DrawingMapController,
  createCursor,
  createDetectionDrawLayer,
  generateViewport,
  maybeUpdateLayer,
  createLayerProps,
  generateMapStyle,
} from "../helpers";
import { ApplicationStore, TaskUIMode, LayerPickerSection, TaskGeoJsonLayerType } from "types";
import { currentTaskLayerConfig, taskMaskLayerConfig } from "../helpers/layerConfigDefaults";
import {
  createSetDisplayBuffer,
  createSetLayerPickerConfig,
  createSetActionBufferCache,
} from "state/ui-segmentation/actions";
import { foldOption, seqOption } from "lib";
import LoadingIcon from "components/LoadingIcon";
import LabelingGeoJsonLayer from "../helpers/LabelingGeoJsonLayer";
import MapControlsContainer from "../MapControlsContainer";
import SemanticLayerToggle from "components/map/control/SemanticLayerToggle/SemanticLayerToggle";
import setLayerPickerConfig from "../helpers/setLayerPickerConfig";
import DetectionDeleteMenu from "../ContextMenu/DetectionDeleteMenu";
import DetectionReclassifyMenu from "../ContextMenu/DetectionReclassifyMenu";
import ContextMenu from "../ContextMenu";
import { merge } from "pages/SegmentationTask/helpers/transformDetectionDrawingActions";
import { transform } from "pages/SegmentationTask/helpers/transformDetectionDrawingActions";
import HiddenClassMessage from "../HiddenClassMessage";

const DEFAULT_HIGHLIGHT_COLOR = "#ececec";
const currentTaskLayerId = "current-task";
const taskMaskLayerId = "task-mask";

const getHighlightColor = (colorHex: string, alpha: number): number[] => {
  const c = d3c.rgb(colorHex);
  return [c.r, c.g, c.b, alpha];
};

const DetectionMap = () => {
  const dispatch = useDispatch();
  const [
    selectedClassOption,
    activeDrawingTypeOption,
    projectOption,
    taskOption,
    displayBuffer,
    actionBuffer,
    actionBufferCache,
    mode,
    imageryLayersConfig,
    basemapLayersConfig,
    vectorLayersConfig,
    overlayLayersConfig,
    drawingInProgress,
    hiddenClassIds,
    idTokenOption,
  ] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.selectedClass,
        state.segmentationUI.activeDrawType,
        state.segmentationUI.project,
        state.segmentationUI.task,
        state.segmentationUI.displayBuffer,
        state.segmentationUI.actionBuffer,
        state.segmentationUI.actionBufferCache,
        state.segmentationUI.mode,
        state.segmentationUI.imageryLayersConfig,
        state.segmentationUI.basemapLayersConfig,
        state.segmentationUI.vectorLayersConfig,
        state.segmentationUI.overlayLayersConfig,
        state.segmentationUI.drawingInProgress,
        state.segmentationUI.hiddenClassIds,
        state.newAuth.idToken,
      ] as const
  );

  const [isRightClick, setIsRightClick] = useState(false);
  const [clickedLabelIdx]: [number, Function] = useState(-1);
  const [highlightColor]: [string, Function] = useState(DEFAULT_HIGHLIGHT_COLOR);

  const deckRef = useRef<any>();

  useEffect(() => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Vector,
        vectorLayersConfig.length
          ? vectorLayersConfig
          : [currentTaskLayerConfig(currentTaskLayerId), taskMaskLayerConfig(taskMaskLayerId)]
      )
    );
  }, [vectorLayersConfig, dispatch]);

  useEffect(() => {
    if (!overlayLayersConfig.length) {
      setLayerPickerConfig(projectOption, dispatch);
    }
  }, [projectOption, overlayLayersConfig.length, dispatch]);

  const [isLoading, isLabeling] = useMemo(
    () => [
      mode === TaskUIMode.Loading,
      [TaskUIMode.Labeling, TaskUIMode.Validating].includes(mode),
    ],
    [mode]
  );

  const currentCursor = useMemo(
    () => createCursor(isLabeling, activeDrawingTypeOption),
    [isLabeling, activeDrawingTypeOption]
  );

  const drawHandler = useMemo(() => {
    const handler = new DrawPolygonHandler();
    handler.dispatch = dispatch as any;
    return handler;
  }, [dispatch]);

  const drawingLayer = useMemo<void | LabelingGeoJsonLayer>(
    () =>
      createDetectionDrawLayer(
        activeDrawingTypeOption,
        selectedClassOption,
        actionBufferCache,
        isLabeling,
        isRightClick,
        drawHandler,
        dispatch
      ),
    [
      activeDrawingTypeOption,
      selectedClassOption,
      actionBufferCache,
      isLabeling,
      isRightClick,
      drawHandler,
      dispatch,
    ]
  );

  const { viewportOption } = useMemo(() => generateViewport(taskOption), [taskOption]);

  useEffect(() => {
    const merged = merge(actionBuffer);
    dispatch(createSetActionBufferCache(merged));
    dispatch(createSetDisplayBuffer(transform(merged)));
  }, [actionBuffer, dispatch]);

  useEffect(() => {
    if (!drawingInProgress) {
      drawHandler.cancelDraw();
    }
  }, [drawingInProgress, drawHandler]);

  const onImageryLayerChange = (id: string, enabled: boolean, opacity: number) => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Imagery,
        imageryLayersConfig.map(maybeUpdateLayer(id, enabled, opacity))
      )
    );
  };

  const onBasemapChange = (id: string) => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Basemap,
        basemapLayersConfig.map((l) => ({ ...l, enabled: l.id === id }))
      )
    );
  };

  const onVectorLayerChange = (id: string, enabled: boolean, opacity: number) => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Vector,
        vectorLayersConfig.map(maybeUpdateLayer(id, enabled, opacity))
      )
    );
  };

  const onOverlayChange = (id: string, enabled: boolean, opacity: number) => {
    dispatch(
      createSetLayerPickerConfig(
        LayerPickerSection.Overlay,
        overlayLayersConfig.map(maybeUpdateLayer(id, enabled, opacity))
      )
    );
  };

  const detectionLayerProps = useMemo(
    () => ({
      getLineWidth: 2,
      lineWidthUnits: "pixels",
      highlightedObjectIndex: clickedLabelIdx,
      highlightColor: getHighlightColor(highlightColor, 100),
    }),
    [clickedLabelIdx, highlightColor]
  );

  const getLayerProps = useCallback(
    (layerId: string, layerType: TaskGeoJsonLayerType) => {
      if (layerType === TaskGeoJsonLayerType.Current) {
        return createLayerProps(layerId, layerType, vectorLayersConfig, some(detectionLayerProps));
      }
      return createLayerProps(layerId, layerType, vectorLayersConfig);
    },
    [vectorLayersConfig, detectionLayerProps]
  );

  const mapStyleOption = useMemo(
    () =>
      generateMapStyle(
        projectOption,
        imageryLayersConfig.concat(basemapLayersConfig, overlayLayersConfig, vectorLayersConfig),
        idTokenOption,
        taskOption,
        hiddenClassIds
      ),
    [
      projectOption,
      imageryLayersConfig,
      basemapLayersConfig,
      overlayLayersConfig,
      vectorLayersConfig,
      idTokenOption,
      taskOption,
      hiddenClassIds,
    ]
  );

  return isLoading ? (
    <Box display="flex" width="100%" height="100%">
      <LoadingIcon />
    </Box>
  ) : (
    foldOption(
      seqOption(viewportOption, mapStyleOption),
      () => (
        <Box display="flex" width="100%" height="100%">
          <LoadingIcon />
        </Box>
      ),
      ([viewport, mapStyle]) => (
        <>
          <DeckGL
            initialViewState={viewport}
            controller={DrawingMapController}
            layers={[drawingLayer]}
            getCursor={() => currentCursor}
            ContextProvider={MapContext.Provider}
            ref={deckRef}
          >
            <MapControlsContainer>
              <NavigationControl showCompass={false} />
              <SemanticLayerToggle
                imageryLayers={{ onChange: onImageryLayerChange, layers: imageryLayersConfig }}
                vectorLayers={{ onChange: onVectorLayerChange, layers: vectorLayersConfig }}
                basemaps={{ onChange: onBasemapChange, layers: basemapLayersConfig }}
                overlayLayers={{ onChange: onOverlayChange, layers: overlayLayersConfig }}
              />
            </MapControlsContainer>
            <GeoJsonLayer
              {...getLayerProps(currentTaskLayerId, TaskGeoJsonLayerType.Current)}
              data={displayBuffer.features.filter(
                (f) => !hiddenClassIds.includes(f.properties.label)
              )}
              pickable={true}
            />
            <StaticMap mapStyle={mapStyle} width="100%" height="100%" />
          </DeckGL>
          <ContextMenu
            deckRef={deckRef}
            onActivate={() => {
              setIsRightClick(true);
            }}
            onHide={() => {
              setIsRightClick(false);
            }}
          >
            {(pick, callback) => (
              <>
                {"coordinate" in pick && (
                  <>
                    <DetectionReclassifyMenu pick={pick} callback={callback} />
                    <Divider m={0} />
                    <DetectionDeleteMenu pick={pick} callback={callback} />
                  </>
                )}
              </>
            )}
          </ContextMenu>
          <HiddenClassMessage />
        </>
      )
    )
  );
};

export default DetectionMap;
