import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";
import { Button } from "@blasterjs/core";

const ColorPickerButton = styled(Button)`
  padding: ${themeGet("textInput.space.p")};
  border-radius: ${themeGet("radii.base")};
  border: ${themeGet("textInput.borders.border")};
  border-color: ${themeGet("textInput.colors.borderColor")};
  width: 60px;
`;

export default ColorPickerButton;
