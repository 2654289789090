import { UploadSequence, UploadStep } from "../types";

export const isActive = (sequence: UploadSequence) =>
  [
    UploadStep.WAITING,
    UploadStep.START,
    UploadStep.VALIDATED,
    UploadStep.UPLOADING,
    UploadStep.UPLOADED,
  ].includes(sequence.uploadStep);

export const isActiveOrFailed = (sequence: UploadSequence) =>
  isActive(sequence) || sequence.uploadStep === UploadStep.ERROR;

export const isWaiting = (sequence: UploadSequence) => sequence.uploadStep === UploadStep.WAITING;

export const isComplete = (sequence: UploadSequence) => sequence.uploadStep === UploadStep.COMPLETE;
