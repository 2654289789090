import { fromNullable, some, none } from "fp-ts/es6/Option";

import { foldOption } from "lib";

const getWebGL2Error = () => {
  const canvasB = document.createElement("canvas");

  return foldOption(
    fromNullable(canvasB.getContext("webgl2") || canvasB.getContext("experimental-webgl2")),
    () =>
      typeof WebGL2RenderingContext !== "undefined"
        ? some(
            `Your browser appears to support WebGL 2.0 but it might be disabled. Try updating your OS and/or video card drivers.`
          )
        : some(`Your browser does not support WebGL 2.0.`),
    (_) => none
  );
};

export default getWebGL2Error;
