import { Dispatch } from "redux";
import { head } from "fp-ts/es6/Array";

import { SemanticLabelFeatureCollection, TaskUIMode, TaskURLActionType } from "types";
import { Task } from "datamodel/task";
import { splitTask as split } from "http/task";
import { foldOption, noop } from "lib";
import { push } from "connected-react-router";
import { createSetMode } from "state/ui-segmentation/actions";
import urlForRandomTask from "pages/helpers/urlForRandomTask";
import replaceLabels from "pages/SegmentationTask/helpers/replaceLabels";

const splitTask = async (
  task: Task,
  displayBuffer: SemanticLabelFeatureCollection,
  dispatch: Dispatch
) => {
  dispatch(createSetMode(TaskUIMode.Loading));
  await replaceLabels(task, displayBuffer, task.properties.status);
  const splitTasks = (await split(task)).data;
  foldOption(head(splitTasks.features), noop, (task) =>
    dispatch(
      push(urlForRandomTask(task.properties.projectId, task.id, TaskURLActionType.Label, {}))
    )
  );
};

export default splitTask;
