import React, { useEffect, useState } from "react";
import { TaskStatus } from "datamodel/task";
import { Option, none, some } from "fp-ts/es6/Option";
import { foldOption, toPercent, seqOption, noop } from "lib";

import { Project } from "datamodel/project";
import { Box, HStack, Progress, Skeleton, Text } from "@chakra-ui/react";
import getTotalTaskCountFromSummary from "pages/helpers/getTotalTaskCountFromSummary";

type Props = {
  project: Project;
};

const labeledStatuses = [
  TaskStatus.Labeled,
  TaskStatus.Flagged,
  TaskStatus.ValidationInProgress,
  TaskStatus.Validated,
];
const validatedStatuses = [TaskStatus.Validated];
const excludeStatuses = [TaskStatus.Invalid, TaskStatus.Split];

const ProgressIndicators = ({ project }: Props) => {
  const [labeledCountOption, setLabeledCountOption] = useState<Option<number>>(none);
  const [validatedCountOption, setValidatedCountOption] = useState<Option<number>>(none);
  const [totalCountOption, setTotalCountOption] = useState<Option<number>>(none);

  useEffect(() => {
    foldOption(project.taskStatusSummary, noop, (taskStatusSummary) => {
      const labeledCount = Object.entries(taskStatusSummary).reduce(
        (acc, [k, v]) => acc + (labeledStatuses.includes(k as TaskStatus) ? v : 0),
        0
      );
      const validatedCount = Object.entries(taskStatusSummary).reduce(
        (acc, [k, v]) => acc + (validatedStatuses.includes(k as TaskStatus) ? v : 0),
        0
      );
      const labeledTotal = getTotalTaskCountFromSummary(taskStatusSummary, excludeStatuses);
      setLabeledCountOption(some(labeledCount));
      setValidatedCountOption(some(validatedCount));
      setTotalCountOption(some(labeledTotal));
    });
  }, [project]);

  return foldOption(
    seqOption(labeledCountOption, validatedCountOption, totalCountOption),
    () => (
      <Box>
        <Box mb={1}>
          <Skeleton width="175px" />
        </Box>
        <Box display="flex" alignItems="center">
          <Progress flex="1" mr={2} />
          <Skeleton width="25px" />
          <Text color="textLight">%</Text>
        </Box>
      </Box>
    ),
    ([labeledCount, validatedCount, total]) => (
      <HStack spacing={5}>
        <HStack flex="0.5" spacing={3}>
          <Progress
            value={+toPercent(labeledCount, total, 1)}
            size="sm"
            borderRadius="1000px"
            colorScheme="gray"
            title={`${toPercent(labeledCount, total, 1)}% labeled`}
            width="100%"
          />
          <Text fontSize="12px" width="30px" color="textLight">
            {toPercent(labeledCount, total, 0)}%
          </Text>
        </HStack>
        <HStack flex="0.5">
          <Progress
            value={+toPercent(validatedCount, total, 1)}
            size="sm"
            borderRadius="1000px"
            colorScheme="blue"
            title={`${toPercent(validatedCount, total, 1)}% validated`}
            width="100%"
          />
          <Text fontSize="12px" width="30px" color="textLight">
            {toPercent(validatedCount, total, 0)}%
          </Text>
        </HStack>
      </HStack>
    )
  );
};

export default ProgressIndicators;
