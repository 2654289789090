const HERE_APP_ID = "EHQzFvMsfKloy12HdLAU";
const HERE_APP_CODE = "izVFtlcj-nc4KaquQ2TRTg";

export function getDefaultBasemapKey() {
  return basemaps.layers[basemaps.default].id;
}

export const getDefaultBasemap = () => {
  return basemaps.layers[basemaps.default];
};

export const basemaps = {
  layers: {
    Light: {
      id: "a2ff0cc0-7e08-4e83-b508-51db6a123465",
      url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
      properties: {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">' +
          'OpenStreetMap</a> &copy;<a href="http://cartodb.com/attributions">CartoDB</a>',
        maxZoom: 30,
        preview: "https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/17/38168/49642.png",
      },
      default: true,
    },
    LightNoLabels: {
      id: "light_no_labels",
      url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_nolabels/{z}/{x}/{y}.png",
      properties: {
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">' +
          'OpenStreetMap</a> &copy;<a href="http://cartodb.com/attributions">CartoDB</a>',
        maxZoom: 30,
        preview:
          "https://cartodb-basemaps-a.global.ssl.fastly.net/light_nolabels/17/38168/49642.png",
      },
      default: true,
    },
    Aerial: {
      id: "024a8c36-2892-4039-ba6f-02a662c8cefa",
      url:
        "https://{s}.{baseType}.maps.cit.api.here.com/maptile/2.1/{type}/{mapID}/hybrid.day" +
        "/{z}/{x}/{y}/{size}/{format}?app_id={app_id}&app_code={app_code}&lg={language}",
      properties: {
        attribution: 'Map &copy; 1987-2014 <a href="http://developer.here.com">HERE</a>',
        subdomains: "1234",
        mapID: "newest",
        app_id: HERE_APP_ID,
        app_code: HERE_APP_CODE,
        baseType: "aerial",
        maxZoom: 30,
        maxNativeZoom: 20,
        type: "maptile",
        language: "eng",
        format: "png8",
        size: "256",
        preview: `https://1.aerial.maps.cit.api.here.com/maptile/2.1/maptile/newest/hybrid.day/17/38168/49642/256/png8?app_id=${HERE_APP_ID}&app_code=${HERE_APP_CODE}&lg=eng`,
      },
    },
  },
  default: "Light",
};
