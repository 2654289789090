import React from "react";
import { FieldArrayRenderProps, FieldArray } from "react-final-form-arrays";
import { Box } from "@blasterjs/core";

import ClassField from "./ClassField";
import { FieldGroupValue, FieldValue } from "..";

interface Props {
  fieldArrayProps: FieldArrayRenderProps<FieldGroupValue, HTMLElement>;
  maxClassCount: number;
}

const ClassFieldArray = ({ fieldArrayProps, maxClassCount }: Props) => {
  return (
    <>
      {fieldArrayProps.fields.map((name, index) => (
        <Box display="flex" flexDirection="column" key={index}>
          <>
            <FieldArray name={`${name}.classes`}>
              {(fieldArrProps: FieldArrayRenderProps<FieldValue, HTMLElement>) => (
                <ClassField fieldArrProps={fieldArrProps} maxClassCount={maxClassCount} />
              )}
            </FieldArray>
          </>
        </Box>
      ))}
    </>
  );
};

export default ClassFieldArray;
