import React, { useEffect } from "react";
import { foldOption, noop } from "lib";
import { useSelector } from "react-redux";
import { ApplicationStore } from "types";
import FullScreenLoadingAnimation from "components/FullScreenLoadingAnimation";
import config from "config";

const Logout = () => {
  const [clientOption] = useSelector((state: ApplicationStore) => [state.newAuth.client]);

  useEffect(
    () => foldOption(clientOption, noop, (client) => client.logout({ returnTo: config.baseUrl })),
    [clientOption]
  );

  return <FullScreenLoadingAnimation />;
};

export default Logout;
