import { Task, TaskStatus } from "datamodel/task";
import { Dispatch } from "redux";
import { putTaskStatus } from "http/task";
import { createSetLastError } from "state/ui-segmentation/actions";
import { Error } from "types";
import { none } from "fp-ts/es6/Option";

const deleteTask = async (task: Task, dispatch: Dispatch) => {
  const requestPromise = putTaskStatus(task.properties.projectId, task.id, {
    nextStatus: TaskStatus.Invalid,
    note: none,
  }).then(() => Promise.resolve());

  requestPromise.catch((err: Error) =>
    dispatch(createSetLastError("Failed to update task when trying to delete task", err))
  );

  return requestPromise;
};

export default deleteTask;
