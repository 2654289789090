import { getOrElse } from "fp-ts/es6/Either";
import { pipe } from "fp-ts/es6/function";
import * as T from "io-ts";
import { useLocation } from "react-router-dom";

function useDecodedParamOrElse<
  ApplicationType = any,
  EncodeTo = ApplicationType,
  DecodeFrom = unknown
>(
  paramName: string,
  codec: T.Type<ApplicationType, EncodeTo, DecodeFrom>,
  fallbackValue: ApplicationType
) {
  const params = new URLSearchParams(useLocation<any>().search);
  return pipe(
    codec.decode(params.get(paramName) as any),
    getOrElse(() => fallbackValue)
  );
}

export default useDecodedParamOrElse;
