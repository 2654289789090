import React, { useState } from "react";
import { FormSpy } from "react-final-form";
import { Box } from "@blasterjs/core";
import { Option, none, some } from "fp-ts/es6/Option";

import { foldOption, noEl } from "lib";
import { getEmptyClass, getEmptyClassGroup } from "./helpers";
import NonClassification from "./nonClassification";
import Classification from "./classification";
import { CampaignType } from "datamodel/campaign";

export interface FieldValue {
  name: string;
  colorHexCode: string;
}

export interface FieldGroupValue {
  name: string;
  classes: FieldValue[];
}

export const DEFAULTS = {
  maxClassCount: 0,
  classDefInitVals: [getEmptyClassGroup("", [getEmptyClass()])],
};

const ClassCreation = () => {
  const [projectTypeOption, setProjectTypeOption] = useState<Option<CampaignType>>(none);

  return (
    <>
      <FormSpy
        subscription={{ values: true }}
        onChange={(props) => {
          setProjectTypeOption(some(props.values.projectType));
        }}
      />
      <Box display="flex" flex={1} px={1} py={3} flexDirection="column" m="0 auto" width="1024px">
        {foldOption(projectTypeOption, noEl, (projectType) =>
          projectType !== CampaignType.Classification ? (
            <NonClassification projectTypeOption={projectTypeOption} />
          ) : (
            <Classification projectTypeOption={projectTypeOption} />
          )
        )}
      </Box>
    </>
  );
};

export default ClassCreation;
