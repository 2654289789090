import React, { ReactNode } from "react";
import { LimitsContextConsumer } from "./LimitsContext";

import { LimitDomain, ExistingLimit } from "datamodel/limits";
import { foldOption, noEl } from "lib";
import pickLimit from "pages/helpers/pickLimit";

interface Props {
  render: (limit: ExistingLimit) => ReactNode;
  domain: LimitDomain;
  action?: string;
  objectId?: string;
  showUnlimitedMsg?: boolean;
}

const LimitMessage = ({
  render,
  domain,
  action = "",
  objectId = "",
  showUnlimitedMsg = false,
}: Props) => (
  <LimitsContextConsumer>
    {(limitsOption) =>
      foldOption(pickLimit(limitsOption, domain, action, objectId), noEl, (domainLimit) =>
        render({ ...domainLimit } as ExistingLimit)
      )
    }
  </LimitsContextConsumer>
);

export default LimitMessage;
