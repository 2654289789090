import React from "react";
import { Card, Button } from "@blasterjs/core";
import { useSelector, useDispatch } from "react-redux";
import Pluralize from "react-pluralize";

import { ApplicationStore } from "types";
import { createSetHiddenClassIds } from "state/ui-segmentation/actions";

const HiddenClassMessage = () => {
  const dispatch = useDispatch();

  const [hiddenClassIds] = useSelector((state: ApplicationStore) => [
    state.segmentationUI.hiddenClassIds,
  ]);

  const onClickShowAll = () => {
    dispatch(createSetHiddenClassIds([]));
  };

  return hiddenClassIds.length ? (
    <Card alignSelf="flex-start" mt={2} p={1} bg="gray200">
      <Pluralize
        singular={"class is hidden"}
        plural={"classes are hidden"}
        count={hiddenClassIds.length}
      />
      <Button p={1} ml={1} onClick={onClickShowAll} withBorder>
        Show all
      </Button>
    </Card>
  ) : null;
};

export default HiddenClassMessage;
