import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  HStack,
  Text,
} from "@chakra-ui/react";
import Moment from "react-moment";
import styled from "styled-components";

import fetchTaskParticipants from "./helpers/fetchTaskParticipants";
import { ApplicationStore, TaskUIMode } from "types";
import { foldOption, noop } from "lib";
import { TaskActionWithRelated } from "datamodel/task";

export const DataRow = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 4px 4px;
  font-size: 12px;
`;

export const DataValue = styled.div`
  flex: 1;
  color: #555;
  display: flex;
  align-items: center;
  overflow: hidden;
  time {
    font-size: 11px;
  }
`;

export const SmallAvatar = styled.img`
  width: 16px;
  height: 16px;
`;

const MetaItem = ({ label, actions }: { label: string; actions: TaskActionWithRelated[] }) => (
  <AccordionItem maxHeight="150px">
    <AccordionButton>
      <Box flex="1" textAlign="left" fontSize="1.4rem">
        {label}
      </Box>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel pb={4} overflowY="auto" maxHeight="100px">
      {!!actions.length ? (
        actions.map((action, idx) => <ActionFields key={idx} action={action} />)
      ) : (
        <DataRow>
          <DataValue>No {label === "Labelers" ? "labeler" : "validator"} yet</DataValue>
        </DataRow>
      )}
    </AccordionPanel>
  </AccordionItem>
);

const ActionFields = ({ action }: { action: TaskActionWithRelated }) => (
  <DataRow>
    <DataValue>
      <HStack spacing={2} overflow="hidden" flex="1">
        <HStack spacing={1} overflow="hidden" title={action.userName} flex="1">
          <SmallAvatar src={action.userProfileImageUri} />
          <Text
            flex="1"
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize="1.2rem"
            fontWeight="semibold"
            whiteSpace="nowrap"
          >
            {action.userName}
          </Text>
        </HStack>
        <Moment format="l LT">{action.timestamp}</Moment>
      </HStack>
    </DataValue>
  </DataRow>
);

const TaskMeta = () => {
  const [taskOption, lastLabelingActions, lastValidatingActions, mode] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.task,
        state.segmentationUI.lastLabelingActions,
        state.segmentationUI.lastValidatingActions,
        state.segmentationUI.mode,
      ] as const
  );

  const dispatch = useDispatch();

  useEffect(
    () => foldOption(taskOption, noop, (task) => fetchTaskParticipants(task, dispatch)),
    [taskOption, dispatch]
  );

  return (
    <Accordion defaultIndex={[0, 1]} allowMultiple>
      <MetaItem label="Labelers" actions={lastLabelingActions} />
      {(mode === TaskUIMode.Validating || mode === TaskUIMode.ValidatingCompleteByUser) && (
        <MetaItem label="Validators" actions={lastValidatingActions} />
      )}
    </Accordion>
  );
};

export default TaskMeta;
