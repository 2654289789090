import styled from "styled-components";
import { Box } from "@blasterjs/core";
import { themeGet } from "@styled-system/theme-get";

export const StyledContextMenu = styled.div`
  position: absolute;
  width: 210px;
  background: white;
  border: 1px solid #ccc;
  z-index: 10000;
  cursor: pointer;
  box-shadow: 0 0 2px 3px rgba(0, 0, 0, 0.15);
`;

export const MenuHeader = styled.div`
  font-weight: 600;
  padding: ${() => themeGet("space.1")} ${() => themeGet("space.2")};
  cursor: default;
`;

export type MenuItemProps = {
  darken?: boolean;
};

export const MenuItem = styled(Box)<MenuItemProps>`
  display: flex;
  align-items: center;
  padding: ${() => themeGet("space.1")} ${() => themeGet("space.2")};
  background: ${(props) => (props.darken ? themeGet("colors.gray100") : "white")};
  &:hover {
    background: ${() => themeGet("colors.gray200")};
  }
`;

export const MenuActions = styled.div`
  background: #fff;
`;
