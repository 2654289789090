import React, { ElementType, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Config from "config";

import { ApplicationStore } from "types";
import { saveTargetPath } from "state/newAuth/helpers";
import FullScreenLoadingAnimation from "./FullScreenLoadingAnimation";

type Props = {
  component: ElementType;
  [x: string]: any;
};

const AuthenticatedRoute = ({ component: ProxiedComponent, ...restProps }: Props) => {
  const [isReady, setIsReady] = useState(false);
  const [isAuthenticated, isLoading] = useSelector((state: ApplicationStore) => [
    state.newAuth.isAuthenticated,
    state.newAuth.isLoading,
  ]);

  // Because the effect runs after render (but before paint occurs) we need to
  // use a flag to prevent the redirect until after we've saved the target url
  // (i.e. the current url).
  useEffect(() => {
    if (!isAuthenticated) {
      saveTargetPath();
    }
    setIsReady(true);
  }, [isAuthenticated]);

  const allowRedirect = !isLoading && isReady;

  return allowRedirect ? (
    isAuthenticated ? (
      <Route {...restProps} render={() => <ProxiedComponent {...restProps} />} />
    ) : (
      <Redirect to={Config.loginRoute} />
    )
  ) : (
    <FullScreenLoadingAnimation />
  );
};

export default AuthenticatedRoute;
