import React, { useState, useEffect } from "react";
import { Option, some, none } from "fp-ts/es6/Option";
import { Box, Heading, Text } from "@blasterjs/core";

import config from "config";
import { textForProjectType } from "pages/helpers";
import classificationCountForCampaign from "./helpers/classificationCountForCampaign";
import { LinkCard, UnderlineOnHoverText } from "./styled";
import Pluralize from "react-pluralize";
import PreviewMaps from "./PreviewMaps";
import { Project } from "datamodel/project";
import { listProjects } from "http/campaign";
import { foldOption, noop } from "lib";
import { Campaign } from "datamodel/campaign";
import LoadingCard from "./LoadingCard";
import ErrorCard from "./ErrorCard";
import ProgressIndicators from "./ProgressIndicators";
import { Link } from "react-router-dom";
import { findFirst } from "fp-ts/es6/Array";
import isProjectReady from "./helpers/isProjectReady";
import getProjectsStatusCounts from "./helpers/getProjectsStatusCounts";
import ClassificationTooltip from "./ClassificationTooltip";
import useUploader from "components/Uploader/store";
import { Badge } from "@chakra-ui/layout";
import { isActive } from "components/Uploader/helpers/filters";
import SkeletonCard from "./SkeletonCard";
import useEntityOwner from "hooks/useEntityOwner";

const INTERVAL = config.projectStatusPollInterval;

interface Props {
  campaign: Campaign;
}

enum CampaignProjectState {
  ShowImage,
  ShowBalloon,
  ShowError,
}

const listCampaignProjectQP = { pageSize: 9999, isActive: true };

const CampaignCard = ({ campaign }: Props) => {
  const [allProjects, setAllProjects] = useState<Project[]>([]);
  const hasActiveUploads = useUploader(
    (state) => !!state.sequences.filter((s) => isActive(s) && s.campaignId === campaign.id).length
  );
  const [previewProjectOpt, setPreviewProjectOpt] = useState<Option<Project>>(none);
  const [campaignProjectStateOpt, setCampaignProjectStateOpt] =
    useState<Option<CampaignProjectState>>(none);

  const isOwner = useEntityOwner(campaign.owner);

  useEffect(() => {
    const fetchProjects = async () => {
      const {
        data: { results: projects },
      } = await listProjects(campaign.id, listCampaignProjectQP);
      setAllProjects(projects);
    };
    fetchProjects();
  }, [campaign.id]);

  useEffect(() => {
    const projectCount = allProjects.length;
    const {
      ready: readyCount,
      failed: failedCount,
      inProgress: inProgressCount,
    } = getProjectsStatusCounts(allProjects);
    if (!projectCount) {
      setCampaignProjectStateOpt(some(CampaignProjectState.ShowBalloon));
    } else if (!!readyCount) {
      // show the first image
      setCampaignProjectStateOpt(some(CampaignProjectState.ShowImage));
    } else if (failedCount === projectCount) {
      // show empty state
      setCampaignProjectStateOpt(some(CampaignProjectState.ShowError));
    } else if (!!inProgressCount) {
      let interval: NodeJS.Timeout;
      const callback = () => {
        listProjects(campaign.id, listCampaignProjectQP).then((projectsResp) => {
          const {
            data: { results: projects },
          } = projectsResp;
          setAllProjects(projects);
          const { ready, failed, inProgress } = getProjectsStatusCounts(projects);
          if (!projectCount) {
            setCampaignProjectStateOpt(some(CampaignProjectState.ShowBalloon));
          } else if (!!ready) {
            // show the first image, stop refetching
            setCampaignProjectStateOpt(some(CampaignProjectState.ShowImage));
            clearInterval(interval);
          } else if (failed === projectCount) {
            // show empty state, stop refetching
            setCampaignProjectStateOpt(some(CampaignProjectState.ShowError));
            clearInterval(interval);
          } else if (inProgress === projectCount) {
            // show balloon, keep refetching
            setCampaignProjectStateOpt(some(CampaignProjectState.ShowBalloon));
          }
        });
      };

      interval = setInterval(callback, INTERVAL);
      return () => {
        !!interval && clearInterval(interval);
      };
    }
    return () => {};
  }, [campaign.id, allProjects]);

  useEffect(() => {
    if (!!allProjects.length) {
      foldOption(campaignProjectStateOpt, noop, (state) => {
        if (state === CampaignProjectState.ShowImage) {
          setPreviewProjectOpt(
            findFirst((project: Project) => isProjectReady(project))(allProjects)
          );
        }
      });
    }
  }, [allProjects, campaignProjectStateOpt]);

  return (
    <LinkCard width="273px" m={1} to={`/campaign/${campaign.id}`} as={Link}>
      <Box
        height="236px"
        bg={"gray100"}
        borderWidth="1px"
        borderStyle="solid"
        borderColor={"gray100"}
        position="relative"
        borderRadius="base"
      >
        {foldOption(
          campaignProjectStateOpt,
          () => (
            <LoadingCard />
          ),
          (state) => {
            if (allProjects.length === 0) {
              return <SkeletonCard />;
            }
            if (!!allProjects.length && state === CampaignProjectState.ShowBalloon) {
              return <LoadingCard />;
            }
            if (state === CampaignProjectState.ShowImage) {
              return foldOption(
                previewProjectOpt,
                () => <LoadingCard />,
                (preview) => <PreviewMaps project={preview} campaignId={campaign.id} />
              );
            }
            if (state === CampaignProjectState.ShowError) {
              return <ErrorCard campaign={campaign} />;
            }
            return <></>;
          }
        )}
      </Box>
      <Heading
        as="h5"
        fontSize="16px"
        lineHeight="1.3"
        mt={2}
        mb="4px"
        pl="1px"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        title={campaign.name}
      >
        {campaign.name}
      </Heading>
      <Text display="block" mb="4px" color="textLight">
        {textForProjectType(campaign.campaignType)}
      </Text>
      <Box mb="2px">
        <UnderlineOnHoverText display="inline-block" color="textLight" data-tooltip>
          <ClassificationTooltip campaign={campaign} />
          <Pluralize singular={"images"} plural={"images"} count={allProjects.length} />
          <Text> / </Text>
          <Pluralize
            singular={"class"}
            plural={"classes"}
            count={classificationCountForCampaign(campaign)}
          />
        </UnderlineOnHoverText>
      </Box>
      <ProgressIndicators campaign={campaign} />
      {!!hasActiveUploads && (
        <Badge
          colorScheme="gray"
          position="absolute"
          top="0"
          left="0"
          m={10}
          fontSize="11px"
          variant="subtle"
        >
          Uploading
        </Badge>
      )}
      {!isOwner && (
        <Badge position="absolute" top="0" left="0" m={10} fontSize="11px">
          Shared with you
        </Badge>
      )}
    </LinkCard>
  );
};

export default CampaignCard;
