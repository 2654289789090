import React from "react";
import { useDispatch, useSelector } from "react-redux";

import splitTask from "../helpers/splitTask";
import { ApplicationStore, TaskUIMode } from "types";
import { foldOption } from "lib";
import { createSetMode } from "state/ui-segmentation/actions";
import { Box, Heading, Stack } from "@chakra-ui/react";
import GButton from "components/GButton";

const ConfirmTaskSplit = () => {
  const dispatch = useDispatch();
  const [taskO, displayBuffer] = useSelector(
    (state: ApplicationStore) =>
      [state.segmentationUI.task, state.segmentationUI.displayBuffer] as const
  );

  return foldOption(
    taskO,
    () => <></>,
    (task) => (
      <Box m={4}>
        <Box mt={6}>
          <Heading as="h5" fontSize="18px">
            Split task
          </Heading>
        </Box>
        <Box mt={8}>You can divide a complicated task into four more manageable tasks.</Box>
        <Stack mt={8} spacing={4}>
          <GButton
            data-intercom-target="confirm-split-task"
            colorScheme="primary"
            width="100%"
            onClick={() => splitTask(task, displayBuffer, dispatch)}
          >
            Split this task
          </GButton>
          <GButton
            data-intercom-target="cancel-split-task"
            colorScheme="#fff"
            width="100%"
            onClick={() => dispatch(createSetMode(TaskUIMode.Labeling))}
          >
            <Box>Cancel</Box>
          </GButton>
        </Stack>
      </Box>
    )
  );
};

export default ConfirmTaskSplit;
