import React from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import UpgradeSelection from "./Limits/UpgradeSelection";

type Props = Omit<ModalProps, "children">;

const UpgradeModal = ({ isOpen, onClose }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent maxWidth="fit-content">
      <ModalHeader fontSize="16px">Upgrade to get the most from GroundWork</ModalHeader>
      <ModalCloseButton />
      <ModalBody p={5}>
        <UpgradeSelection />
      </ModalBody>
    </ModalContent>
  </Modal>
);

export default UpgradeModal;
