import React, { useState } from "react";
import { some, none, Option } from "fp-ts/es6/Option";
import { foldOption } from "lib";
import { Card, Text, Button, Tooltip, Dialog, Box, Heading, Table } from "@blasterjs/core";
import {
  FaRegKeyboard,
  FaMousePointer,
  FaArrowUp,
  FaArrowDown,
  FaArrowLeft,
  FaArrowRight,
  FaPlus,
  FaRegQuestionCircle,
} from "react-icons/fa";
import { Icon } from "@chakra-ui/react";

interface KeybindProps {
  icon: Option<IconType>;
  text: Option<string>;
}

export enum IconType {
  MouseDefault = "mouseDefault",
  ArrowUp = "arrowUp",
  ArrowDown = "arrowDown",
  ArrowLeft = "arrowLeft",
  ArrowRight = "arrowRight",
  Plus = "plus",
}

const switchIcon = (name: IconType) => {
  switch (name) {
    case IconType.MouseDefault:
      return <FaMousePointer />;
    case IconType.ArrowUp:
      return <FaArrowUp />;
    case IconType.ArrowDown:
      return <FaArrowDown />;
    case IconType.ArrowLeft:
      return <FaArrowLeft />;
    case IconType.ArrowRight:
      return <FaArrowRight />;
    case IconType.Plus:
      return <FaPlus />;
    default:
      return <FaRegQuestionCircle />;
  }
};

const Key = (props: KeybindProps) => (
  <Card
    borderRadius="2px"
    bg="offwhite"
    border="1px solid gray300"
    display="flex"
    flexDirection="row"
    boxShadow={2}
    padding="0.4rem"
  >
    {foldOption(
      props.icon,
      () => null,
      (icon) => switchIcon(icon)
    )}
    {foldOption(
      props.text,
      () => null,
      (text) => (
        <Text>{text}</Text>
      )
    )}
  </Card>
);

export enum KeyType {
  Text,
  Icon,
}

interface KeySet {
  type: KeyType;
  name: string | IconType;
}

interface KeyGroupProps {
  keysets: Array<Array<KeySet>>;
}

const KeyGroup = (props: KeyGroupProps) => (
  <>
    {props.keysets.map((set, index) => (
      <Box
        display="flex"
        flexDirection="row"
        justifyContext="flex-end"
        width="100%"
        mb="1rem"
        key={index}
      >
        {set.map((key, index) => {
          switch (key.type) {
            case KeyType.Text:
              return (
                <Box key={index} display="flex" flexDirection="row">
                  <Key text={some(key.name)} icon={none} />
                  {index < set.length - 1 ? (
                    <Box padding="0.4rem">{switchIcon(IconType.Plus)}</Box>
                  ) : null}
                </Box>
              );
            case KeyType.Icon:
              return <Key icon={some(key.name as IconType)} text={none} key={index} />;
            default:
              throw new Error(`Invalid keybind type: ${key}`);
          }
        })}
      </Box>
    ))}
  </>
);

interface Binding {
  keysets: Array<Array<KeySet>>;
  action: string;
}

interface BindingProps {
  binding: Binding;
}

const BindingRow = (props: BindingProps) => (
  <tr>
    <td>
      <KeyGroup keysets={props.binding.keysets} />
    </td>
    <td>{props.binding.action}</td>
  </tr>
);

interface KeybindsToggleProps {
  bindings: Array<Binding>;
}

/**
 * Toggle a modal describing keybinds for the current view
 */
export function KeybindsToggle(props: KeybindsToggleProps) {
  const [keybindsOpen, setKeybindsOpen] = useState(false);
  return (
    <>
      <Button
        data-intercom-target="keyboard-shortcuts"
        appearance="minimal"
        p={1}
        mr="0.4rem"
        data-tooltip
        onClick={() => setKeybindsOpen(true)}
      >
        <Icon as={FaRegKeyboard} />
        <Tooltip trigger="hover" bg="grayLight1" placement="bottom">
          Keyboard Shortcuts
        </Tooltip>
      </Button>
      <Dialog
        appElementId="root"
        isOpen={keybindsOpen}
        onRequestClose={() => setKeybindsOpen(false)}
        width="50rem"
      >
        <Box>
          <Heading as="h3">Keyboard Shortcuts</Heading>
          <Table borderRows={true} width="100%">
            <tbody>
              {props.bindings.map((binding) => (
                <BindingRow binding={binding} key={binding.action} />
              ))}
            </tbody>
          </Table>
        </Box>
      </Dialog>
    </>
  );
}

export default KeybindsToggle;
