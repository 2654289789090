import polygonClipping from "polygon-clipping";
import * as gp from "geojson-precision";

import { DrawingActionType, SemanticLabelFeatureCollection } from "types";
import { isNotNullOrUndefined } from "lib";
import DrawingAction from "common/modules/DrawingAction";
import config from "config";

// Given an action, a list of other actions, and some options, generate a new
// action list that incorporates the action accordingly
const apply = (action: DrawingAction, prevActions: DrawingAction[]): DrawingAction[] => {
  const erase =
    action.actionType === DrawingActionType.Erase || action.actionType === DrawingActionType.Clear;
  let applied = false;
  // This reduce function builds up a list of `DrawingAction`s that have the
  // current `action` applied. It returns a tuple where the first element is the
  // built-up list and the second element is either a `DrawingAction` that should
  // be appended or `null`, which is then filtered out.
  const [actions, tail] = prevActions.reduce(
    (acc: [DrawingAction[], DrawingAction | null], a) => {
      // We only use the second element of the tuple after the reduce is complete
      const builtUp = acc[0];
      const matchingLabels = a.label === action.label;
      if (action.actionType === DrawingActionType.Erase) {
        // Erase should only affect drawing actions that have the same label
        if (matchingLabels) {
          return [
            [
              ...builtUp,
              a.clone({
                geoJSON: {
                  ...a.geoJSON,
                  geometry: {
                    ...a.geoJSON.geometry,
                    type: "MultiPolygon",
                    coordinates: polygonClipping.difference(
                      gp.parse(a.geoJSON, config.drawingPrecision).geometry.coordinates,
                      gp.parse(action.geoJSON, config.drawingPrecision).geometry.coordinates
                    ),
                  },
                },
              }),
            ],
            null,
          ];
        } else {
          // If the labels don't match, pass the drawing action through
          return [[...builtUp, a], null];
        }
      } else if (action.actionType === DrawingActionType.Clear) {
        return [[], null];
      } else if (matchingLabels) {
        applied = true;
        // We only want to merge actions if they have the same label
        const unioned = a.clone({
          geoJSON: {
            ...a.geoJSON,
            geometry: {
              ...a.geoJSON.geometry,
              type: "MultiPolygon",
              coordinates: polygonClipping.union(
                gp.parse(a.geoJSON, config.drawingPrecision).geometry.coordinates,
                gp.parse(action.geoJSON, config.drawingPrecision).geometry.coordinates
              ),
            },
          },
        });
        return [[...builtUp, unioned], null];
      }
      // If `action` isn't a `DrawingActionType.Erase` and the labels don't match, use `action`
      // to "overwrite" other `DrawingAction`s
      const diff = a.clone({
        geoJSON: {
          ...a.geoJSON,
          geometry: {
            ...a.geoJSON.geometry,
            // we know that the polygon clipping result is always a multipolygon
            // we cannot rely on the type of either a.geojson.geometry or action.geojson.geometry
            type: "MultiPolygon",
            coordinates: polygonClipping.difference(
              gp.parse(a.geoJSON, config.drawingPrecision).geometry.coordinates,
              gp.parse(action.geoJSON, config.drawingPrecision).geometry.coordinates
            ),
          },
        },
      });
      // if the builtUp is not empty, it means the label is already merged to a
      // previous action, so we do not want to pass it through
      return [[...builtUp, diff], applied || erase ? null : action];
    },
    [[], erase ? null : action]
  );
  return [...actions, tail].filter(isNotNullOrUndefined);
};

const doTransform = (actions: DrawingAction[]): DrawingAction[] => {
  return actions.reduce((acc: DrawingAction[], action: DrawingAction) => {
    // When the reduce function runs into a `DrawBelow`, we move it to the
    // front of the array and treat is as a `DrawAbove` and then re-apply the
    // other actions on top of it
    // When the reduce function runs into a `DrawAbove`
    if (action.actionType === DrawingActionType.DrawBelow) {
      // This recursion can be considered safe as we know it will only recurse
      // a single time for each `DrawBelow` action
      return doTransform([action.clone({ actionType: DrawingActionType.DrawAbove }), ...acc]);
    }
    return apply(action, acc);
  }, []);
};

export const merge = (actions: DrawingAction[]): DrawingAction[] =>
  doTransform(actions).filter((a: DrawingAction) => a.geoJSON.geometry.coordinates.length);

export const transform = (actions: DrawingAction[]): SemanticLabelFeatureCollection => {
  return {
    type: "FeatureCollection",
    features: actions.map((a: DrawingAction) => a.toSemanticLabelFeature()),
  };
};
