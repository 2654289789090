import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { some, none, exists, filterMap } from "fp-ts/es6/Option";

import { DrawingActionType, ApplicationStore, TaskURLActionType, TaskUIMode } from "types";
import { createSetActiveDrawType } from "state/ui-segmentation/actions";
import { CampaignType } from "datamodel/campaign";
import { pipe } from "fp-ts/es6/pipeable";
import { getLatestTaskAction } from "pages/SegmentationTask/helpers";
import { TaskStatus } from "datamodel/task";
import useRouter from "use-react-router";
import BlockableBox from "components/BlockableBox";
import { RouteComponentProps } from "react-router";
import FlexFiller from "components/FlexFiller";
import DrawModeButton from "./DrawModeButton";
import config from "config";
import { foldOption, noEl } from "lib";
import AutosaveIndicator from "./AutosaveIndicator";
import { Divider } from "@chakra-ui/react";
import ClearTaskButton from "./ClearTaskButton";
import { UserFeatureFlag } from "components/FeatureFlags";

type Route = RouteComponentProps<{
  action: TaskURLActionType;
}>;

export const Toolbar = ({ projectType }: { projectType: CampaignType }) => {
  const dispatch = useDispatch();
  const { match }: Route = useRouter();

  const [mode, taskOption, activeDrawTypeOption, userOption] = useSelector(
    (state: ApplicationStore) =>
      [
        state.segmentationUI.mode,
        state.segmentationUI.task,
        state.segmentationUI.activeDrawType,
        state.newAuth.user,
      ] as const
  );

  const inProgressStatus = useMemo(
    () =>
      match.params.action === TaskURLActionType.Label
        ? TaskStatus.LabelingInProgress
        : TaskStatus.ValidationInProgress,
    [match.params.action]
  );

  const latestTaskActionOption = pipe(
    taskOption,
    filterMap((task) => getLatestTaskAction(task))
  );

  const isFlagged = pipe(
    latestTaskActionOption,
    exists(
      (action) => action.toStatus === TaskStatus.Flagged && action.fromStatus === inProgressStatus
    )
  );

  const disableDrawing = isFlagged || ![TaskUIMode.Labeling, TaskUIMode.Validating].includes(mode);

  useEffect(() => {
    switch (projectType) {
      case CampaignType.Segmentation:
        dispatch(createSetActiveDrawType(some(DrawingActionType.DrawBelow)));
        return;
      case CampaignType.Detection:
        dispatch(createSetActiveDrawType(some(DrawingActionType.DrawAbove)));
        return;
      case CampaignType.Classification:
        dispatch(createSetActiveDrawType(none));
        return;
    }
  }, [projectType, dispatch]);

  switch (projectType) {
    case CampaignType.Segmentation:
      return (
        <BlockableBox preventInteractions={disableDrawing} display="flex" p={3} zIndex="1">
          <DrawModeButton
            activeDrawTypeOption={activeDrawTypeOption}
            mode={DrawingActionType.DrawBelow}
            iconBefore="mapDraw"
            data-intercom-target="draw-button"
          >
            Draw
          </DrawModeButton>
          {foldOption(
            userOption,
            noEl,
            (user) =>
              config.userFeatures.magicWand(user) && (
                <DrawModeButton
                  activeDrawTypeOption={activeDrawTypeOption}
                  mode={DrawingActionType.MagicWand}
                  iconBefore="magicWand"
                  data-intercom-target="draw-button"
                >
                  Wand
                </DrawModeButton>
              )
          )}
          <DrawModeButton
            activeDrawTypeOption={activeDrawTypeOption}
            mode={DrawingActionType.DrawAbove}
            iconBefore="mapDrawBehind"
            data-intercom-target="replace-button"
          >
            Replace
          </DrawModeButton>
          <DrawModeButton
            activeDrawTypeOption={activeDrawTypeOption}
            mode={DrawingActionType.Erase}
            iconBefore="mapSubtract"
            data-intercom-target="erase-button"
          >
            Erase
          </DrawModeButton>
          <Divider orientation="vertical" color="#999999" mx={2} />
          <ClearTaskButton />
          <FlexFiller />
          <UserFeatureFlag featureName="naiveAutoSaving">
            <AutosaveIndicator />
          </UserFeatureFlag>
        </BlockableBox>
      );
    case CampaignType.Detection:
      return (
        <BlockableBox preventInteractions={disableDrawing} display="flex" p={3} zIndex="1">
          <DrawModeButton
            activeDrawTypeOption={activeDrawTypeOption}
            mode={DrawingActionType.DrawAbove}
            iconBefore="mapDraw"
            data-intercom-target="draw-button"
          >
            Draw
          </DrawModeButton>
          <DrawModeButton
            activeDrawTypeOption={activeDrawTypeOption}
            mode={DrawingActionType.Erase}
            iconBefore="mapSubtract"
            data-intercom-target="erase-button"
          >
            Erase
          </DrawModeButton>
          <UserFeatureFlag featureName="naiveAutoSaving">
            <Divider orientation="vertical" color="#999999" mx={2} />
            <ClearTaskButton />
            <FlexFiller />
            <AutosaveIndicator />
          </UserFeatureFlag>
        </BlockableBox>
      );
    case CampaignType.Classification:
      return <></>;
  }
};

export default Toolbar;
