import { Option } from "fp-ts/es6/Option";

import { foldOption } from "lib";
import { DrawingActionType } from "types";

const createCursor = (isLabeling: boolean, activeDrawingTypeOption: Option<DrawingActionType>) =>
  isLabeling &&
  foldOption(
    activeDrawingTypeOption,
    () => "default",
    (activeDrawType) => (activeDrawType !== DrawingActionType.NoOp ? "crosshair" : "default")
  );

export default createCursor;
