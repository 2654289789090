import axios, { AxiosResponse } from "axios";
import { UUID } from "io-ts-types";

import {
  HitlJobCreate,
  HitlJob,
  hitlJobCodec,
  hitlJobCreateCodec,
  HITLJobStatus,
} from "datamodel/hitlJob";
import getBaseRequestConfig from "./helpers/getBaseRequestConfig";
import { decodeAxiosResponse } from "./transformers/base";
import { PaginatedQueryParams, PaginatedResponse } from "types";
import { decodePaginated } from "./transformers/paginated";

export interface HitlJobListQP extends PaginatedQueryParams {
  owner?: string;
  status?: HITLJobStatus;
  projectId?: string;
  campaignId?: string;
  version?: number;
}

export const postHitlJob = async (hitlJobCreate: HitlJobCreate): Promise<AxiosResponse<HitlJob>> =>
  decodeAxiosResponse(hitlJobCodec)(
    await axios.post(
      `/hitl-jobs/`,
      hitlJobCreateCodec.encode(hitlJobCreate),
      getBaseRequestConfig()
    )
  );

export const getHitlJob = async (jobId: UUID): Promise<AxiosResponse<HitlJob>> =>
  decodeAxiosResponse(hitlJobCodec)(await axios.get(`/hitl-jobs/${jobId}`, getBaseRequestConfig()));

export const listHitlJobs = async (
  params: HitlJobListQP
): Promise<AxiosResponse<PaginatedResponse<HitlJob>>> =>
  decodePaginated(hitlJobCodec)(
    await axios.get("/hitl-jobs", {
      ...getBaseRequestConfig(),
      params,
    })
  );
