import React, { useMemo } from "react";
import { FieldArray, FieldArrayRenderProps } from "react-final-form-arrays";
import { Box } from "@blasterjs/core";
import { Text } from "@chakra-ui/react";
import { Option } from "fp-ts/es6/Option";

import ClassFieldArray from "./ClassFieldArray";
import FormSectionHeading from "components/FormSectionHeading";
import { FieldGroupValue, DEFAULTS } from "../index";
import getClassGroupInitVal from "../helpers/getClassGroupInitVal";
import HitlLimitBanner from "components/Hitl/LimitBanner";
import useUserFeatureFlag from "hooks/useUserFeatureFlag";
import { CampaignType } from "datamodel/campaign";

const NonClassification = ({ projectTypeOption }: { projectTypeOption: Option<CampaignType> }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const classGroupInitVal = useMemo(() => getClassGroupInitVal(projectTypeOption), []);
  const hitlEnabled = useUserFeatureFlag("hitl");

  return (
    <>
      <FormSectionHeading
        title="Choose your classes"
        description="Pick a name and color for each of the classes to be identified in this campaign."
      />
      {hitlEnabled && <HitlLimitBanner message="one class definition is supported per campaign." />}
      <Box display="flex" flexDirection="row" mt={3} mb={1}>
        <Box width="280px" mr={2}>
          <Text fontSize="1.6rem" fontWeight="bold">
            Name
          </Text>
        </Box>
        <Box width="60px">
          <Text fontSize="1.6rem" fontWeight="bold">
            Color
          </Text>
        </Box>
        <Box display="flex" flex={1} flexDirection="column" maxWidth={"10px"}></Box>
      </Box>
      {/* sadly, this is necessary to avoid bad typings from react-final-form-arrays
                // @ts-ignore */}
      <FieldArray name="nonclassification" initialValue={classGroupInitVal}>
        {(fieldArrayProps: FieldArrayRenderProps<FieldGroupValue, HTMLElement>) => (
          <ClassFieldArray
            fieldArrayProps={fieldArrayProps}
            maxClassCount={DEFAULTS.maxClassCount}
          />
        )}
      </FieldArray>
    </>
  );
};

export default NonClassification;
