import React, { useMemo, useRef, useState } from "react";
import ReactMapGL, { InteractiveMap } from "react-map-gl";
import center from "@turf/center";
import bbox from "@turf/bbox";
import WebMercatorViewport from "viewport-mercator-project";
import { Option, some, none, fromNullable, map, isSome, flatten } from "fp-ts/es6/Option";
import styled from "styled-components";
import { pipe } from "fp-ts/es6/pipeable";
import { findFirst } from "fp-ts/es6/Array";

import { foldOption, noEl, seqOption } from "lib";
import { basemaps, getDefaultBasemap } from "common/basemaps";
import { Project } from "datamodel/project";
import { Box } from "@chakra-ui/react";
import { Tile } from "datamodel/tile";

type Props = {
  project: Project;
};

const MapImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const MiniMapBox = styled(Box)`
  transform: scale(0.15);
  transform-origin: bottom right;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
`;

const PreviewMaps = ({ project }: Props) => {
  const imageryMapRef = useRef<InteractiveMap>(null);
  const miniMapRef = useRef<InteractiveMap>(null);

  const [imageryMapDataUrlOption, setImageryMapDataUrlOption] = useState<Option<string>>(none);
  const [miniMapDataUrlOption, setMiniMapDataUrlOption] = useState<Option<string>>(none);

  const viewportOption = useMemo(
    () =>
      flatten(
        pipe(
          project.aoi,
          map((aoi) => {
            const bound = bbox(aoi);
            const centroid = center(aoi);

            const { zoom }: { zoom: number } = new WebMercatorViewport({
              width: 316,
              height: 300,
            }).fitBounds(
              [
                [bound[0], bound[1]],
                [bound[2], bound[3]],
              ],
              {
                padding: 25,
                offset: [0, 0],
              }
            );

            return fromNullable(
              centroid.geometry
                ? {
                    width: "auto",
                    height: "100%",
                    latitude: centroid.geometry.coordinates[1],
                    longitude: centroid.geometry.coordinates[0],
                    zoom: zoom + 1.5,
                    pitch: 0,
                    bearing: 0,
                    maxZoom: 24,
                    minZoom: 0,
                  }
                : null
            );
          })
        )
      ),
    [project]
  );

  const minimapViewportOption = useMemo(
    () =>
      pipe(
        viewportOption,
        map((v) => ({
          ...v,
          zoom: 2,
        }))
      ),
    [viewportOption]
  );

  const mapStyleOption = useMemo(() => {
    const tms = findFirst((tms: Tile) => tms.default)(project.tileLayers);
    return pipe(
      tms,
      map((tms) => ({
        version: 8,
        sources: {
          projectTile: {
            type: "raster",
            tiles: [tms.zxyUrl],
            tileSize: 256,
            scheme: "xyz",
          },
        },
        layers: [
          {
            id: "projectTile",
            type: "raster",
            source: "projectTile",
            fadeDuration: 0,
          },
        ],
      }))
    );
  }, [project]);

  const minimapStyleOption = useMemo(
    () =>
      pipe(
        project.aoi,
        map((aoi) => {
          const defaultBasemap = getDefaultBasemap();
          const basemapDef = Object.values(basemaps.layers).find(
            (def) => def.id === "light_no_labels"
          );
          const url = (basemapDef && basemapDef.url) || defaultBasemap.url;
          const tiles = ["1", "2", "3"].map((d) => url.replace("{s}", d));
          const centroid = center(aoi);
          return {
            version: 8,
            sources: {
              baseMapSource: {
                type: "raster",
                tiles,
                tileSize: 256,
                scheme: "xyz",
              },
              projectCentroid: {
                type: "geojson",
                data: centroid,
              },
            },
            layers: [
              {
                id: "baseMap",
                type: "raster",
                source: "baseMapSource",
              },
              {
                id: "projectCentroid",
                type: "circle",
                source: "projectCentroid",
                paint: {
                  "circle-radius": 12,
                },
              },
            ],
          };
        })
      ),
    [project]
  );

  const onImageryMapLoad = () => {
    const map = imageryMapRef.current && imageryMapRef.current.getMap();
    if (map) {
      map.once("idle", () => setImageryMapDataUrlOption(some(map.getCanvas().toDataURL())));
    }
  };

  const onMiniMapLoad = () => {
    const map = miniMapRef.current && miniMapRef.current.getMap();
    if (map) {
      map.once("idle", () => setMiniMapDataUrlOption(some(map.getCanvas().toDataURL())));
    }
  };

  return (
    <Box height="236px" width="236px" position="relative" bg="gray.100" pointerEvents="none">
      {foldOption(
        imageryMapDataUrlOption,
        () =>
          foldOption(seqOption(viewportOption, mapStyleOption), noEl, ([viewport, mapStyle]) => (
            <ReactMapGL
              ref={imageryMapRef}
              {...viewport}
              mapStyle={mapStyle}
              getCursor={() => "default"}
              onLoad={() => onImageryMapLoad()}
            />
          )),
        (imageryMapDataUrl) => (
          <>
            <MapImage src={imageryMapDataUrl} />
            <MiniMapBox
              opacity={isSome(miniMapDataUrlOption) ? "1" : "0"}
              position="absolute"
              right="0px"
              bottom="0px"
              width="600px"
              height="400px"
              bg="none"
              borderRadius="base"
              p="0"
            >
              {foldOption(
                miniMapDataUrlOption,
                () =>
                  foldOption(
                    seqOption(minimapViewportOption, minimapStyleOption),
                    noEl,
                    ([minimapViewport, minimapStyle]) => (
                      <ReactMapGL
                        ref={miniMapRef}
                        {...minimapViewport}
                        mapStyle={minimapStyle}
                        getCursor={() => "default"}
                        onLoad={() => onMiniMapLoad()}
                      ></ReactMapGL>
                    )
                  ),
                (miniMapDataUrl) => (
                  <MapImage src={miniMapDataUrl} />
                )
              )}
            </MiniMapBox>
          </>
        )
      )}
    </Box>
  );
};

export default PreviewMaps;
