import React from "react";
import { fromNullable } from "fp-ts/es6/Option";
import { getCampaignActions } from "http/campaign";
import { AccessControlContextProvider } from "./AccessControlContext";
import { UUID } from "io-ts-types";
import { useQuery } from "react-query";

type Props = { projectId?: string; campaignId: UUID; children: React.ReactNode };

const AccessControlProvider = ({ campaignId, children }: Props) => {
  const { data: actions } = useQuery(["access-control-provider", campaignId], () => {
    if (campaignId) return getCampaignActions(campaignId).then((resp) => resp.data);
  });

  return (
    <AccessControlContextProvider value={fromNullable(actions)}>
      {children}
    </AccessControlContextProvider>
  );
};

export default AccessControlProvider;
