import React from "react";
import { useMutation, useQuery } from "react-query/react";
import {
  Box,
  HStack,
  Icon,
  VStack,
  WrapItem,
  Text,
  Flex,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import { pipe } from "fp-ts/es6/function";
import { filterMap, getOrElse, none } from "fp-ts/es6/Option";
import GButton from "components/GButton";
import { Task, TaskStatus } from "datamodel/task";
import { getProject } from "http/project";
import getLatestTaskAction from "pages/SegmentationTask/helpers/getLatestTaskAction";
import { putTaskStatus } from "http/task";
import { Campaign } from "datamodel/campaign";
import { FaFlag } from "react-icons/fa";
import IssueCardMenu from "./IssueCardMenu";
import IssueCardMap from "./IssueCardMap";

type Props = {
  campaign: Campaign;
  task: Task;
  invalidate: () => void;
};

const IssueCard = ({ task, campaign, invalidate }: Props) => {
  const latestTaskActionOption = getLatestTaskAction(task);
  const toast = useToast();
  const { data: project } = useQuery(["projects", task.properties.projectId], () =>
    getProject(task.properties.projectId).then((resp) => resp.data)
  );

  const { mutateAsync: unflagTaskMutation, ...unflagTaskInfo } = useMutation(
    () =>
      putTaskStatus(task.properties.projectId, task.id, {
        nextStatus: TaskStatus.Unlabeled,
        note: none,
      }),
    {
      onError: () => {
        toast({
          title: "An error occurred.",
          description: "Unable to send this task back to the queue",
          status: "error",
          isClosable: true,
        });
      },
      onSuccess: invalidate,
    }
  );

  const { mutateAsync: deleteTaskMutation, ...deleteTaskInfo } = useMutation(
    () =>
      putTaskStatus(task.properties.projectId, task.id, {
        nextStatus: TaskStatus.Invalid,
        note: none,
      }),
    {
      onError: () => {
        toast({
          title: "An error occurred.",
          description: "Unable to delete this task",
          status: "error",
          isClosable: true,
        });
      },
      onSuccess: invalidate,
    }
  );

  return (
    <WrapItem>
      <Flex
        direction="column"
        border="1px solid"
        borderColor="gray.100"
        shadow="base"
        position="relative"
        height="100%"
      >
        <VStack width="374px" flex="1">
          <Box height="308px" background="gray.200" alignSelf="stretch" position="relative">
            {!!project && <IssueCardMap task={task} project={project} />}
          </Box>
          <VStack alignSelf="stretch" p={4} flex="1" spacing={6}>
            <HStack alignSelf="stretch" alignItems="flex-start" flex="1">
              <VStack flex="1" alignItems="flex-start" spacing={3}>
                <Flex direction="row" fontWeight="semibold" alignItems="center">
                  <Icon as={FaFlag} color="orange.500" size="14px" mr={3} />
                  {campaign.name}
                  <Text as="span" mx={2} fontWeight="normal">
                    /
                  </Text>
                  {project ? <>{project.name}</> : <Skeleton width="100px" />}
                </Flex>
                <Text>
                  {pipe(
                    latestTaskActionOption,
                    filterMap((action) => action.note),
                    getOrElse(() => "This task was flagged")
                  )}
                </Text>
              </VStack>
              <IssueCardMenu task={task} />
            </HStack>
            <HStack alignSelf="stretch" spacing={6}>
              <GButton
                colorScheme="#fff"
                flex="1"
                isLoading={unflagTaskInfo.isLoading}
                disabled={deleteTaskInfo.isLoading}
                onClick={() => unflagTaskMutation()}
              >
                Remove flag
              </GButton>
              <GButton
                colorScheme="danger"
                flex="1"
                isLoading={deleteTaskInfo.isLoading}
                disabled={unflagTaskInfo.isLoading}
                onClick={() => deleteTaskMutation()}
              >
                Delete task
              </GButton>
            </HStack>
          </VStack>
        </VStack>
      </Flex>
    </WrapItem>
  );
};

export default IssueCard;
