import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { Select } from "@blasterjs/core";

export const LabelBox = styled.div`
  position: absolute;
  left: 0;
  border: none;
  border-radius: 9999px;
  padding: 12px 1.8rem;
  line-height: 16px;
  padding-right: 0.5rem;
  white-space: nowrap;
  background: none;
  pointer-events: none;
  z-index: 1;
`;

export const StyledSelect = styled(Select)`
  border: none;
  border-left: none;
  overflow: hidden;
  border-radius: 9999px;
  color: ${themeGet("colors.textLight")};
  &:focus-within {
    border-color: none;
    outline: 0;
  }
  line-height: 16px;
  select {
    padding: 12px 4.2rem 12px 1.8rem;
    padding-left: ${(props) => props.labelSize}px;
  }
  svg.menu-icon {
    margin: 0 1.8rem 0 0.8rem;
  }
  font-weight: bold;
`;
