import React from "react";
import { Option } from "fp-ts/es6/Option";
import { Flex, Box, Divider, Stack, Text } from "@chakra-ui/react";

import { Campaign } from "datamodel/campaign";
import AccessControlProvider from "components/AccessControl/AccessControlProvider";
import { ACRActionType } from "datamodel/permissions";
import TaskMap from "../../TaskMap/Map";
import { UserFeatureFlag } from "components/FeatureFlags";
import AccessLimiter from "components/AccessControl/AccessLimiter";
import config from "config";
import ProjectProgressItem from "./ProjectProgressItem";
import { TaskStatus } from "datamodel/task";
import ClassSummary from "../../ClassSummary";
import TaskFlags from "./TaskFlags";
import useLabelClassSummary from "hooks/useLabelClassSummary/useLabelClassSummary";
import { Project } from "datamodel/project";
import { HitlJob } from "datamodel/hitlJob";

interface Props {
  campaign: Campaign;
  project: Project | undefined;
  jobOpt: Option<HitlJob>;
  dirty: boolean;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const Overview = ({ campaign, project, jobOpt, dirty, setDirty }: Props) => {
  const { summary, loadingSummary } = useLabelClassSummary(campaign, project);

  return (
    <Box
      bg="white"
      borderTop="1px solid"
      borderColor="gray.100"
      flex={1}
      display="flex"
      flexDirection="column"
    >
      <Box
        m="0 auto"
        maxWidth="1172px"
        alignItems={"flex-start"}
        width="100%"
        display="flex"
        flex="1"
        px={2}
        pt="26px"
        pb={4}
      >
        <Flex
          maxWidth={"100%"}
          p={0}
          flex={1}
          alignSelf="stretch"
          direction="column"
          mr="38px"
          alignItems="stretch"
          justifyContent="flex-start"
        >
          {!!project && (
            <TaskMap
              campaign={campaign}
              project={project}
              jobOpt={jobOpt}
              dirty={dirty}
              setDirty={setDirty}
            />
          )}
        </Flex>
        <Stack
          direction="column"
          divider={<Divider color="#f7f8f7" size="2px" height="2px" />}
          spacing={6}
          width="350px"
        >
          <Stack direction="column" data-intercom-target="project-progress" spacing={8}>
            <Text textStyle="campaignOverviewSectionHeading">Progress</Text>
            <Stack direction="column" spacing={2}>
              {!!project && (
                <ProjectProgressItem
                  project={project}
                  label="labeled"
                  statuses={[
                    TaskStatus.Labeled,
                    TaskStatus.Flagged,
                    TaskStatus.ValidationInProgress,
                    TaskStatus.Validated,
                  ]}
                  excludeStatuses={[TaskStatus.Invalid, TaskStatus.Split]}
                />
              )}
              <UserFeatureFlag featureName="validation">
                <AccessControlProvider campaignId={campaign.id}>
                  <AccessLimiter requiredActions={[[ACRActionType.Validate]]}>
                    {(isLoading) => (
                      <Box mt={1}>
                        {!!project && (
                          <ProjectProgressItem
                            project={project}
                            label="validated"
                            statuses={[TaskStatus.Validated]}
                            excludeStatuses={[TaskStatus.Invalid, TaskStatus.Split]}
                            showSkeleton={isLoading}
                          />
                        )}
                      </Box>
                    )}
                  </AccessLimiter>
                </AccessControlProvider>
              </UserFeatureFlag>
              {!!project && config.featureFlags.taskFlagging && <TaskFlags project={project} />}
            </Stack>
          </Stack>
          <Stack direction="column" data-intercom-target="project-classifications" spacing={8}>
            <Text textStyle="campaignOverviewSectionHeading">Classifications</Text>
            <ClassSummary type={campaign.campaignType} summary={summary} loading={loadingSummary} />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Overview;
