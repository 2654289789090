import React from "react";
import { Icon } from "@blasterjs/core";
import { useQuery } from "react-query";

import fetchRandomTask from "pages/helpers/fetchRandomTask";
import fetchHitlTask from "pages/helpers/fetchHitlTask";
import { TaskURLActionType } from "types";
import AccessLimiter from "components/AccessControl/AccessLimiter";
import { ACRActionType } from "datamodel/permissions";
import AccessControlProvider from "components/AccessControl/AccessControlProvider";
import GButton, { GButtonProps } from "components/GButton";
import { isNone } from "fp-ts/es6/Option";
import { foldOption, noop } from "lib";
import { useHistory } from "react-router";
import urlForRandomTask from "pages/helpers/urlForRandomTask";
import { queryClient } from "App";
import { Project } from "datamodel/project";
import { Campaign, CampaignType } from "datamodel/campaign";

type Props = {
  campaign: Campaign;
  project: Project;
  hasHitlTask: boolean;
} & Omit<GButtonProps, "children">;

const UnauthedValidateButton = ({ project, campaign, hasHitlTask, ...rest }: Props) => {
  const history = useHistory();

  const { data: nextTaskOption, isLoading } = useQuery(
    ["campaign-validate-next", project.id],
    () => {
      const params = { projectId: project.id };
      return hasHitlTask && campaign.campaignType !== CampaignType.Classification
        ? fetchHitlTask(TaskURLActionType.Validate, params)
        : fetchRandomTask(TaskURLActionType.Validate, params);
    },
    {
      refetchInterval: 30000,
      onSuccess: (taskOption) => {
        foldOption(taskOption, noop, (task) => {
          queryClient.setQueryData(["task", task.id], task);
        });
      },
    }
  );

  const onClick = () => {
    nextTaskOption &&
      foldOption(nextTaskOption, noop, (task) =>
        history.push(
          urlForRandomTask(task.properties.projectId, task.id, TaskURLActionType.Validate, {
            projectId: task.properties.projectId,
          })
        )
      );
  };

  return (
    <GButton
      data-intercom-target="label-button"
      ml={1}
      disabled={!nextTaskOption || isNone(nextTaskOption)}
      isLoading={isLoading}
      onClick={onClick}
      {...rest}
    >
      <Icon name="checkCircle" mr={1} />
      Validate
    </GButton>
  );
};

const ValidateButton = ({ project, campaign, hasHitlTask, ...rest }: Props) => (
  <AccessControlProvider campaignId={campaign.id}>
    <AccessLimiter requiredActions={[[ACRActionType.Validate]]}>
      <UnauthedValidateButton
        project={project}
        hasHitlTask={hasHitlTask}
        campaign={campaign}
        {...rest}
      />
    </AccessLimiter>
  </AccessControlProvider>
);

export default ValidateButton;
