import axios from "axios";
import { none } from "fp-ts/es6/Option";
import { UUID } from "io-ts-types";
import create from "zustand";
import { combine, devtools } from "zustand/middleware";
import { UploadSequence, UploadStep } from "./types";
import { v4 as uuid } from "uuid";

const BATCH_SIZE = 10;

type SetterA = (prev: UploadSequence[]) => UploadSequence[];
type Setter = (prev: UploadSequence | undefined) => UploadSequence;
const initialState = { sequences: [] as UploadSequence[] };

const useUploader = create(
  devtools(
    combine(initialState, (set) => ({
      setAll: (setter: SetterA) => set((state) => ({ sequences: setter(state.sequences) })),
      setOne: (sequenceId: string, setter: Setter) =>
        set((state) => {
          const index = state.sequences.findIndex((s) => s.id === sequenceId);
          const one = state.sequences[index];
          return { sequences: Object.assign([], state.sequences, { [index]: setter(one) }) };
        }),
      startNext: () =>
        set((state) => {
          const nextSequenceIndex = state.sequences.findIndex(
            (s) => s.uploadStep === UploadStep.WAITING
          );
          const nextSequence = state.sequences[nextSequenceIndex];
          return {
            sequences: nextSequence
              ? Object.assign([], state.sequences, {
                  [nextSequenceIndex]: { ...nextSequence, uploadStep: UploadStep.START },
                })
              : state.sequences,
          };
        }),
      deleteOne: (sequenceId: string) =>
        set((state) => ({ sequences: state.sequences.filter((seq) => seq.id !== sequenceId) })),
      addByFiles: (campaignId: UUID, files: File[]) => {
        const seqs: UploadSequence[] = files.map((file, index) => ({
          id: uuid(),
          campaignId,
          uploadProgress: 0,
          isSingleBand: false,
          file,
          upload: none,
          error: none,
          uploadStep: index < BATCH_SIZE ? UploadStep.START : UploadStep.WAITING,
          cancelTokenSource: axios.CancelToken.source(),
        }));
        return set((state) => ({
          sequences: [...state.sequences, ...seqs],
        }));
      },
    }))
  )
);

export default useUploader;
