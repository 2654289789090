import { Task, TaskStatus } from "datamodel/task";
import { Dispatch } from "redux";
import { putTaskStatus, getTask, deleteTaskLabels, postTaskLabels } from "http/task";
import {
  createSetLastError,
  createSetExistingAnnotations,
  createSetTask,
} from "state/ui-segmentation/actions";
import { Error, SemanticLabelFeatureCollection, SemanticLabelFeature } from "types";
import { some, fromNullable, none } from "fp-ts/es6/Option";
import { LabelCreateCollection, LabelCreate } from "datamodel/label";
import { AxiosResponse } from "axios";

const flagTask = async (
  task: Task,
  reason: string,
  displayBuffer: SemanticLabelFeatureCollection,
  dispatch: Dispatch
) => {
  // We always want to return a promise even if we choose not to send a request
  // so we initialize with a resolved promise
  let requestPromise = Promise.resolve();

  if (
    [TaskStatus.LabelingInProgress, TaskStatus.ValidationInProgress].includes(
      task.properties.status
    )
  ) {
    await deleteTaskLabels(task);

    // Create new annotations
    // Transform the annotation properties
    const taskLabelCreateCollection: LabelCreateCollection = {
      nextStatus: task.properties.status,
      ...displayBuffer,
      features: displayBuffer.features.map(
        (f: SemanticLabelFeature): LabelCreate => ({
          ...f,
          properties: {
            classes: [f.properties.label],
            hitlVersionId: none,
          },
        })
      ),
    };

    const newLabels = (await postTaskLabels(task, taskLabelCreateCollection)).data;

    dispatch(createSetExistingAnnotations(newLabels));

    requestPromise = putTaskStatus(task.properties.projectId, task.id, {
      nextStatus: TaskStatus.Flagged,
      note: some(reason),
    })
      .then(() => getTask(task.properties.projectId, task.id))
      .then((resp: AxiosResponse<Task>) => {
        const taskOption = fromNullable(resp.data);
        dispatch(createSetTask(taskOption));
        return Promise.resolve();
      });

    requestPromise.catch((err: Error) =>
      dispatch(createSetLastError("Failed to update task when trying to flag", err))
    );
  }

  return requestPromise;
};

export default flagTask;
